export const GET_TYPE = 'GET_TYPE';
export const GET_TOTAL_PAGES = 'GET_TOTAL_PAGES';
export const GET_PAGE_NUMBER = 'GET_PAGE_NUMBER';
export const GET_PER_PAGE = 'GET_PER_PAGE';
export const GET_CRITERIA = 'GET_CRITERIA';
export const GET_STATUS = 'GET_STATUS';
export const GET_STATUS_FLAG = 'GET_STATUS_FLAG';
export const GET_TYPE_FLAG = 'GET_TYPE_FLAG';
export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_OPPORTUNITY_TYPE = 'GET_OPPORTUNITY_TYPE';
