/* eslint-disable react/react-in-jsx-scope */
import { Stack } from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../components/button/CustomButton';
import SearchIcon from '../../../components/svgComponents/searchIcon';
import { ContainerStyle, CustomTitle, GridStyle, InputStyle } from './UniversityListToolbar.styles';
import { UniversityListToolbarProps } from './UniversityListToolbar.types';

export default function UniversityListToolbar({ onOpenAddDialog, filterKeyword, onFilterKeyword }: UniversityListToolbarProps) {
  const { t } = useTranslation();
  return (
    <ContainerStyle>
      <GridStyle>
        <CustomTitle>{t('university.title')}</CustomTitle>
        <Stack direction="column" spacing={4}>
          <CustomButton label={t('university.add')} onClick={onOpenAddDialog} />
          <InputStyle
            value={filterKeyword}
            placeholder={t('common.search')}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterKeyword(e.target.value)}
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
          />
        </Stack>
      </GridStyle>
    </ContainerStyle>
  );
}
