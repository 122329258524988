/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Stack, CircularProgress, Backdrop, FormControl, InputLabel, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import CustomLoadingButton from '../../button/CustomLoadingButton';
import FormProvider from '../../hook-form/FormProvider';
import RHFTextField from '../../hook-form/RHFTextField';
import useSnackBar from '../../../hooks/useSnackBar';
import { RootState } from '../../../store/rootStore';
import { colors } from '../../../theme/sharedTheme';
import SubscriptionPlans from '../../../models/SubscriptionPlans';
import { CreateSubscriptionPlansSchema } from './create-subscriptionPlan.schema';
import { subscriptionPlansApi } from '../../../store/subscriptionPlans/api/SubscriptionPlans.api';
import { SubscriptionPlansFormProps } from './SubscriptionPlans-form.types';
import { SubscriptionPlansActionTypes } from '../../../store/subscriptionPlans/slice/SubscriptionPlans-slice-types';
import { Editor } from '@tinymce/tinymce-react';
import { toolbar } from '../../../utils';
import './../../tinyMceEditor/TinyMceEditor.css';

const { updateSubscriptionPlan, createSubscriptionPlan } = subscriptionPlansApi.endpoints;

export default function SubscriptionPlanForm({ currentSubscriptionPlan, isUpdate, onClose, isEyeIconClicked }: SubscriptionPlansFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, SubscriptionPlansActionTypes>>();
  const [loading, setLoading] = useState<boolean>(false);

  const defaultValues = useMemo<SubscriptionPlans>(
    () => ({
      title: currentSubscriptionPlan?.title || '',
      description: currentSubscriptionPlan?.description || '',
      price: currentSubscriptionPlan?.price || 0,
    }),
    [currentSubscriptionPlan],
  );

  const methods = useForm<SubscriptionPlans>({
    resolver: yupResolver(CreateSubscriptionPlansSchema),
    defaultValues,
    mode: 'all',
  });

  const { alertSuccess, alertError } = useSnackBar();

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
    clearErrors,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentSubscriptionPlan]);

  useEffect(() => {
    const updatedDefaultValues = {
      title: currentSubscriptionPlan?.title || '',
      description: currentSubscriptionPlan?.description || '',
      price: currentSubscriptionPlan?.price || 0,
    };
    methods.reset(updatedDefaultValues);
  }, [currentSubscriptionPlan, isUpdate]);

  const onSubmit = async () => {
    if (!isUpdate) {
      create();
    } else {
      update();
    }
  };

  const create = async () => {
    try {
      setLoading(true); // Start loading
      const isValid = await trigger();
      if (isValid) {
        await dispatch(createSubscriptionPlan(values));
        reset(defaultValues);
        dispatch(alertSuccess(t('subscriptionPlan.create_success_message')));
        onClose();
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    } finally {
      setLoading(false); // End loading
    }
  };

  const update = async () => {
    try {
      setLoading(true); // Start loading
      const isValid = await trigger();
      if (isValid && currentSubscriptionPlan?.id) {
        const data = {
          title: values.title,
          description: values.description,
          price: values.price,
        };
        await dispatch(updateSubscriptionPlan(currentSubscriptionPlan?.id, data));
        reset(defaultValues);
        dispatch(alertSuccess(t('subscriptionPlan.update_success_message')));
        onClose();
      }
    } catch (err) {
      const error = err as Error; // Type assertion
      if (error.message === 'Master Class Plan already exist') {
        dispatch(alertError(t('subscriptionPlan.title_already_exist')));
      } else {
        dispatch(alertError(t('common.something_wrong_happend')));
      }
      console.error(err);
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <>
      <Backdrop
        sx={{
          color: colors.yellow,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          zIndex: (theme) => theme.zIndex.drawer + 1, // Ensure this value is high enough
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <h2>{t('common.loading')}</h2>
      </Backdrop>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={`artgapi-modal`} container spacing={3}>
          <Grid className="artgapi-plan-form" item xs={12} md={12}>
            <Box sx={{ p: 3 }}>
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <RHFTextField
                    name={'title'}
                    placeholder={t('subscriptionPlan.form_fields.title.placeholder')}
                    label={t('subscriptionPlan.form_fields.title.label')}
                    disabled={isEyeIconClicked}
                  />
                  <RHFTextField
                    name={'price'}
                    placeholder={t('subscriptionPlan.form_fields.price.placeholder')}
                    label={t('subscriptionPlan.form_fields.price.placeholder')}
                    disabled={isEyeIconClicked || !!isUpdate}
                  />
                </Box>
              </Box>
              <Grid item sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 3 }}>
                <FormControl fullWidth>
                  <InputLabel
                    id="description-label"
                    sx={{
                      transform: 'translate(0px, 0px) scale(0.95)',
                      position: 'relative !important',
                      maxWidth: '0',
                      minWidth: '100%',
                      color: 'rgb(171, 171, 171)',
                      marginBottom: '0.5rem',

                      '& .css-to7sdl-MuiFormControl-root': {
                        marginTop: '4px !important',
                      },
                    }}
                  >
                    {t('subscriptionPlan.form_fields.description.label')}
                  </InputLabel>
                  <Controller
                    name="description"
                    control={methods.control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Editor
                          apiKey={'2pnevqcdkli8ted7tuvx3lf1r3o60i279jfi1wpe0kzza6db' || ''}
                          init={{
                            placeholder: t('subscriptionPlan.form_fields.description.placeholder'),
                            toolbar: toolbar,
                            plugins: 'advlist autolink lists image charmap preview anchor pagebreak code visualchars wordcount',
                            fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
                            height: 240,
                            menubar: false,
                            statusbar: false,
                            toolbar_location: 'bottom',
                            content_css: './tinyMceEditor.css',
                          }}
                          value={field.value}
                          onEditorChange={(content) => field.onChange(content)}
                          disabled={isEyeIconClicked}
                        />
                        {/* Display error message if there's an error for countryId */}
                        {error && (
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: '0.75rem',
                              lineHeight: '1.66',
                              letterSpacing: '0.03333em',
                              textAlign: 'left',
                              marginTop: '3px',
                              marginRight: '14px',
                              marginBottom: '0',
                              marginLeft: '14px',
                              color: '#d32f2f',
                            }}
                          >
                            {t(`${error?.message}`)}
                          </Typography>
                        )}
                      </>
                    )}
                  />
                </FormControl>
              </Grid>
              {/* Submit Button */}
              {!isEyeIconClicked && (
                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                  <CustomLoadingButton
                    loading={loading || isSubmitting}
                    label={!isUpdate ? t('interests.create_button_label') : t('interests.update_button_label')}
                    onClick={onSubmit}
                  />
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
