/* eslint-disable react/react-in-jsx-scope */
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddDialogProps } from './add-dialog.types';
import { AddDialogStyle } from './add-dialog.styles';
import CloseIcon from '../../../../svgComponents/closeIcon';
import SubscriptionPlanForm from '../form/SubscriptionPlanForm';
import ViewSubscriptionPlan from '../form/ViewSubscriptionPlan';

export default function AddDialog({ open, onClose, currentSubscriptionPlan, isUpdate, isEyeIconClicked }: AddDialogProps) {
  const { t } = useTranslation();
  return (
    <AddDialogStyle open={open} fullWidth className="artgapi-wrapper">
      <Stack display={'flex'} direction={'row'} justifyContent={'space-between'} padding={2}>
        <Typography variant={'h5'}>
          {isEyeIconClicked ? t('adds.view_plan_title') : isUpdate ? t('adds.update_plan_title') : t('adds.add_plan_title')}
        </Typography>
        <IconButton aria-label="close" color="primary" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      {isEyeIconClicked ? (
        <ViewSubscriptionPlan
          onClose={onClose}
          currentSubscriptionPlan={currentSubscriptionPlan}
          isUpdate={isUpdate}
          isEyeIconClicked={isEyeIconClicked}
        />
      ) : (
        <SubscriptionPlanForm
          onClose={onClose}
          currentSubscriptionPlan={currentSubscriptionPlan}
          isUpdate={isUpdate}
          isEyeIconClicked={isEyeIconClicked}
        />
      )}
    </AddDialogStyle>
  );
}
