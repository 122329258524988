/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * ***** GET STATISTIC
 **/

import { ThunkDispatch } from 'redux-thunk';
import { CONSTANTS, ENDPOINTS, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { RootState } from '../rootStore';
import { SnackBarActionTypes } from '../snackBar/models';
import { SHOW_SNACK_BAR } from '../snackBar/types';
import { DashboardActionsTypes } from './models';
import { GET_STATISTIC_ERROR, GET_STATISTIC_SUCCESS } from './types';

export const getStatistic = () => async (dispatch: ThunkDispatch<RootState, undefined, DashboardActionsTypes | SnackBarActionTypes>) => {
  await sendAsyncRequest(HttpMethod.Get, ENDPOINTS.DASHBOARD, {}, true, {})
    .then((res: any) => {
      dispatch({
        type: GET_STATISTIC_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
        localStorage.clear();
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: CONSTANTS.SESSION_EXPIRED,
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
        dispatch({
          type: GET_STATISTIC_ERROR,
          error: error,
        });
      } else {
        dispatch({
          type: GET_STATISTIC_ERROR,
          error: error,
        });
      }
    });
};
