import { Container, Grid, TextField, Typography, styled } from '@mui/material';

export const ContainerStyle = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: theme.palette.secondary.main,
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '128px',
  padding: theme.spacing(11, 0, 5, 0),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

export const GridStyle = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const InputStyle = styled(TextField)({
  '& .MuiInputBase-root': {
    backgroundColor: '#ffffff',
    borderRadius: '0px',
    input: { border: 'none', paddingLeft: '0px' },
    '& .MuiInputAdornment-root': { marginLeft: 0 },
  },
});

export const CustomTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: '24px',
  fontFamily: 'SFProText',
}));
