import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { TableFactory } from '../table/tableFactory/TableFactory';
import { BugsHeader } from './bugsHeader';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { BugsActionTypes } from '../../store/bugs/models';
import { RootState } from '../../store/rootStore';
import { getBugs } from '../../store/bugs/action';
import { CONSTANTS } from '../../utils';
import { OnTheTable } from '../table/onTheTable/OnTheTable';
import { getPageNumber } from '../../store/pagination/actions';
import { PaginationActionTypes } from '../../store/pagination/models';

/**
 * Component BugsList
 *
 * @component
 *
 * @example
 * return (
 *   <BugsList/>
 * )
 */
const BugsList = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, BugsActionTypes | PaginationActionTypes>>();
  const bugState = useSelector((state: RootState) => state.bug.bugs);
  const loader = useSelector((state: RootState) => state.bug.loading);
  const loaderDetailBug = useSelector((state: RootState) => state.bug.loadingDetailBug);

  const paginationState = useSelector((state: RootState) => state.pagination);
  const { pageNumber, perPage, status: statusPagination } = paginationState;
  const [status, setStatus] = useState(statusPagination);

  useEffect(() => {
    setStatus(statusPagination);
  }, [statusPagination]);

  const selectStatus = (value: string) => {
    if (value === CONSTANTS.OUVERT || value === CONSTANTS.OUVERT_EN) {
      setStatus('open');
    } else if (value === CONSTANTS.FERME || value === CONSTANTS.FERME_EN) {
      setStatus('closed');
    } else {
      setStatus('');
    }
    dispatch(getPageNumber(0));
  };

  useEffect(() => {
    dispatch(getBugs(pageNumber + 1, perPage, status));
  }, [pageNumber, perPage, status]);

  return (
    <>
      <Grid container direction="column" rowSpacing={5}>
        <Grid item>{'ok'}</Grid>
        <Grid item>
          <OnTheTable title={CONSTANTS.BUGS} />
          <TableFactory
            headItems={BugsHeader}
            bodyData={bugState}
            name={CONSTANTS.Bugs}
            handleFilter={selectStatus}
            loading={loader}
            loaderDetailBug={loaderDetailBug}
            statusTable={statusPagination}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BugsList;
