/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { RootState } from '../../rootStore';
import { IFetchHowItWorksSuccessAction, HowItWorksActionTypes } from '../slice/HowItWorks-slice.types';
import { howItWorksActions, howItWorksReducer } from '../slice/HowItWorks-slice';

export const howItWorksApi = {
  reducerPath: 'workUsers',
  reducer: howItWorksReducer,
  endpoints: {
    getWorkUsers: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, HowItWorksActionTypes>) => {
      dispatch(howItWorksActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.HOW_IT_WORKS, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(howItWorksActions.matchFulFilled(responseBody, 'fetch') as IFetchHowItWorksSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(howItWorksActions.matchRejected(error));
        });
    },
    updateWorkUsers: (id: number, data: any) => async (dispatch: ThunkDispatch<RootState, undefined, HowItWorksActionTypes>) => {
      dispatch(howItWorksActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Patch, `${ENDPOINTS.HOW_IT_WORKS}/${id}`, data, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(howItWorksActions.matchFulFilled(responseBody, 'update') as IFetchHowItWorksSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(howItWorksActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },
  },
};
