/* eslint-disable @typescript-eslint/no-explicit-any*/
import React, { ChangeEvent, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ButtonBase, Container, Grid, InputBase, InputLabel, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { theme } from '../../../theme/theme';
import { RootState } from '../../../store/rootStore';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fr } from 'date-fns/locale';
import DateIcon from '../../svgComponents/DateIcon';
import { useFormik } from 'formik';
import { compagniesActionTypes } from '../../../store/campagnes/models';
import { Box } from '@mui/system';
import Company from '../../../models/Company';
import { useUpdateCompany } from '../../../store/campagnes/actions';
import { Stack } from '@mui/material';
import * as yup from 'yup';
import Spinner from '../../spinner';
import CheckIcon from '../../svgComponents/CheckIcon';
import { useTranslation } from 'react-i18next';

interface UpdateDialogProps {
  openUpdateDialog: boolean;
  setOpenUpdateDialog: any;
}
export const UpdateCampagneDialog = (props: UpdateDialogProps) => {
  const compagniesState = useSelector((state: RootState) => state.compagnies.updatedCampany);
  const { loader: updateLoader } = compagniesState;
  const [mediaName, setMediaName] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const { updateCompany } = useUpdateCompany();
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const { openUpdateDialog, setOpenUpdateDialog } = props;
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, compagniesActionTypes>>();
  const foundCampanyState = useSelector((state: RootState) => state.compagnies.foundCampany);
  const { loader: foundCampanyLoader, company } = foundCampanyState;
  const { t } = useTranslation();

  const handleClose = () => {
    setOpenUpdateDialog(!openUpdateDialog);
    formik.resetForm();
  };
  const onUpdateCompany = (data: Company, file?: File | undefined) => {
    dispatch(updateCompany(data, file));
  };
  const schema = yup.object().shape({
    title: yup.string().required('Le nom est requis'),
    startDate: yup
      .date()
      .max(yup.ref('endDate'), 'La date de début doit etre inferieur à la date de fin')
      .nullable()
      .required('La date est obligatoire')
      .nullable(),
    endDate: yup.date().nullable().required('La date est obligatoire'),
    url: yup
      .string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "L'url n'est pas valide",
      )
      .required("L'url est obligatoire"),
  });
  const formik = useFormik({
    validationSchema: schema,
    enableReinitialize: true,
    initialValues: {
      id: company?.id,
      title: company?.title || '',
      status: company?.status || '',
      type: company?.type || '',
      platform: company?.platform || '',
      format: company?.format || '',
      image: company?.image || '',
      video: company?.video || '',
      startDate: company?.startDate || '',
      endDate: company?.endDate || '',
      url: company?.url || '',
    },
    onSubmit: (values) => {
      onUpdateCompany(values, selectedFile);
      handleClose();
    },
  });
  useEffect(() => {
    if (company && company?.image) {
      const imageName = company?.image.split('/');
      setMediaName(imageName[imageName.length - 1]);
    } else if (company && company?.video) {
      const videoName = company?.video.split('/');
      setMediaName(videoName[videoName.length - 1]);
    }
    return () => {};
  }, [company]);
  const checkSelectedMedia = (campagne: any, name: string) => {
    if (!campagne) return '';
    if (campagne.format !== formik.values.format && !selectedFile) return 'Selectioner media';
    return selectedFile?.name ?? name;
  };
  const fileHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files instanceof FileList && fileName === '') {
      const file = event.target.files[0];
      setFileName(file.name);
      setSelectedFile(file);
    }
  };
  if (foundCampanyLoader)
    return (
      <Container sx={{ minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
        <Spinner loading={foundCampanyLoader} />
      </Container>
    );
  else
    return (
      <Dialog
        open={openUpdateDialog}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              maxHeight: 'calc(100vh - 15px)',
              maxWidth: '900px',
              minWidth: '900px',
              borderRadius: 0,
            },
          },
        }}
      >
        <Spinner loading={updateLoader} />
        <DialogActions sx={{ height: '70px', padding: 0, display: 'flex', justifyContent: 'space-between', marginLeft: '32px' }}>
          <Typography
            variant="caption"
            sx={{
              width: '800px',
              fontFamily: 'SFPro-Regular',
              fontSize: '24px',
              textAlign: 'left',
              justifySelf: 'start',
            }}
            data-testid="title"
          >
            {formik.values.title}
          </Typography>
          <ButtonBase
            sx={{
              color: '#a4a4a5',
              display: 'flex',
              width: '62px',
              paddingRight: '20px',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'SFPro-Regular',
              fontSize: '17px',
            }}
            onClick={handleClose}
          >
            {t('common.cancel')}
          </ButtonBase>
          <button
            type="submit"
            form="updateForm"
            className={!updateLoader ? 'isRealsed ' : 'isActive '}
            disabled={company?.status === 'ended' || (selectedFile && selectedFile.size > 40e7)}
            style={{
              opacity: company?.status === 'ended' ? 0.3 : 1,
            }}
          >
            {!updateLoader ? 'Enregistrer' : <CheckIcon />}
          </button>
        </DialogActions>
        <DialogContent sx={{ backgroundColor: theme.palette.secondary.main }}>
          <form onSubmit={formik.handleSubmit} id="updateForm">
            <Grid item sx={{ marginTop: '10px ', display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
              <Typography
                variant="caption"
                sx={{
                  paddingLeft: '30px',
                  fontFamily: 'SFPro-Regular',
                  fontSize: '24px',
                  textAlign: 'left',
                }}
                data-testid="title"
              >
                Type de campagne et plateform
              </Typography>
              <Grid item sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid item sx={{ width: '436px', marginBottom: '24px' }}>
                  <Grid item sx={{ display: 'flex' }}>
                    <InputLabel htmlFor="type">Type</InputLabel>
                  </Grid>
                  <ToggleButtonGroup
                    disabled={company?.status === 'ended'}
                    fullWidth
                    exclusive
                    value={formik.values.type}
                    onChange={(e: React.ChangeEvent<any>) => {
                      formik.handleChange(e);
                    }}
                  >
                    <ToggleButton id="type" sx={{ textTransform: 'none' }} disabled={formik.values.type === '0' ? true : false} value="0">
                      Interstitiel
                    </ToggleButton>
                    <ToggleButton id="type" sx={{ textTransform: 'none' }} disabled={formik.values.type === '1' ? true : false} value="1">
                      Post sponsorisé
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item sx={{ width: '436px' }}>
                  <Grid item sx={{ display: 'flex' }}>
                    <InputLabel htmlFor="plateform">Plateform</InputLabel>
                  </Grid>
                  <ToggleButtonGroup
                    disabled={company?.status === 'ended'}
                    color="standard"
                    fullWidth
                    exclusive
                    value={formik.values.platform}
                    onChange={(e: React.ChangeEvent<any>) => {
                      formik.handleChange(e);
                    }}
                  >
                    <ToggleButton id="platform" sx={{ textTransform: 'none' }} disabled={formik.values.platform === 'ios' ? true : false} value="ios">
                      iOS
                    </ToggleButton>
                    <ToggleButton
                      id="platform"
                      sx={{ textTransform: 'none' }}
                      disabled={formik.values.platform === 'android' ? true : false}
                      value="android"
                    >
                      Android
                    </ToggleButton>
                    <ToggleButton id="platform" sx={{ textTransform: 'none' }} disabled={formik.values.platform === 'all' ? true : false} value="all">
                      iOS &amp; Android
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Grid>

            <Box
              sx={{
                width: '100%',
                height: '1px',
                marginY: '15px',
                backgroundColor: '#e7e7e7',
              }}
            />
            <Grid item sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
              <Typography
                variant="caption"
                sx={{
                  paddingLeft: '30px',
                  fontFamily: 'SFPro-Regular',
                  fontSize: '24px',
                  textAlign: 'left',
                }}
                data-testid="title"
              >
                Format et média
              </Typography>
              <Grid item sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid item sx={{ width: '436px', marginBottom: '24px' }}>
                  <Grid item sx={{ display: 'flex' }}>
                    <InputLabel htmlFor="format">Format</InputLabel>
                  </Grid>
                  <ToggleButtonGroup
                    disabled={company?.status === 'ended'}
                    onClick={() => {
                      setFileName('');
                      setSelectedFile(undefined);
                    }}
                    fullWidth
                    exclusive
                    value={formik.values.format}
                    onChange={(e: React.ChangeEvent<any>) => {
                      formik.handleChange(e);
                    }}
                  >
                    <ToggleButton id="format" sx={{ textTransform: 'none' }} disabled={formik.values.format === 'image' ? true : false} value="image">
                      Photo
                    </ToggleButton>
                    <ToggleButton id="format" sx={{ textTransform: 'none' }} disabled={formik.values.format === 'video' ? true : false} value="video">
                      Vidéo
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item sx={{ display: 'flex', width: '436px' }}>
                  <input
                    disabled={company?.status === 'ended'}
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    type="file"
                    accept={formik.values.format === 'image' ? 'image/png,image/jpeg' : 'video/mp4,video/x-m4v,video/*'}
                    onChange={(event) => fileHandler(event)}
                  />
                  <label htmlFor="contained-button-file">
                    <ButtonBase
                      disabled={company?.status === 'ended'}
                      component="span"
                      sx={{
                        minWidth: '436px',
                        height: '46px',
                        fontFamily: 'SFPro-Regular',
                        fontSize: '17px',
                        backgroundColor: '#ffffff',
                        color: theme.palette.primary.main,
                        textAlign: 'center',
                        justifySelf: 'end',
                        border: 'solid 1px #e7e7e7',

                        '&:hover': {
                          backgroundColor: '#e7e7e7',
                        },
                      }}
                    >
                      {checkSelectedMedia(company, mediaName)}
                    </ButtonBase>
                    {selectedFile && selectedFile.size > 40e7 && (
                      <Typography
                        variant="caption"
                        sx={{
                          color: '#ff453a',
                          marginBottom: '7px',
                          width: '170px',
                          height: '29px',
                          fontFamily: 'SFPro-Regular',
                          fontSize: '12px',
                          textAlign: 'left',
                        }}
                        data-testid="title"
                      >
                        Fichier trop large
                      </Typography>
                    )}
                  </label>
                </Grid>
              </Grid>
            </Grid>
            <Box
              sx={{
                width: '100%',
                height: '1px',
                marginY: '15px',
                backgroundColor: '#e7e7e7',
              }}
            />
            <Grid item sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
              <Typography
                variant="caption"
                sx={{
                  paddingLeft: '30px',
                  fontFamily: 'SFPro-Regular',
                  fontSize: '24px',
                  textAlign: 'left',
                }}
                data-testid="title"
              >
                Période
              </Typography>
              <Grid item sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Grid item sx={{ display: 'flex', flexDirection: 'column', marginRight: '16px' }}>
                    <InputLabel htmlFor="startDate">Du</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                      <DatePicker
                        disabled={company?.status === 'ended'}
                        disablePast
                        inputFormat="dd/MM/yyyy"
                        components={{ OpenPickerIcon: DateIcon }}
                        value={formik.values.startDate}
                        onChange={(value) => {
                          formik.setFieldValue('startDate', value);
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Stack
                            direction="row"
                            alignItems="center"
                            p={2}
                            sx={{
                              width: 'fit-content',
                              border: 'solid 1px #e7e7e7',
                              backgroundColor: '#ffffff',
                              input: {
                                border: 'none',
                              },
                            }}
                          >
                            <input
                              style={{ border: 'none', backgroundColor: '#FFFFFF', height: '20px', fontSize: '17px', width: '134px' }}
                              ref={inputRef}
                              {...inputProps}
                              disabled
                            />
                            {InputProps?.endAdornment}
                          </Stack>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item sx={{ display: 'flex', flexDirection: 'column' }}>
                    <InputLabel htmlFor="endDate">Au</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                      <DatePicker
                        disabled={company?.status === 'ended'}
                        disablePast
                        inputFormat="dd/MM/yyyy"
                        components={{ OpenPickerIcon: DateIcon }}
                        value={formik.values.endDate}
                        onChange={(value) => {
                          formik.setFieldTouched('endDate');
                          formik.setFieldValue('endDate', value);
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Stack
                            direction="row"
                            alignItems="center"
                            p={2}
                            sx={{
                              width: 'fit-content',
                              border: 'solid 1px #e7e7e7',
                              backgroundColor: '#ffffff',
                              input: {
                                border: 'none',
                              },
                            }}
                          >
                            <input
                              style={{ border: 'none', backgroundColor: '#FFFFFF', height: '20px', fontSize: '17px', width: '134px' }}
                              ref={inputRef}
                              {...inputProps}
                              disabled
                            />
                            {InputProps?.endAdornment}
                          </Stack>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  {formik.touched.startDate && formik.errors.startDate && (
                    <Typography
                      variant="caption"
                      sx={{
                        color: '#ff453a',
                        marginBottom: '7px',
                        width: '100%',
                        height: '29px',
                        fontFamily: 'SFPro-Regular',
                        fontSize: '12px',
                        textAlign: 'left',
                      }}
                      data-testid="startDate"
                    >
                      {formik.errors.startDate}
                    </Typography>
                  )}
                  {formik.touched.endDate && formik.errors.endDate && (
                    <Typography
                      variant="caption"
                      sx={{
                        color: '#ff453a',
                        width: '100%',
                        marginBottom: '7px',
                        fontFamily: 'SFPro-Regular',
                        fontSize: '12px',
                        textAlign: 'left',
                      }}
                      data-testid="endDate"
                    >
                      {formik.errors.endDate}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Box
              sx={{
                width: '100%',
                height: '1px',
                marginY: '15px',
                backgroundColor: '#e7e7e7',
              }}
            />
            <Grid item sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
              <Grid item sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  variant="caption"
                  sx={{
                    paddingLeft: '30px',
                    fontFamily: 'SFPro-Regular',
                    fontSize: '24px',
                    textAlign: 'left',
                  }}
                  data-testid="title"
                >
                  Url à promouvoir
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: '#a4a4a5',
                    width: '400px',
                    paddingLeft: '30px',
                    fontFamily: 'SFPro-Regular',
                    fontSize: '14px',
                    textAlign: 'left',
                  }}
                  data-testid="title"
                >
                  Choisissez l&#39;adresse du site web vers lequel vous souhaitez rediriger les visiteurs.
                </Typography>
              </Grid>
              <Grid item sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid item sx={{ width: '436px' }}>
                  <Grid item sx={{ display: 'flex' }}>
                    <InputLabel htmlFor="url">Url</InputLabel>
                  </Grid>
                  <InputBase
                    disabled={company?.status === 'ended'}
                    id="url"
                    sx={{ width: '100%' }}
                    placeholder="www.urlapromouvoir.com"
                    value={formik.values.url}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.errors.url && formik.touched.url}
                  />
                </Grid>
                {formik.touched.url && formik.errors.url && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#ff453a',
                      marginBottom: '7px',
                      width: '170px',
                      height: '29px',
                      fontFamily: 'SFPro-Regular',
                      fontSize: '12px',
                      textAlign: 'left',
                    }}
                    data-testid="title"
                  >
                    {formik.errors.url}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    );
};
export default UpdateCampagneDialog;
