export const university = {
  title: 'Universités',
  name: 'Nom',
  address: 'Adresse',
  phone_number: 'Numéro de téléphone',
  email: 'Email',
  add: 'Ajouter une université',
  about_section_title: 'À propos',
  users_section_title: 'Utilisateurs',
  university_details_title: "Détails de l'université",
  create_button_label: 'Créer',
  update_button_label: 'Modifier les changements',
  add_university_title: 'Ajouter une nouvelle université',
  update_university_title: 'Modifier une université',
  no_users: 'Aucun utilisateur trouvé dans cette université',
  no_data: 'Aucune université trouvée',
  create_success_message: "L'université a été créée avec succès",
  update_success_message: "L'université a été modifiée avec succès",
  something_wrong_happend: "Une erreur s'est produite",
  confirm_delete_title: 'Confirmer la suppression',
  confirm_delete_message: 'Êtes-vous sûr de vouloir supprimer cette université?',
  delete_success_message: "L'université a été supprimée avec succès",
  users_filter: {
    all: 'Tous',
    students: 'Étudiants',
    teachers: 'Enseignants',
  },
  users_list: {
    first_name: 'Prénom',
    last_name: 'Nom',
    email: 'Email',
  },
  form_fields: {
    name: {
      label: 'Nom',
      placeholder: "Nom de l'université",
      required_error_message: "Nom de l'université est requis",
    },
    address: {
      label: 'Adresse',
      placeholder: "Adresse de l'université",
      required_error_message: "Adresse de l'université est requis",
    },
    phone_number: {
      label: 'Numéro de téléphone',
      placeholder: "Numéro de téléphone de l'université",
      required_error_message: 'Numéro de téléphone est requis',
      invalid_error_message: 'Numéro de téléphone doit étre entre 7 et 16 caractères',
    },
    email: {
      label: 'Email',
      placeholder: "Email de l'université",
      required_error_message: "Email de l'université est requis",
      invalid_error_message: "Email de l'université est invalide",
    },
  },
};
