import { makeStyles } from '@mui/styles';
import React from 'react';
import './ResetPasswordSuccess.css';
const useStyles = makeStyles((theme) => ({
  root: {
    width: ' 100%',
    height: ' 100vh',
    backgroundColor: ' #363636',
    margin: 0,
    fontFamily: 'Raleway',
  },
}));
const ResetPasswordSuccess = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <section className="central">
        <div style={{ display: 'block' }} id="successAlert" className="screenAlert-icon screenAlert-success animate">
          <span className="screenAlert-line screenAlert-tip animateSuccessTip"></span>
          <span className="screenAlert-line screenAlert-long animateSuccessLong"></span>
          <div className="screenAlert-placeholder"></div>
          <div className="screenAlert-fix"></div>
        </div>

        <h1 id="textInfo">Votre mot de passe a été modifié!</h1>
      </section>
    </div>
  );
};

export default ResetPasswordSuccess;
