import React from 'react';

export const ArticleIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8131 15.033L15.6449 16.22C15.6113 16.2593 15.5678 16.289 15.5191 16.3062L12.5805 17.2804H14.3887C14.5951 17.2804 14.7625 17.4478 14.7625 17.6542C14.7625 17.8607 14.5951 18.028 14.3887 18.028H4.43502C4.22857 18.028 4.06119 17.8607 4.06119 17.6542C4.06119 17.4478 4.22857 17.2804 4.43502 17.2804H12.2099C12.1782 17.2804 12.1486 17.2459 12.1223 17.2196C12.0223 17.1212 11.9872 16.9741 12.0321 16.8412L12.2574 16.1589H4.43502C4.22857 16.1589 4.06119 15.9915 4.06119 15.785C4.06119 15.5786 4.22857 15.4112 4.43502 15.4112H12.5073L13.0344 13.8272C13.0476 13.7755 13.0735 13.7281 13.11 13.6894L16.8131 9.97404V5.78505H13.4444C13.238 5.78505 13.0748 5.63884 13.0748 5.43221V2H2V22H16.8131V15.033ZM4.43502 13.5421H9.71576C9.9222 13.5421 10.0896 13.7094 10.0896 13.9159C10.0896 14.1223 9.9222 14.2897 9.71576 14.2897H4.43502C4.22857 14.2897 4.06119 14.1223 4.06119 13.9159C4.06119 13.7094 4.22857 13.5421 4.43502 13.5421ZM14.3887 19.8972H4.43502C4.22857 19.8972 4.06119 19.7298 4.06119 19.5234C4.06119 19.3169 4.22857 19.1495 4.43502 19.1495H14.3887C14.5951 19.1495 14.7625 19.3169 14.7625 19.5234C14.7625 19.7298 14.5951 19.8972 14.3887 19.8972Z"
        fill="white"
      />
      <path d="M13.8224 2.55176V5.03734H16.325L13.8224 2.55176Z" fill="white" />
      <path d="M12.9788 16.3758L14.709 15.7973L13.5572 14.6455L12.9788 16.3758Z" fill="white" />
      <path d="M15.4003 15.4312L20.1334 10.6877L18.6669 9.22119L13.9234 13.9543L15.4003 15.4312Z" fill="white" />
      <path d="M21.7939 9.0232L20.3311 7.56055L19.1961 8.69299L20.6615 10.1582L21.7939 9.0232Z" fill="white" />
    </svg>
  );
};

export default ArticleIcon;
