/* eslint-disable @typescript-eslint/no-explicit-any*/

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor, { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import { ENDPOINTS } from '../utils/endpoints';
import { CONSTANTS } from '../utils/constants';
import { config } from '../config/config';
import { useNavigate } from 'react-router-dom';

const instanceAxios: AxiosInstance = axios.create();

const refreshAuthLogic = async (_failedRequest: any): Promise<any> => {
  const tokenRefreshResponse = await instanceAxios.post(config.BASE_URL_API + ENDPOINTS.REFRESH_TOKEN, {}, {
    headers: {
      Accept: 'application/json',
      Authorization: CONSTANTS.REFRESH_KEY,
    },
    skipAuthRefresh: true,
  } as AxiosAuthRefreshRequestConfig);

  await localStorage.setItem(CONSTANTS.LOCAL_STORAGE_REFRESH_TOKEN, tokenRefreshResponse.data.data.refreshToken);
  await localStorage.setItem(CONSTANTS.ROLE, JSON.stringify(tokenRefreshResponse.data.data.Role));
  await localStorage.setItem(CONSTANTS.LOCAL_STORAGE_TOKEN, tokenRefreshResponse.data.data.accessToken);
  _failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.data.accessToken;

  return Promise.resolve();
  // return setTimeout(() => Promise.resolve(), 30000, 'request timed out');
};

const getAccessToken = async (): Promise<string> => {
  const accessToken = await localStorage.getItem(CONSTANTS.LOCAL_STORAGE_TOKEN);
  if (accessToken) {
    return accessToken;
  } else {
    return '';
  }
};

const getReefreshToken = async (): Promise<string> => {
  const refreshToken = await localStorage.getItem(CONSTANTS.LOCAL_STORAGE_REFRESH_TOKEN);
  if (refreshToken) {
    return refreshToken;
  } else {
    return '';
  }
};

// Use interceptor to inject the token to requests
instanceAxios.interceptors.request.use(
  async (request: AxiosRequestConfig) => {
    if (request.headers && request.headers[CONSTANTS.AUTHORIZATION_KEY]) {
      if ((request.headers[CONSTANTS.AUTHORIZATION_KEY] as string).includes(CONSTANTS.BEARER_KEY)) {
        const token = await getAccessToken();
        request.headers[CONSTANTS.AUTHORIZATION_KEY] = `${CONSTANTS.BEARER_KEY} ${token}`;
      } else if ((request.headers[CONSTANTS.AUTHORIZATION_KEY] as string).includes(CONSTANTS.REFRESH_KEY)) {
        const newtoken = await getReefreshToken();
        request.headers[CONSTANTS.AUTHORIZATION_KEY] = `${CONSTANTS.BEARER_KEY} ${newtoken}`;
      }
    }
    return request;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Instantiate the interceptor
createAuthRefreshInterceptor(instanceAxios, refreshAuthLogic, {
  pauseInstanceWhileRefreshing: false,
});

export default instanceAxios;
