import React from 'react';
import { DATA } from '../../../../store/flags/models';
import ReactPlayer from 'react-player';
interface FlagVideoPublicationDialogProps {
  flag: DATA;
}

// Video Publication Dialog
export const FlagVideoPublicationDialog = (props: FlagVideoPublicationDialogProps) => {
  const flag = props.flag;

  return (
    <>
      <ReactPlayer
        light={flag.flaggedPublication.video.minImageUrl}
        playing={false}
        width="400px"
        controls
        style={{ marginTop: -40 }}
        url={flag.flaggedPublication.video.videoUrl}
      />
    </>
  );
};

export default FlagVideoPublicationDialog;
