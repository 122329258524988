import React, { useEffect, useState, ChangeEvent } from 'react';
import { Box, Container, Table, TableBody, TableContainer, TablePagination, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TablePaginationActions } from '../table/TablePaginationActions/TablePaginationActions';
import TableLoadingData from '../table/TableLoadingData';
import TableNoData from '../table/TableNoData';
import { TABLE_COLUMNS_CONFIG, initialPaginationState } from './AdsUsers.constant';
import AdsusersTableRow from './adsUsers-row/AdsUsersTableRow';
import { RootStyle } from '../../pages/systemusers/SystemUser.styles';
import SearchIcon from '../svgComponents/searchIcon';
import TableHeadCustom from '../table/table-head/CustomTableHead';
import { AdsUsersActionTypes, IAdsUsersResponse, IAdsUsersState } from '../../store/adsUsers/slice/AdsUser-slice.types';
import AdsUsers from '../../models/AdsUsers';
import { adsUserApi } from '../../store/adsUsers/api/AdsUsers.api';
import { useDebounce } from '@uidotdev/usehooks';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store/rootStore';
import useTable from '../../hooks/useTable';
import AdsUsersView from './adsUsersView/AdsUsersView';
import { ContainerStyle, CustomTitle, GridStyle, InputStyle } from '../../utils/toolbar/CommonToolbar.styles';
import Spinner from '../spinner';
import { NoDataFoundComponent } from '../shared/NoDataFoundComponent/NoDataFoundComponent';

function AdsUsersDetails() {
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedName, setSelectedName] = useState<string | undefined>(undefined);
  const [filterKeyword, setfilterKeyword] = useState('');
  const [isEyeIconClicked, setIsEyeIconClicked] = useState(false);
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AdsUsersActionTypes>>();
  const { users, isLoading } = useSelector<RootState, IAdsUsersState>((state: RootState) => state[adsUserApi.reducerPath]);
  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialPaginationState);

  useEffect(() => {
    dispatch(
      adsUserApi.endpoints.getAdsUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  }, [order, orderBy, page, rowsPerPage, debouncedSearchTerm, dispatch]);

  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
    setPage(0);
  };

  const onShowDetails = (id?: number, name?: string, isShow?: boolean) => {
    setIsEyeIconClicked(isShow ?? false);
    setSelectedId(id || 0);
    setSelectedName(name || '');
  };

  const onCloseDetails = (isShow?: boolean) => {
    setIsEyeIconClicked(isShow ?? false);
    setfilterKeyword('');

    dispatch(
      adsUserApi.endpoints.getAdsUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  };

  const isFullFilled = !isLoading && users && users?.users?.length > 0;

  return (
    <>
      {isEyeIconClicked ? (
        <AdsUsersView selectedId={selectedId} onCloseDetails={onCloseDetails} selectedName={selectedName || ''} />
      ) : (
        <RootStyle>
          <ContainerStyle style={{ padding: '0 24px' }}>
            <GridStyle>
              <CustomTitle>{t('adds.adsusertitle')}</CustomTitle>
              <Stack direction="column" spacing={4}>
                <InputStyle
                  value={filterKeyword}
                  placeholder={t('common.search')}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterKeyword(e.target.value)}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                  }}
                />
              </Stack>
            </GridStyle>
          </ContainerStyle>

          <Container sx={{ minHeight: '70vh' }}>
            <div className="table-block">
              <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                <Table size={'medium'}>
                  <TableHeadCustom order={order} orderBy={orderBy} columnsConfig={TABLE_COLUMNS_CONFIG} onSort={onSort} />
                  <TableBody>
                    {isFullFilled &&
                      (users as IAdsUsersResponse)?.users?.map((row: AdsUsers) => {
                        return (
                          <AdsusersTableRow
                            key={row.id}
                            row={row}
                            selected={false}
                            columnsConfig={TABLE_COLUMNS_CONFIG}
                            onShowDetails={(id, name) => onShowDetails(id, name, true)}
                          />
                        );
                      })}
                    <TableNoData isNotFound={!isLoading && users?.users?.length === 0} title={t('adds.adsusers_no_data')} />

                    <TableLoadingData isLoading={isLoading} />
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {isFullFilled && (
              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={users ? users.total : 0}
                  rowsPerPage={rowsPerPage}
                  page={users && page ? page - 1 : 0}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelRowsPerPage={t('common.rowsperpage')}
                  sx={{
                    '& .MuiInputBase-root': {
                      maxWidth: '60px',
                    },
                  }}
                />
              </Box>
            )}
          </Container>
        </RootStyle>
      )}
    </>
  );
}

export default AdsUsersDetails;
