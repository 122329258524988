import { ExportRevenueReport, RevenueReport } from '../../../models/RevenueReport';

export interface IRevenueReportsResponse {
  count: number;
  next: number | null;
  previous: number | null;
  users: RevenueReport[];
}

export interface IRevenueReportsState {
  isLoading: boolean;
  users: IRevenueReportsResponse | null;
  revenueReports: RevenueReport | null;
  error: string | null;
}

export interface IExportRevenueReportsResponse {
  count: number;
  next: number | null;
  previous: number | null;
  data: ExportRevenueReport[];
}

export interface IExportRevenueReportsState {
  isLoading: boolean;
  data: IExportRevenueReportsResponse | null;
  revenueExportReports: ExportRevenueReport | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_REVENUE_REPORTS_SUCCESS = 'FETCH_REVENUE_REPORTS_SUCCESS',
  FETCH_REVENUE_REPORTS_FAILURE = 'FETCH_REVENUE_REPORTS_FAILURE',
  CREATE_REVENUE_REPORTS_SUCCESS = 'CREATE_REVENUE_REPORTS_SUCCESS',
}

export interface IRevenueReportsAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchRevenueReportsSuccessAction {
  type: typeof ActionTypes.FETCH_REVENUE_REPORTS_SUCCESS | typeof ActionTypes.CREATE_REVENUE_REPORTS_SUCCESS;
  payload: IRevenueReportsResponse | RevenueReport;
}

export interface IExportFetchRevenueReportsSuccessAction {
  type: typeof ActionTypes.FETCH_REVENUE_REPORTS_SUCCESS | typeof ActionTypes.CREATE_REVENUE_REPORTS_SUCCESS;
  payload: IExportRevenueReportsResponse | ExportRevenueReport;
}

export interface IFetchRevenueReportsRejectedAction {
  type: typeof ActionTypes.FETCH_REVENUE_REPORTS_FAILURE;
  payload: string;
}

export type RevenueReportsActionTypes = IRevenueReportsAction | IFetchRevenueReportsSuccessAction | IFetchRevenueReportsRejectedAction;

export type ExportRevenueReportsActionTypes = IRevenueReportsAction | IExportFetchRevenueReportsSuccessAction | IFetchRevenueReportsRejectedAction;
