import React from 'react';

export const TransactionIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.25 8.99979C23.2506 9.09849 23.2317 9.19634 23.1943 9.28771C23.157 9.37909 23.102 9.46221 23.0325 9.53229L18.5325 14.0323C18.4624 14.1018 18.3793 14.1568 18.2879 14.1941C18.1966 14.2314 18.0987 14.2504 18 14.2498C17.9025 14.251 17.8057 14.2331 17.715 14.1973C17.5769 14.1405 17.4588 14.0438 17.376 13.9196C17.2931 13.7953 17.2493 13.6491 17.25 13.4998V11.2498H10.5C10.3011 11.2498 10.1103 11.1708 9.96967 11.0301C9.82902 10.8895 9.75 10.6987 9.75 10.4998V7.49979C9.75 7.30087 9.82902 7.11011 9.96967 6.96946C10.1103 6.8288 10.3011 6.74979 10.5 6.74979H17.25V4.49979C17.2506 4.35146 17.2952 4.20664 17.3781 4.08365C17.4611 3.96067 17.5786 3.86503 17.7159 3.80883C17.8531 3.75264 18.004 3.73841 18.1493 3.76795C18.2947 3.79749 18.428 3.86947 18.5325 3.97479L23.0325 8.47479C23.1711 8.61448 23.2492 8.80303 23.25 8.99979ZM13.5 12.7498H6.75V10.4998C6.74926 10.3517 6.70471 10.2072 6.62196 10.0844C6.53921 9.9616 6.42196 9.86605 6.285 9.80979C6.14841 9.75235 5.99788 9.73666 5.85239 9.76469C5.7069 9.79272 5.57297 9.86322 5.4675 9.96729L0.967497 14.4673C0.897986 14.5374 0.842992 14.6205 0.805669 14.7119C0.768345 14.8032 0.749427 14.9011 0.749997 14.9998C0.749427 15.0985 0.768345 15.1963 0.805669 15.2877C0.842992 15.3791 0.897986 15.4622 0.967497 15.5323L5.4675 20.0323C5.53758 20.1018 5.62069 20.1568 5.71207 20.1941C5.80345 20.2314 5.90129 20.2504 6 20.2498C6.09838 20.2523 6.19599 20.2318 6.285 20.1898C6.42196 20.1335 6.53921 20.038 6.62196 19.9152C6.70471 19.7924 6.74926 19.6479 6.75 19.4998V17.2498H13.5C13.6989 17.2498 13.8897 17.1708 14.0303 17.0301C14.171 16.8895 14.25 16.6987 14.25 16.4998V13.4998C14.25 13.3009 14.171 13.1101 14.0303 12.9695C13.8897 12.8288 13.6989 12.7498 13.5 12.7498Z"
        fill="white"
      />
    </svg>
  );
};

export default TransactionIcon;
