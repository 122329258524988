import { SubscriptionPlan } from '../../../models/SubscriptionPlan';

export interface ISubscriptionPlanResponse {
  total: number;
  next: number | null;
  previous: number | null;
  palnListing: {
    rows: SubscriptionPlan[];
  };
}

export interface ISubscriptionPlanState {
  isLoading: boolean;
  palnListing: ISubscriptionPlanResponse | null;
  subscriptionPlanUsers: SubscriptionPlan | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_SUBSCRIPTION_PLAN_SUCCESS = 'FETCH_SUBSCRIPTION_PLAN_SUCCESS',
  FETCH_SUBSCRIPTION_PLAN_FAILURE = 'FETCH_SUBSCRIPTION_PLAN_FAILURE',
  CREATE_SUBSCRIPTION_PLAN_SUCCESS = 'CREATE_SUBSCRIPTION_PLAN_SUCCESS',
}

export interface ISubscriptionPlanPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchSubscriptionPlanSuccessAction {
  type: typeof ActionTypes.FETCH_SUBSCRIPTION_PLAN_SUCCESS | typeof ActionTypes.CREATE_SUBSCRIPTION_PLAN_SUCCESS;
  payload: ISubscriptionPlanResponse | SubscriptionPlan;
}

export interface IFetchSubscriptionPlanRejectedAction {
  type: typeof ActionTypes.FETCH_SUBSCRIPTION_PLAN_FAILURE;
  payload: string;
}

export type SubscriptionPlanActionTypes = ISubscriptionPlanPendingAction | IFetchSubscriptionPlanSuccessAction | IFetchSubscriptionPlanRejectedAction;
