import React from 'react';

export const MasterClassIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.75 19C22.75 18.271 22.46 17.571 21.945 17.055C21.429 16.54 20.729 16.25 20 16.25C18.917 16.25 17.583 16.25 16.5 16.25C15.771 16.25 15.071 16.54 14.555 17.055C14.04 17.571 13.75 18.271 13.75 19V20C13.75 20.967 14.534 21.75 15.5 21.75H21C21.966 21.75 22.75 20.967 22.75 20V19Z"
        fill="white"
      />
      <path
        d="M18.25 15.75C20.0449 15.75 21.5 14.2949 21.5 12.5C21.5 10.7051 20.0449 9.25 18.25 9.25C16.4551 9.25 15 10.7051 15 12.5C15 14.2949 16.4551 15.75 18.25 15.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.758 18.75C12.819 17.847 13.204 16.992 13.848 16.348C14.239 15.957 14.708 15.662 15.217 15.476C14.465 14.709 14 13.659 14 12.5C14 10.154 15.904 8.25 18.25 8.25C20.596 8.25 22.5 10.154 22.5 12.5C22.5 13.659 22.035 14.709 21.283 15.476C21.792 15.662 22.261 15.957 22.652 16.348C22.685 16.382 22.718 16.416 22.75 16.45V4C22.75 3.536 22.566 3.091 22.237 2.763C21.909 2.434 21.464 2.25 21 2.25C17.586 2.25 6.414 2.25 3 2.25C2.536 2.25 2.091 2.434 1.763 2.763C1.434 3.091 1.25 3.536 1.25 4V17C1.25 17.966 2.033 18.75 3 18.75H12.758ZM5.5 12.75H12C12.414 12.75 12.75 12.414 12.75 12C12.75 11.586 12.414 11.25 12 11.25H5.5C5.086 11.25 4.75 11.586 4.75 12C4.75 12.414 5.086 12.75 5.5 12.75ZM5.5 8.75H9.5C9.914 8.75 10.25 8.414 10.25 8C10.25 7.586 9.914 7.25 9.5 7.25H5.5C5.086 7.25 4.75 7.586 4.75 8C4.75 8.414 5.086 8.75 5.5 8.75Z"
        fill="white"
      />
    </svg>
  );
};

export default MasterClassIcon;
