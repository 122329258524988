export const subscriptionPlan = {
  listTitle: 'Title',
  description: 'Description',
  price: 'Price',
  actions: 'Actions',
  subscriptionPlan_no_data: 'No subscription plan found',
  title: 'Subscription Plan',
  add: 'CREATE A SUBSCRIPTION PLAN',
  confirm_delete_title: 'Confirm deletion',
  confirm_delete_message: 'Are you sure you want to remove this subscription plan ?',
  delete_success_message: 'Subscription plan was successfully deleted',
  view_subscription_plan_title: 'View subscription plan details',
  create_success_message: 'Subscription plan has been created successfully',
  update_success_message: 'Subscription plan has been updated successfully',
  add_subscription_plan_title: 'Create a subscription plan',
  update_subscription_plan_title: 'Edit a subscription plan',
  title_already_exist: 'Title name already exists',
  form_fields: {
    title: {
      label: 'Title*',
      placeholder: 'Title',
      max_length_error_message: 'Title cannot be longer than 50 characters',
      required_error_message: 'Title field is required',
    },
    price: {
      label: 'Price*',
      placeholder: 'Price',
      required_error_message: 'Price field is required',
    },
    description: {
      label: 'Description*',
      placeholder: 'Description',
      required_error_message: 'Description field is required',
    },
  },
};
