/* eslint-disable @typescript-eslint/no-explicit-any */
import { Interests } from '../../../models/Interests';
import {
  ActionTypes,
  IFetchInterestsRejectedAction,
  IFetchInterestsSuccessAction,
  IInterestsPendingAction,
  IInterestsResponse,
  IInterestsState,
  InterestsActionTypes,
} from './Interests-slice.types';

export const initialState: IInterestsState = {
  isLoading: false,
  error: null,
  data: null,
  interestTypes: null,
};

export const interestsReducer = (state = initialState, action: InterestsActionTypes): IInterestsState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_INTERESTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload as IInterestsResponse,
      };
    case ActionTypes.FETCH_INTERESTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_INTERESTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        interestTypes: action.payload as Interests,
      };
    default:
      return state;
  }
};

const matchPending = (): IInterestsPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (data: IInterestsResponse, action: 'fetch' | 'update' | 'create' | 'delete'): IFetchInterestsSuccessAction | Interests => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_INTERESTS_SUCCESS : ActionTypes.CREATE_INTERESTS_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchInterestsRejectedAction => {
  return {
    type: ActionTypes.FETCH_INTERESTS_FAILURE,
    payload: error,
  };
};

export const interestsActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
