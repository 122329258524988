import React, { useEffect, useTransition } from 'react';
import { Grid, Typography, InputBase, ButtonBase, Container, ThemeProvider } from '@mui/material';
import { theme } from '../../theme/theme';
import { Box } from '@mui/system';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store/rootStore';
import { AuthActionTypes } from '../../store/authentication/models';
import { PasswordDialogActionTypes } from '../../store/Dialog/models';
import { getSettings, updateSettings } from '../../store/user/action';
import { userActionTypes } from '../../store/user/models';
import { Spinner } from '../../components';
import { useFormik } from 'formik';
import UserSettingRequest from '../../models/UserSettingRequest';
import * as yup from 'yup';
import { OnTheTable } from '../../components/table/onTheTable/OnTheTable';
import { useTranslation } from 'react-i18next';

export default function Settings() {
  const schema = yup.object().shape({
    app_version: yup.string().required("L'adresse postale est requise"),
    adsCount: yup
      .number()
      .min(0, "Le nombre d'annonces ne peut pas être inférieur à 0")
      .max(9, "Le nombre d'annonces doit être inférieur à 10")
      .required("Le nombre d'annonces est requis"),
  });
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AuthActionTypes | PasswordDialogActionTypes | userActionTypes>>();
  const userGetState = useSelector((state: RootState) => state.user.Settings);
  const { loader: getProfileLoader, user: userGet } = userGetState;
  const userUpdateState = useSelector((state: RootState) => state.user.NewSettings);
  const { loader: updateProfileLoader } = userUpdateState;

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(getSettings());
    }
    return () => {
      mounted = false;
    };
  }, []);

  const onUpdateProfile = (data: UserSettingRequest) => {
    dispatch(updateSettings(data, userGet?.settings?.id));
  };

  const formik = useFormik({
    validationSchema: schema,
    enableReinitialize: true,
    initialValues: {
      app_version: userGet?.settings?.app_version || '',
      adsCount: userGet?.settings?.adsCount || 0,
      is_forcefully_update: userGet?.settings?.is_forcefully_update === true,
    },
    onSubmit: (values) => {
      if (values !== formik.initialValues) {
        onUpdateProfile(values);
      }
    },
  });

  const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
  };

  if (getProfileLoader)
    return (
      <Container sx={{ minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
        <Spinner loading={getProfileLoader} />
      </Container>
    );
  else
    return (
      <>
        <Grid container direction="column" rowSpacing={25}>
          {/* <Grid container direction="column" rowSpacing={5}> */}
          <Grid item>{'ok'}</Grid>

          <Grid item>
            <OnTheTable title={t('common.settings')} />
          </Grid>
          <Container sx={{ minHeight: '80vh' }}>
            <ThemeProvider theme={theme}>
              <form onSubmit={formik.handleSubmit} id="updateForm">
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    gap: '10px',
                    paddingY: '20px',
                  }}
                >
                  <Typography
                    sx={{
                      height: '29px',
                      fontFamily: 'SFPro-Regular',
                      fontSize: '20px',
                      color: theme.palette.primary.light,
                      justifySelf: 'start',
                      marginRight: 0,
                      flexGrow: 0,
                      minWidth: '300px',
                    }}
                  >
                    {t('common.force_update')}
                  </Typography>
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '20px',
                      width: ' 436px',
                      justifySelf: 'end',
                    }}
                  >
                    <Grid item sx={{ width: '436px', alignSelf: 'end' }}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={formik.values.is_forcefully_update.toString()}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          formik.setFieldValue('is_forcefully_update', e.target.value === 'true');
                        }}
                        name="is_forcefully_update"
                      >
                        <FormControlLabel value="true" control={<Radio />} label={t('common.yes')} />
                        <FormControlLabel value="false" control={<Radio />} label={t('common.no')} />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    gap: '10px',
                    paddingY: '20px',
                  }}
                >
                  <Typography
                    sx={{
                      height: '29px',
                      flexGrow: 0,
                      fontFamily: 'SFPro-Regular',
                      fontSize: '20px',
                      color: theme.palette.primary.light,
                      justifySelf: 'start',
                      marginRight: 0,
                      minWidth: '300px',
                    }}
                  >
                    {t('common.app_version')}
                  </Typography>
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '20px',
                      width: ' 436px',
                      justifySelf: 'end',
                    }}
                  >
                    <InputBase
                      id="app_version"
                      inputProps={{
                        sx: {
                          '&::placeholder': {
                            opacity: 1,
                            color: theme.palette.primary.main,
                          },
                        },
                      }}
                      sx={{ width: '100%' }}
                      value={formik.values.app_version}
                      placeholder="Version de l`application"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onHandleChange(e)}
                      onBlur={formik.handleBlur}
                      error={!!formik.errors.app_version && formik.touched.app_version}
                    />
                    {formik.touched.app_version && formik.errors.app_version && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#ff453a',
                          mt: '7px',
                          width: '170px',
                          height: '29px',
                          fontFamily: 'SFPro-Regular',
                          fontSize: '12px',
                          textAlign: 'left',
                        }}
                        data-testid="app_version"
                      >
                        {formik.errors.app_version}
                      </Typography>
                    )}
                  </Grid>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    gap: '10px',
                    paddingY: '20px',
                  }}
                >
                  <Typography
                    sx={{
                      height: '29px',
                      flexGrow: 0,
                      fontFamily: 'SFPro-Regular',
                      fontSize: '20px',
                      color: theme.palette.primary.light,
                      justifySelf: 'start',
                      marginRight: 0,
                      minWidth: '300px',
                    }}
                  >
                    {t('common.no_announcements')}
                  </Typography>
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '20px',
                      width: ' 436px',
                      justifySelf: 'end',
                    }}
                  >
                    <InputBase
                      id="adsCount"
                      inputProps={{
                        sx: {
                          '&::placeholder': {
                            opacity: 1,
                            color: theme.palette.primary.main,
                          },
                        },
                      }}
                      sx={{ width: '100%' }}
                      value={formik.values.adsCount}
                      placeholder="Nombre d'annonces"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onHandleChange(e)}
                      onBlur={formik.handleBlur}
                      error={!!formik.errors.adsCount && formik.touched.adsCount}
                    />
                    {formik.touched.adsCount && formik.errors.adsCount && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#ff453a',
                          mt: '7px',
                          width: '170px',
                          height: '29px',
                          fontFamily: 'SFPro-Regular',
                          fontSize: '12px',
                          textAlign: 'left',
                        }}
                        data-testid="adsCount"
                      >
                        {formik.errors.adsCount}
                      </Typography>
                    )}
                  </Grid>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    paddingY: '20px',
                  }}
                >
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '24px',
                      width: ' 436px',
                      justifySelf: 'end',
                    }}
                  >
                    <ButtonBase
                      type="submit"
                      form="updateForm"
                      sx={{
                        backgroundColor: '#ffcc33',
                        height: '52px',
                        width: '246px',
                        fontFamily: 'SFPro-Regular',
                        fontSize: '17px',
                        gap: '10px',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifySelf: 'end',
                      }}
                      className={!updateProfileLoader ? 'isRealsed ' : 'isActive '}
                    >
                      {t('common.save')}
                    </ButtonBase>
                  </Grid>
                </Box>
              </form>
            </ThemeProvider>
          </Container>
        </Grid>
      </>
    );
}
