export const adds = {
  approvedtitle: 'APPROVED ADDITIONS',
  pendingtitle: 'PENDING ADDITIONS',
  plan_id: 'Id',
  adds_post_label: 'Ads Post',
  adds_banner_label: 'Ads Banner',
  adds_popup_label: 'Popup Ads',
  subscriptiontitle: 'Subscription plan',
  name: 'Name',
  email: 'Email',
  phone_number: 'Phone number',
  status: 'Status',
  adsTitle: 'Ads Title',
  acceptedby: 'Accepted by',
  date: 'Date',
  title: 'Ad title',
  work_title: 'Title',
  description: 'Description',
  image: 'Image',
  active: 'Active',
  rejected: 'Rejected',
  pending: 'Pending',
  add_pending_ads_title: 'Add pending announcements',
  update_pending_ads_title: 'Update pending listings',
  view_pending_ads_title: 'Show pending announcements',
  actions: 'Actions',
  confirm_accept: 'Confirm Approve',
  confirm_reject: 'Confirm rejection',
  confirm_accept_message: 'Are you sure you want to approve this ad?',
  confirm_reject_message: 'Are you sure you want to reject this ad?',
  accept: 'Approve',
  reject: 'Reject',
  approved: 'Approved',
  expired: 'Expired',
  no_data: 'No pending listings found',
  approved_no_data: 'No approved listings found',
  successfull: `Pending ad request was successfully accepted`,
  reject_message: `Pending ad request is rejected`,
  update_button_label: 'Edit Changes',
  create_button_label: 'Save changes',
  personal_info: 'Personal informations',
  adds_info: 'Advertising information',
  adds_plan_info: 'Type of advertising plan',
  file_size_error: 'File size should not exceed 5 MB.',
  update_success_message: `Pending ad request was successfully modified`,
  view_approved_ads_title: 'Show approved announcements',
  plan_update_success_message: 'Subscription plan was successfully modified',
  view_plan_title: 'View subscription plans',
  update_plan_title: 'Update subscription plans',
  add_plan_title: 'Create subscription plans',
  no_plans: 'No subscription plans found',
  profileType: 'Profile Type',
  adsusertitle: 'Ads Users',
  adsusers_no_data: 'No ads users found',
  users_ads_details: 'Users Ads Details',
  user_details_no_data: 'No user ads details found',
  revenue_no_data: 'No revenue data found',
  nickname: 'Nickname',
  usersname_ads_details: 'ads details',
  expire: 'Expire Date & Time',
  plantype: 'Plan Type',
  view_users_ads_title: 'Users Ads Details',
  role: 'User Type',
  price: 'Total Price',
  adsReportstitle: 'Ads Reports',
  workstitle: 'How It Works',
  workstitle_no_data: 'No data Found',
  how_works_title: 'How it works details',
  update_works_title: 'Update how it works listings',
  no_active_users: 'No Active Ads Reports Found ',
  how_it_works_success_message: 'How it works was successfully modified',
  subscription_no_data: 'No subscription plans found',
  starttime: 'Start Date & Time',
  userreports_no_data: 'No data found',
  user_no_data: 'No user found',
  form_fields: {
    name: {
      label: 'Name*',
      placeholder: 'Name',
      required_error_message: 'Name field is required',
    },
    mail: {
      label: 'Email*',
      placeholder: 'Email',
      required_error_message: `Email address field is required`,
      invalid_error_message: 'Invalid email address',
    },
    phoneNb: {
      label: 'Phone number*',
      placeholder: 'Phone number',
      required_error_message: 'Phone number field is required',
      invalid_error_message: 'Phone number must be between 7 and 16 characters',
    },
    planType: {
      label: 'Plan type',
      placeholder: 'Plan type',
      required_error_message: 'The Plan Type field is required',
    },
    adsTitle: {
      label: `Ad Title*`,
      placeholder: `Ad Title`,
      required_error_message: `Ad Title field is required`,
    },
    adsBanner: {
      label: 'Ad Banner*',
      placeholder: 'Ad Banner',
      required_error_message: `The Banner Ad field is required`,
    },
    adsPost: {
      label: `Publication d'annonces*`,
      placeholder: `Publication d'annonces`,
      required_error_message: `Le champ Publication d'annonces est requis`,
    },
    adsPopup: {
      label: 'Ad Popup*',
      placeholder: 'Ad Popup',
      required_error_message: 'The Pop-up Ads field is required',
    },
    description: {
      label: 'Description*',
      placeholder: 'Description',
      required_error_message: 'Description field is required',
    },
    websiteUrl: {
      label: `Website URL*`,
      placeholder: `Website URL`,
      required_error_message: 'Website URL field is required',
      invalid_error_message: 'Invalid website URL',
    },
    roleIds: {
      label: `Target audience*`,
      placeholder: `Target audience`,
      required_error_message: 'At least one target audience is required',
    },
    planname: {
      label: `Plan Name*`,
      placeholder: `Plan Name`,
      required_error_message: 'The Plan Name field is required',
    },
    planDescription: {
      label: `Plan Description*`,
      placeholder: `Plan Description`,
      required_error_message: 'The Plan Description field is required',
    },
    planPrice: {
      label: 'Plan Price*',
      placeholder: `Plan Price`,
      required_error_message: 'The Plan Price field is required',
    },
    title: {
      label: 'Title*',
      placeholder: `Title`,
      required_error_message: 'The Title field is required',
    },
    imageOrVideoUrl: {
      label: 'Image/Video*',
      placeholder: `Image/Video`,
      required_error_message: 'The Image/Video field is required',
    },
  },
};
