import React from 'react';

export const ArrowComponent = () => {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="m10.5 13 5 5 5-5" stroke="#A4A4A5" strokeLinecap="round" />
    </svg>
  );
};

export default ArrowComponent;
