import { Welcome } from '../../../models/Welcome';

export interface IWelcomeVideoResponse {
  total: number;
  next: number | null;
  previous: number | null;
  welComeVideo: Welcome[];
}

export interface IWelcomeVideoState {
  isLoading: boolean;
  data: IWelcomeVideoResponse | null;
  welcomeData: Welcome | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_WELCOME_VIDEO_SUCCESS = 'FETCH_WELCOME_VIDEO_SUCCESS',
  FETCH_WELCOME_VIDEO_FAILURE = 'FETCH_WELCOME_VIDEO_FAILURE',
  CREATE_WELCOME_VIDEO_SUCCESS = 'CREATE_WELCOME_VIDEO_SUCCESS',
}

export interface IWelcomeVideoPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchWelcomeVideoSuccessAction {
  type: typeof ActionTypes.FETCH_WELCOME_VIDEO_SUCCESS | typeof ActionTypes.CREATE_WELCOME_VIDEO_SUCCESS;
  payload: IWelcomeVideoResponse | Welcome;
}

export interface IFetchWelcomeVideoRejectedAction {
  type: typeof ActionTypes.FETCH_WELCOME_VIDEO_FAILURE;
  payload: string;
}

export type WelcomeVideoActionTypes = IWelcomeVideoPendingAction | IFetchWelcomeVideoSuccessAction | IFetchWelcomeVideoRejectedAction;
