import React from 'react';
import { Button, TableRow, Stack, IconButton, Tooltip } from '@mui/material';
import { TableCellStyle } from './SubscriptionPlans.styles';
import { SubscriptionPlansRowProps } from './SubscriptionPlans.types';
import EyeIcon from '../../svgComponents/EyeIcon';
import Iconify from '../../Iconify';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/ic_trash.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/pencil-outline.svg';

export default function SubscriptionPLansTableRow({
  row,
  selected,
  onShowDetails,
  columnsConfig,
  onOPenEditDialog,
  onConfirmDelete,
}: SubscriptionPlansRowProps) {
  const { id, title } = row;

  return (
    <TableRow key={id} hover selected={selected} sx={{ backgroundColor: '#ffffff' }}>
      {columnsConfig.map((column) => (
        <TableCellStyle
          key={column.id}
          align={column.align || 'left'}
          width={column.width}
          className={column.id === 'description' ? 'hide-text-overflow' : ''}
        >
          {column.id !== 'actions' ? (
            String(row[column.id as keyof typeof row])
          ) : (
            <Stack direction="row" spacing={2}>
              <Tooltip title="View Details" arrow>
                <IconButton sx={{ padding: '0px !important' }} onClick={() => onShowDetails(id, true)}>
                  <EyeIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit" arrow>
                <IconButton onClick={() => onOPenEditDialog(id)}>
                  <Iconify icon={EditIcon} />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Delete" arrow>
                <IconButton onClick={() => onConfirmDelete(id)}>
                  <Iconify icon={DeleteIcon} />
                </IconButton>
              </Tooltip> */}
            </Stack>
          )}
        </TableCellStyle>
      ))}
    </TableRow>
  );
}
