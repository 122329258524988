/* eslint-disable @typescript-eslint/no-explicit-any */
import PortfolioPlan from '../../../models/Portfolio';
import {
  ActionTypes,
  IFetchPortfolioPlanRejectedAction,
  IFetchPortfolioPlanSuccessAction,
  IPortfolioPlanPendingAction,
  IPortfolioPlanResponse,
  IPortfolioPlanState,
  PortfolioActionTypes,
} from './PortfolioPlans-slice.types';

export const initialState: IPortfolioPlanState = {
  isLoading: false,
  error: null,
  data: null,
  portfolioData: null,
};

export const portfolioReducer = (state = initialState, action: PortfolioActionTypes): IPortfolioPlanState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_PORTFOLIO_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload as IPortfolioPlanResponse,
      };
    case ActionTypes.FETCH_PORTFOLIO_PLAN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_PORTFOLIO_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        portfolioData: action.payload as PortfolioPlan,
      };
    default:
      return state;
  }
};

const matchPending = (): IPortfolioPlanPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (
  data: IPortfolioPlanResponse,
  action: 'fetch' | 'create' | 'delete' | 'update',
): IFetchPortfolioPlanSuccessAction | PortfolioPlan => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_PORTFOLIO_PLAN_SUCCESS : ActionTypes.CREATE_PORTFOLIO_PLAN_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchPortfolioPlanRejectedAction => {
  return {
    type: ActionTypes.FETCH_PORTFOLIO_PLAN_FAILURE,
    payload: error,
  };
};

export const portfolioPlanActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
