import { Categories } from '../../../models/Categories';

export interface ICategoriesResponse {
  total: number;
  next: number | null;
  previous: number | null;
  data: Categories[];
}

export interface ICategoriesState {
  isLoading: boolean;
  data: ICategoriesResponse | null;
  categoryUsers: Categories | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE',
  CREATE_CATEGORIES_SUCCESS = 'CREATE_CATEGORIES_SUCCESS',
}

export interface ICategoriesPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchCategoriesSuccessAction {
  type: typeof ActionTypes.FETCH_CATEGORIES_SUCCESS | typeof ActionTypes.CREATE_CATEGORIES_SUCCESS;
  payload: ICategoriesResponse | Categories;
}

export interface IFetchCategoriesRejectedAction {
  type: typeof ActionTypes.FETCH_CATEGORIES_FAILURE;
  payload: string;
}

export type CategoriesActionTypes = ICategoriesPendingAction | IFetchCategoriesSuccessAction | IFetchCategoriesRejectedAction;
