/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import './ResetPassword.css';
import { makeStyles } from '@mui/styles';
import { useLocation, useNavigate } from 'react-router';
import { resetPassword } from '../../services/auth/loginApi';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    margin: 0,
    padding: 0,
    backgroundColor: '#1E1E1C',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& h1': {
      color: 'white',
      maxWidth: '90%',
      textAlign: 'center',
    },
    '& input': {
      minWidth: '290px',
      backgroundColor: '#30302F',
      fontSize: 16,
      color: 'white',
      padding: '1em',
      paddingTop: '0.7em',
      paddingBottom: '0.7em',
      outline: 'none',
      marginTop: '1em',
      border: 'none',
      borderBottom: '2px solid #FDC723',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    '& button': {
      minWidth: '290px',
      color: 'white',
      backgroundColor: '#30302F',
      border: 'none',
      padding: '2em',
      paddingTop: '1em',
      paddingBottom: '1em',
      marginTop: '1em',
      fontSize: '16px',
      borderRadius: '5px',
      transition: '0.5s',
    },
    '& button:hover': {
      transition: '0.5s',
      backgroundColor: '#40403F',
      cursor: 'pointer',
    },

    '& button:disabled': {
      cursor: 'not-allowed',
    },
  },
}));
function checkStrongPass(password) {
  const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,32}$');
  return strongRegex.test(password);
}
const errors = {
  EXPIRED_TOKEN: 'Le lien a expiré! Veuillez refaire une demande de changement de mot de passe !',
  UNABLE_TO_RESET_PASSWORD: 'Impossible de changer le mot de passe!',
  CHANGE_PASSWORD_FORBIDDEN: "Vous n'avez pas la possibilité de changer le mot de passe",
  PASSWORD_IDENTICAL: 'Les deux mots de passes doivent être identiques',
  INCOMPLETE_FORM: 'Vous devez compléter tous les champs!',
  WEAK_PASSWORD:
    "Vous devez avoir un mdp entre 8 et 32 caractères,\
   composés d'au moins 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial",
};
const alertTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
  DANGER: 'danger',
  WARNING: 'warning',
};
const ResetPassword = () => {
  const classes = useStyles();
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [alertStatus, setAlertStatus] = React.useState('');
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();

  const { disabled, token, userId } = React.useMemo(() => {
    const token = new URLSearchParams(location.search).get('token');
    const userId = new URLSearchParams(location.search).get('userId');
    return { disabled: !token || !userId, token, userId };
  }, [location]);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (disabled) {
      setError(errors.CHANGE_PASSWORD_FORBIDDEN);
      setAlertStatus(alertTypes.DANGER);
    }
  }, [disabled]);

  // hide the alert after 3 seconds if it's not already hidden
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (alertStatus) {
        setAlertStatus('');
        setError('');
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [alertStatus, error]);

  const onpasswordChange = (e) => {
    setError('');
    setAlertStatus('');
    setPassword(e.target.value);
  };
  const onconfirmPasswordChange = (e) => {
    setError('');
    setAlertStatus('');
    setConfirmPassword(e.target.value);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    // check fields are not empty
    if (!password || !confirmPassword) {
      setError(errors.INCOMPLETE_FORM);
      setAlertStatus(alertTypes.DANGER);
      return;
    }
    if (password !== confirmPassword) {
      setError(errors.PASSWORD_IDENTICAL);
      setAlertStatus(alertTypes.DANGER);
      return;
    }
    if (!checkStrongPass(password)) {
      setError(errors.WEAK_PASSWORD);
      setAlertStatus(alertTypes.WARNING);
      return;
    }

    setLoading(true);
    const res = await resetPassword(password, confirmPassword, userId, token);

    if (res.code === 200) {
      setAlertStatus(alertTypes.SUCCESS);
      // redirect to reset-password-success page
      navigate('/reset-password-success');
      setError('');
      return;
    }
    if (res.code === 401) {
      setError(errors.EXPIRED_TOKEN);
      setAlertStatus(alertTypes.DANGER);
      return;
    }
    setError(errors.UNABLE_TO_RESET_PASSWORD);
    setAlertStatus(alertTypes.DANGER);
  };
  return (
    <form className={classes.root}>
      <div className="alertBox" id="alertContainer">
        {alertStatus !== '' ? (
          <div className={`alert alert-${alertStatus}`} role="alert">
            {error}
          </div>
        ) : null}
      </div>
      <h1>Changement de votre mot de passe</h1>

      <input value={password} onChange={onpasswordChange} disabled={disabled} id="password" type="password" placeholder="Nouveau mot de passe" />
      <input
        onChange={onconfirmPasswordChange}
        value={confirmPassword}
        disabled={disabled}
        id="passwordConf"
        type="password"
        placeholder="Confirmation du mot de passe"
      />
      <button onClick={onSubmit} disabled={disabled} id="changePass">
        Changer mon mot de passe
      </button>
    </form>
  );
};

export default ResetPassword;
