import React, { useEffect, useState } from 'react';
import { Box, Container, Table, TableBody, TableContainer, TablePagination, Stack } from '@mui/material';
import { RootStyle } from '../../../../pages/systemusers/SystemUser.styles';
import TableHeadCustom from '../../../table/table-head/CustomTableHead';
import useTable from '../../../../hooks/useTable';
import { TABLE_COLUMNS_CONFIG, initialPaginationState } from './HowItWorks.constant';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../../store/rootStore';
import { HowItWorksActionTypes, IHowItWorksResponse, IHowItWorksState } from '../../../../store/howItWorks/slice/HowItWorks-slice.types';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import SearchIcon from '../../../svgComponents/searchIcon';
import { TablePaginationActions } from '../../../table/TablePaginationActions/TablePaginationActions';
import TableNoData from '../../../table/TableNoData';
import TableLoadingData from '../../../table/TableLoadingData';
import { howItWorksApi } from '../../../../store/howItWorks/api/HowItWorks.api';
import { ENDPOINTS, sendAsyncRequest } from '../../../../utils';
import { HttpMethod } from '../../../../utils/httpMethods';
import { AxiosResponse } from 'axios';
import HowItWorksTableRow from './howitWorks-row/HowItWorksTableRow';
import HowitWorks from '../../../../models/HowItWorks';
import AddDialog from './add-dialog/AddDialog';
import { ContainerStyle, CustomTitle, GridStyle, InputStyle } from '../../../../utils/toolbar/CommonToolbar.styles';

function HowItWorksDetails() {
  const { t } = useTranslation();
  const [filterKeyword, setfilterKeyword] = useState('');
  const [isEyeIconClicked, setIsEyeIconClicked] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [currentApprovedAds, setCurrentApprovedAds] = useState<HowitWorks | undefined>(undefined);
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, HowItWorksActionTypes>>();
  const { howItWorksList, isLoading } = useSelector<RootState, IHowItWorksState>((state: RootState) => state[howItWorksApi.reducerPath]);
  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialPaginationState);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(
      howItWorksApi.endpoints.getWorkUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  }, [order, orderBy, page, rowsPerPage, debouncedSearchTerm, dispatch]);

  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
    setPage(0);
  };

  const onShowDetails = (id?: number, isShow?: boolean) => {
    setIsEdit(false);
    sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.HOW_IT_WORKS}/${id}`, {}, true, {})
      .then((response: AxiosResponse) => {
        const responseBody = response.data.data;
        const { title, imageOrVideoUrl, description } = responseBody;
        const userData: HowitWorks = {
          id: id,
          title: title,
          imageOrVideoUrl: imageOrVideoUrl,
          description: description,
        };
        setIsEyeIconClicked(isShow ?? false);
        setCurrentApprovedAds(userData);
      })
      .catch((err) => {
        console.log('error', err);
      });
    setAddDialogOpen(true);
  };

  const onOPenEditDialog = (id?: number) => {
    setIsEdit(true);
    sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.HOW_IT_WORKS}/${id}`, {}, true, {})
      .then((response: AxiosResponse) => {
        const responseBody = response.data.data;
        const { title, imageOrVideoUrl, description } = responseBody;
        const userData: HowitWorks = {
          id: id,
          title: title,
          imageOrVideoUrl: imageOrVideoUrl,
          description: description,
        };
        setCurrentApprovedAds(userData);
      })
      .catch((err) => {
        console.log('error', err);
      });
    setAddDialogOpen(true);
  };

  const onCloseDialog = () => {
    setAddDialogOpen(false);
    setCurrentApprovedAds(undefined);
    setIsEyeIconClicked(false);
    setIsEdit(false);
    setfilterKeyword('');

    dispatch(
      howItWorksApi.endpoints.getWorkUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  };

  const isFullFilled = !isLoading && howItWorksList && howItWorksList?.howItWorksList?.rows?.length > 0;

  return (
    <>
      <AddDialog
        open={addDialogOpen}
        onClose={onCloseDialog}
        isUpdate={isEdit}
        currentWorkData={currentApprovedAds}
        isEyeIconClicked={isEyeIconClicked}
      />

      <RootStyle>
        <ContainerStyle style={{ padding: '0 24px' }}>
          <GridStyle>
            <CustomTitle>{t('adds.workstitle')}</CustomTitle>
            <Stack direction="column" spacing={4}>
              <InputStyle
                value={filterKeyword}
                placeholder={t('common.search')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterKeyword(e.target.value)}
                InputProps={{
                  startAdornment: <SearchIcon />,
                }}
              />
            </Stack>
          </GridStyle>
        </ContainerStyle>

        <Container sx={{ minHeight: '70vh' }}>
          <div className="table-block">
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <TableHeadCustom order={order} orderBy={orderBy} columnsConfig={TABLE_COLUMNS_CONFIG} onSort={onSort} />
                <TableBody>
                  {isFullFilled &&
                    (howItWorksList as IHowItWorksResponse)?.howItWorksList?.rows?.map((row: HowitWorks) => {
                      return (
                        <HowItWorksTableRow
                          key={row.id}
                          row={row}
                          selected={false}
                          columnsConfig={TABLE_COLUMNS_CONFIG}
                          onShowDetails={(id) => onShowDetails(id, true)}
                          onOPenEditDialog={onOPenEditDialog}
                        />
                      );
                    })}
                  <TableNoData isNotFound={!isLoading && howItWorksList?.howItWorksList?.rows?.length === 0} title={t('adds.workstitle_no_data')} />

                  <TableLoadingData isLoading={isLoading} />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {isFullFilled && (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={howItWorksList ? howItWorksList.total : 0}
                rowsPerPage={rowsPerPage}
                page={howItWorksList && page ? page - 1 : 0}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t('common.rowsperpage')}
                sx={{
                  '& .MuiInputBase-root': {
                    maxWidth: '60px',
                  },
                }}
              />
            </Box>
          )}
        </Container>
      </RootStyle>
    </>
  );
}

export default HowItWorksDetails;
