/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Stack, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AlertIcon } from '../../../assets/icons/alert-danger.svg';
import Iconify from '../../Iconify';
import { ConfirmLogoutDialogProps } from './confirm-logout-dialog.types';
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmLogoutDialog({ onClose, open, handleLogout }: ConfirmLogoutDialogProps) {
  const { t } = useTranslation();
  return (
    <>
      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={onClose} fullWidth>
        <DialogTitle>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {t('common.confirm_logout_title')}
            </Typography>
            <Iconify icon={AlertIcon} />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t('common.confirm_logout_message')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleLogout}
            sx={{
              backgroundColor: '#ff574d',
              borderRadius: '0px',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#ff574d',
                color: '#FFFFFF',
              },
            }}
          >
            {t('common.Logout')}
          </Button>
          <Button
            onClick={onClose}
            sx={{
              backgroundColor: '#ebe5e5',
              borderRadius: '0px',
              color: '#000000',
              '&:hover': {
                backgroundColor: '#ebe5e5',
                color: '#000000',
              },
            }}
          >
            {t('common.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
