import { config } from '../config/config';

export const CONSTANTS = {
  TOKEN: 'token',
  USER_ID: 'userId',
  FRENCH_ABV: 'fr',
  ENGLISH_ABV: 'en',
  LOCAL_STORAGE_TOKEN: 'accessToken',
  ADMIN_DATA: '45@76gj@nk%',
  ROLE: 'Role',
  LOCAL_STORAGE_REFRESH_TOKEN: 'refreshToken',
  BEARER_KEY: 'Bearer',
  REFRESH_KEY: 'Refresh',
  AUTHORIZATION_KEY: 'Authorization',
  MIN_PASSWORD_LENGTH: 8,
  UNAUTHORIZED_STATUS: 423,
  MODEL_NOT_FOUND: 404,
  UNAUTHORIZED_TOKEN: 401,
  DEFAULT_PAGE: 1,
  DEFAULT_PER_PAGE: 25,
  User: 'user',
  Signalement: 'signalement',
  Companies: 'companies',
  Articles: 'Articles',
  Opportunities: 'Opportunités',
  Bugs: 'bugs',
  Flags: 'flags',
  TYPE: 'Type',
  RAISON: 'Raison',
  TIMEOUT: 180000,
  COLORED: 'colored',
  DASHBOARD: 'Tableau de bord',
  VH: 'Ventes hebdomadaire',
  NDTV: "Nombre d'oeuvres totales en ventes",
  NDTVENDUES: "Nombre d'oeuvres totales vendues",
  BUGS: 'Bugs',
  APPROVED_ADDS: 'APPROVED ADDS',
  NA: "Nombre d'abonnements",
  EXPORT: 'Export',
  SVG: 'Exporter SVG',
  PNG: 'Exporter PNG',
  CSV: 'Exporter CSV',
  USER: 'Utilisateur',
  TITRE: 'Titre',
  EMAIL: 'E-mail',
  STATUS: 'statut',
  DATE: 'date',
  FLAGS_DATE: 'dateFlags',
  OUVERT: 'Ouvert',
  OUVERT_EN: 'Open',
  FERME: 'Fermé',
  FERME_EN: 'Close',
  ANNULER: 'Annuler',
  TIME_OUT_ERROR_MESSAGE: "Délai d'attente dépassé, veuillez réessayer plus tard",
  SESSION_EXPIRED: 'Votre session est expirée',
  INVALIDE_REFRESH_TOKEN: 'Invalid refreshToken',
  INVALID_TOKEN: 401,
  SAVE_SUCCESS: 'Enregistrement avec succès',
  SETTING_SAVE_SUCCESS: 'Paramètres mis à jour avec succès',
  ECHEC_MAJ_PROFIL: 'Échec de mise à jour de profil',
  ECHEC_MAJ_SETTINGS: 'Échec de la mise à jour des paramètres',
  ECHEC_MAJ_CAMPAGNE: 'Échec de mise à de campagne',
  SUCCES_MAJ_CAMPAGNE: 'Campagne mise à jour avec succès',
  STRING_TYPE: 'string',
  PHOTO: 'Photo',
  CATEGORY: 'CATEGORIE',
  DATE_PUB: 'DATE DE PUBL.',
  OPEN: 'open',
  OPEN_FR: 'Ouvert',
  REPORTED: 'reported',
  REPORTED_FR: 'Signalé',
  DRAFT_FR: 'Brouillon',
  DRAFT_EN: 'Draft',
  DRAFT: 'DRAFT',
  PUBLISHED_FR: 'Publié',
  PUBLISHED: 'PUBLISHED',
  PUBLISHED_en: 'Published',
  DELETED_FR: 'Supprimé',
  DELETED_EN: 'Deleted',
  DELETED: 'DELETE',
  CLOSED: 'closed',
  CLOSED_FR: 'Fermé',
  TREATED: 'treated',
  TREATED_FR: 'Traité',
  IGNORED: 'ignored',
  IGNORED_FR: 'Non traité',
  PODCAST: 'Podcast',
  ARTICLE: 'Article',
  STATUSS: 'STATUS',
  Image: 'image',
  START_DATE: 'Date de début',
  END_DATE: 'Date de fin',
  NB_CANDIDATS: 'NB. CANDIDATS',
  COLLABORATION_TYPE: 'Collaboration',
  EXPOSITION_TYPE: 'Exposition',
  EXPOSITION_TYPE_EN: 'Exposure',
  ARTICLE_CATEGORY: 'articleCategory',
  ARTICLE_STATUS: 'articleStatus',
  OPPORTUNITY_TYPE: 'opportunityType',
  PERIODE: 'Période',
  PODCAST_TO_SEND: 'PODCAST',
  ARTICLE_TO_SEND: 'ARTICLE',
  FLAGS_BADGE: 'SIGNALEMENTS',
  BUGS_BADGE: 'BUGS',
  OPPORTUNITIES_BADGE: 'OPPORTUNITIES',
  DELETE_STATUS: 'status=DELETE',
};
export const allowedItemTypes = ['normal', 'mail', 'date', 'dateFlags', 'image'];
export const FILE_MAX_SIZE = Number(config.FILE_MAX_SIZE) * 1000000;
export const toolbar =
  'styleselect fontselect fontsizeselect fontsize|bold italic underline forecolor|align|bullist numlist|indent | cut copy past prview |forcecolor backcolor';
