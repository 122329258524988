import React, { useEffect, useState, ChangeEvent } from 'react';
import { Box, Container, Table, TableBody, TableContainer, TablePagination, Stack, SelectChangeEvent, MenuItem, Select, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TablePaginationActions } from '../table/TablePaginationActions/TablePaginationActions';
import TableLoadingData from '../table/TableLoadingData';
import TableNoData from '../table/TableNoData';
import { TABLE_COLUMNS_CONFIG, TABLE_COLUMNS_CONFIG_ARTS, initialPaginationState, TABLE_COLUMNS_CONFIG_NO } from './ArtUsers.constant';
import { RootStyle } from '../../pages/systemusers/SystemUser.styles';
import SearchIcon from '../svgComponents/searchIcon';
import TableHeadCustom from '../table/table-head/CustomTableHead';
import AdsUsers from '../../models/AdsUsers';
import { useDebounce } from '@uidotdev/usehooks';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store/rootStore';
import useTable from '../../hooks/useTable';
import { ContainerStyle, CustomTitle, GridStyle, InputStyle } from '../../utils/toolbar/CommonToolbar.styles';
import ArtusersTableRow from './artUsers-row/ArtUsersTableRow';
import useSnackBar from '../../hooks/useSnackBar';
import { artsUserApi } from '../../store/arts/api/ArtUsers.api';
import { ArtsUsersActionTypes, IArtsUsersResponse, IArtsUsersState } from '../../store/arts/slice/ArtUser-slice.types';
import ConfirmDeleteDialog from '../../utils/confirm-delete-dialog/ConfirmDeleteDialog';

const { addArtsOfWeek } = artsUserApi.endpoints;

function ArtUsersDetails() {
  const { t } = useTranslation();
  const [filterKeyword, setfilterKeyword] = useState('');
  const [selectedOption, setSelectedOption] = useState('all');
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, ArtsUsersActionTypes>>();
  const { arts, isLoading } = useSelector<RootState, IArtsUsersState>((state: RootState) => state[artsUserApi.reducerPath]);
  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialPaginationState);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]);
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    if (selectedOption === 'all') {
      dispatch(
        artsUserApi.endpoints.getAllArts({
          page,
          limit: rowsPerPage,
          orderBy,
          order,
          keyword: debouncedSearchTerm,
        }),
      );
    } else if (selectedOption === 'week') {
      dispatch(
        artsUserApi.endpoints.getArtOfTheWeek({
          page,
          limit: rowsPerPage,
          orderBy,
          order,
          keyword: debouncedSearchTerm,
        }),
      );
      setSelectedCheckboxes([]);
    }
  }, [selectedOption, order, orderBy, page, rowsPerPage, debouncedSearchTerm, dispatch]);

  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
    setPage(0);
  };

  const { alertSuccess, alertError } = useSnackBar();

  const handleApplyChange = () => {
    const data = {
      artIds: selectedCheckboxes,
    };
    dispatch(addArtsOfWeek(data))
      .then(() => {
        dispatch(alertSuccess(t('common.created_five_arts')));
        setSelectedCheckboxes([]);
        dispatch(
          artsUserApi.endpoints.getAllArts({
            page,
            limit: rowsPerPage,
            orderBy,
            order,
            keyword: debouncedSearchTerm,
          }),
        );
      })
      .catch((err) => {
        dispatch(alertError(err.message)); // Show error message in alert
      });
  };

  const handleCheckboxChange = (checkboxId: number | undefined) => {
    const updatedSelectedIds = selectedCheckboxes.includes(checkboxId || 0)
      ? selectedCheckboxes.filter((ids) => ids !== checkboxId)
      : [...selectedCheckboxes, checkboxId || 0];

    setSelectedCheckboxes(updatedSelectedIds.filter((id) => typeof id === 'number'));
  };

  useEffect(() => {
    if (
      selectedOption === 'all' &&
      arts &&
      arts.currentArtOfTheWeekCount !== 5 &&
      selectedCheckboxes?.length >= 5 - ((arts && arts.currentArtOfTheWeekCount) ?? 0)
    ) {
      dispatch(alertSuccess(`You can select maximum ${5 - ((arts && arts.currentArtOfTheWeekCount) ?? 0)} arts of the week only.`));
    }
  }, [selectedCheckboxes, selectedOption]);

  const isFullFilled = !isLoading && arts && arts?.arts?.length > 0;

  const onOPenDeleteDialog = (id?: number) => {
    setSelectedId(id);
    setConfirmDeleteOpen(true);
  };

  const onCloseConfirmDeleteDialog = () => {
    setSelectedId(undefined);
    setConfirmDeleteOpen(false);
    setfilterKeyword('');
  };

  const onDeleteAristst = async () => {
    try {
      if (selectedId) {
        const data = {
          artIds: [selectedId],
        };
        await dispatch(artsUserApi.endpoints.deleteArtUser(data));
        onCloseConfirmDeleteDialog();
        dispatch(alertSuccess(t('interests.art.delete_success_message')));
        if (arts?.next === null && arts?.total <= page * rowsPerPage) {
          setPage(page - 1);
        }
        dispatch(
          artsUserApi.endpoints.getArtOfTheWeek({
            page,
            limit: rowsPerPage,
            orderBy,
            order,
            keyword: debouncedSearchTerm,
          }),
        );
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
    }
  };

  return (
    <>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        onClose={onCloseConfirmDeleteDialog}
        onDelete={onDeleteAristst}
        confirm_delete_title={t('systemuser.confirm_delete_title')}
        confirm_delete_message={t('common.confirm_delete_art_message')}
      />
      <RootStyle>
        <ContainerStyle style={{ padding: '0 24px' }}>
          <GridStyle>
            <CustomTitle>{t('common.arts_of_week')}</CustomTitle>
            <Stack direction="column" spacing={4}>
              <InputStyle
                value={filterKeyword}
                placeholder={t('common.search')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterKeyword(e.target.value)}
                InputProps={{
                  startAdornment: <SearchIcon />,
                }}
              />
            </Stack>
          </GridStyle>
        </ContainerStyle>
        <div style={{ marginLeft: 'auto', marginBottom: '10px' }}>
          <Select
            value={selectedOption}
            onChange={(event: SelectChangeEvent) => {
              setSelectedOption(event.target.value);
              setPage(0);
            }}
            style={{ minWidth: 120 }}
          >
            <MenuItem value={'all'}>All Arts</MenuItem>
            <MenuItem value={'week'}>Arts of the Week</MenuItem>
          </Select>
          {selectedOption === 'all' && (
            <Button
              variant="contained"
              style={{
                cursor: 'pointer',
                backgroundColor: selectedCheckboxes?.length <= 0 ? '#0000001f' : '#1976d2',
                color: '#fff',
                marginLeft: '10px',
                paddingBottom: '16px',
                paddingTop: '17px',
                height: '58px',
                marginTop: '-2px',
              }}
              onClick={handleApplyChange}
              disabled={selectedCheckboxes?.length <= 0}
            >
              Apply
            </Button>
          )}
        </div>
        <Container sx={{ minHeight: '70vh' }}>
          <div className="table-block">
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  columnsConfig={
                    selectedOption === 'all' && isFullFilled
                      ? TABLE_COLUMNS_CONFIG
                      : selectedOption === 'week'
                      ? TABLE_COLUMNS_CONFIG_ARTS
                      : TABLE_COLUMNS_CONFIG_NO
                  }
                  onSort={onSort}
                />
                <TableBody>
                  {isFullFilled &&
                    (arts as IArtsUsersResponse)?.arts?.map((row: AdsUsers) => {
                      return (
                        <ArtusersTableRow
                          key={row.id}
                          row={row}
                          handleCheckboxChange={handleCheckboxChange}
                          selectedCheckboxes={selectedCheckboxes}
                          selected={false}
                          columnsConfig={selectedOption === 'all' ? TABLE_COLUMNS_CONFIG : TABLE_COLUMNS_CONFIG_ARTS}
                          artCount={arts.currentArtOfTheWeekCount ?? 0}
                          onOPenDeleteDialog={onOPenDeleteDialog}
                        />
                      );
                    })}
                  <TableNoData isNotFound={!isLoading && arts?.arts?.length === 0} title={t('common.no_data')} />
                  <TableLoadingData isLoading={isLoading} />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {isFullFilled && (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={arts ? arts.total : 0}
                rowsPerPage={rowsPerPage}
                page={arts && page ? page - 1 : 0}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t('common.rowsperpage')}
                sx={{
                  '& .MuiInputBase-root': {
                    maxWidth: '60px',
                  },
                }}
              />
            </Box>
          )}
        </Container>
      </RootStyle>
    </>
  );
}

export default ArtUsersDetails;
