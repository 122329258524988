/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HowItWorksFormProps } from './HowItWorks-form.types';
import RHFTextField from '../../../../hook-form/RHFTextField';
import AddPhotoIcon from '../../../../svgComponents/AddPhotoIcon';
import AdminVideoControl from './VideoControl';
import FullScreenModal from './FullScreenModal';
import HowitWorks from '../../../../../models/HowItWorks';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateWorksSchema } from './create-works.schema';
import { theme } from '../../../../../theme/theme';

export default function ViewHowItWorks({ currentWorkData, isUpdate, onClose, isEyeIconClicked }: HowItWorksFormProps) {
  const { t } = useTranslation();

  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);

  const [postURL, setPostURL] = useState<string | undefined>(undefined);
  const [postType, setPostType] = useState<string | undefined>(undefined);
  const [post, setPost] = useState<string | undefined>(undefined);
  const [postError, setPostError] = useState<string | undefined>(undefined);

  const [videoURL, setVideoURL] = useState<string | undefined>(undefined);
  const [videoType, setVideoType] = useState<string | undefined>(undefined);
  const [largeImgUrl, setLargeImgUrl] = useState<string | undefined>(undefined);
  const [openImgModel, setOpenImgModal] = useState(false);

  const customStyle = {
    marginBottom: '0.5rem',
    fontFamily: 'SFPro-Regular',
    color: theme.palette.secondary.dark,
  };

  useEffect(() => {
    if (currentWorkData) {
      const posturl = currentWorkData?.imageOrVideoUrl || '';
      const postExtension = posturl.split('.').pop()?.toLowerCase() || '';
      setPostURL(posturl);
      setPostType(postExtension);
      setPost(posturl);
    }
  }, [currentWorkData]);

  const defaultValues = useMemo<HowitWorks>(
    () => ({
      title: currentWorkData?.title || '',
      description: currentWorkData?.description || '',
      imageOrVideoUrl: currentWorkData?.imageOrVideoUrl || '',
    }),
    [currentWorkData],
  );

  const methods = useForm<HowitWorks>({
    resolver: yupResolver(CreateWorksSchema),
    defaultValues,
    mode: 'all',
  });

  const { reset, watch, setValue } = methods;

  const values = watch();

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentWorkData]);

  useEffect(() => {
    // Update defaultValues whenever currentWorkData or isUpdate changes
    const updatedDefaultValues = {
      title: currentWorkData?.title || '',
      description: currentWorkData?.description || '',
      imageOrVideoUrl: currentWorkData?.imageOrVideoUrl || '',
    };
    methods.reset(updatedDefaultValues);
  }, [currentWorkData, isUpdate]);

  const handleLogoUpload = (e: any) => {
    const logoFile = e.target.files[0];
    const imageUrl = URL.createObjectURL(logoFile);
    const fileType = logoFile?.type.split('/')[1];
    if (logoFile) {
      if (logoFile.type.includes('image') || logoFile.type.includes('video')) {
        if (logoFile.size >= 5 * 1024 * 1024) {
          setPostError(t('adds.file_size_error'));
          setPost(undefined);
          setPostURL(undefined);
          setPostType(undefined);
        } else {
          setPostError(undefined);
          setPost(logoFile);
          setValue('imageOrVideoUrl', logoFile);
          setPostType(fileType);
          setPostURL(imageUrl || undefined);
        }
      } else {
        setPostError('Please choose an image/video file.');
        setPost(undefined);
        setPostURL(undefined);
        setPostType(undefined);
      }
    }
  };

  const openVideoPopup = (type: string) => {
    if (type === 'post') {
      setVideoURL(postURL);
      setVideoType(postType);
    }
    setVideoPopupOpen(true);
  };

  const closeVideoPopup = () => {
    setVideoPopupOpen(false);
  };

  const handleClickOpenModal = (imageurl: string) => {
    setLargeImgUrl(imageurl || '');
    setOpenImgModal(true);
  };
  const handleClickCloseImgModal = () => {
    setLargeImgUrl('');
    setOpenImgModal(false);
  };
  return (
    <>
      <Grid className="artgapi-modal" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box sx={{ p: 3 }}>
            <Box sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <div className="col">
                  <Typography>{t('adds.form_fields.title.placeholder')}</Typography>
                  <label style={customStyle}>{values?.title}</label>
                </div>
                <div className="col">
                  <Typography>{t('adds.form_fields.description.placeholder')}</Typography>
                  <label style={customStyle}>{values?.description}</label>
                </div>
              </Box>
            </Box>

            {/* Advertisement plan type Information Section */}
            <Box>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 3,
                  rowGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                }}
              >
                {values?.imageOrVideoUrl && (
                  <Grid>
                    <div>
                      <Typography>{t('adds.form_fields.imageOrVideoUrl.placeholder')}</Typography>
                      <div style={{ display: 'flex', marginBottom: '0', alignItems: 'center' }}>
                        {postURL ? (
                          <>
                            <div>
                              <span>
                                {postType?.includes('mp4') ? (
                                  <>
                                    <div
                                      style={{
                                        width: '100px',
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative',
                                      }}
                                    >
                                      <video
                                        style={{
                                          cursor: 'pointer',
                                          width: '100%',
                                          objectFit: 'cover',
                                          height: '100%',
                                        }}
                                        src={postURL}
                                        onClick={() => openVideoPopup('post')}
                                      ></video>

                                      <span
                                        style={{
                                          backgroundColor: 'white',
                                          display: 'flex',
                                          alignItems: 'center',
                                          borderRadius: '9999px',
                                          position: 'absolute',
                                          width: '1.5 rem',
                                          height: '1,5 rem',
                                          cursor: 'pointer',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <PlayCircleOutlineIcon className="text-base" onClick={() => openVideoPopup('post')} />
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <img
                                    src={postURL}
                                    alt="post"
                                    style={{
                                      width: '100px',
                                      height: '100px',
                                      objectFit: 'contain',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => handleClickOpenModal(postURL || '')}
                                  />
                                )}
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '0px' }}>
                              <label
                                htmlFor="logos"
                                style={{
                                  width: '44px',
                                  height: '44px',
                                  minWidth: '44PX',
                                  backgroundColor: '#f0f0f0',
                                  border: '1px dashed #ccc',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <AddPhotoIcon />
                              </label>
                            </div>
                          </>
                        )}
                        {!isEyeIconClicked && (
                          <Button
                            className="artgapi-upload-btn"
                            style={{
                              textTransform: 'capitalize',
                              boxShadow: 'inset 0px -1px 0px #E7E7E7',
                              marginLeft: '15px',
                              backgroundColor: isEyeIconClicked ? '#ffcc33' : '#ffcc33',
                            }}
                            size="small"
                            variant="contained"
                            component="label"
                          >
                            {t('articles.upload')}
                            <input hidden type="file" name="" id="add-post" onChange={(e) => handleLogoUpload(e)} />
                          </Button>
                        )}
                      </div>
                      {postError && <p style={{ color: '#d32f2f', marginTop: '0.5rem' }}>{postError}</p>}
                    </div>
                  </Grid>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {isVideoPopupOpen && (
        <AdminVideoControl
          isVideoPopupOpen={isVideoPopupOpen}
          closeVideoPopup={closeVideoPopup}
          videourl={videoURL || ''}
          selectedVideo={videoType || ''}
        />
      )}
      {openImgModel && <FullScreenModal openImgModel={openImgModel} closeImgPopup={handleClickCloseImgModal} largeImgUrl={largeImgUrl || ''} />}
    </>
  );
}
