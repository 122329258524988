/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { RootState } from '../../rootStore';
import { IFetchCategoriesSuccessAction, CategoriesActionTypes } from '../slice/Categories-slice.types';
import { categoriesActions, categoriesReducer } from '../slice/Categories-slice';
import { Categories } from '../../../models/Categories';

export const categoriesApi = {
  reducerPath: 'categoryUsers',
  reducer: categoriesReducer,
  endpoints: {
    getCategoriesUsers: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, CategoriesActionTypes>) => {
      dispatch(categoriesActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.CATEGORY_USERS, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(categoriesActions.matchFulFilled(responseBody, 'fetch') as IFetchCategoriesSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(categoriesActions.matchRejected(error));
        });
    },
    updateCategories: (id: number, data: any) => async (dispatch: ThunkDispatch<RootState, undefined, CategoriesActionTypes>) => {
      dispatch(categoriesActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Patch, `${ENDPOINTS.CATEGORY_USERS}/${id}`, data, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(categoriesActions.matchFulFilled(responseBody, 'update') as IFetchCategoriesSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(categoriesActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },
    createCategoryUser: (data: any) => async (dispatch: ThunkDispatch<RootState, undefined, CategoriesActionTypes>) => {
      dispatch(categoriesActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Post, ENDPOINTS.CATEGORY_USERS, data, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(categoriesActions.matchFulFilled(responseBody, 'create') as IFetchCategoriesSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(categoriesActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },
    deleteCategoryUser: (id: number) => async (dispatch: ThunkDispatch<RootState, undefined, CategoriesActionTypes>) => {
      dispatch(categoriesActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Delete, `${ENDPOINTS.CATEGORY_USERS}/${id}`, {}, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(categoriesActions.matchFulFilled(responseBody, 'delete') as IFetchCategoriesSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(categoriesActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },
  },
};
