/* eslint-disable @typescript-eslint/no-explicit-any */
import HowitWorks from '../../../models/HowItWorks';
import {
  ActionTypes,
  IFetchHowItWorksRejectedAction,
  IFetchHowItWorksSuccessAction,
  iHowItWorksPendingAction,
  IHowItWorksResponse,
  IHowItWorksState,
  HowItWorksActionTypes,
} from './HowItWorks-slice.types';

export const initialState: IHowItWorksState = {
  isLoading: false,
  error: null,
  howItWorksList: null,
  workUsers: null,
};

export const howItWorksReducer = (state = initialState, action: HowItWorksActionTypes): IHowItWorksState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_HOWITWORKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        howItWorksList: action.payload as IHowItWorksResponse,
      };
    case ActionTypes.FETCH_HOWITWORKS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_HOWITWORKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        workUsers: action.payload as HowitWorks,
      };
    default:
      return state;
  }
};

const matchPending = (): iHowItWorksPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (data: IHowItWorksResponse, action: 'fetch' | 'update'): IFetchHowItWorksSuccessAction | HowitWorks => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_HOWITWORKS_SUCCESS : ActionTypes.CREATE_HOWITWORKS_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchHowItWorksRejectedAction => {
  return {
    type: ActionTypes.FETCH_HOWITWORKS_FAILURE,
    payload: error,
  };
};

export const howItWorksActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
