import { usersActionTypes, UsersState } from './models';
import { GET_USERS_ATTEMPT, GET_USERS_ERROR, GET_USERS_SUCCESS } from './types';

const INITIAL_STATE: UsersState = {
  loader: false,
  error: '',
  users: undefined,
};
export const usersReducer = (state = INITIAL_STATE, action: usersActionTypes): UsersState => {
  switch (action.type) {
    case GET_USERS_ATTEMPT:
      return { ...state, loader: true, error: '' };
    case GET_USERS_SUCCESS:
      return { ...state, loader: false, users: action.users };
    case GET_USERS_ERROR:
      return { ...state, loader: false, error: action.error };

    default:
      return state;
  }
};
