/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { RootState } from '../../rootStore';
import { ITransactionReportSuccessAction, TransactionReportActionTypes } from '../slice/TransactionReports-slice.types';
import { transactionReportActions, transactionReportReducer } from '../slice/TransactionReports-slice';

export const transactionReportApi = {
  reducerPath: 'transactionUsers',
  reducer: transactionReportReducer,
  endpoints: {
    getTransactionReportsUsers: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, TransactionReportActionTypes>) => {
      dispatch(transactionReportActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.GET_TRANSACTION_REPORTS, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(transactionReportActions.matchFulFilled(responseBody, 'fetch') as ITransactionReportSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(transactionReportActions.matchRejected(error));
        });
    },
  },
};
