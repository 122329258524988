/* eslint-disable @typescript-eslint/no-explicit-any */
import { RevenueReport } from '../../../models/RevenueReport';
import {
  ActionTypes,
  IFetchRevenueReportsRejectedAction,
  IFetchRevenueReportsSuccessAction,
  IRevenueReportsAction,
  IRevenueReportsResponse,
  IRevenueReportsState,
  RevenueReportsActionTypes,
} from './RevenueReports-slice.types';

export const initialState: IRevenueReportsState = {
  isLoading: false,
  error: null,
  users: null,
  revenueReports: null,
};

export const revenueReportReducer = (state = initialState, action: RevenueReportsActionTypes): IRevenueReportsState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_REVENUE_REPORTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.payload as IRevenueReportsResponse,
      };
    case ActionTypes.FETCH_REVENUE_REPORTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_REVENUE_REPORTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        revenueReports: action.payload as RevenueReport,
      };
    default:
      return state;
  }
};

const matchPending = (): IRevenueReportsAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (data: IRevenueReportsResponse, action: 'fetch'): IFetchRevenueReportsSuccessAction | RevenueReport => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_REVENUE_REPORTS_SUCCESS : ActionTypes.CREATE_REVENUE_REPORTS_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchRevenueReportsRejectedAction => {
  return {
    type: ActionTypes.FETCH_REVENUE_REPORTS_FAILURE,
    payload: error,
  };
};

export const revenueReportsActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
