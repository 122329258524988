import * as yup from 'yup';

export const ServicePlanSchema = yup.object().shape({
  price: yup.string().required('adds.form_fields.planPrice.required_error_message'),
  description: yup.string().required('adds.form_fields.planDescription.required_error_message'),
});

export const CreateSubscriptionPLanSchema = yup.object().shape({
  plan_name: yup.string().required('adds.form_fields.planname.required_error_message'), // Adjusted error message
  plan_description: yup.string().required('adds.form_fields.description.required_error_message'), // Adjusted error message
  ServicePlans: yup.array().of(ServicePlanSchema),
});
