export const articles = {
  articles: 'ARTICLES',
  picture: 'PHOTO',
  title: 'TITLE',
  category: 'Category',
  status: 'STATUS',
  create_Article: 'Create an article',
  description: 'Item Description',
  podcast_url: 'Podcast URL',
  all: 'All',
  category_article: 'Article',
  category_podcast: 'Podcast',
  draft_status: 'Draft',
  published_status: 'Published',
  deleted_status: 'Deleted',
  article_success_deleted: 'Article successfully deleted',
  article_status_success_changed: 'Article successfully deleted',
  delete_message: 'Are you sure you want to delete this media?',
  content: 'Content',
  upload: 'Upload',
  save_article: 'Save and exit',
  do_not_save: 'Do not save',
  creation_failure: 'Failed to create an article',
  update_failure: 'Failed to edit an item',
  author: 'Author',
  author_image: 'Image of the author',
  article_url: 'Article URL',
  articleurl: 'Article url is invalid',
  formats: 'Accepted formats: jpeg, png',
  max_size: 'Maximum size: 2 MB',
};
