/* eslint-disable react/react-in-jsx-scope */
// @mui
import { Box, Typography } from '@mui/material';
import { SxProps, styled } from '@mui/material/styles';
import { ReactComponent as NotFoundIcon } from '../assets/icons/ic_no_data.svg';
//

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(8, 2),
}));

// ----------------------------------------------------------------------

interface EmptyContentProps {
  title: string;
  description?: string;
  sx?: SxProps;
}

export default function EmptyContent({ title, description, sx }: EmptyContentProps) {
  return (
    <RootStyle sx={sx}>
      <Box component={NotFoundIcon} sx={{ width: '200px', height: '200px', objectFit: 'cover' }} />

      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>

      {description && (
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {description}
        </Typography>
      )}
    </RootStyle>
  );
}
