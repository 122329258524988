import React from 'react';

export const ArrowUp = () => {
  return (
    <svg width="8" height="5" style={{ marginTop: '5px' }} viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 5L0 0H10L5 5Z" fill="#1C1B1F" />
    </svg>
  );
};

export default ArrowUp;
