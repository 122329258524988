/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import React, { forwardRef } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Dialog, DialogContent, DialogTitle, IconButton, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface AdminVideoControlProps {
  isVideoPopupOpen: boolean;
  closeVideoPopup: () => void;
  videourl: string;
  selectedVideo: string;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminVideoControl: React.FC<AdminVideoControlProps> = ({
  isVideoPopupOpen,
  closeVideoPopup,
  videourl,
  selectedVideo,
}: AdminVideoControlProps) => {
  return (
    <>
      <Dialog open={isVideoPopupOpen} onClose={closeVideoPopup} fullWidth TransitionComponent={Transition} keepMounted>
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton onClick={closeVideoPopup}>
              <CloseIcon color="primary" fontSize="large" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <video width="30%" height="auto" controls autoPlay>
            <source src={videourl} type={`video/${selectedVideo}`} />
            Your browser does not support the video tag.
          </video>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdminVideoControl;
