/* eslint-disable react/react-in-jsx-scope */
// @mui
import { TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TableCellStyle, TableSortLableStyle } from './CustomTableHead.styles';
import { CustomTableHeadProps } from './CustomTableHead.types';

// ----------------------------------------------------------------------

export default function CustomTableHead({ order, orderBy, columnsConfig, onSort, sx }: CustomTableHeadProps) {
  const { t } = useTranslation();
  return (
    <TableHead sx={sx}>
      <TableRow>
        {columnsConfig.map((column) => (
          <TableCellStyle
            key={column.id}
            align={column.align || 'left'}
            sortDirection={orderBy === column.id ? order : false}
            width={column.width}
            minwidth={column.minWidth}
          >
            {column.isNotSortable || !onSort ? (
              <TableSortLableStyle hideSortIcon style={{ cursor: 'default' }}>
                {t(column.label)}
              </TableSortLableStyle>
            ) : (
              <TableSortLableStyle
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={() => onSort(column.id)}
                sx={{
                  '.MuiTableSortLabel-icon': {
                    opacity: 1, // Ensure the icon is always visible
                    fontSize: '1rem',
                  },
                }}
              >
                {t(column.label)}
              </TableSortLableStyle>
            )}
          </TableCellStyle>
        ))}
      </TableRow>
    </TableHead>
  );
}
