import { InputLabel, TextField, styled } from '@mui/material';

export const RHFTextFieldStyle = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '0px',
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const CustomLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  marginBottom: '0.5rem',
  fontFamily: 'SFPro-Regular',
}));
