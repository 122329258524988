import { SystemUser } from '../../../models/SystemUser';

export interface ISystemUserResponse {
  total: number;
  next: number | null;
  previous: number | null;
  users: SystemUser[];
}

export interface ISystemUserState {
  isLoading: boolean;
  data: ISystemUserResponse | null;
  systemuser: SystemUser | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_SYSTEMUSER_SUCCESS = 'FETCH_SYSTEMUSER_SUCCESS',
  FETCH_SYSTEMUSER_FAILURE = 'FETCH_SYSTEMUSER_FAILURE',
  CREATE_SYSTEMUSER_SUCCESS = 'CREATE_SYSTEMUSER_SUCCESS',
}

export interface ISystemUserPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchSystemUserSuccessAction {
  type: typeof ActionTypes.FETCH_SYSTEMUSER_SUCCESS | typeof ActionTypes.CREATE_SYSTEMUSER_SUCCESS;
  payload: ISystemUserResponse | SystemUser;
}

export interface IFetchSystemUserRejectedAction {
  type: typeof ActionTypes.FETCH_SYSTEMUSER_FAILURE;
  payload: string;
}

export type SystemUserActionTypes = ISystemUserPendingAction | IFetchSystemUserSuccessAction | IFetchSystemUserRejectedAction;
