import { languageActionTypes, LanguageState } from './models';
import { GET_LANGUAGES_ATTEMPT, GET_LANGUAGES_ERROR, GET_LANGUAGES_SUCCESS } from './types';

const INITIAL_STATE: LanguageState = {
  loader: false,
  error: '',
  languages: undefined,
};
export const languagesReducer = (state = INITIAL_STATE, action: languageActionTypes): LanguageState => {
  switch (action.type) {
    case GET_LANGUAGES_ATTEMPT:
      return { ...state, loader: true, error: '' };
    case GET_LANGUAGES_SUCCESS:
      return { ...state, loader: false, languages: action.languages };
    case GET_LANGUAGES_ERROR:
      return { ...state, loader: false, error: action.error };

    default:
      return state;
  }
};
