import React from 'react';
import { Button, TableRow, Stack, IconButton, Tooltip } from '@mui/material';
import { TableCellStyle } from './ApprovedLogsRow.styles';
import { ApprovedLogsRowProps } from './ApprovedLogsRow.types';
import EyeIcon from '../../../../svgComponents/EyeIcon';
import { useTranslation } from 'react-i18next';

export default function ApprovedLogsTableRow({ row, selected, onShowDetails, columnsConfig }: ApprovedLogsRowProps) {
  const { id, createdAt } = row;
  const { t } = useTranslation();

  const formatDate = (dateString: string) => {
    const dateTime = new Date(dateString);
    const formattedDate = `${dateTime.toLocaleDateString()} à ${dateTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`;
    return formattedDate;
  };

  return (
    <TableRow key={id} hover selected={selected} sx={{ backgroundColor: '#ffffff' }}>
      {columnsConfig.map((column) => (
        <TableCellStyle key={column.id} align={column.align || 'left'} width={column.width}>
          {column.id === 'status' ? (
            <Button variant="contained" size="small" style={{ color: '#fff', backgroundColor: '#1AB53C' }} disabled>
              {t('adds.approved')}
            </Button>
          ) : column.id === 'createdAt' && createdAt ? (
            formatDate(createdAt)
          ) : column.id !== 'actions' ? (
            String(row[column.id as keyof typeof row])
          ) : (
            <Stack direction="row" spacing={2}>
              <Tooltip title="View Details" arrow>
                <IconButton
                  onClick={() => onShowDetails(id, true)}
                  sx={{
                    padding: '0px !important',
                  }}
                >
                  <EyeIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </TableCellStyle>
      ))}
    </TableRow>
  );
}
