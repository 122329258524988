import React from 'react';
import { Grid, ThemeProvider } from '@mui/material';
import { theme } from '../theme/theme';
import { GlobalProvider } from '../context/globalContext';
import { CONSTANTS } from '../utils';
import Header from '../components/header/header';
import SubAdminHeader from '../components/header/subadminheader';

/**
 * Component Layout
 *
 * @component
 *
 * @example
 * return (
 *   <Layout>...</Layout>
 * )
 */
interface Layoutprops {
  children: React.ReactNode;
}

export const Layout = (props: Layoutprops) => {
  const roles = localStorage.getItem(CONSTANTS.ROLE);
  const parsedRoles = roles ? JSON.parse(roles) : null;

  return (
    <>
      <div className="data-wrapper">
        <div className="drawer-wrapper">{parsedRoles?.name === 'admin' ? <Header /> : <SubAdminHeader />}</div>
        <div className="dashboard-wrapper">{props.children}</div>
      </div>
    </>
  );
};
export default Layout;
