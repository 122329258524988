/* eslint-disable react/react-in-jsx-scope */
// @mui
import { TableCell, TableRow } from '@mui/material';
//
import EmptyContent from '../EmptyContent';

// ----------------------------------------------------------------------

interface TableNoDataProps {
  isNotFound: boolean;
  title: string;
  description?: string;
}

export default function TableNoData({ isNotFound, title, description }: TableNoDataProps) {
  return (
    <>
      {isNotFound && (
        <TableRow>
          <TableCell colSpan={9} sx={{ border: 'none' }}>
            <EmptyContent
              title={title}
              description={description}
              sx={{
                '& span.MuiBox-root': { height: 160 },
              }}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
