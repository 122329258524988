import React from 'react';

export const CheckIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.435 11.9497L12.9497 20.435L8 15.4852" stroke="#1ab53c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CheckIcon;
