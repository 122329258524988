import React from 'react';

export const EyeIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 22C17.4546 22 21.4546 20 27 16C21.4546 12 17.4546 10 15 10C12.5454 10 8.5454 12 3 16C8.5454 20 12.5454 22 15 22Z"
        stroke="#A4A4A5"
        strokeLinejoin="round"
      />
      <path
        d="M15 18C16.1046 18 17 17.1046 17 16C17 14.8954 16.1046 14 15 14C13.8954 14 13 14.8954 13 16C13 17.1046 13.8954 18 15 18Z"
        stroke="#A4A4A5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeIcon;
