import { makeStyles } from '@mui/styles';
import { colors } from '../../theme/sharedTheme';

export const useStyles = makeStyles({
  chart: {
    backgroundColor: 'white',
    '& .dxc-item': {
      display: 'none',
    },
    '& .dxc-val-line path': {
      strokeWidth: '0 !important',
    },
    '& .dxc-arg-line path': {
      stroke: '#e7e7e7',
    },
    '& .dxc-val-grid path': {
      stroke: '#e7e7e7',
    },
    '& .dxc-series rect': {
      width: '35px',
    },
    '& .dx-export-menu-list-item rect': {
      value: 'Exporter',
    },
    '& .dx-export-menu-list rect': {
      fill: '#26272c',
    },
    '& .dx-export-menu-list text': {
      fill: 'white !important',
    },
    '& .dx-export-menu-list path': {
      strokeWidth: '0 !important',
      borderRadius: '4px',
    },
    // dx-export-menu-button
    '& .dx-export-menu-button path': {
      display: 'none',
      fill: 'white',
    },
    '& .dx-export-menu-button rect': {
      strokeWidth: '0 !important',
      fill: 'white',
      dispaly: 'none',
    },
  },
  typ: { color: colors.MAIN_GREY, size: '15px', font: 'SFProText' },
  button: { width: '170px', height: '28px', backgroundColor: '#26272c', color: '#ffffff' },
  link: { color: '#ffffff', textDecoration: 'none' },
});
