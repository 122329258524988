/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Container, Dialog, DialogContent, Divider, Grid, ThemeProvider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Img } from '../../../store/bugs/models';
import { RootState } from '../../../store/rootStore';
import { dialogTheme } from '../../../theme/dialogTheme';
import { CONSTANTS } from '../../../utils';
import Spinner from '../../spinner';
import DialogHeader from '../dialogHeader/dialogHeader';

interface BugDialogProps {
  show: boolean;
  data: any;
  setOpenEdit: any;
  statusTable?: string;
  loadingState?: boolean;
}

export const BugDialog = (props: BugDialogProps) => {
  const { show, data, setOpenEdit, statusTable, loadingState } = props;
  const bug = useSelector((state: RootState) => state.bug.bug);
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState('');

  const closeModal = () => {
    setOpenEdit(!show);
  };

  useEffect(() => {
    bug.data ? setTitle(bug.data.title) : setTitle('');
    if (bug.data) {
      setStatus(bug.data.status);
    }
  }, [bug]);

  const parseImages = (bugImages: any): Img[] | undefined => {
    if (bugImages) {
      const images = JSON.parse(JSON.stringify(bugImages));
      return images;
    }
  };

  return (
    <>
      <ThemeProvider theme={dialogTheme}>
        <Dialog open={show}>
          <DialogHeader
            title={title}
            onClose={closeModal}
            data={data}
            status={status}
            bug={bug}
            name={CONSTANTS.Bugs}
            statusTable={statusTable}
            loadingState={loadingState}
          />
          <DialogContent sx={{ backgroundColor: '#f5f5f5', overflowY: 'auto' }}>
            {loadingState ? (
              <Container sx={{ minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
                <Spinner loading={loadingState} />
              </Container>
            ) : (
              <Grid container direction="column" justifyContent="space-between" spacing={12}>
                <Grid item container xs={12} direction="row">
                  <Grid xs={6} sx={{ ml: 4 }}>
                    <Typography variant="h6">{CONSTANTS.USER}</Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Grid item container direction="row" justifyContent="space-between">
                      <Grid>
                        <Typography variant="h5">Nom Prénom</Typography>
                        <Typography variant="h4">{bug.data && bug.data.user ? bug.data.user.fullname : ''}</Typography>
                      </Grid>
                      <Grid>
                        <Typography variant="h5">Pseudo</Typography>
                        <Typography variant="h4">{bug.data && bug.data.user ? bug.data.user.nickname : ''}</Typography>
                      </Grid>
                    </Grid>
                    <Typography sx={{ mt: 4 }} variant="h5">
                      E-mail
                    </Typography>
                    <Typography variant="h4">{bug.data && bug.data.user ? bug.data.user.mail : ''} </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 3 }} />
                <Grid item container direction="row" xs={12} sx={{ mt: -8 }}>
                  <Grid xs={6} sx={{ ml: 4 }}>
                    <Typography variant="h6">Description</Typography>
                  </Grid>
                  <Grid item container direction="column" xs={4}>
                    <Typography variant="h5">Titre</Typography>
                    <Typography variant="h4">{bug.data ? bug.data.title : ''} </Typography>
                    <Typography sx={{ mt: 2 }} variant="h5">
                      Description
                    </Typography>
                    <Typography variant="h4">{bug.data ? bug.data.description : ''}</Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 2 }} />

                <Grid item container xs={12} sx={{ mt: -8 }}>
                  <Grid xs={6}>
                    <Typography variant="h6" sx={{ ml: 4 }}>
                      Photos
                    </Typography>
                  </Grid>
                  <Grid container xs={5} sx={{ ml: 4 }} display="flex" justifyContent="space-between" spacing={1}>
                    {parseImages(bug?.data?.images)?.map((image) => {
                      return (
                        <a href={image.imageUrl} target="_blank" rel="noopener noreferrer" key={image.imageUrl}>
                          <img
                            key={image.imageUrl}
                            src={image.imageUrl && image.imageUrl}
                            style={{ width: 140, height: 140, objectFit: 'scale-down' }}
                          />
                        </a>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default BugDialog;
