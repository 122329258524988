/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApprovedAds } from '../../../models/ApprovedAds';
import {
  ActionTypes,
  IFetchApprovedAdsRejectedAction,
  IFetchApprovedAdsSuccessAction,
  IApprovedAdsPendingAction,
  IApprovedAdsResponse,
  IApprovedAdsState,
  ApprovedAdsActionTypes,
} from './ApprovedAds-slice.types';

export const initialState: IApprovedAdsState = {
  isLoading: false,
  error: null,
  data: null,
  approvedUsers: null,
};

export const approvedAdsReducer = (state = initialState, action: ApprovedAdsActionTypes): IApprovedAdsState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_APPROVED_ADS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload as IApprovedAdsResponse,
      };
    case ActionTypes.FETCH_APPROVED_ADS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_APPROVED_ADS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        approvedUsers: action.payload as ApprovedAds,
      };
    default:
      return state;
  }
};

const matchPending = (): IApprovedAdsPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (data: IApprovedAdsResponse, action: 'fetch'): IFetchApprovedAdsSuccessAction | ApprovedAds => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_APPROVED_ADS_SUCCESS : ActionTypes.CREATE_APPROVED_ADS_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchApprovedAdsRejectedAction => {
  return {
    type: ActionTypes.FETCH_APPROVED_ADS_FAILURE,
    payload: error,
  };
};

export const approvedAdsActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
