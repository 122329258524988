import { Dialog, styled } from '@mui/material';

export const DetailsDialogStyle = styled(Dialog)({
  margin: '4%',
  '& .MuiDialog-paper': {
    width: '100%',
    height: '95%',
    margin: '0',
    padding: '0',
    borderRadius: '0',
    backgroundColor: '#ffffff',
  },
});
