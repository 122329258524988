import { Dialog, styled } from '@mui/material';

export const AddDialogStyle = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '100%',
    height: '90%',
    margin: '0',
    padding: '0',
    borderRadius: '0',
    backgroundColor: '#ffffff',
  },
});
