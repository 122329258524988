/* eslint-disable react/react-in-jsx-scope */
// @mui
import { Button, IconButton, Stack, TableRow, Tooltip } from '@mui/material';
// components
import { ReactComponent as EditIcon } from '../../../../../assets/icons/pencil-outline.svg';
import { TableCellStyle } from './PendingAdsRow.styles';
import { ApprovedLogsRowProps } from './PendingAdsRow.types';
import EyeIcon from '../../../../svgComponents/EyeIcon';
import { Column } from '../../../../table/table-head/CustomTableHead.types';
import Iconify from '../../../../Iconify';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function PendingAdsTableRow({
  row,
  selected,
  onShowDetails,
  columnsConfig,
  onOPenEditDialog,
  onConfirmAcceptReject,
}: ApprovedLogsRowProps) {
  const { id, status } = row;
  const { t } = useTranslation();
  const [isActived, setIsActived] = useState<number>(2); // Specify the type as number
  const handleChange = (ids?: number, isValue?: number) => {
    if (isValue !== undefined) {
      setIsActived(isValue);
    }
    onConfirmAcceptReject(ids, isValue);
  };

  return (
    <TableRow key={id} hover selected={selected} sx={{ backgroundColor: '#ffffff' }}>
      {columnsConfig.map((column: Column) => (
        <TableCellStyle key={column.id} align={column.align || 'left'} width={column.width}>
          {column.id !== 'actions' ? (
            String(row[column.id as keyof typeof row])
          ) : (
            <Stack direction="row" spacing={2}>
              <Tooltip title="View Details" arrow>
                <IconButton
                  onClick={() => onShowDetails(id, true)}
                  sx={{
                    padding: '0px !important',
                  }}
                >
                  <EyeIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit" arrow>
                <IconButton onClick={() => onOPenEditDialog(id)}>
                  <Iconify icon={EditIcon} />
                </IconButton>
              </Tooltip>
              <Button variant="contained" size="small" onClick={() => handleChange(id, 1)} sx={{ backgroundColor: '#4caf50', color: '#fff' }}>
                {t('adds.accept')}
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleChange(id, 2)}
                sx={{ backgroundColor: '#f44336', color: '#fff' }}
                disabled={status == 2}
              >
                {t('adds.reject')}
              </Button>
            </Stack>
          )}
        </TableCellStyle>
      ))}
    </TableRow>
  );
}
