import { InitialPaginationParams } from '../../api/common/common.types';
import { Column } from '../table/table-head/CustomTableHead.types';

export const initialPaginationState: InitialPaginationParams = {
  defaultCurrentPage: 1,
  defaultOrderBy: 'id',
  defaultOrder: 'desc',
  defaultRowsPerPage: 25,
};

export const TABLE_COLUMNS_CONFIG_NO: Column[] = [
  {
    id: 'name',
    label: 'adds.name',
    width: '20%',
  },
  {
    id: 'nickname',
    label: 'adds.nickname',
    width: '20%',
  },
  {
    id: 'mail',
    label: 'adds.email',
    width: '20%',
  },
  {
    id: 'profileType',
    label: 'adds.profileType',
    width: '20%',
  },
];

export const TABLE_COLUMNS_CONFIG: Column[] = [
  {
    id: 'checkbox',
    label: '',
    width: '20%',
    isNotSortable: true,
  },
  {
    id: 'name',
    label: 'adds.name',
    width: '20%',
  },
  {
    id: 'nickname',
    label: 'adds.nickname',
    width: '20%',
  },
  {
    id: 'mail',
    label: 'adds.email',
    width: '20%',
  },
  {
    id: 'profileType',
    label: 'adds.profileType',
    width: '20%',
  },
];

export const TABLE_COLUMNS_CONFIG_ARTISTS: Column[] = [
  {
    id: 'name',
    label: 'adds.name',
    width: '20%',
  },
  {
    id: 'nickname',
    label: 'adds.nickname',
    width: '20%',
  },
  {
    id: 'mail',
    label: 'adds.email',
    width: '20%',
  },
  {
    id: 'profileType',
    label: 'adds.profileType',
    width: '20%',
  },
  {
    id: 'actions',
    label: 'adds.actions',
    width: '20%',
    isNotSortable: true,
  },
];
