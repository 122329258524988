import * as yup from 'yup';

export const CreateAdvertisementsSchema = yup.object().shape({
  videoOrImage: yup.string().trim().required('Une vidéo ou une image est requise'),
  title: yup
    .string()
    .trim()
    .max(50, 'advertisements.form_fields.title.max_length_error_message')
    .required('advertisements.form_fields.title.required_error_message'),
});
