import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../store/rootStore';
import { SnackBarActionTypes } from '../store/snackBar/models';
import { SHOW_SNACK_BAR } from '../store/snackBar/types';

export default function useSnackBar() {
  const alertSuccess = (message: string) => {
    return (dispatch: ThunkDispatch<RootState, undefined, SnackBarActionTypes>) => {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: {
          message: message,
          severity: 'success',
          icon: 'CheckIcon',
          size: 'medium',
        },
      });
    };
  };

  const alertError = (message: string) => {
    return (dispatch: ThunkDispatch<RootState, undefined, SnackBarActionTypes>) => {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: {
          message: message,
          severity: 'error',
          icon: 'ErrorIcon',
          size: 'medium',
        },
      });
    };
  };

  const alertWarning = (message: string) => {
    return (dispatch: ThunkDispatch<RootState, undefined, SnackBarActionTypes>) => {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: {
          message: message,
          severity: 'warning',
          icon: 'WarningIcon',
          size: 'medium',
        },
      });
    };
  };

  return {
    alertSuccess,
    alertError,
    alertWarning,
  };
}
