/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { yupResolver } from '@hookform/resolvers/yup';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { PendingAdsFormProps } from './PendingAds-form.types';
import { CreatePendingAdsSchema } from './create-pendingads.schema';
import { PendingAdsActionTypes } from '../../../../../store/pendingAds/slice/PendingAds-slice.types';
import { ModulePermission, PendingAds } from '../../../../../models/PendingAds';
import RHFTextField from '../../../../hook-form/RHFTextField';
import CustomLoadingButton from '../../../../button/CustomLoadingButton';
import { pendingAdsApi } from '../../../../../store/pendingAds/api/PendingAds.api';
import { RootState } from '../../../../../store/rootStore';
import useSnackBar from '../../../../../hooks/useSnackBar';
import FormProvider from '../../../../hook-form/FormProvider';
import AddPhotoIcon from '../../../../svgComponents/AddPhotoIcon';
import AdminVideoControl from './VideoControl';
import { Resolver } from 'react-hook-form';
import FullScreenModal from './FullScreenModal';

const { updatePendingAds } = pendingAdsApi.endpoints;
export default function PendingAdsForm({ currentPendingAds, isUpdate, onClose, isEyeIconClicked }: PendingAdsFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, PendingAdsActionTypes>>();
  const audienceList = [
    { id: 0, name: 'Art Lover' },
    { id: 1, name: 'Artist' },
    { id: 2, name: 'Artgaper' },
    { id: 3, name: 'Gallery' },
    { id: 4, name: 'University' },
    { id: 5, name: 'Institution' },
    { id: 6, name: 'Student' },
  ];
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);

  const [postURL, setPostURL] = useState<string | undefined>(undefined);
  const [postType, setPostType] = useState<string | undefined>(undefined);
  const [post, setPost] = useState<string | undefined>(undefined);
  const [postError, setPostError] = useState<string | undefined>(undefined);

  const [bannerURL, setBannerURL] = useState<string | undefined>(undefined);
  const [bannerType, setBannerType] = useState<string | undefined>(undefined);
  const [banner, setBanner] = useState<string | undefined>(undefined);
  const [bannerError, setBannerError] = useState<string | undefined>(undefined);

  const [popupURL, setpopupURL] = useState<string | undefined>(undefined);
  const [popupType, setPopupType] = useState<string | undefined>(undefined);
  const [popup, setPopup] = useState<string | undefined>(undefined);
  const [popupError, setPopupError] = useState<string | undefined>(undefined);
  const [videoURL, setVideoURL] = useState<string | undefined>(undefined);
  const [videoType, setVideoType] = useState<string | undefined>(undefined);
  const [largeImgUrl, setLargeImgUrl] = useState<string | undefined>(undefined);
  const [openImgModel, setOpenImgModal] = useState(false);
  const [roleIds, setRoleIds] = useState<PendingAds['roleIds']>(currentPendingAds?.roleIds || audienceList);

  useEffect(() => {
    if (isUpdate && currentPendingAds) {
      const posturl = currentPendingAds?.adsPost || '';
      const postExtension = posturl.split('.').pop()?.toLowerCase() || '';
      setPostURL(posturl);
      setPostType(postExtension);
      setPost(posturl);
      const selectedValue =
        currentPendingAds?.roleIds?.map((permission) => {
          const id = String(permission);
          return parseInt(id, 10);
        }) || [];
      setSelectedIds(selectedValue || []);
      const valuessss = selectedValue?.map((id) => ({ id: id }));
      setValue('roleIds', valuessss);
      trigger('roleIds');
      // setValue('roleIds', { id: 0, name: 'Art Lover' }, { id: 1, name: 'Artist' });

      const bannerurl = currentPendingAds?.adsBanner || '';
      const bannerExtension = bannerurl.split('.').pop()?.toLowerCase() || '';
      setBannerURL(bannerurl);
      setBannerType(bannerExtension);
      setBanner(bannerurl);
      const popupurl = currentPendingAds?.adsPopup || '';
      const popupExtension = popupurl.split('.').pop()?.toLowerCase() || '';
      setpopupURL(popupurl);
      setPopupType(popupExtension);
      setPopup(popupurl);
    }
  }, [currentPendingAds, isUpdate]);

  const defaultValues = useMemo<PendingAds>(
    () => ({
      name: currentPendingAds?.name || '',
      mail: currentPendingAds?.mail || '',
      planType: currentPendingAds?.planType || '',
      adsTitle: currentPendingAds?.adsTitle || '',
      adsBanner: currentPendingAds?.adsBanner || '',
      adsPost: currentPendingAds?.adsPost || '',
      adsPopup: currentPendingAds?.adsPopup || '',
      description: currentPendingAds?.description || '',
      websiteUrl: currentPendingAds?.websiteUrl || '',
      roleIds:
        currentPendingAds?.roleIds?.map((permission) => ({
          id: parseInt(String(permission), 10),
        })) || [],
      isPaid: currentPendingAds?.isPaid || '',
      countryCode: currentPendingAds?.countryCode || '',
      price: currentPendingAds?.price || '',
      servicePlanId: currentPendingAds?.servicePlanId || '',
      plan_type: currentPendingAds?.plan_type || '',
    }),
    [currentPendingAds],
  );

  const methods = useForm<PendingAds>({
    resolver: yupResolver(CreatePendingAdsSchema) as Resolver<PendingAds>, // Cast resolver to Resolver<PendingAds>
    defaultValues,
    mode: 'all',
  });

  const { alertSuccess, alertError } = useSnackBar();

  const {
    reset,
    handleSubmit,
    trigger,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;
  const values = watch();

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentPendingAds]);

  useEffect(() => {
    // Update defaultValues whenever currentPendingAds or isUpdate changes
    const updatedDefaultValues = {
      name: currentPendingAds?.name || '',
      mail: currentPendingAds?.mail || '',
      planType: currentPendingAds?.planType || '',
      adsTitle: currentPendingAds?.adsTitle || '',
      adsBanner: currentPendingAds?.adsBanner || '',
      adsPost: currentPendingAds?.adsPost || '',
      adsPopup: currentPendingAds?.adsPopup || '',
      description: currentPendingAds?.description || '',
      websiteUrl: currentPendingAds?.websiteUrl || '',
      roleIds:
        currentPendingAds?.roleIds?.map((permission) => ({
          id: parseInt(String(permission), 10),
        })) || [],
      isPaid: currentPendingAds?.isPaid || '',
      countryCode: currentPendingAds?.countryCode || '',
      price: currentPendingAds?.price || '',
      servicePlanId: currentPendingAds?.servicePlanId || '',
      plan_type: currentPendingAds?.plan_type || '',
    };
    methods.reset(updatedDefaultValues);
  }, [currentPendingAds, isUpdate]);

  const onSubmit = async () => {
    if (isUpdate) {
      update();
    }
  };

  const update = async () => {
    try {
      trigger().then(async (isValid) => {
        if (isValid && currentPendingAds?.id && !postError && !bannerError && !popupError) {
          const formData = new FormData();
          formData.append('name', values?.name);
          formData.append('mail', values?.mail);
          formData.append('adsTitle', values?.adsTitle || '');
          formData.append('adsBanner', banner || '');
          formData.append('adsPost', post || '');
          formData.append('adsPopup', popup || '');
          formData.append('planType', values?.planType || '');
          formData.append('description', values?.description);
          formData.append('websiteUrl', values?.websiteUrl);
          formData.append('isPaid', values?.isPaid || '');
          formData.append('countryCode', values?.countryCode || '');
          formData.append('price', values?.price || '');
          formData.append(`servicePlanId`, values?.servicePlanId || '');
          const roleIdsString = selectedIds.join(',');
          formData.append('roleIds', roleIdsString);
          dispatch(updatePendingAds(currentPendingAds?.id, formData))
            .then(() => {
              reset(defaultValues);
              dispatch(alertSuccess(t('adds.update_success_message')));
              onClose();
            })
            .catch((err) => {
              dispatch(alertError(err.message)); // Show error message in alert
            });
        }
      });
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    }
  };

  const handleBulk = (id: number) => {
    const updatedSelectedIds = selectedIds?.includes(id) ? selectedIds?.filter((ids) => ids !== id) : [...selectedIds, id];
    setSelectedIds(updatedSelectedIds);
    trigger('roleIds');
    const valuessss = updatedSelectedIds?.map((ids) => ({ id: ids }));
    setValue('roleIds', valuessss);
    trigger('roleIds');
  };

  const handleLogoUpload = (e: any) => {
    const logoFile = e.target.files[0];
    const imageUrl = URL.createObjectURL(logoFile);
    const fileType = logoFile?.type.split('/')[1];
    if (logoFile) {
      if (logoFile.type.includes('image') || logoFile.type.includes('video')) {
        if (logoFile.size >= 5 * 1024 * 1024) {
          setPostError(t('adds.file_size_error'));
          setPost(undefined);
          setPostURL(undefined);
          setPostType(undefined);
        } else {
          setPostError(undefined);
          setPost(logoFile);
          setValue('adsPost', logoFile);
          setPostType(fileType);
          setPostURL(imageUrl || undefined);
        }
      } else {
        setPostError('Please choose an image/video file.');
        setPost(undefined);
        setPostURL(undefined);
        setPostType(undefined);
      }
    }
  };

  const handleBannerUpload = (e: any) => {
    const bannerFile = e.target.files[0];
    const fileType = bannerFile?.type.split('/')[1];
    const bannerImgUrl = URL.createObjectURL(bannerFile);
    if (bannerFile) {
      if (bannerFile.type.includes('image') || bannerFile.type.includes('video')) {
        if (bannerFile.size >= 5 * 1024 * 1024) {
          setBannerError(t('adds.file_size_error'));
          setBanner(undefined);
          setBannerURL(undefined);
          setBannerType(undefined);
        } else {
          setBannerError(undefined);
          setBanner(bannerFile);
          setValue('adsBanner', bannerFile);
          setBannerType(fileType);
          setBannerURL(bannerImgUrl || undefined);
        }
      } else {
        setBannerError('Please choose an image/video file.');
        setBanner(undefined);
        setBannerURL(undefined);
        setBannerType(undefined);
      }
    }
  };

  const handlePopupUpload = (e: any) => {
    const popupFile = e.target.files[0];
    const popupImageUrl = URL.createObjectURL(popupFile);
    const fileType = popupFile?.type.split('/')[1];
    if (popupFile) {
      if (popupFile.type.includes('image') || popupFile.type.includes('video')) {
        if (popupFile.size >= 5 * 1024 * 1024) {
          setPopupError(t('adds.file_size_error'));
          setPopup(undefined);
          setpopupURL(undefined);
          setPopupType(undefined);
        } else {
          setPopupError(undefined);
          setPopup(popupFile);
          setValue('adsPopup', popupFile);
          setPopupType(fileType);
          setpopupURL(popupImageUrl || undefined);
        }
      } else {
        setPopupError('Please choose an image/video file.');
        setPopup(undefined);
        setpopupURL(undefined);
        setPopupType(undefined);
      }
    }
  };

  const openVideoPopup = (type: string) => {
    if (type === 'popup') {
      setVideoURL(popupURL);
      setVideoType(popupType);
    } else if (type === 'post') {
      setVideoURL(postURL);
      setVideoType(postType);
    } else if (type === 'banner') {
      setVideoURL(bannerURL);
      setVideoType(bannerType);
    }

    setVideoPopupOpen(true);
  };

  const closeVideoPopup = () => {
    setVideoPopupOpen(false);
  };

  const handleClickOpenModal = (imageurl: string) => {
    setLargeImgUrl(imageurl || '');
    setOpenImgModal(true);
  };
  const handleClickCloseImgModal = () => {
    setLargeImgUrl('');
    setOpenImgModal(false);
  };
  return (
    <>
      {currentPendingAds ? (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid className="artgapi-modal" container spacing={3}>
            <Grid className="artgapi-plan-form" item xs={12} md={12}>
              <Box sx={{ p: 3 }}>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                    {t('adds.personal_info')}
                  </Typography>
                  <Box
                    sx={{
                      display: 'grid',
                      columnGap: 2,
                      rowGap: 3,
                      gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <RHFTextField
                      name={'name'}
                      placeholder={t('adds.form_fields.name.placeholder')}
                      label={t('adds.form_fields.name.label')}
                      disabled
                    />
                    <RHFTextField
                      name={'mail'}
                      placeholder={t('adds.form_fields.mail.placeholder')}
                      label={t('adds.form_fields.mail.label')}
                      disabled
                    />
                    <RHFTextField
                      name={'plan_type'}
                      placeholder={t('adds.form_fields.planType.placeholder')}
                      label={t('adds.form_fields.planType.label')}
                      disabled
                    />
                    <RHFTextField
                      name={'price'}
                      placeholder={t('adds.form_fields.planPrice.placeholder')}
                      label={t('adds.form_fields.planPrice.label')}
                      disabled
                    />
                  </Box>
                </Box>

                {/* Advertisement Information Section */}
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                    {t('adds.adds_info')}
                  </Typography>
                  <Box
                    sx={{
                      display: 'grid',
                      columnGap: 2,
                      rowGap: 3,
                      gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <RHFTextField
                      name={'adsTitle'}
                      placeholder={t('adds.form_fields.adsTitle.placeholder')}
                      label={t('adds.form_fields.adsTitle.label')}
                      disabled={isEyeIconClicked}
                    />
                    <RHFTextField
                      name={'description'}
                      placeholder={t('adds.form_fields.description.placeholder')}
                      label={t('adds.form_fields.description.label')}
                      disabled={isEyeIconClicked}
                    />
                    <RHFTextField
                      name={'websiteUrl'}
                      placeholder={t('adds.form_fields.websiteUrl.placeholder')}
                      label={t('adds.form_fields.websiteUrl.label')}
                      disabled={isEyeIconClicked}
                    />
                  </Box>
                </Box>
                {/* Advertisement plan type Information Section */}
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                    {t('adds.adds_plan_info')}
                  </Typography>
                  {values?.adsPost && (
                    <Grid>
                      <div>
                        <label className="MuiInputLabel-root MuiInputLabel-animated MuiFormLabel-root MuiFormLabel-colorPrimary css-r440dj-MuiFormLabel-root-MuiInputLabel-root">
                          {t('adds.form_fields.adsPost.label')}
                        </label>
                        <div style={{ display: 'flex', marginBottom: '0', alignItems: 'center' }}>
                          {postURL ? (
                            <>
                              <div>
                                <span>
                                  {postType?.includes('mp4') ? (
                                    <>
                                      <div
                                        style={{
                                          width: '100px',
                                          height: '100px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          position: 'relative',
                                        }}
                                      >
                                        <video
                                          style={{
                                            cursor: 'pointer',
                                            width: '100%',
                                            objectFit: 'cover',
                                            height: '100%',
                                          }}
                                          src={postURL}
                                          onClick={() => openVideoPopup('post')}
                                        ></video>

                                        <span
                                          style={{
                                            backgroundColor: 'white',
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderRadius: '9999px',
                                            position: 'absolute',
                                            width: '1.5 rem',
                                            height: '1,5 rem',
                                            cursor: 'pointer',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <PlayCircleOutlineIcon className="text-base" onClick={() => openVideoPopup('post')} />
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    <img
                                      src={postURL}
                                      alt="post"
                                      style={{
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'contain',
                                      }}
                                      onClick={() => handleClickOpenModal(postURL || '')}
                                    />
                                  )}
                                </span>
                                {/* <label className="text-[#5e5e5e] break-all !mb-0">{'gdfgdfh'}</label> */}
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '0px' }}>
                                <label
                                  htmlFor="logos"
                                  style={{
                                    width: '44px',
                                    height: '44px',
                                    minWidth: '44PX',
                                    backgroundColor: '#f0f0f0',
                                    border: '1px dashed #ccc',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <AddPhotoIcon />
                                </label>
                              </div>
                            </>
                          )}
                          {!isEyeIconClicked && (
                            <Button
                              className="artgapi-upload-btn"
                              style={{
                                textTransform: 'capitalize',
                                boxShadow: 'inset 0px -1px 0px #E7E7E7',
                                marginLeft: '15px',
                                backgroundColor: isEyeIconClicked ? '#ffcc33' : '#ffcc33',
                              }}
                              size="small"
                              variant="contained"
                              component="label"
                              disabled={isEyeIconClicked}
                            >
                              {t('articles.upload')}
                              <input hidden type="file" name="" id="add-post" onChange={(e) => handleLogoUpload(e)} />
                            </Button>
                          )}
                        </div>
                        {postError && <p style={{ color: '#d32f2f', marginTop: '0.5rem' }}>{postError}</p>}
                      </div>
                    </Grid>
                  )}
                  {values?.adsBanner && (
                    <Grid>
                      <div>
                        <label className="MuiInputLabel-root MuiInputLabel-animated MuiFormLabel-root MuiFormLabel-colorPrimary css-r440dj-MuiFormLabel-root-MuiInputLabel-root">
                          {t('adds.form_fields.adsBanner.label')}
                        </label>
                        <div style={{ display: 'flex', marginBottom: '0', alignItems: 'center' }}>
                          {bannerURL ? (
                            <>
                              <div>
                                <span>
                                  {bannerType?.includes('mp4') ? (
                                    <>
                                      <div
                                        style={{
                                          width: '100px',
                                          height: '100px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          position: 'relative',
                                        }}
                                      >
                                        <video
                                          style={{
                                            cursor: 'pointer',
                                            width: '100%',
                                            objectFit: 'cover',
                                            height: '100%',
                                          }}
                                          src={bannerURL}
                                          onClick={() => openVideoPopup('banner')}
                                        ></video>

                                        <span
                                          style={{
                                            backgroundColor: 'white',
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderRadius: '9999px',
                                            position: 'absolute',
                                            width: '1.5 rem',
                                            height: '1,5 rem',
                                            cursor: 'pointer',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <PlayCircleOutlineIcon className="text-base" onClick={() => openVideoPopup('banner')} />
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    <img
                                      src={bannerURL}
                                      alt="post"
                                      style={{
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'contain',
                                      }}
                                      onClick={() => handleClickOpenModal(bannerURL || '')}
                                    />
                                  )}
                                </span>
                                {/* <label className="text-[#5e5e5e] break-all !mb-0">{'gdfgdfh'}</label> */}
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '0px' }}>
                                <label
                                  htmlFor="banner"
                                  style={{
                                    width: '44px',
                                    height: '44px',
                                    minWidth: '44PX',
                                    backgroundColor: '#f0f0f0',
                                    border: '1px dashed #ccc',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <AddPhotoIcon />
                                </label>
                              </div>
                            </>
                          )}
                          {!isEyeIconClicked && (
                            <Button
                              className="artgapi-upload-btn"
                              style={{
                                textTransform: 'capitalize',
                                boxShadow: 'inset 0px -1px 0px #E7E7E7',
                                marginLeft: '15px',
                                backgroundColor: isEyeIconClicked ? '#ffcc33' : '#ffcc33',
                              }}
                              size="small"
                              variant="contained"
                              component="label"
                              disabled={isEyeIconClicked}
                            >
                              {t('articles.upload')}
                              <input hidden type="file" name="" id="add-banner" onChange={(e) => handleBannerUpload(e)} />
                            </Button>
                          )}
                        </div>
                        {bannerError && <p style={{ color: '#d32f2f', marginTop: '0.5rem' }}>{bannerError}</p>}
                      </div>
                    </Grid>
                  )}
                  {values?.adsPopup && (
                    <Grid>
                      <div>
                        <label className="MuiInputLabel-root MuiInputLabel-animated MuiFormLabel-root MuiFormLabel-colorPrimary css-r440dj-MuiFormLabel-root-MuiInputLabel-root">
                          {t('adds.form_fields.adsPopup.label')}
                        </label>
                        <div style={{ display: 'flex', marginBottom: '0', alignItems: 'center' }}>
                          {popupURL ? (
                            <>
                              <div>
                                <span>
                                  {popupType?.includes('mp4') ? (
                                    <>
                                      <div
                                        style={{
                                          width: '100px',
                                          height: '100px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          position: 'relative',
                                        }}
                                      >
                                        <video
                                          style={{
                                            cursor: 'pointer',
                                            width: '100%',
                                            objectFit: 'cover',
                                            height: '100%',
                                          }}
                                          src={popupURL}
                                          onClick={() => openVideoPopup('popup')}
                                        ></video>

                                        <span
                                          style={{
                                            backgroundColor: 'white',
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderRadius: '9999px',
                                            position: 'absolute',
                                            width: '1.5 rem',
                                            height: '1,5 rem',
                                            cursor: 'pointer',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <PlayCircleOutlineIcon className="text-base" onClick={() => openVideoPopup('popup')} />
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    <img
                                      src={popupURL}
                                      alt="post"
                                      style={{
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'contain',
                                      }}
                                      onClick={() => handleClickOpenModal(popupURL || '')}
                                    />
                                  )}
                                </span>
                                {/* <label className="text-[#5e5e5e] break-all !mb-0">{'gdfgdfh'}</label> */}
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '0px' }}>
                                <label
                                  htmlFor="popup"
                                  style={{
                                    width: '44px',
                                    height: '44px',
                                    minWidth: '44PX',
                                    backgroundColor: '#f0f0f0',
                                    border: '1px dashed #ccc',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <AddPhotoIcon />
                                </label>
                              </div>
                            </>
                          )}
                          {!isEyeIconClicked && (
                            <Button
                              className="artgapi-upload-btn"
                              style={{
                                textTransform: 'capitalize',
                                boxShadow: 'inset 0px -1px 0px #E7E7E7',
                                marginLeft: '15px',
                                backgroundColor: isEyeIconClicked ? '#ffcc33' : '#ffcc33',
                              }}
                              size="small"
                              variant="contained"
                              component="label"
                              disabled={isEyeIconClicked}
                            >
                              {t('articles.upload')}
                              <input hidden type="file" name="" id="add-popup" onChange={(e) => handlePopupUpload(e)} />
                            </Button>
                          )}
                        </div>
                        {popupError && <p style={{ color: '#d32f2f', marginTop: '0.5rem' }}>{popupError}</p>}
                      </div>
                    </Grid>
                  )}
                  <Box sx={{ mt: 3 }}>
                    <Typography variant="subtitle1">{t('adds.form_fields.roleIds.label')}</Typography>
                    <FormGroup>
                      {roleIds?.map((item) => (
                        <FormControlLabel
                          key={item?.id}
                          control={
                            <Controller
                              name={`roleIds`}
                              control={methods.control}
                              render={({ field }) => (
                                <Checkbox
                                  {...field}
                                  name={item?.name || ''}
                                  onChange={() => handleBulk(item.id)}
                                  checked={selectedIds?.includes(item.id)}
                                  disabled={isEyeIconClicked}
                                />
                              )}
                            />
                          }
                          label={item?.name}
                        />
                      ))}
                      {errors.roleIds && (
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: '0.75rem',
                            lineHeight: '1.66',
                            letterSpacing: '0.03333em',
                            textAlign: 'left',
                            marginTop: '3px',
                            marginRight: '14px',
                            marginBottom: '0',
                            marginLeft: '14px',
                            color: '#d32f2f',
                          }}
                        >
                          {t('adds.form_fields.roleIds.required_error_message')}
                        </Typography>
                      )}
                    </FormGroup>
                  </Box>
                </Box>
                {/* Submit Button */}
                {isEyeIconClicked ? (
                  ''
                ) : (
                  <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                    <CustomLoadingButton
                      loading={isSubmitting}
                      label={isUpdate ? t('adds.update_button_label') : t('adds.create_button_label')}
                      onClick={onSubmit}
                    />
                  </Stack>
                )}
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '70px' }}>
          <CircularProgress
            sx={{
              color: '#ffcc33',
            }}
          />
        </div>
      )}
      {isVideoPopupOpen && (
        <AdminVideoControl
          isVideoPopupOpen={isVideoPopupOpen}
          closeVideoPopup={closeVideoPopup}
          videourl={videoURL || ''}
          selectedVideo={videoType || ''}
        />
      )}
      {openImgModel && <FullScreenModal openImgModel={openImgModel} closeImgPopup={handleClickCloseImgModal} largeImgUrl={largeImgUrl || ''} />}
    </>
  );
}
