import { InitialPaginationParams } from '../../api/common/common.types';
import { Column } from '../../components/table/table-head/CustomTableHead.types';

export const initialPaginationState: InitialPaginationParams = {
  defaultCurrentPage: 1,
  defaultOrderBy: 'name',
  defaultOrder: 'asc',
  defaultRowsPerPage: 25,
};

export const TABLE_COLUMNS_CONFIG: Column[] = [
  {
    id: 'name',
    label: 'university.name',
    width: '20%',
  },
  {
    id: 'mail',
    label: 'university.email',
    width: '20%',
  },
  {
    id: 'phoneNb',
    label: 'university.phone_number',
    width: '20%',
    isNotSortable: true,
  },
  {
    id: 'isActive',
    label: 'systemuser.status',
    width: '20%',
    isNotSortable: true,
  },
  {
    id: 'actions',
    label: 'systemuser.actions',
    width: '10%',
    isNotSortable: true,
  },
];
