/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Stack } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import CustomLoadingButton from '../../../components/button/CustomLoadingButton';
import FormProvider from '../../../components/hook-form/FormProvider';
import RHFTextField from '../../../components/hook-form/RHFTextField';
import useSnackBar from '../../../hooks/useSnackBar';
import { University } from '../../../models/University';
import { RootState } from '../../../store/rootStore';
import { universityApi } from '../../../store/university/api/university.api';
import { UniversityActionTypes } from '../../../store/university/slice/university-slice.types';
import useUpdateUniversityMutation from '../hooks/useUpdateUniversityMutation';
import { CreateUniversitySchema } from './create-university.schema';
import { UniversityFormProps } from './university-form.types';

const { createUniversity } = universityApi.endpoints;

export default function UniversityForm({ currentUniversity, isUpdate, onClose }: UniversityFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, UniversityActionTypes>>();
  const defaultValues = useMemo<University>(
    () => ({
      name: currentUniversity?.name || '',
      address: currentUniversity?.address || '',
      phoneNumber: currentUniversity?.phoneNumber || '',
      email: currentUniversity?.email || '',
    }),
    [currentUniversity],
  );

  const methods = useForm<University>({
    resolver: yupResolver(CreateUniversitySchema),
    defaultValues,
    mode: 'all',
  });

  const { updateUniversity, isLoading, error, data } = useUpdateUniversityMutation();
  const { alertSuccess, alertError } = useSnackBar();

  const {
    reset,
    handleSubmit,
    trigger,
    watch,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isUpdate && currentUniversity) {
      reset(defaultValues);
    }
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentUniversity]);

  const onSubmit = async () => {
    if (!isUpdate) {
      create();
    } else {
      update();
    }
  };

  const create = async () => {
    try {
      trigger().then(async (isValid) => {
        if (isValid) {
          dispatch(createUniversity(values)).then(() => {
            reset(defaultValues);
            dispatch(alertSuccess(t('university.create_success_message')));
            onClose();
          });
        }
      });
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    }
  };

  const update = async () => {
    try {
      trigger().then(async (isValid) => {
        if (isValid && currentUniversity?.id) {
          updateUniversity(currentUniversity.id, values).then(() => {
            dispatch(alertSuccess(t('university.update_success_message')));
            reset(defaultValues);
            onClose();
          });
        }
      });
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid className="artgapi-plan-form" item xs={12} md={12}>
          <Box sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name={'name'} label={t('university.form_fields.name.placeholder')} />
              <RHFTextField name={'email'} label={t('university.form_fields.email.placeholder')} />
              <RHFTextField name={'address'} label={t('university.form_fields.address.placeholder')} />
              <RHFTextField name={'phoneNumber'} label={t('university.form_fields.phone_number.placeholder')} />
            </Box>
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <CustomLoadingButton
                loading={isSubmitting}
                label={isUpdate ? t('university.update_button_label') : t('university.create_button_label')}
                onClick={onSubmit}
              />
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
