/* eslint-disable @typescript-eslint/no-explicit-any*/
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { theme } from '../../theme/theme';
import { ButtonBase, IconButton } from '@mui/material';
import CloseIcon from '../svgComponents/closeIcon';
import UserRequest from '../../models/UserRequest';
interface ProfilPopUpProps {
  openProfilPopUp: boolean;
  onUpdateProfile: any;
  cancelNavigation: any;
  confirmNavigation: any;
  data: UserRequest;
  setOpenProfilPopUp: any;
  formik: any;
}
export const ProfilPopUp = (props: ProfilPopUpProps) => {
  const { openProfilPopUp, onUpdateProfile, cancelNavigation, confirmNavigation, data, setOpenProfilPopUp, formik } = props;
  function confirmUpdateProfil() {
    if (formik.isValid) {
      onUpdateProfile(data);
      confirmNavigation();
    }
  }
  function handelCancel() {
    confirmNavigation();
    setOpenProfilPopUp(false);
  }
  return (
    <Dialog
      open={openProfilPopUp}
      onClose={cancelNavigation}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '500px',
            maxWidth: '500px',
            borderRadius: 0,
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          width: '500px',
          color: theme.palette.primary.main,
          fontFamily: 'SFPro-Regular',
          backgroundColor: theme.palette.secondary.main,
          textAlign: 'center',
        }}
      >
        <IconButton sx={{ position: 'absolute', right: 5, top: 5 }} onClick={cancelNavigation}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogTitle
        sx={{
          width: '500px',
          fontSize: '24px',
          color: theme.palette.primary.main,
          fontFamily: 'SFPro-Regular',
          backgroundColor: theme.palette.secondary.main,
          textAlign: 'center',
        }}
      >
        ATTENTION
      </DialogTitle>

      <DialogTitle
        sx={{
          width: '500px',
          fontFamily: 'SFPro-Regular',
          backgroundColor: theme.palette.secondary.main,
          fontSize: '14px',
          textAlign: 'center',
          color: ' #A4A4A5',
          alignSelf: 'center',
          justifySelf: 'center',
          paddingTop: 0,
        }}
      >
        {formik.isValid
          ? 'Voulez-vous enregistrer les modifications avant de continuer ?'
          : "Avant d'enregistrer vous devez respecter les contraintes de saisie"}
      </DialogTitle>
      <DialogActions sx={{ width: '100%', height: '100px ' }}>
        <ButtonBase
          sx={{
            color: '#a4a4a5',
            height: '42px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'SFPro-Regular',
            fontSize: '17px',
            padding: '16px 24px',
          }}
          onClick={handelCancel}
        >
          Ne pas enregistrer
        </ButtonBase>
        <ButtonBase
          sx={{
            marginRight: '32px ',
            height: '52px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#ffcc33',
            fontFamily: 'SFPro-Regular',
            fontSize: '17px',
            padding: '16px 24px',
          }}
          onClick={confirmUpdateProfil}
        >
          Enregistrer et continuer
        </ButtonBase>
      </DialogActions>
      {/* <Spinner loading={loader} /> */}
    </Dialog>
  );
};
export default ProfilPopUp;
