import { countriesActionTypes, CountriesState } from './models';
import { GET_COUNTRIES_ATTEMPT, GET_COUNTRIES_ERROR, GET_COUNTRIES_SUCCESS } from './types';

const INITIAL_STATE: CountriesState = {
  loader: false,
  error: '',
  countries: undefined,
};
export const countriesReducer = (state = INITIAL_STATE, action: countriesActionTypes): CountriesState => {
  switch (action.type) {
    case GET_COUNTRIES_ATTEMPT:
      return { ...state, loader: true, error: '' };
    case GET_COUNTRIES_SUCCESS:
      return { ...state, loader: false, countries: action.countries };
    case GET_COUNTRIES_ERROR:
      return { ...state, loader: false, error: action.error };

    default:
      return state;
  }
};
