// .css-1kj1d4f-MuiButtonBase-root-MuiTab-root
// .css-jpln7h-MuiTabs-scroller
import { createTheme } from '@mui/material/styles';

export const menuBar = createTheme({
  palette: {
    primary: {
      main: '#26272C',
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          backgroudColor: '#26272C',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        scroller: {
          position: 'inherit',
        },
      },
    },
  },
});
