import AdsUsers from '../../../models/AdsUsers';

export interface IArtsUsersResponse {
  total: number;
  next: number | null;
  previous: number | null;
  currentArtOfTheWeekCount: number | 0;
  arts: AdsUsers[];
}

export interface IArtsUsersState {
  isLoading: boolean;
  arts: IArtsUsersResponse | null;
  artUsers: AdsUsers | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_ARTS_USERS_SUCCESS = 'FETCH_ARTS_USERS_SUCCESS',
  FETCH_ARTS_USERS_FAILURE = 'FETCH_ARTS_USERS_FAILURE',
  CREATE_ARTS_USERS_SUCCESS = 'CREATE_ARTS_USERS_SUCCESS',
}

export interface IArtsUsersPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchArtsUsersSuccessAction {
  type: typeof ActionTypes.FETCH_ARTS_USERS_SUCCESS | typeof ActionTypes.CREATE_ARTS_USERS_SUCCESS;
  payload: IArtsUsersResponse | AdsUsers;
}

export interface IFetchArtsUsersRejectedAction {
  type: typeof ActionTypes.FETCH_ARTS_USERS_FAILURE;
  payload: string;
}

export type ArtsUsersActionTypes = IArtsUsersPendingAction | IFetchArtsUsersSuccessAction | IFetchArtsUsersRejectedAction;
