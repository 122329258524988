/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { yupResolver } from '@hookform/resolvers/yup';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PendingAdsFormProps } from './PendingAds-form.types';
import { CreatePendingAdsSchema } from './create-pendingads.schema';
import { PendingAds } from '../../../../../models/PendingAds';
import AddPhotoIcon from '../../../../svgComponents/AddPhotoIcon';
import AdminVideoControl from './VideoControl';
import { Resolver } from 'react-hook-form';
import FullScreenModal from './FullScreenModal';
import { theme } from '../../../../../theme/theme';

export default function ViewPendingAdsForm({ currentPendingAds }: PendingAdsFormProps) {
  const { t } = useTranslation();
  const audienceList = [
    { id: 0, name: 'Art Lover' },
    { id: 1, name: 'Artist' },
    { id: 2, name: 'Artgaper' },
    { id: 3, name: 'Gallery' },
    { id: 4, name: 'University' },
    { id: 5, name: 'Institution' },
    { id: 6, name: 'Student' },
  ];
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);

  const [postURL, setPostURL] = useState<string | undefined>(undefined);
  const [postType, setPostType] = useState<string | undefined>(undefined);
  const [post, setPost] = useState<string | undefined>(undefined);

  const [bannerURL, setBannerURL] = useState<string | undefined>(undefined);
  const [bannerType, setBannerType] = useState<string | undefined>(undefined);
  const [banner, setBanner] = useState<string | undefined>(undefined);

  const [popupURL, setpopupURL] = useState<string | undefined>(undefined);
  const [popupType, setPopupType] = useState<string | undefined>(undefined);
  const [popup, setPopup] = useState<string | undefined>(undefined);
  const [videoURL, setVideoURL] = useState<string | undefined>(undefined);
  const [videoType, setVideoType] = useState<string | undefined>(undefined);
  const [largeImgUrl, setLargeImgUrl] = useState<string | undefined>(undefined);
  const [openImgModel, setOpenImgModal] = useState(false);
  const [roleIds, setRoleIds] = useState<PendingAds['roleIds']>(currentPendingAds?.roleIds || audienceList);
  const customStyle = {
    marginBottom: '0.5rem',
    fontFamily: 'SFPro-Regular',
    color: theme.palette.secondary.dark,
  };
  useEffect(() => {
    if (currentPendingAds) {
      const posturl = currentPendingAds?.adsPost || '';
      const postExtension = posturl.split('.').pop()?.toLowerCase() || '';
      setPostURL(posturl);
      setPostType(postExtension);
      setPost(posturl);
      const selectedValue =
        currentPendingAds?.roleIds?.map((permission) => {
          const id = String(permission);
          return parseInt(id, 10);
        }) || [];
      setSelectedIds(selectedValue || []);
      const valuessss = selectedValue?.map((id) => ({ id: id }));
      setValue('roleIds', valuessss);
      trigger('roleIds');
      // setValue('roleIds', { id: 0, name: 'Art Lover' }, { id: 1, name: 'Artist' });

      const bannerurl = currentPendingAds?.adsBanner || '';
      const bannerExtension = bannerurl.split('.').pop()?.toLowerCase() || '';
      setBannerURL(bannerurl);
      setBannerType(bannerExtension);
      setBanner(bannerurl);
      const popupurl = currentPendingAds?.adsPopup || '';
      const popupExtension = popupurl.split('.').pop()?.toLowerCase() || '';
      setpopupURL(popupurl);
      setPopupType(popupExtension);
      setPopup(popupurl);
    }
  }, [currentPendingAds]);

  const defaultValues = useMemo<PendingAds>(
    () => ({
      name: currentPendingAds?.name || '',
      mail: currentPendingAds?.mail || '',
      planType: currentPendingAds?.planType || '',
      adsTitle: currentPendingAds?.adsTitle || '',
      adsBanner: currentPendingAds?.adsBanner || '',
      adsPost: currentPendingAds?.adsPost || '',
      adsPopup: currentPendingAds?.adsPopup || '',
      description: currentPendingAds?.description || '',
      websiteUrl: currentPendingAds?.websiteUrl || '',
      roleIds:
        currentPendingAds?.roleIds?.map((permission) => ({
          id: parseInt(String(permission), 10),
        })) || [],
      isPaid: currentPendingAds?.isPaid || '',
      countryCode: currentPendingAds?.countryCode || '',
      price: currentPendingAds?.price || '',
      servicePlanId: currentPendingAds?.servicePlanId || '',
      plan_type: currentPendingAds?.plan_type || '',
    }),
    [currentPendingAds],
  );

  const methods = useForm<PendingAds>({
    resolver: yupResolver(CreatePendingAdsSchema) as Resolver<PendingAds>, // Cast resolver to Resolver<PendingAds>
    defaultValues,
    mode: 'all',
  });

  const { reset, trigger, watch, setValue } = methods;
  const values = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [currentPendingAds]);

  useEffect(() => {
    // Update defaultValues whenever currentPendingAds or isUpdate changes
    const updatedDefaultValues = {
      name: currentPendingAds?.name || '',
      mail: currentPendingAds?.mail || '',
      planType: currentPendingAds?.planType || '',
      adsTitle: currentPendingAds?.adsTitle || '',
      adsBanner: currentPendingAds?.adsBanner || '',
      adsPost: currentPendingAds?.adsPost || '',
      adsPopup: currentPendingAds?.adsPopup || '',
      description: currentPendingAds?.description || '',
      websiteUrl: currentPendingAds?.websiteUrl || '',
      roleIds:
        currentPendingAds?.roleIds?.map((permission) => ({
          id: parseInt(String(permission), 10),
        })) || [],
      isPaid: currentPendingAds?.isPaid || '',
      countryCode: currentPendingAds?.countryCode || '',
      price: currentPendingAds?.price || '',
      servicePlanId: currentPendingAds?.servicePlanId || '',
      plan_type: currentPendingAds?.plan_type || '',
    };
    methods.reset(updatedDefaultValues);
  }, [currentPendingAds]);

  const openVideoPopup = (type: string) => {
    if (type === 'popup') {
      setVideoURL(popupURL);
      setVideoType(popupType);
    } else if (type === 'post') {
      setVideoURL(postURL);
      setVideoType(postType);
    } else if (type === 'banner') {
      setVideoURL(bannerURL);
      setVideoType(bannerType);
    }

    setVideoPopupOpen(true);
  };

  const closeVideoPopup = () => {
    setVideoPopupOpen(false);
  };

  const handleClickOpenModal = (imageurl: string) => {
    setLargeImgUrl(imageurl || '');
    setOpenImgModal(true);
  };
  const handleClickCloseImgModal = () => {
    setLargeImgUrl('');
    setOpenImgModal(false);
  };
  const selectedRoleNames = audienceList?.filter((item) => selectedIds?.includes(item.id))?.map((item) => item.name);

  return (
    <>
      <Grid className="artgapi-modal" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box sx={{ p: 3 }}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                {t('adds.personal_info')}
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 3,
                  rowGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                }}
              >
                <div className="col">
                  <Typography>{t('adds.form_fields.name.placeholder')}</Typography>
                  <label style={customStyle}>{values?.name}</label>
                </div>
                <div className="col">
                  <Typography>{t('adds.form_fields.mail.placeholder')}</Typography>
                  <label style={customStyle}>{values?.mail}</label>
                </div>
                <div className="col">
                  <Typography>{t('adds.form_fields.planType.placeholder')}</Typography>
                  <label style={customStyle}>{values?.plan_type}</label>
                </div>
                <div className="col">
                  <Typography>{t('adds.form_fields.planPrice.placeholder')}</Typography>
                  <label style={customStyle}>{currentPendingAds?.price}</label>
                </div>
                <div className="col">
                  <Typography>{t('adds.form_fields.adsTitle.placeholder')}</Typography>
                  <label style={customStyle}>{values?.adsTitle}</label>
                </div>
                <div className="col">
                  <Typography>{t('adds.form_fields.description.placeholder')}</Typography>
                  <label style={customStyle}>{values?.description}</label>
                </div>
                <div className="col">
                  <Typography>{t('adds.form_fields.websiteUrl.placeholder')}</Typography>
                  <label style={customStyle}>{values?.websiteUrl}</label>
                </div>
              </Box>
            </Box>

            {/* Advertisement plan type Information Section */}
            <Box>
              <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                {t('adds.adds_plan_info')}
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 3,
                  rowGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                }}
              >
                {values?.adsPost && (
                  <Grid>
                    <div>
                      <label className="MuiInputLabel-root MuiInputLabel-animated MuiFormLabel-root MuiFormLabel-colorPrimary css-r440dj-MuiFormLabel-root-MuiInputLabel-root">
                        {t('adds.form_fields.adsPost.placeholder')}
                      </label>
                      <div style={{ display: 'flex', marginBottom: '0', alignItems: 'center' }}>
                        {postURL ? (
                          <>
                            <div>
                              <span>
                                {postType?.includes('mp4') ? (
                                  <>
                                    <div
                                      style={{
                                        width: '100px',
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative',
                                      }}
                                    >
                                      <video
                                        style={{
                                          cursor: 'pointer',
                                          width: '100%',
                                          objectFit: 'cover',
                                          height: '100%',
                                        }}
                                        src={postURL}
                                        onClick={() => openVideoPopup('post')}
                                      ></video>

                                      <span
                                        style={{
                                          backgroundColor: 'white',
                                          display: 'flex',
                                          alignItems: 'center',
                                          borderRadius: '9999px',
                                          position: 'absolute',
                                          width: '1.5 rem',
                                          height: '1,5 rem',
                                          cursor: 'pointer',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <PlayCircleOutlineIcon className="text-base" onClick={() => openVideoPopup('post')} />
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <img
                                    src={postURL}
                                    alt="post"
                                    style={{
                                      width: '100px',
                                      height: '100px',
                                      objectFit: 'contain',
                                    }}
                                    onClick={() => handleClickOpenModal(postURL || '')}
                                  />
                                )}
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '0px' }}>
                              <label
                                htmlFor="logos"
                                style={{
                                  width: '44px',
                                  height: '44px',
                                  minWidth: '44PX',
                                  backgroundColor: '#f0f0f0',
                                  border: '1px dashed #ccc',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <AddPhotoIcon />
                              </label>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Grid>
                )}
                {values?.adsBanner && (
                  <Grid>
                    <div>
                      <label className="MuiInputLabel-root MuiInputLabel-animated MuiFormLabel-root MuiFormLabel-colorPrimary css-r440dj-MuiFormLabel-root-MuiInputLabel-root">
                        {t('adds.form_fields.adsBanner.placeholder')}
                      </label>
                      <div style={{ display: 'flex', marginBottom: '0', alignItems: 'center' }}>
                        {bannerURL ? (
                          <>
                            <div>
                              <span>
                                {bannerType?.includes('mp4') ? (
                                  <>
                                    <div
                                      style={{
                                        width: '100px',
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative',
                                      }}
                                    >
                                      <video
                                        style={{
                                          cursor: 'pointer',
                                          width: '100%',
                                          objectFit: 'cover',
                                          height: '100%',
                                        }}
                                        src={bannerURL}
                                        onClick={() => openVideoPopup('banner')}
                                      ></video>

                                      <span
                                        style={{
                                          backgroundColor: 'white',
                                          display: 'flex',
                                          alignItems: 'center',
                                          borderRadius: '9999px',
                                          position: 'absolute',
                                          width: '1.5 rem',
                                          height: '1,5 rem',
                                          cursor: 'pointer',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <PlayCircleOutlineIcon className="text-base" onClick={() => openVideoPopup('banner')} />
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <img
                                    src={bannerURL}
                                    alt="post"
                                    style={{
                                      width: '100px',
                                      height: '100px',
                                      objectFit: 'contain',
                                    }}
                                    onClick={() => handleClickOpenModal(bannerURL || '')}
                                  />
                                )}
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '0px' }}>
                              <label
                                htmlFor="banner"
                                style={{
                                  width: '44px',
                                  height: '44px',
                                  minWidth: '44PX',
                                  backgroundColor: '#f0f0f0',
                                  border: '1px dashed #ccc',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <AddPhotoIcon />
                              </label>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Grid>
                )}
                {values?.adsPopup && (
                  <Grid>
                    <div>
                      <label className="MuiInputLabel-root MuiInputLabel-animated MuiFormLabel-root MuiFormLabel-colorPrimary css-r440dj-MuiFormLabel-root-MuiInputLabel-root">
                        {t('adds.form_fields.adsPopup.placeholder')}
                      </label>
                      <div style={{ display: 'flex', marginBottom: '0', alignItems: 'center' }}>
                        {popupURL ? (
                          <>
                            <div>
                              <span>
                                {popupType?.includes('mp4') ? (
                                  <>
                                    <div
                                      style={{
                                        width: '100px',
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative',
                                      }}
                                    >
                                      <video
                                        style={{
                                          cursor: 'pointer',
                                          width: '100%',
                                          objectFit: 'cover',
                                          height: '100%',
                                        }}
                                        src={popupURL}
                                        onClick={() => openVideoPopup('popup')}
                                      ></video>

                                      <span
                                        style={{
                                          backgroundColor: 'white',
                                          display: 'flex',
                                          alignItems: 'center',
                                          borderRadius: '9999px',
                                          position: 'absolute',
                                          width: '1.5 rem',
                                          height: '1,5 rem',
                                          cursor: 'pointer',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <PlayCircleOutlineIcon className="text-base" onClick={() => openVideoPopup('popup')} />
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <img
                                    src={popupURL}
                                    alt="post"
                                    style={{
                                      width: '100px',
                                      height: '100px',
                                      objectFit: 'contain',
                                    }}
                                    onClick={() => handleClickOpenModal(popupURL || '')}
                                  />
                                )}
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '0px' }}>
                              <label
                                htmlFor="popup"
                                style={{
                                  width: '44px',
                                  height: '44px',
                                  minWidth: '44PX',
                                  backgroundColor: '#f0f0f0',
                                  border: '1px dashed #ccc',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <AddPhotoIcon />
                              </label>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Grid>
                )}
              </Box>
            </Box>
            <Box sx={{ mt: 3, mb: 0 }}>
              <Typography variant="subtitle1">{t('adds.form_fields.roleIds.placeholder')}</Typography>
              <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                {selectedRoleNames?.map((name, index) => (
                  <div key={index} style={{ marginTop: '0', marginRight: '10px' }}>
                    <Button variant="outlined" sx={{ cursor: 'not-allowed !important', marginBottom: '7px' }}>
                      {name}
                    </Button>
                  </div>
                ))}
              </FormGroup>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {isVideoPopupOpen && (
        <AdminVideoControl
          isVideoPopupOpen={isVideoPopupOpen}
          closeVideoPopup={closeVideoPopup}
          videourl={videoURL || ''}
          selectedVideo={videoType || ''}
        />
      )}
      {openImgModel && <FullScreenModal openImgModel={openImgModel} closeImgPopup={handleClickCloseImgModal} largeImgUrl={largeImgUrl || ''} />}
    </>
  );
}
