import { CONSTANTS } from '../../utils';

export interface Description {
  label: string;
  type: string;
  height: number;
  width: string;
  choices?: { label: string; value: string; id?: number }[];
  value: string;
  filterBy?: string;
}

export const BugsHeader: Description[] = [
  {
    label: 'common.title',
    type: 'normal',
    height: 50,
    width: '30%',
    value: 'title',
  },
  {
    label: 'common.email',
    type: 'mail',
    height: 50,
    width: '30%',
    value: 'mail',
  },
  {
    label: 'common.Status',
    type: 'select',
    height: 50,
    width: '25%',
    value: 'status',
    choices: [
      { label: 'common.all', value: '10', id: 10 },
      { label: 'common.open', value: '20', id: 20 },
      { label: 'common.close', value: '30', id: 30 },
    ],
  },
  {
    label: 'common.date',
    type: 'date',
    height: 50,
    width: '25%',
    value: 'createdAt',
    filterBy: 'date',
  },
];
