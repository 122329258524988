import React from 'react';

export const CampaignIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00001 13.5C5.68834 13.5 5.39449 13.6453 5.2053 13.893C5.01612 14.1407 4.95324 14.4624 5.03525 14.7631L6.23379 19.1578C6.53043 20.2454 7.51833 21 8.6457 21H9.53613C11.1846 21 12.3818 19.4326 11.948 17.8422L10.9648 14.2369C10.8461 13.8018 10.451 13.5 10 13.5H6.00001Z"
        fill="white"
      />
      <path
        d="M19 3C18.7776 3 18.5616 3.07412 18.3861 3.21065L14.1569 6.5H6C3.79086 6.5 2 8.29086 2 10.5V11.5C2 13.7091 3.79086 15.5 6 15.5H14.1569L18.3861 18.7894C18.5616 18.9259 18.7776 19 19 19H21C21.5523 19 22 18.5523 22 18V4C22 3.44772 21.5523 3 21 3H19Z"
        fill="white"
      />
    </svg>
  );
};

export default CampaignIcon;
