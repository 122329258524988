import { InitialPaginationParams } from '../../api/common/common.types';
import { Column } from '../table/table-head/CustomTableHead.types';

export const initialPaginationState: InitialPaginationParams = {
  defaultCurrentPage: 1,
  defaultOrderBy: 'id',
  defaultOrder: 'desc',
  defaultRowsPerPage: 25,
};

export const TABLE_COLUMNS_CONFIG_NO: Column[] = [
  {
    id: 'title',
    label: 'common.title',
    width: '20%',
  },
  {
    id: 'price',
    label: 'common.price',
    width: '20%',
  },
  {
    id: 'image',
    label: 'common.image',
    width: '20%',
    isNotSortable: true,
  },
];

export const TABLE_COLUMNS_CONFIG: Column[] = [
  {
    id: 'checkbox',
    label: '',
    width: '20%',
    isNotSortable: true,
  },
  {
    id: 'title',
    label: 'common.title',
    width: '20%',
  },
  {
    id: 'price',
    label: 'common.price',
    width: '20%',
  },
  {
    id: 'image',
    label: 'common.image',
    width: '20%',
    isNotSortable: true,
  },
];

export const TABLE_COLUMNS_CONFIG_ARTS: Column[] = [
  {
    id: 'title',
    label: 'common.title',
    width: '20%',
  },
  {
    id: 'price',
    label: 'common.price',
    width: '20%',
  },
  {
    id: 'image',
    label: 'common.image',
    width: '20%',
    isNotSortable: true,
  },
  {
    id: 'actions',
    label: 'adds.actions',
    width: '20%',
    isNotSortable: true,
  },
];
