/* eslint-disable react/react-in-jsx-scope */
import { Stack, Select, MenuItem, Button } from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerStyle, CustomTitle, GridStyle, InputStyle } from './CommonToolbar.styles';
import { CommonToolbarProps } from './CommonToolbar.type';
import CustomButton from '../../components/button/CustomButton';
import SearchIcon from '../../components/svgComponents/searchIcon';

export default function CommonToolbar({
  onOpenAddDialog,
  filterKeyword,
  onFilterKeyword,
  title,
  message,
  filterOptions = [],
  handleFilterOptions,
  isFilterOptions,
  filterValue,
  handleClearFilter,
}: CommonToolbarProps) {
  const { t } = useTranslation();
  return (
    <ContainerStyle className="artgapi-upper-fix">
      <GridStyle className="artgapi-system-admin">
        <CustomTitle>{title}</CustomTitle>
        <Stack direction="column" spacing={4}>
          <div className="artgapi-filterbox-searchbar-wrapper">
            <CustomButton label={message} onClick={onOpenAddDialog} />
            <div className="artgapi-filterbox-searchbar-box">
              {isFilterOptions && (
                <div style={{ display: 'flex' }}>
                  <Select
                    className="artgapi-btn-holder"
                    value={filterValue}
                    onChange={(e) => handleFilterOptions?.(parseInt(e.target.value as string, 10))}
                  >
                    <MenuItem key="select" selected value="0">
                      {t('common.filter')}
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.map((item) => (
                        <MenuItem key={item?.id} value={item?.id}>
                          {item?.title}
                        </MenuItem>
                      ))}
                  </Select>
                  <Button variant="contained" onClick={handleClearFilter} style={{ marginLeft: '10px', backgroundColor: '#1976d2' }}>
                    {t('common.clear')}
                  </Button>
                </div>
              )}
              <InputStyle
                value={filterKeyword}
                placeholder={t('common.search')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterKeyword(e.target.value)}
                InputProps={{
                  startAdornment: <SearchIcon />,
                }}
              />
            </div>
          </div>
        </Stack>
      </GridStyle>
    </ContainerStyle>
  );
}
