export const GET_COMPANIES_ATTEMPT = 'GET_COMPANIES_ATTEMPT';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_ERROR = 'GET_COMPANIES_ERROR';
export const CREATE_COMPANIES_ATTEMPT = 'CREATE_COMPANIES_ATTEMPT';
export const CREATE_COMPANIES_SUCCESS = 'CREATE_COMPANIES_SUCCESS';
export const CREATE_COMPANIES_ERROR = 'CREATE_COMPANIES_ERROR';
export const DELETE_COMPANIES_ATTEMPT = 'DELETE_COMPANIES_ATTEMPT';
export const DELETE_COMPANIES_SUCCESS = 'DELETE_COMPANIES_SUCCESS';
export const DELETE_COMPANIES_ERROR = 'DELETE_COMPANIES_ERROR';
export const GET_COMPANIE_ATTEMPT = 'GET_COMPANIE_ATTEMPT';
export const GET_COMPANIE_SUCCESS = 'GET_COMPANIE_SUCCESS';
export const GET_COMPANIE_ERROR = 'GET_COMPANIE_ERROR';
export const UPDATE_COMPANIE_ATTEMPT = 'UPDATE_COMPANIE_ATTEMPT';
export const UPDATE_COMPANIE_SUCCESS = 'UPDATE_COMPANIE_SUCCESS';
export const UPDATE_COMPANIE_ERROR = 'UPDATE_COMPANIE_ERROR';
export const UPDATE_COMPANIE_STATUS_ATTEMPT = 'UPDATE_COMPANIE_STATUS_ATTEMPT';
export const UPDATE_COMPANIE_STATUS_SUCCESS = 'UPDATE_COMPANIE_STATUS_SUCCESS';
export const UPDATE_COMPANIE_STATUS_ERROR = 'UPDATE_COMPANIE_STATUS_ERROR';
export const RESET_COMPANIES_ERROR = 'RESET_COMPANIES_ERROR';
