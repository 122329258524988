import { PaginationActionTypes } from './models';
import {
  GET_CATEGORY,
  GET_CRITERIA,
  GET_OPPORTUNITY_TYPE,
  GET_PAGE_NUMBER,
  GET_PER_PAGE,
  GET_STATUS,
  GET_STATUS_FLAG,
  GET_TOTAL_PAGES,
  GET_TYPE,
  GET_TYPE_FLAG,
} from './types';

export const getType = (type: number): PaginationActionTypes => ({
  type: GET_TYPE,
  companyType: type,
});
export const getTotalPages = (totalPages: number): PaginationActionTypes => ({
  type: GET_TOTAL_PAGES,
  total: totalPages,
});

export const getPageNumber = (pageNumber: number): PaginationActionTypes => ({
  type: GET_PAGE_NUMBER,
  pageNumber: pageNumber,
});
export const getPerPage = (limit: number): PaginationActionTypes => ({
  type: GET_PER_PAGE,
  perPage: limit,
});
export const getCriteria = (criteria: string): PaginationActionTypes => ({
  type: GET_CRITERIA,
  criteria: criteria,
});
export const getStatus = (status: string): PaginationActionTypes => ({
  type: GET_STATUS,
  status: status,
});
export const getCategory = (category: string): PaginationActionTypes => ({
  type: GET_CATEGORY,
  category: category,
});
export const getStatusFlag = (statusFlag: string): PaginationActionTypes => ({
  type: GET_STATUS_FLAG,
  statusFlag: statusFlag,
});
export const getTypeFlag = (typeFlag: string): PaginationActionTypes => {
  return {
    type: GET_TYPE_FLAG,
    typeFlag: typeFlag,
  };
};
export const GetOpportunityType = (type: number | string): PaginationActionTypes => {
  return {
    type: GET_OPPORTUNITY_TYPE,
    opportunityType: type,
  };
};
