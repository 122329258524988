/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { UserProfile } from '../user-profiles/user-profiles.types';

export default function useGetUserProfilesQuery() {
  const [userProfiles, setUserProfiles] = React.useState<UserProfile[] | undefined>(undefined);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | undefined>(undefined);

  const getUserProfiles = async (id: number) => {
    try {
      setLoading(true);
      const response = await sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.PROFILES}/${id}`, {}, true, {});
      setUserProfiles(response.data.data);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    userProfiles,
    loading,
    error,
    getUserProfiles,
  };
}
