import { DATA, flagsActionsTypes, FlagsPaginated, FlagsState } from './models';
import { DELETE_COMMENT, DELETE_PUBLICATION, DELETE_USER, GETTING_FLAG, GETTING_FLAGS, GET_FLAGS_SUCCESS, GET_FLAG_SUCCESS } from './types';

const INITIAL_STATE: FlagsState = {
  flagsPaginated: {} as FlagsPaginated,
  flages: [] as DATA[],
  flag: {} as DATA,
  loading: true,
  loadingDetailFlag: true,
  addLoading: false,
  error: '',
};
export const flagsReducer = (state: FlagsState = INITIAL_STATE, action: flagsActionsTypes): FlagsState => {
  switch (action.type) {
    case GETTING_FLAGS:
      return {
        ...state,
        loading: true,
      };
    case GETTING_FLAG:
      return {
        ...state,
        loadingDetailFlag: true,
      };
    case GET_FLAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        flages: action.payload,
      };
    case GET_FLAG_SUCCESS:
      return {
        ...state,
        loadingDetailFlag: false,
        flag: action.payload,
      };
    case DELETE_PUBLICATION:
      return {
        ...state,
        loading: false,
        flag: action.payload,
      };
    case DELETE_COMMENT:
      return {
        ...state,
        loading: false,
        flag: action.payload,
      };
    case DELETE_USER:
      return {
        ...state,
        loading: false,
        flag: action.payload,
      };
    default:
      return state;
  }
};
