import { createTheme } from '@mui/material/styles';
import { colors } from './sharedTheme';

export const tableTheme = createTheme({
  palette: {
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  typography: {
    allVariants: {
      textTransform: 'none',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'SFPro-Regular',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '17px',
      letterSpacing: 'normal',
      lineHeight: 'normal',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiTableContainer-root': {
            backgroundColor: colors.BODY_GREY,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '.grey': {
            opacity: 0.3,
          },
          '.icon_hover': {
            backgroundColor: colors.WHITE,
            '& .hidden_cell': {
              opacity: '0',
            },
            '&:hover .hidden_cell': {
              opacity: '1',
              color: '#d8d8d8',
            },
          },
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: `0 2px 16px 0 ${colors.HOVER_COLOR}`,
          },
          border: ' 1px solid #f5f5f5',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: 50,
          border: 'none',
          padding: '0px 16px',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          display: 'none',
        },
        root: {
          '& .MuiToolbar-regular ': {
            paddingRight: '35%',
          },
        },
      },
    },
  },
});
