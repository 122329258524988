/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { RootState } from '../../rootStore';
import { IFetchSubscriptionPlanSuccessAction, SubscriptionPlanActionTypes } from '../slice/SubscriptionPlan-slice.types';
import { SubscriptionPlanActions, subscriptionPlanReducer } from '../slice/SubscriptionPlan-slice';

export const subscriptionPlanApi = {
  reducerPath: 'subscriptionPlanUsers',
  reducer: subscriptionPlanReducer,
  endpoints: {
    getSubscriptionPlanUsers: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, SubscriptionPlanActionTypes>) => {
      dispatch(SubscriptionPlanActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.SUBSCRIPTIONPLAN_USERS, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(SubscriptionPlanActions.matchFulFilled(responseBody, 'fetch') as IFetchSubscriptionPlanSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(SubscriptionPlanActions.matchRejected(error));
        });
    },
    updateSubscriptionPlan: (id: number, data: any) => async (dispatch: ThunkDispatch<RootState, undefined, SubscriptionPlanActionTypes>) => {
      dispatch(SubscriptionPlanActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Patch, `${ENDPOINTS.VIEW_SUBSCRIPTION_PLANS}/${id}`, data, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(SubscriptionPlanActions.matchFulFilled(responseBody, 'update') as IFetchSubscriptionPlanSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(SubscriptionPlanActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },
  },
};
