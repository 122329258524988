import { Stack, styled } from '@mui/material';

export const RootStyle = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'inherit',
  backgroundColor: '#f5f5f5',
  width: '100%',
  height: '100%',
  padding: '0 16px',
}));

export const ContainerStyle = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  alignItems: 'flex-start',
  justifyContent: 'inherit',
}));
