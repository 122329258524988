import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { ArticlesHeader } from './articlesHeader';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store/rootStore';
import { CONSTANTS } from '../../utils';
import { OnTheTable } from '../table/onTheTable/OnTheTable';
import { PaginationActionTypes } from '../../store/pagination/models';
import CreatePreviewDialog from '../dialog/createDialog/CreatePreviewDialog';
import { getArticles } from '../../store/articles/action';
import { ArticlesActionTypes } from '../../store/articles/models';
import { TableFactory } from '../table/tableFactory/TableFactory';
import { getPageNumber } from '../../store/pagination/actions';

/**
 * Component ArticlesList
 *
 * @component
 *
 * @example
 * return (
 *   <ArticlesList/>
 * )
 */
const ArticlesList = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, ArticlesActionTypes | PaginationActionTypes>>();
  const articlesState = useSelector((state: RootState) => state.articles.articles);
  const loader = useSelector((state: RootState) => state.articles.loading);
  const paginationState = useSelector((state: RootState) => state.pagination);
  const { pageNumber, perPage, status: statusPagination, category: categoryPagination } = paginationState;
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState(categoryPagination);
  const [status, setStatus] = useState(statusPagination);
  const openCreateDialog = () => {
    setOpen(true);
  };
  const closeCreateDialog = () => {
    setOpen(false);
  };
  const handleFilter = (value: string) => {
    switch (value) {
      case CONSTANTS.ARTICLE:
        setCategory(CONSTANTS.ARTICLE);
        break;
      case CONSTANTS.PODCAST:
        setCategory(CONSTANTS.PODCAST);
        break;
      case CONSTANTS.DRAFT_FR:
        setStatus(CONSTANTS.DRAFT);
        break;
      case CONSTANTS.DRAFT_EN:
        setStatus(CONSTANTS.DRAFT);
        break;
      case CONSTANTS.PUBLISHED_FR:
        setStatus(CONSTANTS.PUBLISHED);
        break;
      case CONSTANTS.PUBLISHED_en:
        setStatus(CONSTANTS.PUBLISHED);
        break;
      case CONSTANTS.DELETED_FR:
        setStatus(CONSTANTS.DELETED);
        break;
      case CONSTANTS.DELETED_EN:
        setStatus(CONSTANTS.DELETED);
        break;
      default:
        setCategory('');
        setStatus('');
    }
    dispatch(getPageNumber(0));
  };
  useEffect(() => {
    dispatch(getArticles(pageNumber + 1, perPage, status, category));
  }, [pageNumber + 1, perPage, status, category]);
  return (
    <>
      <Grid container direction="column" rowSpacing={5}>
        <Grid item>{'ok'}</Grid>
        <Grid item>
          <OnTheTable title={CONSTANTS.Articles} openDialog={openCreateDialog} />
          <TableFactory
            headItems={ArticlesHeader}
            bodyData={articlesState}
            name={CONSTANTS.ARTICLE}
            loading={loader}
            statusTable={status}
            handleFilter={handleFilter}
            type={category}
          />
        </Grid>
        {open && <CreatePreviewDialog show={open} closeDialog={closeCreateDialog} title={CONSTANTS.ARTICLE} />}
      </Grid>
    </>
  );
};

export default ArticlesList;
