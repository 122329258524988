/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { ENDPOINTS, sendAsyncRequest } from '../../../../../utils';
import { HttpMethod } from '../../../../../utils/httpMethods';

export default function useAcceptRejectMutation() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const acceptRejectPendingAds = async (requestData: any) => {
    try {
      setIsLoading(true);
      const response = await sendAsyncRequest(HttpMethod.Post, `${ENDPOINTS.ACCEPT_REJECT_PENDING_ADS}`, requestData, true, {});
      setData(response.data);
    } catch (err: any) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isLoading,
    error,
    acceptRejectPendingAds,
  };
}
