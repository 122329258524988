/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { RootState } from '../../rootStore';
import { IFetchPortfolioPlanSuccessAction, PortfolioActionTypes } from '../slice/PortfolioPlans-slice.types';
import { portfolioPlanActions, portfolioReducer } from '../slice/PortfolioPlans-slcie';

export const portfolioPlanApi = {
  reducerPath: 'portfolioData',
  reducer: portfolioReducer,
  endpoints: {
    getPortfolioPlanUsers: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, PortfolioActionTypes>) => {
      dispatch(portfolioPlanActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.PORTFOLIO_PLANS, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(portfolioPlanActions.matchFulFilled(responseBody, 'fetch') as IFetchPortfolioPlanSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(portfolioPlanActions.matchRejected(error));
        });
    },

    createPortfolioPlans: (data: any) => async (dispatch: ThunkDispatch<RootState, undefined, PortfolioActionTypes>) => {
      dispatch(portfolioPlanActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Post, ENDPOINTS.PORTFOLIO_PLANS, data, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(portfolioPlanActions.matchFulFilled(responseBody, 'create') as IFetchPortfolioPlanSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(portfolioPlanActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },

    updatePortfolioPlans: (id: number, data: any) => async (dispatch: ThunkDispatch<RootState, undefined, PortfolioActionTypes>) => {
      dispatch(portfolioPlanActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Put, `${ENDPOINTS.PORTFOLIO_PLANS}/${id}`, data, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(portfolioPlanActions.matchFulFilled(responseBody, 'update') as IFetchPortfolioPlanSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(portfolioPlanActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },

    deletePortfolioPlans: (id: number) => async (dispatch: ThunkDispatch<RootState, undefined, PortfolioActionTypes>) => {
      dispatch(portfolioPlanActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Delete, `${ENDPOINTS.PORTFOLIO_PLANS}/${id}`, {}, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(portfolioPlanActions.matchFulFilled(responseBody, 'delete') as IFetchPortfolioPlanSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(portfolioPlanActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },
  },
};
