import React from 'react';

export const DashboardIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.79996 2.3999H3.79995C3.02797 2.3999 2.39999 3.02788 2.39999 3.79986V7.39986C2.39999 8.17198 3.02797 8.79996 3.79995 8.79996H9.79996C10.5721 8.79996 11.2001 8.17198 11.2001 7.39986V3.79986C11.2001 3.02788 10.5721 2.3999 9.79996 2.3999Z"
        fill="white"
      />
      <path
        d="M9.79996 10.3999H3.79995C3.02797 10.3999 2.39999 11.0279 2.39999 11.8V20.2C2.39999 20.972 3.02797 21.6 3.79995 21.6H9.79996C10.5721 21.6 11.2001 20.972 11.2001 20.2V11.8C11.2001 11.0279 10.5721 10.3999 9.79996 10.3999Z"
        fill="white"
      />
      <path
        d="M20.2001 15.1997H14.2001C13.428 15.1997 12.8 15.8277 12.8 16.5998V20.1998C12.8 20.9718 13.428 21.5998 14.2001 21.5998H20.2001C20.9721 21.5998 21.6001 20.9718 21.6001 20.1998V16.5998C21.6001 15.8277 20.9721 15.1997 20.2001 15.1997Z"
        fill="white"
      />
      <path
        d="M20.2001 2.3999H14.2001C13.428 2.3999 12.8 3.02788 12.8 3.79986V12.1999C12.8 12.972 13.428 13.6 14.2001 13.6H20.2001C20.9721 13.6 21.6001 12.972 21.6001 12.1999V3.79986C21.6001 3.02788 20.9721 2.3999 20.2001 2.3999Z"
        fill="white"
      />
    </svg>
  );
};

export default DashboardIcon;
