/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  ThemeProvider,
  Tooltip,
} from '@mui/material';
import TableFooter from '@mui/material/TableFooter';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import applicant_avatar from '../../../assets/images/applicant_avatar.png';
import { config } from '../../../config/config';
import { getArticleById } from '../../../store/articles/action';
import { getBugById } from '../../../store/bugs/action';
import { ChangeToSeenApplicantsByOpportunity } from '../../../store/counters/actions';
import { getFlagById } from '../../../store/flags/action';
import { getOpportunityById } from '../../../store/opportunities/action';
import { getPageNumber, getPerPage } from '../../../store/pagination/actions';
import { PaginationActionTypes } from '../../../store/pagination/models';
import { RootState } from '../../../store/rootStore';
import { rowTheme } from '../../../theme/rowtheme';
import { tableTheme } from '../../../theme/table-theme';
import { CONSTANTS } from '../../../utils';
import { getFontSize, isImportantColors, renderCellContent } from '../../../utils/stylesUtils';
import CandidatInformations from '../../candidat/CandidatInformations';
import BugDialog from '../../dialog/bugDialog/bugDialog';
import { DetailsDialog } from '../../dialog/detailsDialog/DetailsDialog';
import FlagDialog from '../../dialog/flagsDialog/flagsDialog';
import { CandidatsHeader } from '../../opportunities/candidatsHeader';
import { NoDataFoundComponent } from '../../shared/NoDataFoundComponent/NoDataFoundComponent';
import Spinner from '../../spinner';
import EyeIcon from '../../svgComponents/EyeIcon';
import { TablePaginationActions } from '../TablePaginationActions/TablePaginationActions';
import { HeadTable } from '../tableHead/HeadTable';
import { TableFactoryProps } from './tableFactory.type';
import TableNoData from '../TableNoData';
import { useTranslation } from 'react-i18next';

/**
 *component returning all forms of tables except planning table
 *
 * @returns {ExoticComponent<() => JSX.Element>}
 */
export const TableFactory = (props: TableFactoryProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, PaginationActionTypes>>();
  const [openEdit, setOpenEdit] = React.useState(false);
  const [data, setData] = React.useState<any>([]);
  const [size, setSize] = React.useState(0);
  const [authorImageSize, setAuthorImageSize] = React.useState(0);
  const paginationState = useSelector((state: RootState) => state.pagination);
  const { total, pageNumber, perPage } = paginationState;
  const [showActionId, setShowActionId] = useState<number>(-1);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState<any>([]);
  const [showCandidatInformations, setShowCandidatInformations] = useState(false);
  const [candidatInformation, setCandidatInformations] = useState(false);
  const {
    bodyData,
    headItems,
    canEdit = true,
    name,
    handleFilter,
    loading,
    loaderDetailBug,
    loadingDetailFlag,
    statusTable,
    type,
    setUnseenApplicant,
  } = props;
  const changeSize = (value: number) => {
    setSize(value);
  };
  const changeAuthorImageSize = (value: number) => {
    setAuthorImageSize(value);
  };
  const handleChangeToSeenApplicant = () => {
    setUnseenApplicant(true);
  };
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(getPageNumber(newPage));
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(getPerPage(parseInt(event.target.value, 10)));
    dispatch(getPageNumber(0));
  };
  const handleShowCandidatInformations = (applicant: any) => {
    setShowCandidatInformations(true);
    setCandidatInformations(applicant);
    if (applicant.seen === false) dispatch(ChangeToSeenApplicantsByOpportunity(applicant.id));
    handleChangeToSeenApplicant();
  };
  const showUpdateDialog = (values: any) => {
    switch (name) {
      case CONSTANTS.Bugs:
        dispatch(getBugById(values));
        break;
      case CONSTANTS.Flags:
        dispatch(getFlagById(values));
        break;
      case CONSTANTS.ARTICLE:
        dispatch(getArticleById(values));
        break;
      case CONSTANTS.Opportunities:
        dispatch(getOpportunityById(values));
        break;
      default:
        throw new Error(`Invalid resource name: ${name}`);
    }
    setData(values);
    setOpenEdit(!openEdit);
  };
  const closeSecondDialog = () => {
    setOpenEdit(false);
  };
  const closeCandidatModal = () => {
    setShowCandidatInformations(false);
  };

  const handleAddRow = (row: any) => {
    setSelectedRow(row.id === selectedRow ? null : row.id);
    setSelectedRowData(row.OpportunityApplicants);
  };

  if (bodyData && bodyData?.length <= 0 && !loading && pageNumber === 0)
    return (
      <Container sx={{ minHeight: '80vh' }}>
        <ThemeProvider theme={tableTheme}>
          <TableContainer elevation={0} component={Paper}>
            <Table size={'small'}>
              <HeadTable items={headItems} statusTable={statusTable || ''} handleFilter={handleFilter} type={type} />
            </Table>
            <NoDataFoundComponent />
          </TableContainer>
        </ThemeProvider>
      </Container>
    );
  else if (loading)
    return (
      <Container sx={{ minHeight: '80vh', backgroundColor: '#f5f5f5' }}>
        <Spinner loading={loading} />
      </Container>
    );
  else
    return (
      <>
        <Container sx={{ minHeight: '80vh' }}>
          <ThemeProvider theme={tableTheme}>
            <TableContainer elevation={0} component={Paper}>
              <Table size={'small'}>
                <HeadTable items={headItems} statusTable={statusTable || ''} handleFilter={handleFilter} type={type} />
                <TableBody>
                  {bodyData && bodyData?.length > 0 ? (
                    <>
                      {bodyData.map((row: any, index: number) => {
                        const isRowOpen = row.id === selectedRow;
                        return (
                          <>
                            <ThemeProvider theme={rowTheme}>
                              <TableRow
                                className={'icon_hover'}
                                key={index}
                                style={{
                                  backgroundColor: '#ffffff',
                                  paddingBottom: '20px',
                                  cursor: 'pointer',
                                }}
                                onMouseEnter={() => {
                                  setShowActionId(row.id);
                                }}
                                onMouseLeave={() => setShowActionId(-1)}
                                onClick={() => {
                                  handleAddRow(row);
                                }}
                              >
                                {headItems?.map((cell: any, headIndex: number) => {
                                  const cellValue = row[cell.value];
                                  return (
                                    <TableCell
                                      key={`table-cell-${headIndex}`}
                                      style={{
                                        fontSize: getFontSize(cell),
                                        color: isImportantColors(cell, row),
                                      }}
                                    >
                                      {renderCellContent(cell, cellValue, showActionId, row.id, row, isRowOpen)}
                                    </TableCell>
                                  );
                                })}
                                <TableCell align="left">
                                  <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                                    <Grid item>
                                      <Grid container direction="row" justifyContent="flex-end" alignItems="center" wrap="nowrap">
                                        {canEdit && (
                                          <Grid item>
                                            <Tooltip title="View Details" arrow>
                                              <IconButton
                                                className="hidden_cell"
                                                onClick={() => {
                                                  showUpdateDialog(row.id);
                                                }}
                                              >
                                                <EyeIcon />
                                              </IconButton>
                                            </Tooltip>
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            </ThemeProvider>

                            {name === CONSTANTS.Opportunities && row.id === selectedRow && (
                              <>
                                {selectedRowData.map((r: any, ind: number) => {
                                  const opacity = r.seen === false ? '0.6' : '1';
                                  return (
                                    r?.user && (
                                      <TableRow
                                        key={ind}
                                        style={{ backgroundColor: 'white', opacity: opacity }}
                                        className={'icon_hover'}
                                        onClick={() => handleShowCandidatInformations(r)}
                                      >
                                        {CandidatsHeader.map((cell: any, candIndex: number) => {
                                          const cellValue = r.user[cell.value];
                                          const imgContent = (
                                            <img
                                              src={cellValue?.startsWith(config.BASE_URL_API) ? cellValue : applicant_avatar}
                                              alt=""
                                              style={{ width: '48px', height: '48px', paddingTop: '6px' }}
                                            />
                                          );
                                          return (
                                            <TableCell
                                              key={`table-cell-${candIndex}`}
                                              style={{
                                                color: isImportantColors(cell, row),
                                                textTransform: 'none',
                                                fontFamily: 'SFPro-Regular',
                                                fontStyle: 'normal',
                                                fontWeight: 'normal',
                                                fontSize: '17px',
                                                cursor: 'pointer',
                                              }}
                                            >
                                              {cell.type == 'image' ? imgContent : cellValue}
                                            </TableCell>
                                          );
                                        })}
                                        <TableCell />
                                      </TableRow>
                                    )
                                  );
                                })}
                              </>
                            )}
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={total || 0}
                rowsPerPage={perPage}
                page={pageNumber}
                labelRowsPerPage={t('common.rowsperpage')}
                sx={{
                  '& .MuiInputBase-root': {
                    maxWidth: '60px',
                  },
                  '& .MuiTablePagination-selectLabel': {
                    display: 'flex !important',
                  },
                  '& .MuiToolbar-regular ': {
                    paddingRight: '2px',
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Box>
          </ThemeProvider>
          {name === CONSTANTS.Bugs && openEdit ? (
            <BugDialog show={openEdit} setOpenEdit={setOpenEdit} data={data} statusTable={statusTable} loadingState={loaderDetailBug}></BugDialog>
          ) : name === CONSTANTS.Flags ? (
            <FlagDialog
              show={openEdit}
              setOpenEdit={setOpenEdit}
              data={data}
              statusTable={statusTable}
              loadingState={loadingDetailFlag}
              type={type}
            ></FlagDialog>
          ) : name === CONSTANTS.ARTICLE || CONSTANTS.Opportunities ? (
            <DetailsDialog
              openSecondModal={openEdit}
              closeSecondDialog={closeSecondDialog}
              data={data}
              setData={setData}
              moduleName={name}
              isUpdate={true}
              closeDialog={closeSecondDialog}
              changeSize={changeSize}
              size={size}
              authorImageSize={authorImageSize}
              changeAuthorImageSize={changeAuthorImageSize}
            />
          ) : (
            <></>
          )}
          {showCandidatInformations && (
            <CandidatInformations show={showCandidatInformations} data={candidatInformation} closeCandidatModal={closeCandidatModal} />
          )}
        </Container>
      </>
    );
};
