/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box, Button, CircularProgress, Grid, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HowItWorksFormProps } from './HowItWorks-form.types';
import RHFTextField from '../../../../hook-form/RHFTextField';
import CustomLoadingButton from '../../../../button/CustomLoadingButton';
import useSnackBar from '../../../../../hooks/useSnackBar';
import FormProvider from '../../../../hook-form/FormProvider';
import AddPhotoIcon from '../../../../svgComponents/AddPhotoIcon';
import AdminVideoControl from './VideoControl';
import FullScreenModal from './FullScreenModal';
import HowitWorks from '../../../../../models/HowItWorks';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateWorksSchema } from './create-works.schema';
import { HowItWorksActionTypes } from '../../../../../store/howItWorks/slice/HowItWorks-slice.types';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../../../store/rootStore';
import { howItWorksApi } from '../../../../../store/howItWorks/api/HowItWorks.api';

export default function HowItWorksForm({ currentWorkData, isUpdate, onClose, isEyeIconClicked }: HowItWorksFormProps) {
  const { t } = useTranslation();

  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);

  const [postURL, setPostURL] = useState<string | undefined>(undefined);
  const [postType, setPostType] = useState<string | undefined>(undefined);
  const [post, setPost] = useState<string | undefined>(undefined);
  const [postError, setPostError] = useState<string | undefined>(undefined);

  const [videoURL, setVideoURL] = useState<string | undefined>(undefined);
  const [videoType, setVideoType] = useState<string | undefined>(undefined);
  const [largeImgUrl, setLargeImgUrl] = useState<string | undefined>(undefined);
  const [openImgModel, setOpenImgModal] = useState(false);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, HowItWorksActionTypes>>();

  useEffect(() => {
    if (isUpdate && currentWorkData) {
      const posturl = currentWorkData?.imageOrVideoUrl || '';
      const postExtension = posturl.split('.').pop()?.toLowerCase() || '';
      setPostURL(posturl);
      setPostType(postExtension);
      setPost(posturl);
    }
  }, [currentWorkData, isUpdate]);

  const defaultValues = useMemo<HowitWorks>(
    () => ({
      title: currentWorkData?.title || '',
      description: currentWorkData?.description || '',
      imageOrVideoUrl: currentWorkData?.imageOrVideoUrl || '',
    }),
    [currentWorkData],
  );

  const methods = useForm<HowitWorks>({
    resolver: yupResolver(CreateWorksSchema),
    defaultValues,
    mode: 'all',
  });

  const { alertSuccess, alertError } = useSnackBar();

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentWorkData]);

  useEffect(() => {
    // Update defaultValues whenever currentWorkData or isUpdate changes
    const updatedDefaultValues = {
      title: currentWorkData?.title || '',
      description: currentWorkData?.description || '',
      imageOrVideoUrl: currentWorkData?.imageOrVideoUrl || '',
    };
    methods.reset(updatedDefaultValues);
  }, [currentWorkData, isUpdate]);

  const onSubmit = async () => {
    if (isUpdate) {
      update();
    }
  };

  const update = async () => {
    try {
      trigger().then(async (isValid) => {
        if (isValid && currentWorkData?.id && !postError) {
          const formData = new FormData();
          formData.append('title', values?.title);
          formData.append('description', values?.description);
          formData.append('imageOrVideoUrl', post || '');
          dispatch(howItWorksApi.endpoints.updateWorkUsers(currentWorkData?.id, formData))
            .then(() => {
              reset(defaultValues);
              dispatch(alertSuccess(t('adds.how_it_works_success_message')));
              onClose();
            })
            .catch((err) => {
              dispatch(alertError(err.message)); // Show error message in alert
            });
        }
      });
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    }
  };

  const handleLogoUpload = (e: any) => {
    const logoFile = e.target.files[0];
    const imageUrl = URL.createObjectURL(logoFile);
    const fileType = logoFile?.type.split('/')[1];
    if (logoFile) {
      if (logoFile.type.includes('image') || logoFile.type.includes('video')) {
        if (logoFile.size >= 5 * 1024 * 1024) {
          setPostError(t('adds.file_size_error'));
          setPost(undefined);
          setPostURL(undefined);
          setPostType(undefined);
        } else {
          setPostError(undefined);
          setPost(logoFile);
          setValue('imageOrVideoUrl', logoFile);
          setPostType(fileType);
          setPostURL(imageUrl || undefined);
        }
      } else {
        setPostError('Please choose an image/video file.');
        setPost(undefined);
        setPostURL(undefined);
        setPostType(undefined);
      }
    }
  };

  const openVideoPopup = (type: string) => {
    if (type === 'post') {
      setVideoURL(postURL);
      setVideoType(postType);
    }
    setVideoPopupOpen(true);
  };

  const closeVideoPopup = () => {
    setVideoPopupOpen(false);
  };

  const handleClickOpenModal = (imageurl: string) => {
    setLargeImgUrl(imageurl || '');
    setOpenImgModal(true);
  };
  const handleClickCloseImgModal = () => {
    setLargeImgUrl('');
    setOpenImgModal(false);
  };
  return (
    <>
      {currentWorkData ? (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid className="artgapi-plan-form" container spacing={3}>
            <Grid className="artgapi-plan-form" item xs={12} md={12}>
              <Box sx={{ p: 3 }}>
                <Box sx={{ mb: 3 }}>
                  <Box
                    sx={{
                      display: 'grid',
                      columnGap: 2,
                      rowGap: 3,
                      gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <RHFTextField
                      name={'title'}
                      label={t('adds.form_fields.title.label')}
                      placeholder={t('adds.form_fields.title.placeholder')}
                      disabled={isEyeIconClicked}
                    />
                    <RHFTextField
                      name={'description'}
                      label={t('adds.form_fields.description.label')}
                      placeholder={t('adds.form_fields.description.placeholder')}
                      disabled={isEyeIconClicked}
                    />
                  </Box>
                </Box>

                {/* Advertisement plan type Information Section */}
                <Box sx={{ mb: 3 }}>
                  {values?.imageOrVideoUrl && (
                    <Grid>
                      <div>
                        <label className="MuiInputLabel-root MuiInputLabel-animated MuiFormLabel-root MuiFormLabel-colorPrimary css-r440dj-MuiFormLabel-root-MuiInputLabel-root">
                          {t('adds.form_fields.imageOrVideoUrl.label')}
                        </label>
                        <div style={{ display: 'flex', marginBottom: '0', alignItems: 'center' }}>
                          {postURL ? (
                            <>
                              <div>
                                <span>
                                  {postType?.includes('mp4') ? (
                                    <>
                                      <div
                                        style={{
                                          width: '100px',
                                          height: '100px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          position: 'relative',
                                        }}
                                      >
                                        <video
                                          style={{
                                            cursor: 'pointer',
                                            width: '100%',
                                            objectFit: 'cover',
                                            height: '100%',
                                          }}
                                          src={postURL}
                                          onClick={() => openVideoPopup('post')}
                                        ></video>

                                        <span
                                          style={{
                                            backgroundColor: 'white',
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderRadius: '9999px',
                                            position: 'absolute',
                                            width: '1.5 rem',
                                            height: '1,5 rem',
                                            cursor: 'pointer',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <PlayCircleOutlineIcon className="text-base" onClick={() => openVideoPopup('post')} />
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    <img
                                      src={postURL}
                                      alt="post"
                                      style={{
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'contain',
                                      }}
                                      onClick={() => handleClickOpenModal(postURL || '')}
                                    />
                                  )}
                                </span>
                                {/* <label className="text-[#5e5e5e] break-all !mb-0">{'gdfgdfh'}</label> */}
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '0px' }}>
                                <label
                                  htmlFor="logos"
                                  style={{
                                    width: '44px',
                                    height: '44px',
                                    minWidth: '44PX',
                                    backgroundColor: '#f0f0f0',
                                    border: '1px dashed #ccc',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <AddPhotoIcon />
                                </label>
                              </div>
                            </>
                          )}
                          {!isEyeIconClicked && (
                            <Button
                              className="artgapi-upload-btn"
                              style={{
                                textTransform: 'capitalize',
                                boxShadow: 'inset 0px -1px 0px #E7E7E7',
                                marginLeft: '15px',
                                backgroundColor: isEyeIconClicked ? '#ffcc33' : '#ffcc33',
                              }}
                              size="small"
                              variant="contained"
                              component="label"
                            >
                              {t('articles.upload')}
                              <input hidden type="file" name="" id="add-post" onChange={(e) => handleLogoUpload(e)} />
                            </Button>
                          )}
                        </div>
                        {postError && <p style={{ color: '#d32f2f', marginTop: '0.5rem' }}>{postError}</p>}
                      </div>
                    </Grid>
                  )}
                </Box>
                {/* Submit Button */}
                {isEyeIconClicked ? (
                  ''
                ) : (
                  <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                    <CustomLoadingButton
                      loading={isSubmitting}
                      label={isUpdate ? t('adds.update_button_label') : t('adds.create_button_label')}
                      onClick={onSubmit}
                    />
                  </Stack>
                )}
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '70px' }}>
          <CircularProgress
            sx={{
              color: '#ffcc33',
            }}
          />
        </div>
      )}
      {isVideoPopupOpen && (
        <AdminVideoControl
          isVideoPopupOpen={isVideoPopupOpen}
          closeVideoPopup={closeVideoPopup}
          videourl={videoURL || ''}
          selectedVideo={videoType || ''}
        />
      )}
      {openImgModel && <FullScreenModal openImgModel={openImgModel} closeImgPopup={handleClickCloseImgModal} largeImgUrl={largeImgUrl || ''} />}
    </>
  );
}
