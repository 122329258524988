import * as yup from 'yup';

export const CreateSubscriptionPlansSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .max(50, 'masterClass.form_fields.title.max_length_error_message')
    .required('masterClass.form_fields.title.required_error_message'),
  description: yup.string().trim().required('masterClass.form_fields.description.required_error_message'),
  price: yup.number().required('masterClass.form_fields.video.required_error_message'),
});
