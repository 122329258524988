/* eslint-disable react/react-in-jsx-scope */
// @mui
import { IconButton, Stack, TableRow, Switch, Tooltip } from '@mui/material';
// components
import { ReactComponent as DeleteIcon } from '../../../assets/icons/ic_trash.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/pencil-outline.svg';
import Iconify from '../../../components/Iconify';
import { Column } from '../../../components/table/table-head/CustomTableHead.types';
import { TableCellStyle } from './SystemUserRow.styles';
import { SystemUserRowProps } from './SystemUserRow.types';
import EyeIcon from '../../../components/svgComponents/EyeIcon';
import { useState } from 'react';
// ----------------------------------------------------------------------

export default function SystemUserTableRow({
  row,
  selected,
  onShowDetails,
  columnsConfig,
  onOPenEditDialog,
  onConfirmDelete,
  handleSwitchChange,
}: SystemUserRowProps) {
  const { id, isActive } = row;
  const [isActived, setIsActived] = useState(isActive);

  const handleChange = () => {
    setIsActived(!isActived);
    handleSwitchChange(id, !isActived);
  };
  return (
    <TableRow key={id} hover selected={selected} sx={{ backgroundColor: '#ffffff' }}>
      {columnsConfig.map((column: Column) => (
        <TableCellStyle key={column.id} align={column.align || 'left'} width={column.width}>
          {column.id !== 'actions' ? (
            column.id === 'isActive' ? (
              <Switch checked={isActived} color="primary" onChange={handleChange} />
            ) : (
              String(row[column.id as keyof typeof row]) // Convert to string
            )
          ) : (
            <Stack direction="row" spacing={2}>
              <Tooltip title="View Details" arrow>
                <IconButton onClick={() => onShowDetails(id, true)}>
                  <EyeIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit" arrow>
                <IconButton onClick={() => onOPenEditDialog(id)}>
                  <Iconify icon={EditIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <IconButton onClick={() => onConfirmDelete(id)}>
                  <Iconify icon={DeleteIcon} />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </TableCellStyle>
      ))}
    </TableRow>
  );
}
