import * as yup from 'yup';

export const PlanTypeSchema = yup.object().shape({
  id: yup.number().required('Module ID is required'),
  // name: yup.string().required('Module name is required'), // Adjusted error message
});

export const CreatePendingAdsSchema = yup.object().shape({
  name: yup.string().required('adds.form_fields.name.required_error_message'), // Adjusted error message
  mail: yup.string().email('adds.form_fields.mail.invalid_error_message').required('adds.form_fields.mail.required_error_message'),
  adsTitle: yup.string().required('adds.form_fields.adsTitle.required_error_message'), // Adjusted error message
  description: yup.string().required('adds.form_fields.description.required_error_message'), // Adjusted error message
  websiteUrl: yup
    .string()
    .required('adds.form_fields.websiteUrl.required_error_message') // Error message for required field
    .test('is-url', 'adds.form_fields.websiteUrl.invalid_error_message', (value) => {
      if (!value) return true; // Allow empty values (handled by .required())
      // Regular expression to match URLs with or without protocol
      const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
      return urlRegex.test(value);
    }),
  roleIds: yup.array().of(PlanTypeSchema).min(1, 'adds.form_fields.roleIds.required_error_message'),
});
