/* eslint-disable @typescript-eslint/no-explicit-any */
import AdsUsers from '../../../models/AdsUsers';
import {
  ActionTypes,
  IFetchAdsUsersRejectedAction,
  IFetchAdsUsersSuccessAction,
  IAdsUsersPendingAction,
  IAdsUsersResponse,
  IAdsUsersState,
  AdsUsersActionTypes,
} from './AdsUser-slice.types';

export const initialState: IAdsUsersState = {
  isLoading: false,
  error: null,
  users: null,
  adsusers: null,
};

export const adsUsersReducer = (state = initialState, action: AdsUsersActionTypes): IAdsUsersState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_ADS_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.payload as IAdsUsersResponse,
      };
    case ActionTypes.FETCH_ADS_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_ADS_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adsusers: action.payload as AdsUsers,
      };
    default:
      return state;
  }
};

const matchPending = (): IAdsUsersPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (data: IAdsUsersResponse, action: 'fetch' | 'create' | 'delete'): IFetchAdsUsersSuccessAction | AdsUsers => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_ADS_USERS_SUCCESS : ActionTypes.CREATE_ADS_USERS_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchAdsUsersRejectedAction => {
  return {
    type: ActionTypes.FETCH_ADS_USERS_FAILURE,
    payload: error,
  };
};

export const adsUsersActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
