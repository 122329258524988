/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react';
import { University } from '../../../models/University';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';

export default function useUpdateUniversityMutation() {
  const [data, setData] = useState<University | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateUniversity = useCallback(async (id: number, university: University) => {
    setIsLoading(true);
    try {
      const response = await sendAsyncRequest(HttpMethod.Patch, `${ENDPOINTS.UNIVERSITIES}/${id}`, { ...university }, true, {});
      setData(response.data.data);
    } catch (err: any) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);
  return {
    data,
    isLoading,
    error,
    updateUniversity,
  };
}
