import { CONSTANTS } from '../../utils';

export interface Description {
  label: string;
  type: string;
  height: number;
  width: string;
  choices?: { label: string; value: string; id?: number }[];
  choicesTypes?: { label: string; id?: number }[];
  filterBy?: string;
  value: string;
}

export const OpportunitiesHeader: Description[] = [
  {
    label: 'common.photo',
    type: 'image',
    height: 50,
    width: '10%',
    value: 'minImage',
    filterBy: 'image',
  },
  {
    label: 'common.title',
    type: 'normal',
    height: 50,
    width: '15%',
    value: 'title',
  },
  {
    label: 'common.type',
    type: 'select',
    filterBy: 'opportunityType',
    height: 50,
    width: '15%',
    value: 'type',
    choicesTypes: [
      { label: 'common.all', id: 10 },
      { label: 'common.collab', id: 20 },
      { label: 'common.expose', id: 30 },
    ],
  },
  {
    label: 'common.candidates',
    type: 'normal',
    height: 50,
    width: '20%',
    value: 'numberOfApplicants',
    filterBy: 'nbCandidats',
  },
  {
    label: 'common.start_date',
    type: 'date',
    height: 50,
    width: '20%',
    value: 'startDate',
    filterBy: 'date',
  },
  {
    label: 'common.end_date',
    type: 'date',
    height: 50,
    width: '35%',
    value: 'endDate',
    filterBy: 'date',
  },
];
