/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import {
  Box,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Select,
  Typography,
  MenuItem,
  CircularProgress,
  FormHelperText,
  Button,
  IconButton,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { InterestsFormProps } from './Interests-form.types';
import useSnackBar from '../../../../hooks/useSnackBar';
import FormProvider from '../../../hook-form/FormProvider';
import CustomLoadingButton from '../../../button/CustomLoadingButton';
import RHFTextField from '../../../hook-form/RHFTextField';
import { RootState } from '../../../../store/rootStore';
import { interestsApi } from '../../../../store/interests/api/Interests.api';
import { InterestsActionTypes } from '../../../../store/interests/slice/Interests-slice.types';
import { Interests } from '../../../../models/Interests';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getInterestCategories } from '../../../../store/interestCategories/actions';
import { interestCategoriesActionTypes } from '../../../../store/interestCategories/models';
import CancelIcon from '@mui/icons-material/Cancel';
import { languageActionTypes } from '../../../../store/languages/models';
import { getLanguages } from '../../../../store/languages/actions';
import Languages from '../../../../models/Languages';

const { updateInterests, createInterestUser } = interestsApi.endpoints;

export default function InterestsForm({ currentInterests, isUpdate, onClose, isEyeIconClicked }: InterestsFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, InterestsActionTypes>>();
  const dispatchCountries = useDispatch<ThunkDispatch<RootState, undefined, interestCategoriesActionTypes>>();
  const categoriesList = useSelector((state: RootState) => state.categoriesList.categoriesList);
  const [showLanguage, setShowLanguage] = useState<{ id: number; title: string; languageName: string }[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);
  const dispatchLanguages = useDispatch<ThunkDispatch<RootState, undefined, languageActionTypes>>();
  const languages = useSelector((state: RootState) => state.languages.languages || []); // Provide a default value of an empty array
  const [languagesList, setLanguagesList] = useState<Languages[]>([]); // Specify the type explicitly as Languages[]

  const defaultValues = useMemo<Interests>(
    () => ({
      title: '',
      interestCategoryId: currentInterests?.interestCategoryId || 0,
      language: 0, // Default to the first language ID if available, otherwise default to English (ID: 45)
    }),
    [currentInterests],
  );
  const schema = Yup.object().shape({
    title: Yup.string().trim().required(t('interests.form_fields.title.required_error_message')),
    // interestCategoryId: Yup.number().required('Category ID is required'),
    interestCategoryId: Yup.number()
      .min(1, ({ min }) => 'interests.form_fields.category_id.required_error_message')
      .required('interests.form_fields.category_id.required_error_message'),
    language: Yup.number().required(t('interests.form_fields.language.required_error_message')),
  });

  const methods = useForm<Interests>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'all',
  });

  const { alertSuccess, alertError } = useSnackBar();

  const {
    reset,
    handleSubmit,
    trigger,
    watch,
    formState: { isSubmitting, errors },
  } = methods;
  const values = watch();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatchCountries(getInterestCategories());
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatchLanguages(getLanguages());
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (isUpdate) {
      const defaultTitle = defaultValues.title;
      methods.setValue('title', defaultTitle);
    }
  }, [defaultValues.title, isUpdate]);

  useEffect(() => {
    if (languages && currentInterests && currentInterests.translations && currentInterests.translations.length > 0) {
      const translatedLanguagesIds = currentInterests.translations.map((translation) => translation.languageId);
      const missingLanguages = languages.filter((language) => !translatedLanguagesIds.includes(language.id));

      if (isUpdate) {
        const existingLanguagesIds = showLanguage.map((lang) => lang.id);
        const newLanguages = missingLanguages.filter((lang) => !existingLanguagesIds.includes(lang.id));
        setLanguagesList(newLanguages);
      } else {
        setShowLanguage((prevShowLanguage) => [
          ...prevShowLanguage,
          ...missingLanguages.map((lang) => ({
            id: lang.id,
            title: '', // You might want to replace this with an appropriate default value
            languageName: lang.languageName,
          })),
        ]);
      }
    } else if (languages) {
      setLanguagesList(languages);
    }
  }, [languages, isUpdate, currentInterests]);

  useEffect(() => {
    if (currentInterests && currentInterests.translations && currentInterests.translations.length > 0) {
      const transformedTranslations = currentInterests.translations.map((translation) => ({
        id: translation.languageId,
        title: translation.title,
        languageName: translation.language.languageName || 'English',
      }));
      setShowLanguage(transformedTranslations);
    }
  }, [currentInterests]);

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentInterests]);

  useEffect(() => {
    // Reset error message when language changes
    setErrorMessage(null);
  }, [values.language]);

  useEffect(() => {
    // Update defaultValues whenever currentInterests or isUpdate changes
    const updatedDefaultValues = {
      title: '',
      interestCategoryId: currentInterests?.interestCategoryId || 0,
      language: 0, // Default to the first language ID if available, otherwise default to English (ID: 45)
    };
    methods.reset(updatedDefaultValues);
  }, [currentInterests, isUpdate]);

  const validateAdd = async () => {
    const isValid = await trigger('title');
    return isValid;
  };

  useEffect(() => {
    if (languagesList.length > 0) {
      methods.setValue('language', languagesList[0].id);
    } else {
      methods.setValue('language', 0);
    }
  }, [languagesList, methods]);

  const validateCategoryId = async () => {
    const isValid = await trigger('interestCategoryId');
    return isValid;
  };

  useEffect(() => {
    if (formErrorMessage) {
      dispatch(alertError(formErrorMessage));
      setFormErrorMessage(null); // Reset formErrorMessage after showing the alert
    }
  }, [formErrorMessage, dispatch]);

  const onSubmit = async () => {
    if (!isUpdate) {
      if (showLanguage.length === (languages && languages.length)) {
        const isValid = await validateCategoryId();
        if (!isValid) {
          return;
        } else {
          setFormErrorMessage(null);
          create();
        }
      } else {
        await trigger('interestCategoryId');
        setFormErrorMessage(t('interests.form_fields.language.required_error_message'));
      }
    } else {
      if (showLanguage.length === (languages && languages.length)) {
        const isValid = await validateCategoryId();
        if (!isValid) {
          return;
        } else {
          setFormErrorMessage(null);
          update();
        }
      } else {
        await trigger('interestCategoryId');
        setFormErrorMessage(t('interests.form_fields.language.required_error_message'));
      }
    }
  };

  const create = async () => {
    try {
      if (values.interestCategoryId) {
        const englishTitle =
          showLanguage.find((item) => {
            const language = languages.find((lang) => lang.id === item.id);
            return language?.languageCode === 'en';
          })?.title || values.title;
        const interestData = {
          title: englishTitle,
          interestCategoryId: values?.interestCategoryId,
          translations: showLanguage.map(({ id, title }) => ({ languageId: id, title })),
        };
        dispatch(createInterestUser(interestData))
          .then(() => {
            reset(defaultValues);
            dispatch(alertSuccess(t('interests.create_interest_success_message')));
            onClose(); // Close popup only when request succeeds
          })
          .catch((err) => {
            if (err.message === 'Interest title already exists') {
              dispatch(alertError(t('interests.interest_already_exists')));
            } else {
              dispatch(alertError(t('common.something_wrong_happend')));
            }
            console.error(err);
          });
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    }
  };

  const update = async () => {
    try {
      if (currentInterests?.id) {
        const englishTitle =
          showLanguage.find((item) => {
            const language = languages.find((lang) => lang.id === item.id);
            return language?.languageCode === 'en';
          })?.title || values.title;
        const interestData = {
          title: englishTitle,
          interestCategoryId: values?.interestCategoryId,
          translations: showLanguage.map(({ id, title }) => ({ languageId: id, title })),
        };
        dispatch(updateInterests(currentInterests?.id, interestData))
          .then(() => {
            reset(defaultValues);
            dispatch(alertSuccess(t('interests.update_interest_success_message')));
            onClose();
          })
          .catch((err) => {
            dispatch(alertError(err.message)); // Show error message in alert
          });
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    }
  };

  const handleAddValue = async () => {
    const isValid = await validateAdd();
    if (!isValid) {
      return;
    }

    const { title, language, interestCategoryId } = values;
    const selectedLanguage = languagesList.find((lang) => lang.id === Number(language));
    if (selectedLanguage) {
      const alreadyExists = showLanguage.some((item) => item.id === Number(language));
      if (alreadyExists) {
        setErrorMessage(`${selectedLanguage.languageName} est déjà ajouté.`);
      } else {
        setShowLanguage((prevValues) => [...prevValues, { id: selectedLanguage.id, languageName: selectedLanguage.languageName, title }]);
        setErrorMessage(null);
        setLanguagesList((prevLanguages) => prevLanguages.filter((lang) => lang.id !== Number(language))); // Remove from languagesList
        reset({ ...defaultValues, title: '', interestCategoryId }); // Reset the title field only
      }
    }
    if (showLanguage.length === (languages && languages.length)) {
      setFormErrorMessage(null);
    }
  };

  const handleRemoveValue = (id: number) => {
    const removedLanguage = showLanguage.find((item) => item.id === id);
    if (removedLanguage) {
      setShowLanguage((prevValues) => prevValues.filter((item) => item.id !== id));
      setLanguagesList((prevLanguages) => {
        const updatedLanguages = [...prevLanguages, { id: removedLanguage.id, languageName: removedLanguage.languageName } as Languages];
        return updatedLanguages.sort((a, b) => a.languageName.localeCompare(b.languageName));
      });
    }
  };

  return (
    <>
      {!currentInterests && isUpdate ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '70px' }}>
          <CircularProgress
            sx={{
              color: '#ffcc33',
            }}
          />
        </div>
      ) : (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid className="artgapi-modal" container spacing={3}>
            <Grid className="artgapi-plan-form" item xs={12} md={12}>
              <Box sx={{ p: 3 }}>
                <Box sx={{ mb: 3 }}>
                  <Box
                    sx={{
                      display: 'grid',
                      columnGap: 2,
                      rowGap: 3,
                      gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel
                        id="interestCategoryId-label"
                        sx={{
                          transform: 'translate(0px, 0px) scale(0.95)',
                          position: 'relative !important',
                          maxWidth: '0',
                          minWidth: '100%',
                          color: 'rgb(171, 171, 171)',
                          marginBottom: '0.5rem',

                          '& .css-to7sdl-MuiFormControl-root': {
                            marginTop: '4px !important',
                          },
                        }}
                      >
                        {t('interests.form_fields.category_id.label')}
                      </InputLabel>
                      <Controller
                        name="interestCategoryId"
                        control={methods.control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <Select
                              {...field}
                              sx={{
                                width: '100%',
                                height: '48px',
                                borderRadius: '0',
                                '& .MuiSelect-select': {
                                  width: '100%',
                                  height: '48px !important',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  padding: '12.5px 14px',
                                },
                                '& .MuiOutlinedInput-root': {
                                  marginTop: '-8px !important',
                                },
                                '& .MuiOutlinedInput-input': {
                                  border: isEyeIconClicked ? '1px solid #e7e7e7' : '1px solid #0d0d0d',
                                  borderColor: error && '#d32f2f',
                                  borderRadius: '0',
                                },
                                '& .MuiSelect-select:hover': {
                                  borderColor: '#141415 !important',
                                },
                              }}
                              labelId="interestCategoryId-label"
                              id="interestCategoryId"
                              value={field.value}
                              onChange={(e) => field.onChange(e.target.value)}
                              disabled={isEyeIconClicked}
                            >
                              <MenuItem key="select" selected value="0" disabled>
                                {t('common.filter')}
                              </MenuItem>
                              {/* Map over the list of countries */}
                              {categoriesList?.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.title}
                                </MenuItem>
                              ))}
                            </Select>
                            {/* Display error message if there's an error for countryId */}
                            {error && (
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: '0.75rem',
                                  lineHeight: '1.66',
                                  letterSpacing: '0.03333em',
                                  textAlign: 'left',
                                  marginTop: '3px',
                                  marginRight: '14px',
                                  marginBottom: '0',
                                  marginLeft: '14px',
                                  color: '#d32f2f',
                                }}
                              >
                                {t(`${error?.message}`)}
                              </Typography>
                            )}
                          </>
                        )}
                      />
                    </FormControl>
                    {languagesList?.length > 0 && (
                      <>
                        <FormControl fullWidth>
                          <InputLabel
                            id="language-label"
                            sx={{
                              transform: 'translate(0px, 0px) scale(0.95)',
                              position: 'relative !important',
                              maxWidth: '0',
                              minWidth: '100%',
                              color: 'rgb(171, 171, 171)',
                              marginBottom: '0.5rem',

                              '& .css-to7sdl-MuiFormControl-root': {
                                marginTop: '4px !important',
                              },
                            }}
                          >
                            {t('interests.form_fields.language.label')}
                          </InputLabel>
                          <Controller
                            name="language"
                            control={methods.control}
                            render={({ field, fieldState: { error } }) => (
                              <>
                                <Select
                                  {...field}
                                  sx={{
                                    width: '100%',
                                    height: '48px',
                                    borderRadius: '0',
                                    '& .MuiSelect-select': {
                                      width: '100%',
                                      height: '48px !important',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      padding: '12.5px 14px',
                                      paddingRight: '110px !important',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      marginTop: '-8px !important',
                                    },
                                    '& .MuiOutlinedInput-input': {
                                      border: isEyeIconClicked ? '1px solid #e7e7e7' : '1px solid #0d0d0d',
                                      borderColor: error && '#d32f2f',
                                      borderRadius: '0',
                                    },
                                    '& .MuiSelect-select:hover': {
                                      borderColor: '#141415 !important',
                                    },
                                  }}
                                  labelId="language-label"
                                  id="languageId"
                                  value={field.value}
                                  onChange={(e) => field.onChange(e.target.value)}
                                  disabled={isEyeIconClicked}
                                >
                                  {/* Map over the list of countries */}
                                  {languagesList?.map((country) => (
                                    <MenuItem key={country.id} value={country.id}>
                                      {country.languageName}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {/* Display error message if there's an error for countryId */}
                                {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
                              </>
                            )}
                          />
                        </FormControl>
                        <RHFTextField
                          name={'title'}
                          label={t('interests.form_fields.title.label')}
                          placeholder={t('interests.form_fields.title.placeholder')}
                        />
                        <Button className="country-add-btn" sx={{ marginTop: '21px' }} variant="contained" onClick={handleAddValue}>
                          Add
                        </Button>
                      </>
                    )}
                  </Box>
                  {showLanguage?.length > 0 && (
                    <Box sx={{ mt: 3 }}>
                      <Typography variant="h6">{t('interests.listoflangauges')}:</Typography>
                      <ul className="country-box">
                        {showLanguage?.map((item, index) => (
                          <li key={item?.id} style={{ listStyle: 'none' }} className="country-wrapper">
                            <span className="country-container">
                              <span className="country-label">{item.languageName}:</span>
                              <span className="country-title">{item.title}</span>
                            </span>
                            <span className="delete-country-label">
                              <IconButton onClick={() => handleRemoveValue(item.id)} size="small" sx={{ ml: 1 }}>
                                <CancelIcon fontSize="small" sx={{ color: 'red' }} />
                              </IconButton>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  )}
                </Box>

                {/* Submit Button */}
                {isEyeIconClicked ? (
                  ''
                ) : (
                  <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                    <CustomLoadingButton
                      loading={isSubmitting}
                      label={!isUpdate ? t('interests.create_button_label') : t('interests.update_button_label')}
                      onClick={onSubmit}
                    />
                  </Stack>
                )}
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
      )}
    </>
  );
}
