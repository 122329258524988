import { Transactions } from '../../../models/Transactions';

export interface ITransactionReportResponse {
  total: number;
  next: number | null;
  previous: number | null;
  data: {
    transactionData: Transactions[];
    totalPrice: number;
  };
}

export interface ITransactionReportState {
  isLoading: boolean;
  data: ITransactionReportResponse | null;
  transactionUsers: Transactions | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_TRANSACTION_REPORT_SUCCESS = 'FETCH_TRANSACTION_REPORT_SUCCESS',
  FETCH_TRANSACTION_REPORT_FAILURE = 'FETCH_TRANSACTION_REPORT_FAILURE',
  CREATE_TRANSACTION_REPORT_SUCCESS = 'CREATE_TRANSACTION_REPORT_SUCCESS',
}

export interface ITransactionReportPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface ITransactionReportSuccessAction {
  type: typeof ActionTypes.FETCH_TRANSACTION_REPORT_SUCCESS | typeof ActionTypes.CREATE_TRANSACTION_REPORT_SUCCESS;
  payload: ITransactionReportResponse | Transactions;
}

export interface IFetchTransactionReportRejectedAction {
  type: typeof ActionTypes.FETCH_TRANSACTION_REPORT_FAILURE;
  payload: string;
}

export type TransactionReportActionTypes = ITransactionReportPendingAction | ITransactionReportSuccessAction | IFetchTransactionReportRejectedAction;
