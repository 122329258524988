export const interests = {
  name: 'Nom',
  actions: 'Actions',
  interests_title: 'Title',
  interest_category_name: 'Category Name',
  confirm_delete_title: 'Confirm deletion',
  confirm_delete_message: 'Please make sure when deleting a category, any related interests are also deleted',
  title: 'Categories',
  add: 'CREATE A CATEGORY',
  no_data: 'No categories found',
  delete_success_message: 'Category was successfully deleted',
  update_success_message: 'Category has been successfully changed',
  update_button_label: 'Update',
  create_button_label: 'Create',
  view_category_title: 'View category details',
  update_category_title: 'Edit a category',
  add_category_title: 'Create a category',
  view_interest_title: 'View Interests Details',
  update_interest_title: 'Edit Interests',
  add_interest_title: 'Create a interest',
  update_interest_success_message: 'Interest has been successfully changed',
  delete_interest_success_message: 'Interest was successfully deleted',
  confirm_interest_delete_message: 'Are you sure you want to remove this interest?',
  interest_no_data: 'No interests found',
  interest_title: 'Interests',
  interest_add: 'CREATE A INTEREST',
  create_success_message: 'Category has been successfully created',
  create_interest_success_message: 'Interest has been successfully created',
  interstile_delete_message: 'Are you sure you want to remove this Interstitials?',
  category_exsist: 'Category already exist',
  listoflangauges: 'List of languages',
  interest_already_exists: 'Interest title already exists',
  form_fields: {
    title: {
      label: 'Title*',
      placeholder: 'Title',
      required_error_message: 'Title field is required',
      max_length_error_message: 'Title cannot be longer than 60 characters',
    },
    category_id: {
      label: 'Category*',
      placeholder: 'Category',
      required_error_message: 'Category field is required',
    },
    language: {
      label: 'Language*',
      placeholder: 'Language',
      required_error_message: 'All Languages are required',
    },
  },
  artist: {
    delete_success_message: 'Artist was successfully deleted',
  },
  art: {
    delete_success_message: `Art has been successfully deleted`,
  },
};
