import React, { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch, useSelector } from 'react-redux';
import FlagsList from '../../../components/flags/flagsList';
import GlobalContext from '../../../context/globalContext';
import { PaginationActionTypes } from '../../../store/pagination/models';
import { BugsActionTypes } from '../../../store/bugs/models';
import { getAllFlags } from '../../../store/flags/action';
import { RootState } from '../../../store/rootStore';

/**
 * Component Flags
 *
 * @component
 *
 * @example
 * return (
 *   <Flags />
 * )
 */

const Flags = () => {
  const gContext = useContext(GlobalContext);
  const paginationState = useSelector((state: RootState) => state.pagination);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, BugsActionTypes | PaginationActionTypes>>();
  const { pageNumber, perPage } = paginationState;

  useEffect(() => {
    // if (gContext?.bugPageClicked) {
    dispatch(getAllFlags(pageNumber + 1, perPage, '', ''));
    // }
    gContext?.handleChangeClick();
  }, [gContext?.bugPageClicked]);

  return (
    <Grid container alignItems="center" justifyContent="center" className="listing_page_container">
      <FlagsList />
    </Grid>
  );
};

export default Flags;
