import { AuthActionTypes, AuthState } from './models';
import { FORGOTPASSWORD_ERROR, FORGOTPASSWORD_ATTEMPT, FORGOTPASSWORD_SUCCESS } from './types';

const INITIAL_STATE: AuthState = {
  forgotPassword: {
    loader: false,
    error: '',
  },
};
export const forgotPassReducer = (state = INITIAL_STATE, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case FORGOTPASSWORD_ATTEMPT:
      return { ...state, forgotPassword: { ...state.forgotPassword, loader: true, error: '' } };
    case FORGOTPASSWORD_SUCCESS:
      return { ...state, forgotPassword: { ...state.forgotPassword, loader: false } };
    case FORGOTPASSWORD_ERROR:
      return { ...state, forgotPassword: { ...state.forgotPassword, loader: false } };
    default:
      return state;
  }
};
