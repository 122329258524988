export const subscriptionPlan = {
  listTitle: 'Titre',
  description: 'Description',
  price: 'Prix',
  actions: 'Actions',
  subscriptionPlan_no_data: `Aucun plan d'abonnement trouvé`,
  title: `Plan d'abonnement`,
  add: `CRÉER UN PLAN D'ABONNEMENT`,
  confirm_delete_title: 'Confirmer la suppression',
  confirm_delete_message: `Etes-vous sûr de vouloir supprimer ce plan d'abonnement ?`,
  delete_success_message: `Le plan d'abonnement a été supprimé avec succès`,
  view_subscription_plan_title: `Afficher les détails du plan d'abonnement`,
  create_success_message: `Le plan d'abonnement a été créé avec succès`,
  update_success_message: `Le plan d'abonnement a été mis à jour avec succès`,
  add_subscription_plan_title: `Créer un plan d'abonnement`,
  update_subscription_plan_title: `Modifier un plan d'abonnement`,
  title_already_exist: 'Le nom du titre existe déjà',
  form_fields: {
    title: {
      label: 'Titre*',
      placeholder: 'Titre',
      max_length_error_message: 'Le titre ne peut pas comporter plus de 50 caractères',
      required_error_message: 'Le champ titre est requis',
    },
    price: {
      label: 'Prix*',
      placeholder: 'Prix',
      required_error_message: 'Le champ Prix est requis',
    },
    description: {
      label: 'Description*',
      placeholder: 'Description',
      required_error_message: 'Le champ de description est requis',
    },
  },
};
