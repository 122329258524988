/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SubscriptionPlanFormProps } from './SubscriptionPlan-form.types';
import { SubscriptionPlan } from '../../../../../models/SubscriptionPlan';
import { CreateSubscriptionPLanSchema } from './craete-plan-schema';
import { theme } from '../../../../../theme/theme';

export default function ViewSubscriptionPlan({ currentSubscriptionPlan, isUpdate }: SubscriptionPlanFormProps) {
  const { t } = useTranslation();
  const customStyle = {
    marginBottom: '0.5rem',
    fontFamily: 'SFPro-Regular',
    color: theme.palette.secondary.dark,
  };
  const defaultValues = useMemo<SubscriptionPlan>(
    () => ({
      plan_name: currentSubscriptionPlan?.plan_name || '',
      plan_description: currentSubscriptionPlan?.plan_description || '',
      ServicePlans: currentSubscriptionPlan?.ServicePlans || [],
    }),
    [currentSubscriptionPlan],
  );

  const methods = useForm<SubscriptionPlan>({
    resolver: yupResolver(CreateSubscriptionPLanSchema) as Resolver<SubscriptionPlan>, // Cast resolver to Resolver<PendingAds>
    defaultValues,
    mode: 'all',
  });

  const { reset, watch } = methods;
  const values = watch();

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentSubscriptionPlan]);

  useEffect(() => {
    // Update defaultValues whenever currentSubscriptionPlan or isUpdate changes
    const updatedDefaultValues = {
      plan_name: currentSubscriptionPlan?.plan_name || '',
      plan_description: currentSubscriptionPlan?.plan_description || '',
      ServicePlans: currentSubscriptionPlan?.ServicePlans || [],
    };
    methods.reset(updatedDefaultValues);
  }, [currentSubscriptionPlan, isUpdate]);

  return (
    <>
      <Grid className="artgapi-modal" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box sx={{ p: 3 }}>
            <Box sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <div className="col">
                  <Typography>{t('adds.form_fields.planname.placeholder')}</Typography>
                  <label style={customStyle}>{values?.plan_name}</label>
                </div>
                <div className="col">
                  <Typography>{t('adds.form_fields.description.placeholder')}</Typography>
                  <label style={customStyle}>{values?.plan_description}</label>
                </div>
              </Box>
            </Box>

            {values?.ServicePlans?.map((item: any, index: number) => {
              return (
                <>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                      {item.name}
                    </Typography>
                    <Box
                      sx={{
                        display: 'grid',
                        columnGap: 2,
                        rowGap: 3,
                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                      }}
                    >
                      <div className="col">
                        <Typography>{t('adds.form_fields.planPrice.placeholder')}</Typography>
                        <label style={customStyle}>{item?.price}</label>
                      </div>
                      <div className="col">
                        <Typography>{t('adds.form_fields.planDescription.placeholder')}</Typography>
                        <label style={customStyle}>{item?.description}</label>
                      </div>
                    </Box>
                  </Box>
                </>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
