/* eslint-disable @typescript-eslint/no-explicit-any*/
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { theme } from '../../../theme/theme';
import { ButtonBase, IconButton } from '@mui/material';
import CloseIcon from '../../svgComponents/closeIcon';
import { useTranslation } from 'react-i18next';
interface SaveChangesModalProps {
  openSaveChangesModal: boolean;
  setOpenSaveChangesModal: any;
  cancelNavigation: any;
  saveChangesHandler: any;
  confirmNavigation?: any;
}
export const SaveChangesModal = (props: SaveChangesModalProps) => {
  const { openSaveChangesModal, setOpenSaveChangesModal, cancelNavigation, saveChangesHandler } = props;
  const { t } = useTranslation();
  const closeModal = () => {
    setOpenSaveChangesModal(false);
  };

  return (
    <Dialog
      open={openSaveChangesModal}
      onClose={closeModal}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '500px',
            maxWidth: '500px',
            borderRadius: 0,
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          width: '500px',
          color: theme.palette.primary.main,
          fontFamily: 'SFPro-Regular',
          backgroundColor: theme.palette.secondary.main,
          textAlign: 'center',
        }}
      >
        <IconButton sx={{ position: 'absolute', right: 5, top: 5 }} onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogTitle
        sx={{
          width: '500px',
          fontSize: '24px',
          color: theme.palette.primary.main,
          fontFamily: 'SFPro-Regular',
          backgroundColor: theme.palette.secondary.main,
          textAlign: 'center',
        }}
      >
        ATTENTION
      </DialogTitle>

      <DialogTitle
        sx={{
          width: '500px',
          fontFamily: 'SFPro-Regular',
          backgroundColor: theme.palette.secondary.main,
          fontSize: '14px',
          textAlign: 'center',
          color: ' #A4A4A5',
          alignSelf: 'center',
          justifySelf: 'center',
          paddingTop: 0,
        }}
      >
        {'Souhaitez-vous enregistrer vos modifications avant de quitter ?'}
      </DialogTitle>
      <DialogActions sx={{ width: '100%', height: '100px ' }}>
        <ButtonBase
          sx={{
            color: '#a4a4a5',
            height: '42px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'SFPro-Regular',
            fontSize: '17px',
            padding: '16px 24px',
          }}
          onClick={cancelNavigation}
        >
          {t('articles.do_not_save')}
        </ButtonBase>
        <ButtonBase
          sx={{
            marginRight: '32px ',
            height: '52px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#ffcc33',
            fontFamily: 'SFPro-Regular',
            fontSize: '17px',
            padding: '16px 24px',
          }}
          onClick={saveChangesHandler}
        >
          {t('articles.save_article')}
        </ButtonBase>
      </DialogActions>
    </Dialog>
  );
};
export default SaveChangesModal;
