export const INITIALIZE_BUGS_ATTEMPT = 'INITIALIZE_BUGS_ATTEMPT';
export const INITIALIZE_BUGS_SUCCESS = 'INITIALIZE_BUGS_SUCCESS';
export const INITIALIZE_BUGS_ERROR = 'INITIALIZE_BUGS_ERROR ';
export const INCREMENT_BUGS = 'INCREMENT_BUGS';
export const DECREMENT_BUGS = 'DECREMENT_BUGS';
export const INITIALIZE_REPORTINGS_ATTEMPT = 'INITIALIZE_REPORTINGS_ATTEMPT';
export const INITIALIZE_REPORTINGS_SUCCESS = 'INITIALIZE_REPORTINGS_SUCCESS';
export const INITIALIZE_REPORTINGS_ERROR = 'INITIALIZE_REPORTINGS_ERROR';
export const INCREMENT_REPORTINGS = 'INCREMENT_REPORTINGS';
export const DECREMENT_REPORTINGS = 'DECREMENT_REPORTINGS';
export const INITIALIZE_APPLICANTS_ATTEMPT = 'INITIALIZE_APPLICANTS_ATTEMPT';
export const INITIALIZE_APPLICANTS_SUCCESS = 'INITIALIZE_APPLICANTS_SUCCESS';
export const INITIALIZE_APPLICANTS_ERROR = 'INITIALIZE_APPLICANTS_ERROR';
export const INCREMENT_NUMBER_OF_APPLICANTS = 'INCREMENT_NUMBER_OF_APPLICANTS';
export const DECREMENT_NUMBER_OF_APPLICANTS = 'DECREMENT_NUMBER_OF_APPLICANTS';
