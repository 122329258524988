export const profile = {
  email: 'Email',
  title: 'Profiles',
  no_profiles_title: 'No user selected',
  no_profiles_description: 'Please select a user to view their profile list',
  mainProfile: {
    surname: {
      require_error_message: `Surname is required`,
      invalid_error_message: 'Surname is invalid',
    },
    name: {
      require_error_message: `The name is required`,
      invalid_error_message: 'The name is invalid',
    },
    mail: {
      require_error_message: `The email address is required`,
      invalid_error_message: `Email address is invalid`,
    },
    zip: {
      invalid_error_message: `The postal code is invalid`,
      require_error_message: 'Postal code is required',
      min_require_error_message: 'The postal code must contain at least 5 characters',
    },
    city: {
      invalid_error_message: 'The city is invalid',
      require_error_message: 'The city is required',
    },
    address: 'Address is required',
    countryId: 'Country is required',
    birthdate: {
      max_error_message: 'Date of birth cannot be in the future',
      require_error_message: 'Date of birth is required',
    },
  },
};
