import { PaginationActionTypes, PaginationState } from './models';
import {
  GET_CRITERIA,
  GET_CATEGORY,
  GET_PAGE_NUMBER,
  GET_PER_PAGE,
  GET_STATUS,
  GET_STATUS_FLAG,
  GET_TOTAL_PAGES,
  GET_TYPE,
  GET_TYPE_FLAG,
  GET_OPPORTUNITY_TYPE,
} from './types';
import produce from 'immer';

const INITIAL_STATE: PaginationState = {
  type: 0,
  total: 0,
  pageNumber: 0,
  perPage: 25,
  criteria: '',
  status: '',
  category: '',
  statusFlag: '',
  typeFlag: '',
  opportunityType: '',
};
export const paginationReducer = (state = INITIAL_STATE, action: PaginationActionTypes): PaginationState =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_TYPE:
        draft.type = action.companyType;
        break;
      case GET_TOTAL_PAGES:
        draft.total = action.total;
        break;
      case GET_PAGE_NUMBER:
        draft.pageNumber = action.pageNumber;
        break;
      case GET_PER_PAGE:
        draft.perPage = action.perPage;
        break;
      case GET_CRITERIA:
        draft.criteria = action.criteria;
        break;
      case GET_STATUS:
        draft.status = action.status;
        break;
      case GET_CATEGORY:
        draft.category = action.category;
        break;
      case GET_STATUS_FLAG:
        draft.statusFlag = action.statusFlag;
        break;
      case GET_TYPE_FLAG:
        draft.typeFlag = action.typeFlag;
        break;
      case GET_OPPORTUNITY_TYPE:
        draft.opportunityType = action.opportunityType;
        break;
      default:
        return state;
    }
  });
