import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import EmptyData from '../../../assets/images/emptyData.png';
type INoDataFoundComponentProps = {
  message?: string;
};

export const NoDataFoundComponent = ({ message }: INoDataFoundComponentProps) => (
  <Container
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 320px)',
      backgroundColor: '#f5f5f5',
      alignItems: 'center',
      paddingTop: 10,
    }}
  >
    <Box
      component="img"
      sx={{
        width: '15%',
        marginBottom: '15px',
      }}
      alt="emptyData."
      src={EmptyData}
    />
    <Typography
      variant="body2"
      sx={{
        width: '200px',
        fontFamily: 'SFPro-Regular',
        fontSize: '18px',
        textAlign: 'center',
      }}
    >
      {message}
    </Typography>
  </Container>
);

const defaultProps: INoDataFoundComponentProps = {
  message: 'Aucune donnée trouvée',
};

NoDataFoundComponent.defaultProps = defaultProps;
