import { createTheme } from '@mui/material/styles';
import { colors } from './sharedTheme';

export const tableHeaderTheme = createTheme({
  palette: {
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },

  typography: {
    allVariants: {
      display: 'flex',
      fontFamily: 'SFProText',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      color: colors.WHITE,
      marginLeft: '20px',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: 50,
          border: 'none',
          padding: '0px 16px',
          maxWidth: '200px',
          color: colors.MAIN_GREY,
          '&.select_style': {
            opacity: 1,
            '.MuiSelect-select': {
              opacity: 0.5,
              color: colors.MAIN_GREY,
            },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          opacity: 1,
          '.MuiInputBase-input': {
            paddingBottom: 0,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiPaper-rounded': {
            width: '170px',
            backgroundColor: '#26272c',
            borderRadius: '6px',
            marginLeft: '10px',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& .Mui-checked': {
            color: colors.BLACK,
          },
        },
      },
    },
  },
});
