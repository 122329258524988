import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#141415',
      light: '#26272c',
    },
    secondary: {
      main: '#f5f5f5',
    },
    success: {
      main: '#ffffff',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'SFPro-Regular',
          height: '14px',
          fontSize: '12px',
          color: '#a4a4a5',
          textAlign: 'left',
          marginBottom: '7px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: '343px',
        },
        input: {
          height: '48px',
          boxSizing: 'border-box',
          border: 'solid 1px #e7e7e7',
          backgroundColor: '#ffffff',
          paddingLeft: '13px',
          color: '#0d0d0d',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          backgroundColor: '#E7E7E7',
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: '#fefefe',
          },
        },
      },
    },
  },
});
