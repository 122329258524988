/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/react-in-jsx-scope */

import { Box, Container, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useDebounce } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import TableLoadingData from '../../components/table/TableLoadingData';
import TableNoData from '../../components/table/TableNoData';
import TableHeadCustom from '../../components/table/table-head/CustomTableHead';
import AddDialog from '../../features/systemuser/add-dialog/AddDialog';
import useDeleteSystemUserMutation from '../../features/systemuser/hooks/useDeleteSystemUserMutation';
import useSnackBar from '../../hooks/useSnackBar';
import useTable from '../../hooks/useTable';
import { SystemUser } from '../../models/SystemUser';
import { RootState } from '../../store/rootStore';
import { systemUserApi } from '../../store/systemuser/api/systemuser.api';
import { ISystemUserResponse, ISystemUserState, SystemUserActionTypes } from '../../store/systemuser/slice/systemuser-slice.types';
import { TABLE_COLUMNS_CONFIG, initialPaginationState } from './SystemUser.constant';
import { RootStyle } from './SystemUser.styles';
import SystemUserTableRow from '../../features/systemuser/systemuser-row/SystemUseRow';
import { ENDPOINTS, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { AxiosResponse } from 'axios';
import { TablePaginationActions } from '../../components/table/TablePaginationActions/TablePaginationActions';
import useUpdateSystemuserMutation from '../../features/systemuser/hooks/useUpdateSystemUserMutation';
import { ConfirmDeleteDialogStyle } from '../../utils/confirm-delete-dialog/confirm-delete-dialog.styles';
import ConfirmDeleteDialog from '../../utils/confirm-delete-dialog/ConfirmDeleteDialog';
import CommonToolbar from '../../utils/toolbar/CommonToolbar';

export default function SystemUsers() {
  // Hooks
  const [filterKeyword, setfilterKeyword] = useState('');
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, SystemUserActionTypes>>();
  const { data, isLoading, error } = useSelector<RootState, ISystemUserState>((state: RootState) => state[systemUserApi.reducerPath]);
  const [currentSystemUser, setCurrentUniversity] = useState<SystemUser | undefined>(undefined);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isEyeIconClicked, setIsEyeIconClicked] = useState(false);
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const [isEdit, setIsEdit] = useState(false);

  // Custom hooks
  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialPaginationState);
  useEffect(() => {
    dispatch(
      systemUserApi.endpoints.getSystemUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  }, [order, orderBy, page, rowsPerPage, debouncedSearchTerm, dispatch]);

  const { alertSuccess, alertError } = useSnackBar();

  const { deleteSystemuser } = useDeleteSystemUserMutation();
  const { updateSystemuser } = useUpdateSystemuserMutation();
  // Functions
  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
    setPage(0);
  };

  const onOpenAddDialog = () => {
    setCurrentUniversity(undefined);
    setAddDialogOpen(true);
    setIsEdit(false);
  };

  const onCloseDialog = () => {
    setAddDialogOpen(false);
    setCurrentUniversity(undefined);
    setIsEyeIconClicked(false);
    setIsEdit(false);
    setfilterKeyword('');
    dispatch(
      systemUserApi.endpoints.getSystemUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  };

  const onShowDetails = (id?: number, isShow?: boolean) => {
    setIsEdit(false);
    sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.SYSTEMUSERS}/${id}`, {}, true, {})
      .then((response: AxiosResponse) => {
        const responseBody = response.data.user;
        const { name, phoneNb, surname, countryId, address, nickname, mail, city, zip, UserPermissions } = responseBody;

        const userData: SystemUser = {
          id: id,
          name: name,
          surname: surname,
          phoneNb: phoneNb,
          mail: mail,
          nickname: nickname,
          address: address,
          city: city,
          zip: zip,
          countryId: countryId,
          UserPermissions: UserPermissions,
        };
        setIsEyeIconClicked(isShow ?? false);
        setCurrentUniversity(userData);
      })
      .catch((err) => {
        console.log('error', err);
      });
    setAddDialogOpen(true);
  };

  const onOPenEditDialog = (id?: number) => {
    setIsEdit(true);
    sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.SYSTEMUSERS}/${id}`, {}, true, {})
      .then((response: AxiosResponse) => {
        const responseBody = response.data.user;
        const { name, phoneNb, surname, countryId, address, nickname, mail, city, zip, UserPermissions } = responseBody;

        const userData: SystemUser = {
          id: id,
          name: name,
          surname: surname,
          phoneNb: phoneNb,
          mail: mail,
          nickname: nickname,
          address: address,
          city: city,
          zip: zip,
          countryId: countryId,
          UserPermissions: UserPermissions,
        };
        setCurrentUniversity(userData);
      })
      .catch((err) => {
        console.log('error', err);
      });
    setAddDialogOpen(true);
  };

  const onConfirmDelete = (id?: number) => {
    setSelectedId(id);
    setConfirmDeleteOpen(true);
  };

  const handleSwitchChange = async (id?: number, isActive?: boolean) => {
    try {
      if (id && isActive !== undefined) {
        // Check if isActive is defined
        await updateSystemuser(id, isActive);
        onCloseConfirmStatusDialog();
        dispatch(alertSuccess(t('systemuser.status_success_message')));
        dispatch(
          systemUserApi.endpoints.getSystemUsers({
            page,
            limit: rowsPerPage,
            orderBy,
            order,
            keyword: debouncedSearchTerm,
          }),
        );
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
    }
  };

  const onCloseConfirmDeleteDialog = () => {
    setSelectedId(undefined);
    setConfirmDeleteOpen(false);
    setIsEyeIconClicked(false);
    setfilterKeyword('');
  };

  const onCloseConfirmStatusDialog = () => {
    setSelectedId(undefined);
    setIsEyeIconClicked(false);
  };

  const onDeleteUniversity = async () => {
    try {
      if (selectedId) {
        await deleteSystemuser(selectedId);
        onCloseConfirmDeleteDialog();
        dispatch(alertSuccess(t('systemuser.delete_success_message')));
        if (data?.next === null && data?.total <= page * rowsPerPage) {
          setPage(page - 1);
        }
        dispatch(
          systemUserApi.endpoints.getSystemUsers({
            page,
            limit: rowsPerPage,
            orderBy,
            order,
            keyword: debouncedSearchTerm,
          }),
        );
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
    }
  };
  const isFullFilled = !isLoading && data && data?.users?.length > 0;

  return (
    <>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        onClose={onCloseConfirmDeleteDialog}
        onDelete={onDeleteUniversity}
        confirm_delete_title={t('systemuser.confirm_delete_title')}
        confirm_delete_message={t('systemuser.confirm_delete_message')}
      />
      <AddDialog
        open={addDialogOpen}
        onClose={onCloseDialog}
        currentSystemUser={currentSystemUser}
        isUpdate={isEdit}
        isEyeIconClicked={isEyeIconClicked}
      />
      <RootStyle>
        <CommonToolbar
          filterKeyword={filterKeyword}
          onFilterKeyword={(value: string) => onFilterKeyword(value)}
          onOpenAddDialog={onOpenAddDialog}
          title={t('systemuser.title')}
          message={t('systemuser.add')}
        />
        <Container style={{ marginTop: '30px' }} sx={{ minHeight: '70vh' }}>
          <div className="table-block">
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <TableHeadCustom order={order} orderBy={orderBy} columnsConfig={TABLE_COLUMNS_CONFIG} onSort={onSort} />
                <TableBody>
                  {isFullFilled &&
                    (data as ISystemUserResponse)?.users?.map((row: SystemUser) => {
                      return (
                        <SystemUserTableRow
                          key={row.id}
                          row={row}
                          selected={false}
                          onShowDetails={(id) => onShowDetails(id, true)}
                          columnsConfig={TABLE_COLUMNS_CONFIG}
                          onOPenEditDialog={onOPenEditDialog}
                          onConfirmDelete={onConfirmDelete}
                          handleSwitchChange={handleSwitchChange}
                        />
                      );
                    })}
                  <TableNoData isNotFound={!isLoading && data?.users?.length === 0} title={t('systemuser.no_data')} />
                  <TableLoadingData isLoading={isLoading} />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {isFullFilled && (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={data ? data.total : 0}
                rowsPerPage={rowsPerPage}
                page={data && page ? page - 1 : 0}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t('common.rowsperpage')}
                sx={{
                  '& .MuiInputBase-root': {
                    maxWidth: '60px',
                  },
                }}
              />
            </Box>
          )}
        </Container>
      </RootStyle>
    </>
  );
}
