/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState } from 'react';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { UserListItem } from '../user-list/user-list.types';

export default function useGetListUsersQuery(paginationParams: any) {
  const [data, setData] = useState<UserListItem[] | undefined>(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const { keyword, page, rowsPerPage } = paginationParams;

  useMemo(async () => {
    setLoading(true);
    try {
      const response = await sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.PROFILES}/all`, {}, true, {
        ...paginationParams,
      });

      setData(response.data.data);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [keyword, page, rowsPerPage]);

  return {
    data,
    error,
    loading,
  };
}
