export const common = {
  published: 'Publish',
  discard: 'Cancel',
  save_and_publish: 'Save & Publish',
  search: 'Search',
  actions: 'Actions',
  delete: 'Delete',
  cancel: 'Cancel',
  confirm: 'Confirm',
  user_reports: 'User Reports',
  revenue_reports: 'Revenue Reports',
  ads_users_report: 'Active Ads Reports',
  transaction_reports: 'Transaction Reports',
  classroom_reports: 'Classroom Reports',
  transaction_id: 'Transaction Id',
  plan_name: 'Plan Name',
  Logout: 'Logout',
  confirm_logout_title: 'Confirm Logout',
  confirm_logout_message: 'Are you sure you want to logout ?',
  filter: 'Select Category',
  masterClassAds: 'Select Advertisement',
  clear: 'Clear',
  no_image_found: 'No image found',
  arts_of_week: 'Arts of the week',
  artists_of_week: 'Artist of the week',
  no_data: 'No data found',
  only_5_boxes: 'You can select maxiumn 5 arts of the week only.',
  created_five_artists: 'Artists of the week added successfully',
  created_five_arts: 'Arts of the week added successfully',
  rowsperpage: 'Rows per page',
  export: 'Export',
  confirm_delete_artist_message: 'Are you sure you want to remove this Artist?',
  confirm_delete_art_message: 'Are you sure you want to remove this Art?',
  something_wrong_happend: 'Something went wrong',
  title: 'Title',
  price: 'Price',
  image: 'Image',
  loading: 'loading',
  status: 'Show Video',
  selectDuration: 'Select Duration',
  dashbaord: 'Dashboard',
  welcome_video: 'Welcome video',
  utilisateurs: 'Users',
  signalements: 'Reports',
  transactions: 'Transactions',
  campagnes: 'Campaigns',
  annonces: 'Announcements',
  interests: 'Interests',
  mastercclass: 'Master Class',
  portfolio: 'Portfolio',
  opportunities: 'Opportunities',
  articles: 'Articles',
  database: 'Database',
  bugs: 'Bugs',
  subadmin: 'Subadministrator',
  settings: 'Settings',
  profils: 'Profiles',
  adminstrator: 'Administrators',
  artists: 'Artists',
  artsweek: 'Arts of the week',
  artistweek: 'Artist of the week',
  adusers: 'Ad users',
  userReports: 'User Reports',
  incomeReports: 'Income Reports',
  adReports: 'Ad Reports',
  classReports: 'Class Reports',
  interstitials: 'Interstitials',
  sponserPost: 'Sponsored Posts',
  pendingAds: 'Pending Ads',
  approvedAds: 'Approved Ads',
  subscriptionPlan: 'Subscription Plan',
  howItWorks: 'How It Works',
  categories: 'Categories',
  advertisements: 'Advertisements',
  save: 'Save',
  password: 'Password',
  change_password: 'Change password',
  my_profile: 'My profile',
  current_password: 'Current password',
  new_password: 'New Password',
  confirm_password: 'Confirm new password',
  list_of_users: 'List of users by email',
  profile_of: 'Profiles of',
  force_update: 'Force update',
  app_version: 'App version',
  no_announcements: 'Number of announcements',
  yes: 'Yes',
  no: 'No',
  weekly_sales: 'Weekly sales',
  export_svg: 'Export SVG',
  export_png: 'Export PNG',
  export_csv: 'Export CSV',
  total_work_sale: 'Number of total works for sale',
  total_work_sold: 'Number of total works sold',
  number_of_subscriptions: 'Number of subscriptions',
  dim: 'Sun.',
  lun: 'Mon.',
  mar: 'Tue.',
  mer: 'Wed.',
  jeu: 'Thu.',
  ven: 'Fri.',
  sam: 'Sat.',
  start_date: 'Start date',
  end_date: 'End date',
  frst_last_name: 'FIRST AND LAST NAME',
  user_name: 'USER NAME',
  subscription: 'SUBSCRIPTION',
  name: 'Name',
  photo: 'Photo',
  type: 'Type',
  candidates: 'NB. CANDIDATES',
  platform: 'Platform',
  format: 'Format',
  Status: 'Status',
  begining: 'Beginning',
  end: 'END',
  create_new_campagin: 'Create a new companion',
  reason: 'Reason',
  user: 'User',
  date: 'Date',
  email: 'E-mail',
  all: 'All',
  open: 'Open',
  close: 'Close',
  category: 'CATEGORY',
  publish_date: 'PUBLISHING DATE',
  publication_date: 'Publication date',
  collab: 'Collaboration',
  expose: 'Exposure',
  master_transaction: 'Master class transactions',
  comment: 'Comment',
  publication: 'Publication',
  article: 'Article',
  reported: 'Reported',
  treaty: 'Treaty',
};
