export const adds = {
  approvedtitle: 'AJOUTS APPROUVÉS',
  pendingtitle: 'AJOUTS EN ATTENTE',
  subscriptiontitle: `Plan d'abonnement`,
  name: 'Nom',
  email: 'Email',
  plan_id: 'Id',
  adds_post_label: 'Les publicités poste',
  adds_banner_label: 'Les publicités Bannière',
  adds_popup_label: 'Popup de publicités',
  phone_number: 'Numéro de téléphone',
  status: 'Statut',
  adsTitle: 'Ads Title',
  acceptedby: 'Accepté par',
  date: 'Date',
  title: 'Titre des annonces',
  description: 'Description',
  image: 'Image',
  active: 'Active',
  rejected: 'Rejeté',
  pending: 'En attente',
  approved: 'Approuvé',
  expired: 'Expiré',
  add_pending_ads_title: 'Ajouter des annonces en attente',
  update_pending_ads_title: 'Mettre à jour les annonces en attente',
  view_pending_ads_title: 'Afficher les annonces en attente',
  actions: 'Actions',
  confirm_accept: 'Confirmer Approuver',
  confirm_reject: 'Confirmer le rejet',
  confirm_accept_message: 'Etes-vous sûr de vouloir approuver cette annonce ?',
  confirm_reject_message: 'Êtes-vous sûr de vouloir rejeter cette publicité ?',
  accept: 'Approuver',
  reject: 'Rejeter',
  no_data: 'Aucune annonce en attente trouvée',
  approved_no_data: 'Aucune annonce approuvée trouvée',
  successfull: `La demande d'annonces en attente a été acceptée avec succès`,
  reject_message: `La demande d'annonces en attente est rejetée`,
  update_button_label: 'Modifier les changements',
  create_button_label: 'Sauvegarder les modifications',
  personal_info: 'Informations personnelles',
  adds_info: 'Informations sur la publicité',
  adds_plan_info: 'Type de plan publicitaire',
  file_size_error: 'La taille du fichier ne doit pas dépasser 5 Mo.',
  update_success_message: `La demande d'annonces en attente a été modifiée avec succès`,
  view_approved_ads_title: 'Afficher les annonces approuvées',
  plan_update_success_message: `Le plan d'abonnement a été modifié avec succès`,
  view_plan_title: `Afficher les plans d'abonnement`,
  update_plan_title: `Mettre à jour les plans d'abonnement`,
  add_plan_title: `Créer des plans d'abonnement`,
  no_plans: `Aucun plan d'abonnement trouvé`,
  profileType: 'Type de profil',
  adsusertitle: `Ads Users`,
  adsusers_no_data: `Aucun utilisateur publicitaire n'a été trouvé`,
  users_ads_details: 'Détails des annonces des utilisateurs',
  user_details_no_data: 'Aucun détail sur les annonces utilisateur trouvé',
  revenue_no_data: 'Aucune donnée sur les revenus trouvée',
  nickname: 'Surnom',
  usersname_ads_details: 'détails les publicités',
  expire: `Date et heure d'expiration`,
  plantype: 'Type de plan',
  view_users_ads_title: 'Détails des annonces des utilisateurs',
  role: `Type d'utilisateur`,
  price: 'Prix total',
  adsReportstitle: 'Rapports sur les annonces',
  workstitle: 'Comment ça fonctionne',
  workstitle_no_data: 'Aucune donnée disponible',
  how_works_title: 'Comment ça marche détails',
  update_works_title: 'Mettre à jour le fonctionnement des listes',
  work_title: 'Titre',
  how_it_works_success_message: 'Son fonctionnement a été modifié avec succès',
  no_active_users: 'Aucun rapport sur les annonces actives trouvé',
  starttime: 'Date et heure de début',
  subscription_no_data: `Aucun plan d'abonnement trouvé`,
  userreports_no_data: 'Aucune donnée disponible',
  user_no_data: 'Aucun utilisateur trouvé',
  form_fields: {
    name: {
      label: 'Nom*',
      placeholder: 'Nom',
      required_error_message: 'Le champ Nom est requis',
    },
    mail: {
      label: 'Email*',
      placeholder: 'E-mail',
      required_error_message: `Le champ de l'adresse e-mail est requis`,
      invalid_error_message: 'Adresse e-mail invalide',
    },
    phoneNb: {
      label: 'Numéro de téléphone',
      placeholder: 'Numéro de téléphone',
      required_error_message: 'Le champ du numéro de téléphone est requis',
      invalid_error_message: 'Numéro de téléphone doit étre entre 7 et 16 caractères',
    },
    planType: {
      label: 'Type de plan',
      placeholder: 'Type de plan',
      required_error_message: 'Le champ Type de plan est requis',
    },
    adsTitle: {
      label: `Titre de l'annonce*`,
      placeholder: `Titre de l'annonce`,
      required_error_message: `Le champ Titre de l'annonce est requis`,
    },
    adsBanner: {
      label: 'Bannière publicitaire*',
      placeholder: 'Bannière publicitaire',
      required_error_message: `Le champ Bannière publicitaire est requis`,
    },
    adsPost: {
      label: `Publication d'annonces*`,
      placeholder: `Publication d'annonces`,
      required_error_message: `Le champ Publication d'annonces est requis`,
    },
    adsPopup: {
      label: ' Annonces pop-up*',
      placeholder: ' Annonces pop-up',
      required_error_message: 'Le champ  Annonces pop-up est requis',
    },
    description: {
      label: 'Description*',
      placeholder: 'Description',
      required_error_message: 'Le champ Description est requis',
    },
    websiteUrl: {
      label: `URL de site web*`,
      placeholder: `URL de site web`,
      required_error_message: 'Le champ URL de site web est requis',
      invalid_error_message: 'URL de site Web invalide',
    },
    roleIds: {
      label: `Public cible*`,
      placeholder: `Public cible`,
      required_error_message: 'Au moins un public cible est requis',
    },
    planname: {
      label: `Nom du régime*`,
      placeholder: `Nom du régime`,
      required_error_message: 'Le champ Nom du régime est requis',
    },
    planDescription: {
      label: `Description du plan*`,
      placeholder: `Description du plan`,
      required_error_message: 'Le champ Description du plan est requis',
    },
    planPrice: {
      label: 'Prix de plan*',
      placeholder: `Prix de plan`,
      required_error_message: 'Le champ Prix de plan est requis',
    },
    title: {
      label: 'Titre*',
      placeholder: `Titre`,
      required_error_message: 'Le champ Titre est requis',
    },
    imageOrVideoUrl: {
      label: 'Image/Vidéo*',
      placeholder: `Image/Vidéo`,
      required_error_message: 'The Image/Vidéo field is required',
    },
  },
};
