import { InitialPaginationParams } from '../../../api/common/common.types';
import { Column } from '../../../components/table/table-head/CustomTableHead.types';

export const USERS_TYPE_OPTIONS = ['all', 'students', 'teachers'];

export const UNIVERSITY_USERS_TABLE_HEAD: Column[] = [
  { id: 'name', label: 'university.users_list.first_name', align: 'left' },
  { id: 'surname', label: 'university.users_list.last_name', align: 'left' },
  { id: 'mail', label: 'university.users_list.email', align: 'left' },
  { id: 'actions', label: '', align: 'right' },
];

export const initialUniversityUsersPagination: InitialPaginationParams = {
  defaultCurrentPage: 1,
  defaultOrderBy: 'id',
  defaultOrder: 'asc',
  defaultRowsPerPage: 25,
};
