/* eslint-disable @typescript-eslint/no-explicit-any*/
import { AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import Company from '../../models/Company';
import { CONSTANTS, ENDPOINTS, formatDateToSend, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { PaginationActionTypes } from '../pagination/models';
import { GET_TOTAL_PAGES } from '../pagination/types';
import { PasswordDialogActionTypes } from '../Dialog/models';
import { HIDE_DIALOG } from '../Dialog/types';
import { RootState } from '../rootStore';
import { compagniesActionTypes, CompagniesPaginated } from './models';
import {
  CREATE_COMPANIES_ATTEMPT,
  CREATE_COMPANIES_ERROR,
  CREATE_COMPANIES_SUCCESS,
  DELETE_COMPANIES_ATTEMPT,
  DELETE_COMPANIES_ERROR,
  DELETE_COMPANIES_SUCCESS,
  GET_COMPANIES_ATTEMPT,
  GET_COMPANIES_ERROR,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIE_ATTEMPT,
  GET_COMPANIE_ERROR,
  GET_COMPANIE_SUCCESS,
  RESET_COMPANIES_ERROR,
  UPDATE_COMPANIE_ATTEMPT,
  UPDATE_COMPANIE_ERROR,
  UPDATE_COMPANIE_STATUS_ATTEMPT,
  UPDATE_COMPANIE_STATUS_ERROR,
  UPDATE_COMPANIE_STATUS_SUCCESS,
  UPDATE_COMPANIE_SUCCESS,
} from './types';
import { SHOW_SNACK_BAR } from '../snackBar/types';
import { SnackBarActionTypes } from '../snackBar/models';

export const clearErrors =
  () => async (dispatch: ThunkDispatch<RootState, undefined, compagniesActionTypes | PasswordDialogActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: RESET_COMPANIES_ERROR,
    });
  };

export const getCompagnies =
  (data: CompagniesPaginated) =>
  async (dispatch: ThunkDispatch<RootState, undefined, compagniesActionTypes | PaginationActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: GET_COMPANIES_ATTEMPT,
    });

    sendAsyncRequest(HttpMethod.Get, ENDPOINTS.COMPANIES, {}, true, { ...data })
      .then((resultat: AxiosResponse) => {
        const compagniesTable: Company[] = [];

        if (resultat.data.data && resultat.data.data.length > 0) {
          dispatch({
            type: GET_TOTAL_PAGES,
            total: resultat.data.total,
          });
          resultat.data.data.map((item: Company) => {
            const compagny: Company = { ...item };
            if (compagny) {
              compagny.title = item.title;
              compagny.format = item.format === 'image' ? 'Photo' : 'Vidéo';
              compagny.status = item.status === 'ended' ? 'Terminée' : 'En cours';
              compagny.platform = item.platform === 'ios' ? 'iOS' : item.platform === 'android' ? 'Android' : 'iOS & Android';
              compagny.startDate = item.startDate;
              compagny.endDate = item.endDate;
            }
            compagniesTable.push({ ...compagny });
          });
        }
        dispatch({
          type: GET_COMPANIES_SUCCESS,
          compagnies: [...compagniesTable],
        });
      })
      .catch((error) => {
        if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
          localStorage.clear();
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: CONSTANTS.SESSION_EXPIRED,
              severity: 'error',
              icon: 'ErrorICon',
              size: 'medium',
            },
          });
          dispatch({
            type: GET_COMPANIES_ERROR,
            error: error,
          });
        } else {
          dispatch({
            type: GET_COMPANIES_ERROR,
            error: error,
          });
        }
      });
  };
export const useCreateCompany = () => {
  const paginationState = useSelector((state: RootState) => state.pagination);
  const { type, pageNumber, perPage } = paginationState;
  const createCompany =
    (data: Company, file: File) =>
    async (dispatch: ThunkDispatch<RootState, undefined, compagniesActionTypes | PasswordDialogActionTypes | SnackBarActionTypes>) => {
      dispatch({
        type: CREATE_COMPANIES_ATTEMPT,
      });
      const fomData = new FormData();
      fomData.append('type', data.type);
      fomData.append('title', data.title);
      fomData.append('format', data.format);
      fomData.append('platform', data.platform);
      fomData.append('startDate', formatDateToSend(data.startDate));
      fomData.append('endDate', formatDateToSend(data.endDate));
      fomData.append('url', data.url);
      fomData.append(file.type.split('/')[0], file);
      sendAsyncRequest(HttpMethod.Post, ENDPOINTS.COMPANIES, fomData, true, null)
        .then((res: AxiosResponse) => {
          const compagny: Company = { ...res.data.data };
          if (res.data.data) {
            if (compagny) {
              compagny.type = res.data.data.type;
              compagny.title = res.data.data.title;
              compagny.format = res.data.data.format === 'image' ? 'Photo' : 'Vidéo';
              compagny.status = res.data.data.status === 'ended' ? 'Terminée' : 'En cours';
              compagny.platform = res.data.data.platform === 'ios' ? 'iOS' : res.data.data.platform === 'android' ? 'Android' : 'iOS & Android';
              compagny.startDate = res.data.data.startDate;
              compagny.endDate = res.data.data.endDate;
            }
          }
          dispatch({
            type: CREATE_COMPANIES_SUCCESS,
            campany: compagny,
          });
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: 'Campagne crée avec succès',
              severity: 'success',
              icon: 'CheckIcon',
              size: 'medium',
            },
          });
          dispatch(getCompagnies({ type: type, page: pageNumber + 1, limit: perPage }));
          dispatch({
            type: HIDE_DIALOG,
          });
        })

        .catch((error) => {
          if (error?.response?.data?.code == CONSTANTS.INVALID_TOKEN) {
            localStorage.clear();
            dispatch({
              type: SHOW_SNACK_BAR,
              payload: {
                message: CONSTANTS.SESSION_EXPIRED,
                severity: 'error',
                icon: 'ErrorICon',
                size: 'medium',
              },
            });
            dispatch({
              type: CREATE_COMPANIES_ERROR,
              error: error,
            });
          } else if (error?.response?.data?.message === 'File too large') {
            dispatch({
              type: SHOW_SNACK_BAR,
              payload: {
                message: 'Fichier trop large!',
                severity: 'error',
                icon: 'ErrorICon',
                size: 'small',
              },
            });
            dispatch({
              type: CREATE_COMPANIES_ERROR,
              error: error,
            });
          } else {
            dispatch({
              type: SHOW_SNACK_BAR,
              payload: {
                message: 'Échec de création de campagne',
                severity: 'error',
                icon: 'ErrorICon',
                size: 'medium',
              },
            });
            dispatch({
              type: CREATE_COMPANIES_ERROR,
              error: CONSTANTS.TIME_OUT_ERROR_MESSAGE,
            });
          }
        });
    };
  return {
    createCompany,
  };
};
export const useDeleteCompany = () => {
  const paginationState = useSelector((state: RootState) => state.pagination);
  const { type, pageNumber, perPage } = paginationState;
  const deleteCompany = (id: number) => async (dispatch: ThunkDispatch<RootState, undefined, compagniesActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: DELETE_COMPANIES_ATTEMPT,
    });
    sendAsyncRequest(HttpMethod.Delete, ENDPOINTS.COMPANIES + '/' + id, {}, true, null)
      .then((res: AxiosResponse) => {
        dispatch({
          type: DELETE_COMPANIES_SUCCESS,
          id: id,
        });
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: 'Campagne supprimée avec succès',
            severity: 'success',
            icon: 'CheckIcon',
            size: 'medium',
          },
        });
        dispatch(getCompagnies({ type: type, page: pageNumber + 1, limit: perPage }));
        return res;
      })
      .catch((error) => {
        if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
          localStorage.clear();
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: CONSTANTS.SESSION_EXPIRED,
              severity: 'error',
              icon: 'ErrorICon',
              size: 'medium',
            },
          });
          dispatch({
            type: DELETE_COMPANIES_ERROR,
            error: error,
          });
        } else {
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: 'Échec de suppression de campagne',
              severity: 'error',
              icon: 'ErrorICon',
              size: 'large',
            },
          });
          dispatch({
            type: DELETE_COMPANIES_ERROR,
            error: error,
          });
        }
      });
  };
  return {
    deleteCompany,
  };
};

export const getCampany = (id: number) => async (dispatch: ThunkDispatch<RootState, undefined, compagniesActionTypes | SnackBarActionTypes>) => {
  dispatch({
    type: GET_COMPANIE_ATTEMPT,
  });
  sendAsyncRequest(HttpMethod.Get, ENDPOINTS.COMPANIES + '/' + id, {}, true, null)
    .then((res: AxiosResponse) => {
      dispatch({
        type: GET_COMPANIE_SUCCESS,
        foundCampany: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
        localStorage.clear();
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: CONSTANTS.SESSION_EXPIRED,
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
        dispatch({
          type: GET_COMPANIE_ERROR,
          error: error,
        });
      } else {
        dispatch({
          type: GET_COMPANIE_ERROR,
          error: error,
        });
      }
    });
};
export const useUpdateCompany = () => {
  const paginationState = useSelector((state: RootState) => state.pagination);
  const { type, pageNumber, perPage } = paginationState;
  const updateCompany =
    (data: Company, file?: File | undefined) =>
    async (dispatch: ThunkDispatch<RootState, undefined, compagniesActionTypes | PasswordDialogActionTypes | SnackBarActionTypes>) => {
      dispatch({
        type: UPDATE_COMPANIE_ATTEMPT,
      });
      const fomData = new FormData();
      fomData.append('title', data.title);
      fomData.append('type', data.type);
      fomData.append('status', data.status);
      fomData.append('format', data.format);
      fomData.append('platform', data.platform);
      fomData.append('startDate', formatDateToSend(data.startDate));
      fomData.append('endDate', formatDateToSend(data.endDate));
      fomData.append('url', data.url);
      if (file) fomData.append(file.type.split('/')[0], file);
      sendAsyncRequest(HttpMethod.Patch, ENDPOINTS.COMPANIES + '/' + data.id, fomData, true, null)
        .then((res: AxiosResponse) => {
          const compagny: Company = { ...res.data.data };
          if (res.data.data) {
            if (compagny) {
              compagny.id = res.data.data.id;
              compagny.type = res.data.data.type;
              compagny.title = res.data.data.title;
              compagny.format = res.data.data.format === 'image' ? 'Photo' : 'Vidéo';
              compagny.status = res.data.data.status === 'ended' ? 'Terminée' : 'En cours';
              compagny.platform = res.data.data.platform === 'ios' ? 'iOS' : res.data.data.platform === 'android' ? 'Android' : 'iOS & Android';
              compagny.startDate = res.data.data.startDate;
              compagny.endDate = res.data.data.endDate;
            }
          }

          dispatch({
            type: UPDATE_COMPANIE_SUCCESS,
            campany: compagny,
          });
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: CONSTANTS.SUCCES_MAJ_CAMPAGNE,
              severity: 'success',
              icon: 'CheckIcon',
              size: 'large',
            },
          });
          dispatch(getCompagnies({ type: type, page: pageNumber + 1, limit: perPage }));
        })
        .catch((error) => {
          if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
            localStorage.clear();
            dispatch({
              type: SHOW_SNACK_BAR,
              payload: {
                message: CONSTANTS.SESSION_EXPIRED,
                severity: 'error',
                icon: 'ErrorICon',
                size: 'medium',
              },
            });
            dispatch({
              type: UPDATE_COMPANIE_ERROR,
              error: error,
            });
          } else if (error.response.data.message === 'File too large') {
            dispatch({
              type: SHOW_SNACK_BAR,
              payload: {
                message: 'Fichier trop large!',
                severity: 'error',
                icon: 'ErrorICon',
                size: 'small',
              },
            });
            dispatch({
              type: UPDATE_COMPANIE_ERROR,
              error: error,
            });
          } else {
            dispatch({
              type: SHOW_SNACK_BAR,
              payload: {
                message: CONSTANTS.ECHEC_MAJ_CAMPAGNE,
                severity: 'error',
                icon: 'ErrorICon',
                size: 'medium',
              },
            });
            dispatch({
              type: UPDATE_COMPANIE_ERROR,
              error: error,
            });
          }
        });
    };
  return {
    updateCompany,
  };
};
export const updateCompanyStatus =
  (id: number) => async (dispatch: ThunkDispatch<RootState, undefined, compagniesActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: UPDATE_COMPANIE_STATUS_ATTEMPT,
    });

    sendAsyncRequest(HttpMethod.Patch, ENDPOINTS.ACTIVE.replace('id', id.toString()), {}, true, null)
      .then((res: AxiosResponse) => {
        dispatch({
          type: UPDATE_COMPANIE_STATUS_SUCCESS,
          campany: res.data.data,
        });
      })
      .catch((error) => {
        if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
          localStorage.clear();
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: CONSTANTS.SESSION_EXPIRED,
              severity: 'error',
              icon: 'ErrorICon',
              size: 'medium',
            },
          });
          dispatch({
            type: UPDATE_COMPANIE_STATUS_ERROR,
            error: error,
          });
        } else {
          dispatch({
            type: UPDATE_COMPANIE_STATUS_ERROR,
            error: error,
          });
        }
      });
  };
