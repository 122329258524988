import { SnackBarActionTypes } from './models';
import { HIDE_SNACK_BAR, SHOW_SNACK_BAR } from './types';

export const hideSnackBar = (): SnackBarActionTypes => ({
  type: HIDE_SNACK_BAR,
});

export const showSnackBar = (
  message: string,
  severity: 'error' | 'warning' | 'info' | 'success',
  icon: string,
  size: 'small' | 'large' | 'medium',
): SnackBarActionTypes => ({
  type: SHOW_SNACK_BAR,
  payload: {
    message,
    severity,
    icon,
    size,
  },
});
