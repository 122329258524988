import React from 'react';

export const AddPhotoIcon = () => {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.7764 7.75L14.7764 24.25" stroke="#A4A4A5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.75 15.7368L6.53075 15.7368" stroke="#A4A4A5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default AddPhotoIcon;
