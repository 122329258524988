import { PasswordActionTypes, PasswordState } from './models';
import { PASSWORD_ATTEMPT, PASSWORD_ERROR, PASSWORD_SUCCESS } from './types';

const INITIAL_STATE: PasswordState = {
  loader: false,
  error: '',
};
export const passwordReducer = (state = INITIAL_STATE, action: PasswordActionTypes): PasswordState => {
  switch (action.type) {
    case PASSWORD_ATTEMPT:
      return { ...state, loader: true, error: '' };
    case PASSWORD_SUCCESS:
      return { ...state, loader: false };
    case PASSWORD_ERROR:
      return { ...state, loader: false, error: action.error };
    default:
      return state;
  }
};
