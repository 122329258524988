import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { CONSTANTS, ENDPOINTS, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { RootState } from '../rootStore';
import { SnackBarActionTypes } from '../snackBar/models';
import { SHOW_SNACK_BAR } from '../snackBar/types';
import { GET_LANGUAGES_ATTEMPT, GET_LANGUAGES_ERROR, GET_LANGUAGES_SUCCESS } from './types';
import { languageActionTypes } from './models';

export const getLanguages = () => async (dispatch: ThunkDispatch<RootState, undefined, languageActionTypes | SnackBarActionTypes>) => {
  dispatch({
    type: GET_LANGUAGES_ATTEMPT,
  });

  sendAsyncRequest(HttpMethod.Get, ENDPOINTS.LANGUAGES, {}, true, null)
    .then((res: AxiosResponse) => {
      dispatch({
        type: GET_LANGUAGES_SUCCESS,
        languages: res.data.languages,
      });
    })
    .catch((error) => {
      if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
        localStorage.clear();
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: CONSTANTS.SESSION_EXPIRED,
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
        dispatch({
          type: GET_LANGUAGES_ERROR,
          error: error,
        });
      } else {
        dispatch({
          type: GET_LANGUAGES_ERROR,
          error: error,
        });
      }
    });
};
