/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Avatar, Box, Dialog, DialogContent, Grid, IconButton, ThemeProvider, Typography } from '@mui/material';
import { dialogTheme } from '../../theme/dialogTheme';
import { theme } from '../../theme/theme';
import CloseIcon from '../svgComponents/closeIcon';
import { colors } from '../../theme/sharedTheme';
import { config } from '../../config/config';
import applicant_avatar from '../../assets/images/applicant_avatar.png';
interface CandidatDialogProps {
  show: boolean;
  data: any;
  closeCandidatModal: any;
}

export const CandidatInformation = (props: CandidatDialogProps) => {
  const { show, data, closeCandidatModal } = props;

  return (
    <>
      <ThemeProvider theme={dialogTheme}>
        <Dialog
          open={show}
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                height: '424px',
                maxHeight: 'calc(100vh - 40px)',
                width: '400px',
                minWidth: '400px',
                borderRadius: 10,
              },
            },
          }}
        >
          <Box
            sx={{
              width: '350px',
              color: theme.palette.primary.main,
              fontFamily: 'SFPro-Regular',
              backgroundColor: theme.palette.secondary.main,
              textAlign: 'center',
            }}
          >
            <IconButton sx={{ position: 'absolute', right: 5, top: 7 }} onClick={closeCandidatModal}>
              <CloseIcon />
            </IconButton>
          </Box>

          <DialogContent sx={{ backgroundColor: '#f5f5f5' }}>
            <Grid
              container
              flexDirection="column"
              rowGap="30px"
              alignItems="center"
              justifyContent="center"
              sx={{ justifyContent: 'center', marginTop: '15%' }}
            >
              <Avatar
                alt="Candidat"
                src={data?.user?.avatar.startsWith(config.BASE_URL_API) ? data?.user?.avatar : applicant_avatar}
                sx={{ width: 100, height: 100 }}
              />
              <Typography style={{ color: colors.yellow, fontFamily: 'SFPro-Regular', fontSize: '20px' }}>
                {data.user?.name} {data?.user?.surname}
              </Typography>
              <Typography style={{ fontFamily: 'SFPro-Regular', fontSize: '16px' }}> {data?.user?.mail}</Typography>
              <Typography style={{ fontFamily: 'SFPro-Regular', fontSize: '16px' }}>{data?.user?.phoneNb}</Typography>
            </Grid>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default CandidatInformation;
