export const opportunities = {
  create_Opportunity: 'Créer une opportunité',
  description_placeholder: "Description de l'opportunité",
  collaboration: 'Collaboration',
  exposition: 'Exposition',
  opportunity_success_deleted: 'Opportunité supprimé avec succès',
  type: 'Type',
  delete_message: 'Voulez-vous vraiment supprimer cette opportunité ?',
  description: 'Description',
  creation_failure: "Échec de création d'une opportunité",
  update_failure: "Échec de modification d'une opportunité",
};
