import React from 'react';

export const SubadminIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2913 11.1943C12.7842 11.1943 14.8052 9.17335 14.8052 6.68039C14.8052 4.18744 12.7842 2.1665 10.2913 2.1665C7.79834 2.1665 5.7774 4.18744 5.7774 6.68039C5.7774 9.17335 7.79834 11.1943 10.2913 11.1943Z"
        fill="white"
      />
      <path
        d="M12.5793 20.9444C12.1089 20.1301 12.2028 19.1272 12.7626 18.4167C12.2028 17.7062 12.1089 16.7041 12.5793 15.8889L13.3096 14.625C13.7114 13.929 14.4607 13.4965 15.2651 13.4965C15.3752 13.4965 15.4835 13.5047 15.591 13.52C15.6208 13.446 15.6587 13.3765 15.6957 13.306C15.2082 13.1137 14.6819 13 14.1285 13H6.45485C4.09048 13 2.16666 14.9238 2.16666 17.2882V20.4479C2.16666 20.8217 2.46999 21.125 2.84374 21.125H12.6831L12.5793 20.9444Z"
        fill="white"
      />
      <path
        d="M21.7595 18.8396C21.7776 18.6997 21.802 18.5607 21.802 18.4162C21.802 18.2709 21.7767 18.1328 21.7595 17.9928L22.5729 17.3771C22.7544 17.2399 22.805 16.9889 22.6912 16.7912L21.9609 15.5273C21.8471 15.3305 21.6043 15.2484 21.3948 15.3369L20.4586 15.7314C20.2302 15.5571 19.9874 15.4037 19.7193 15.2917L19.5947 14.2978C19.5658 14.0721 19.3744 13.9023 19.1469 13.9023H17.6871C17.4596 13.9023 17.2673 14.0721 17.2393 14.2978L17.1147 15.2917C16.8466 15.4037 16.6029 15.558 16.3754 15.7314L15.4392 15.3369C15.2297 15.2484 14.9869 15.3296 14.8731 15.5273L14.1428 16.7912C14.0291 16.988 14.0796 17.239 14.2611 17.3771L15.0745 17.9928C15.0564 18.1328 15.032 18.2718 15.032 18.4162C15.032 18.5616 15.0573 18.6997 15.0745 18.8396L14.2611 19.4553C14.0796 19.5926 14.0291 19.8435 14.1428 20.0412L14.8731 21.306C14.9869 21.5028 15.2297 21.585 15.4392 21.4965L16.3754 21.1011C16.6038 21.2753 16.8466 21.4288 17.1147 21.5407L17.2393 22.5347C17.2673 22.7604 17.4587 22.9301 17.6862 22.9301H19.146C19.3735 22.9301 19.5658 22.7604 19.5938 22.5347L19.7184 21.5407C19.9865 21.4288 20.2302 21.2744 20.4577 21.1011L21.3939 21.4956C21.6034 21.5841 21.8462 21.5028 21.96 21.3051L22.6903 20.0403C22.8041 19.8435 22.7535 19.5926 22.572 19.4544L21.7595 18.8396ZM18.4166 20.2218C17.419 20.2218 16.611 19.4138 16.611 18.4162C16.611 17.4187 17.419 16.6107 18.4166 16.6107C19.4141 16.6107 20.2221 17.4187 20.2221 18.4162C20.2221 19.4138 19.4141 20.2218 18.4166 20.2218Z"
        fill="white"
      />
    </svg>
  );
};

export default SubadminIcon;
