import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { CONSTANTS, ENDPOINTS, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { RootState } from '../rootStore';
import { SnackBarActionTypes } from '../snackBar/models';
import { SHOW_SNACK_BAR } from '../snackBar/types';
import { countriesActionTypes } from './models';
import { GET_COUNTRIES_ATTEMPT, GET_COUNTRIES_ERROR, GET_COUNTRIES_SUCCESS } from './types';

export const getCountries = () => async (dispatch: ThunkDispatch<RootState, undefined, countriesActionTypes | SnackBarActionTypes>) => {
  dispatch({
    type: GET_COUNTRIES_ATTEMPT,
  });

  sendAsyncRequest(HttpMethod.Get, ENDPOINTS.COUNTIRES, {}, true, null)
    .then((res: AxiosResponse) => {
      dispatch({
        type: GET_COUNTRIES_SUCCESS,
        countries: res.data.countries,
      });
    })
    .catch((error) => {
      if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
        localStorage.clear();
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: CONSTANTS.SESSION_EXPIRED,
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
        dispatch({
          type: GET_COUNTRIES_ERROR,
          error: error,
        });
      } else {
        dispatch({
          type: GET_COUNTRIES_ERROR,
          error: error,
        });
      }
    });
};
