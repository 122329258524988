/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { useTranslation } from 'react-i18next';
import { DATA } from '../../../../store/flags/models';
import { ensureJson } from '../../../../utils';
import FlagLinkPublicationDialog from './flagLinkPublicationDialog';
import FlagProjetDialog from './flagProjetDialog';
import FlagPublicationImageDialog from './flagPublicationImageDialog';
import FlagTextPublicationDialog from './flagTextPublicationDialog';
import FlagVideoPublicationDialog from './flagVideoPublicationDialog';

interface FlagPubicationDialogProps {
  flag: DATA;
}

// Publication Dialog
export const FlagPubicationDialog = (props: FlagPubicationDialogProps) => {
  const flag = props.flag;
  const { t } = useTranslation();

  const getCOntent = () => {
    if (flag?.flaggedPublication?.images) {
      return <FlagPublicationImageDialog flag={flag} />;
    } else if (flag?.flaggedPublication?.art?.images) {
      return <FlagProjetDialog flag={flag} />;
    } else if (flag?.flaggedPublication?.text && ensureJson(flag?.flaggedPublication?.text)?.url) {
      return <FlagLinkPublicationDialog flag={flag} />;
    } else if (flag?.flaggedPublication?.video) {
      return <FlagVideoPublicationDialog flag={flag} />;
    } else if (flag?.flaggedPublication?.text && ensureJson(flag?.flaggedPublication?.text)?.contentText) {
      return <FlagTextPublicationDialog flag={flag} />;
    } else if (!flag?.flaggedPublication?.images) {
      return <h2>{t('common.no_image_found')}</h2>;
    }
    return <></>;
  };
  return <>{getCOntent()}</>;
};

export default FlagPubicationDialog;
