/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { CONSTANTS, ENDPOINTS, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { getStatus } from '../pagination/actions';
import { PaginationActionTypes } from '../pagination/models';
import { GET_PAGE_NUMBER, GET_TOTAL_PAGES } from '../pagination/types';
import { RootState } from '../rootStore';
import { SnackBarActionTypes } from '../snackBar/models';
import { SHOW_SNACK_BAR } from '../snackBar/types';
import { BugsActionTypes, BugstatePaginated } from './models';
import { GETTING_BUG, GETTING_BUGS, GET_BUGS_ERROR, GET_BUGS_SUCCESS, GET_BUG_SUCCESS, PATCH_BUGS_STATUS } from './types';

/* ****** /bug_reports ****** */
export const getBugs =
  (page?: number, limit?: number, status?: string) =>
  async (dispatch: ThunkDispatch<RootState, undefined, BugsActionTypes | PaginationActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: GETTING_BUGS,
    });
    dispatch(getStatus(status || ''));

    await sendAsyncRequest(HttpMethod.Get, ENDPOINTS.BUGS, {}, true, {
      page: page,
      limit: limit,
      status: status,
    })
      .then((res: any) => {
        const data: BugstatePaginated[] = [];
        const bugsFromWs = res;
        if (bugsFromWs && bugsFromWs.data.data?.length > 0) {
          dispatch({ type: GET_TOTAL_PAGES, total: res.data.total });
          bugsFromWs.data.data.map((item: any) => {
            const bug = { ...item };
            if (item.user) {
              bug.mail = item.user.mail;
            }
            data.push({ ...bug });
          });
        }
        res = [...data];
        dispatch({
          type: GET_BUGS_SUCCESS,
          payload: res,
        });
      })
      .catch((error) => {
        if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
          localStorage.clear();
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: CONSTANTS.SESSION_EXPIRED,
              severity: 'error',
              icon: 'ErrorICon',
              size: 'medium',
            },
          });
          dispatch({
            type: GET_BUGS_ERROR,
            error: error,
          });
        } else {
          dispatch({
            type: GET_BUGS_ERROR,
            error: error,
          });
        }
      });
  };
/* ****** /bug_reports/{id} ****** */
export const getBugById = (id: string) => async (dispatch: ThunkDispatch<RootState, undefined, BugsActionTypes | SnackBarActionTypes>) => {
  dispatch({
    type: GETTING_BUG,
  });

  await sendAsyncRequest(HttpMethod.Get, ENDPOINTS.BUGS + '/' + id, {}, true, {})
    .then((res: any) => {
      dispatch({
        type: GET_BUG_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
        localStorage.clear();
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: CONSTANTS.SESSION_EXPIRED,
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
        dispatch({
          type: GET_BUGS_ERROR,
          error: error,
        });
      } else {
        dispatch({
          type: GET_BUGS_ERROR,
          error: error,
        });
      }
    });
};
/* ****** /bug_reports/{id}/status  ****** */
export const changestatusBug =
  (id: string, status?: string, statusTable?: string, pageNumber?: number, perPage?: number) =>
  async (dispatch: ThunkDispatch<RootState, undefined, BugsActionTypes | SnackBarActionTypes | PaginationActionTypes>, getState: () => RootState) => {
    dispatch({
      type: GETTING_BUG,
    });

    await sendAsyncRequest(HttpMethod.Patch, ENDPOINTS.BUGS + '/' + id + '/status', { status }, true, {})
      .then((resultat: AxiosResponse) => {
        dispatch({
          type: PATCH_BUGS_STATUS,
          payload: resultat.data,
        });
        if (pageNumber) {
          const isLength1 = getState().bug.bugs.length == 1;

          if (pageNumber != 0) {
            dispatch(getBugs(pageNumber - (isLength1 ? 1 : 0), perPage, getState().pagination.status));
            dispatch({ type: GET_PAGE_NUMBER, pageNumber: pageNumber - (isLength1 ? 2 : 1) });
          }

          if (pageNumber == 1 && isLength1) {
            dispatch(getBugs(pageNumber, perPage, getState().pagination.status));
            dispatch({ type: GET_PAGE_NUMBER, pageNumber: pageNumber - 1 });
          }
        }
      })
      .catch((error) => {
        if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
          localStorage.clear();
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: CONSTANTS.SESSION_EXPIRED,
              severity: 'error',
              icon: 'ErrorICon',
              size: 'medium',
            },
          });
        }
        console.log('error', error);
      });
  };
