/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import { ThunkDispatch } from 'redux-thunk';
import { CONSTANTS, ENDPOINTS, formatDate, getErrorMessage, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { initializeApplicants } from '../counters/actions';
import { PaginationActionTypes } from '../pagination/models';
import { GET_TOTAL_PAGES } from '../pagination/types';
import { RootState } from '../rootStore';
import { SnackBarActionTypes } from '../snackBar/models';
import { SHOW_SNACK_BAR } from '../snackBar/types';
import { OpportunitiesActionTypes } from './models';
import {
  CREATE_OPPORTUNITY_SUCCESS,
  DELETE_OPPORTUNITY,
  GETTING_OPPORTUNITIES,
  GET_OPPORTUNITIES_ERROR,
  GET_OPPORTUNITIES_SUCCESS,
  GET_OPPORTUNITY_SUCCESS,
} from './types';

export const getOpportunities =
  (page?: number, limit?: number, type?: number | string) =>
  async (dispatch: ThunkDispatch<RootState, undefined, OpportunitiesActionTypes | PaginationActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: GETTING_OPPORTUNITIES,
    });

    sendAsyncRequest(
      HttpMethod.Get,
      ENDPOINTS.OPPORTUNITIES,
      {},
      true,
      {
        page: page,
        limit: limit,
        type: type,
      },
      true,
      false,
    )
      .then((res: AxiosResponse) => {
        dispatch({ type: GET_TOTAL_PAGES, total: res.data.total });
        dispatch({
          type: GET_OPPORTUNITIES_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
          localStorage.clear();
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: CONSTANTS.SESSION_EXPIRED,
              severity: 'error',
              icon: 'ErrorICon',
              size: 'medium',
            },
          });
          dispatch({
            type: GET_OPPORTUNITIES_ERROR,
            error: error,
          });
        } else {
          dispatch({
            type: GET_OPPORTUNITIES_ERROR,
            error: error,
          });
        }
      });
  };

export const AddOpportunity =
  (data: any) => async (dispatch: ThunkDispatch<RootState, undefined, OpportunitiesActionTypes | PaginationActionTypes | SnackBarActionTypes>) => {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('author', data.author);
    formData.append('type', data.type);
    formData.append('image', data.file ? data.file : '');
    formData.append('authorImage', data.authorImage ? data.authorImage : '');
    formData.append('description', data.description);
    formData.append('startDate', formatDate(data.startDate));
    formData.append('endDate', formatDate(data.endDate));
    sendAsyncRequest(HttpMethod.Post, ENDPOINTS.OPPORTUNITIES, formData, true, {}, true, false)
      .then((res: AxiosResponse) => {
        const opportunity: any = { ...res.data.data };
        dispatch({
          type: CREATE_OPPORTUNITY_SUCCESS,
          opportunity: opportunity,
        });
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: 'Opportunité crée avec succès',
            severity: 'success',
            icon: 'CheckIcon',
            size: 'medium',
          },
        });
        dispatch(getOpportunities(1));
      })
      .catch((error) => {
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: getErrorMessage(t('opportunities.creation_failure')),
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
      });
  };

export const getOpportunityById =
  (id: string) => async (dispatch: ThunkDispatch<RootState, undefined, OpportunitiesActionTypes | SnackBarActionTypes>) => {
    await sendAsyncRequest(HttpMethod.Get, ENDPOINTS.OPPORTUNITIES + '/' + id, {}, true, {})
      .then((res: any) => {
        dispatch({
          type: GET_OPPORTUNITY_SUCCESS,
          payload: res.data?.opportunity,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: getErrorMessage(error),
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
      });
  };

export const UpdateOpportunity =
  (data: any) => async (dispatch: ThunkDispatch<RootState, undefined, OpportunitiesActionTypes | PaginationActionTypes | SnackBarActionTypes>) => {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('author', data.author);
    formData.append('type', data.type);
    formData.append('image', data.file ? data.file : '');
    formData.append('authorImage', data.authorImage ? data.authorImage : '');
    formData.append('description', data.description);
    formData.append('startDate', formatDate(data.startDate));
    formData.append('endDate', formatDate(data.endDate));
    sendAsyncRequest(HttpMethod.Patch, ENDPOINTS.OPPORTUNITIES + '/' + data.id, formData, true, {}, true, false)
      .then((res: AxiosResponse) => {
        const opportunity: any = { ...res.data.data };
        dispatch({
          type: CREATE_OPPORTUNITY_SUCCESS,
          opportunity: opportunity,
        });
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: 'Opportunité modifiée avec succès',
            severity: 'success',
            icon: 'CheckIcon',
            size: 'medium',
          },
        });
        dispatch(getOpportunities(1));
      })
      .catch((error) => {
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: getErrorMessage(t('opportunities.update_failure')),
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
      });
  };

export const deleteOpportunity =
  (id: number) => async (dispatch: ThunkDispatch<RootState, undefined, OpportunitiesActionTypes | PaginationActionTypes | SnackBarActionTypes>) => {
    await sendAsyncRequest(HttpMethod.Delete, ENDPOINTS.OPPORTUNITIES + '/' + id, {}, true, {})
      .then((resultat: AxiosResponse) => {
        dispatch(getOpportunities(0, 15));
        dispatch({
          type: DELETE_OPPORTUNITY,
          payload: resultat.data,
        });
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: t('opportunities.opportunity_success_deleted'),
            severity: 'success',
            icon: 'CheckIcon',
            size: 'medium',
          },
        });
        dispatch(initializeApplicants());
      })
      .catch((error) => {
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: getErrorMessage(error),
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
      });
  };
