/* eslint-disable react/react-in-jsx-scope */
import { Box, Stack } from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '../../../components/svgComponents/searchIcon';
import { InputStyle } from '../toolbar/UniversityListToolbar.styles';

// ----------------------------------------------------------------------

interface UserTableToolbarProps {
  keyWord: string;
  onChangeKeyWord: (value: string) => void;
}

export default function UniversityUsersToolbar({ keyWord, onChangeKeyWord }: UserTableToolbarProps) {
  const { t } = useTranslation();
  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
      <Box sx={{ flex: '1 1 auto' }} />
      <InputStyle
        value={keyWord}
        placeholder={t('common.search')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeKeyWord(e.target.value)}
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
      />
    </Stack>
  );
}
