import React, { useState } from 'react';
import { Button, TableRow, Stack, IconButton, Tooltip } from '@mui/material';
import { TableCellStyle } from './Advertisements.styles';
import { AdvertismentsRowProps } from './Advertisments.types';
import EyeIcon from '../../svgComponents/EyeIcon';
import Iconify from '../../Iconify';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/ic_trash.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/pencil-outline.svg';
import FullScreenModal from '../../adds/menubar/approved/form/FullScreenModal';
import AdminVideoControl from '../../adds/menubar/approved/form/VideoControl';

export default function AdvertisementsTableRow({
  row,
  selected,
  onShowDetails,
  columnsConfig,
  onOPenEditDialog,
  onConfirmDelete,
}: AdvertismentsRowProps) {
  const { id, type, videoOrImage } = row;
  const [openImgModel, setOpenImgModal] = useState(false);
  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);

  const handleClickOpenModal = () => {
    setOpenImgModal(true);
  };

  const handleClickCloseImgModal = () => {
    setOpenImgModal(false);
  };

  const openVideoPopup = () => {
    setVideoPopupOpen(true);
  };

  const closeVideoPopup = () => {
    setVideoPopupOpen(false);
  };

  const getVideoType = (url: string) => {
    const extension = url.split('.').pop()?.toLowerCase();
    return extension || 'mp4';
  };

  return (
    <>
      <TableRow key={id} hover selected={selected} sx={{ backgroundColor: '#ffffff' }}>
        {columnsConfig.map((column) => (
          <TableCellStyle
            key={column.id}
            align={column.align || 'left'}
            width={column.width}
            className={column.id === 'description' ? 'hide-text-overflow' : ''}
          >
            {column.id === 'videoOrImage' && Number(type) === 1 ? (
              <img
                src={videoOrImage}
                alt="post"
                style={{
                  width: '40px',
                  height: '40px',
                  objectFit: 'cover',
                  cursor: 'pointer',
                }}
                onClick={handleClickOpenModal}
              />
            ) : column.id === 'videoOrImage' && Number(type) === 2 ? (
              <video
                style={{
                  cursor: 'pointer',
                  width: '40px',
                  objectFit: 'cover',
                  height: '40px',
                }}
                src={videoOrImage}
                onClick={openVideoPopup}
              ></video>
            ) : column.id !== 'actions' ? (
              String(row[column.id as keyof typeof row])
            ) : (
              <Stack direction="row" spacing={2}>
                <Tooltip title="View Details" arrow>
                  <IconButton sx={{ padding: '0px !important' }} onClick={() => onShowDetails(id, true)}>
                    <EyeIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit" arrow>
                  <IconButton onClick={() => onOPenEditDialog(id)}>
                    <Iconify icon={EditIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete" arrow>
                  <IconButton onClick={() => onConfirmDelete(id)}>
                    <Iconify icon={DeleteIcon} />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}
          </TableCellStyle>
        ))}
      </TableRow>
      {isVideoPopupOpen && (
        <AdminVideoControl
          isVideoPopupOpen={isVideoPopupOpen}
          closeVideoPopup={closeVideoPopup}
          videourl={videoOrImage || ''}
          selectedVideo={getVideoType(videoOrImage || '')} // Pass the selected video type
        />
      )}
      {openImgModel && <FullScreenModal openImgModel={openImgModel} closeImgPopup={handleClickCloseImgModal} largeImgUrl={videoOrImage || ''} />}
    </>
  );
}
