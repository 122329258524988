export const portfolioPlans = {
  title: "Plan d'abonnement",
  add: "CRÉER UN PLAN D'ABONNEMENT",
  listTitle: 'Titre',
  description: 'Description',
  confirm_delete_title: 'Confirmer la suppression',
  confirm_delete_message: "Êtes-vous sûr de vouloir supprimer ce plan d'abonnement ?",
  no_data: "Aucun plan d'abonnement trouvé",
  delete_success_message: "Le plan d'abonnement a été supprimé avec succès",
  view_portfolio_title: "Voir les détails du plan d'abonnement",
  update_portfolio_title: "Modifier un plan d'abonnement",
  add_portfolio_title: "Créer un plan d'abonnement",
  create_success_message: "Le plan d'abonnement a été créé avec succès",
  update_success_message: "Le plan d'abonnement a été mis à jour avec succès",
  form_fields: {
    title: {
      label: 'Titre*',
      placeholder: 'Titre',
      max_length_error_message: 'Le titre ne peut pas dépasser 50 caractères',
      required_error_message: 'Le champ titre est requis',
    },
    description: {
      label: 'Description*',
      placeholder: 'Description',
      required_error_message: 'Le champ description est requis',
    },
    ispaid: {
      label: 'Payant*',
      placeholder: 'Payant',
      required_error_message: 'Le champ Payant est requis',
    },
    price: {
      label: 'Montant*',
      placeholder: 'Montant',
      required_error_message: 'Le champ Montant est requis',
    },
    duration: {
      label: 'Durée*',
      placeholder: 'Durée',
      required_error_message: 'Le champ Durée est requis',
    },
  },
};
