import { InitialPaginationParams } from '../../../../api/common/common.types';
import { Column } from '../../../table/table-head/CustomTableHead.types';

export const initialPaginationState: InitialPaginationParams = {
  defaultCurrentPage: 1,
  defaultOrderBy: 'id',
  defaultOrder: 'desc',
  defaultRowsPerPage: 25,
};

export const TABLE_COLUMNS_CONFIG: Column[] = [
  {
    id: 'name',
    label: 'adds.name',
    width: '20%',
  },
  {
    id: 'mail',
    label: 'adds.email',
    width: '20%',
  },
  {
    id: 'adsTitle',
    label: 'adds.adsTitle',
    width: '20%',
    isNotSortable: true,
  },
  {
    id: 'acceptedByRole',
    label: 'adds.acceptedby',
    width: '20%',
    isNotSortable: true,
  },
  {
    id: 'plan_type',
    label: 'adds.plantype',
    width: '20%',
  },
  {
    id: 'price',
    label: 'adds.form_fields.planPrice.placeholder',
    width: '20%',
  },
  {
    id: 'createdAt',
    label: 'adds.date',
    width: '20%',
    isNotSortable: true,
  },
  {
    id: 'status',
    label: 'adds.status',
    width: '20%',
    isNotSortable: true,
  },
  {
    id: 'actions',
    label: 'adds.actions',
    width: '10%',
    isNotSortable: true,
  },
];
