/* eslint-disable @typescript-eslint/no-explicit-any*/
import React, { ChangeEvent, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { theme } from '../../theme/theme';
import { useDispatch, useSelector } from 'react-redux';
import { useChangePassword } from '../../store/modifierMotDepasse/actions';
import { RootState } from '../../store/rootStore';
import Spinner from '../spinner';
import { hidePasswordDialog } from '../../store/Dialog/actions';
import { ThunkDispatch } from 'redux-thunk';
import { PasswordActionTypes } from '../../store/modifierMotDepasse/models';
import { PasswordDialogActionTypes } from '../../store/Dialog/models';
import { ButtonBase, Grid, IconButton, InputBase, InputLabel, Typography } from '@mui/material';
import CloseIcon from '../svgComponents/closeIcon';
import { isPassword } from '../../utils';
import { validations } from '../../locales/fr/validation';
import { useTranslation } from 'react-i18next';

interface InputProps<T> {
  value: T;
  error: string | null;
}

const ModifierPasswordDialog: React.FC = () => {
  const { t } = useTranslation();
  const passDialogState = useSelector((state: RootState) => state.passDialog);
  const passwordState = useSelector((state: RootState) => state.pass);
  const { loader, error } = passwordState;
  const { changePassword } = useChangePassword();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, PasswordActionTypes | PasswordDialogActionTypes>>();
  const [oldPassword, setOldPassword] = useState<InputProps<string>>({
    value: '',
    error: null,
  });
  const [password, setPassword] = useState<InputProps<string>>({
    value: '',
    error: null,
  });

  const [rePassword, setRePassword] = useState<InputProps<string>>({
    value: '',
    error: null,
  });
  const handleOnOldPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOldPassword({
      ...oldPassword,
      value: e.target.value,
      error: null,
    });
  };
  const handleOnPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword({
      ...password,
      value: e.target.value,
      error: null,
    });
  };
  const handleOnRePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRePassword({
      ...rePassword,
      value: e.target.value,
      error: null,
    });
  };
  useEffect(() => {
    setOldPassword({ value: '', error: '' });
    setPassword({ value: '', error: '' });
    setRePassword({ value: '', error: '' });
    return () => {};
  }, [passDialogState.open]);

  const handleClose = () => {
    dispatch(hidePasswordDialog());
    setOldPassword({ value: '', error: '' });
    setPassword({ value: '', error: '' });
    setRePassword({ value: '', error: '' });
  };
  const onSubmitChangePassword = () => {
    if (oldPassword.value === '') setOldPassword({ ...oldPassword, error: validations.required });
    if (oldPassword.value !== '' && error !== '') setOldPassword({ ...oldPassword, error: validations.actual_password });
    if (password.value !== '' && !isPassword(password.value))
      setPassword({
        ...password,
        error: validations.min_length,
      });
    if (password.value === '') setPassword({ ...password, error: validations.required });
    if (rePassword.value === '') setRePassword({ ...rePassword, error: validations.required });
    if (password.value !== '' && rePassword.value !== password.value) setRePassword({ ...rePassword, error: validations.match });

    if (oldPassword.value !== '' && password.value !== '' && isPassword(password.value) && rePassword.value === password.value) {
      dispatch(
        changePassword({
          oldPassword: oldPassword.value,
          newPassword: password.value,
          confirmPassword: rePassword.value,
        }),
      );
    }
  };

  return (
    <Dialog
      open={passDialogState.open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '500px',
            maxWidth: '500px',
            borderRadius: 0,
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          width: '500px',
          height: '50px',
          color: theme.palette.primary.main,
          fontFamily: 'SFPro-Regular',
          backgroundColor: theme.palette.secondary.main,
          textAlign: 'center',
        }}
      >
        <IconButton sx={{ position: 'absolute', right: 5, top: 5 }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogTitle
        sx={{
          width: '500px',
          height: '61px',
          fontSize: '24px',
          color: theme.palette.primary.main,
          fontFamily: 'SFPro-Regular',
          backgroundColor: theme.palette.secondary.main,
          textAlign: 'center',
          textTransform: 'uppercase',
        }}
      >
        {t('common.change_password')}
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: theme.palette.secondary.main }}>
        <form noValidate>
          <Grid item sx={{ width: '100%', marginTop: '24px ' }}>
            <Grid item sx={{ display: 'flex' }}>
              <InputLabel htmlFor="oldPass">{t('common.current_password')}</InputLabel>
              <span style={{ position: 'relative', right: -1, top: -5, color: 'red' }}>*</span>
            </Grid>
            <InputBase
              id="oldPass"
              sx={{ width: '100%' }}
              autoComplete="ancien-password"
              type="password"
              value={oldPassword.value}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnOldPasswordChange(e)}
            />
            <Typography
              variant="caption"
              sx={{
                width: '100%',
                height: '29px',
                fontFamily: 'SFPro-Regular',
                fontSize: '12px',
                color: '#ff453a',
                textAlign: 'left',
              }}
            >
              {oldPassword.error}
            </Typography>
          </Grid>

          <Grid item sx={{ width: '100%', marginTop: '24px ' }}>
            <Grid item sx={{ display: 'flex' }}>
              <InputLabel htmlFor="newPass">{t('common.new_password')}</InputLabel>
              <span style={{ position: 'relative', right: -1, top: -5, color: 'red' }}>*</span>
            </Grid>
            <InputBase
              id="newPass"
              sx={{ width: '100%' }}
              autoComplete="nouveau-password"
              type="password"
              value={password.value}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnPasswordChange(e)}
            />
            <Typography
              variant="caption"
              sx={{
                width: '100px',
                height: '29px',
                fontFamily: 'SFPro-Regular',
                fontSize: '12px',
                color: '#ff453a',
                textAlign: 'left',
              }}
            >
              {password.error}
            </Typography>
          </Grid>
          <Grid item sx={{ width: '100%', marginTop: '24px ' }}>
            <Grid item sx={{ display: 'flex' }}>
              <InputLabel htmlFor="confirmPass">{t('common.confirm_password')}</InputLabel>
              <span style={{ position: 'relative', right: -1, top: -5, color: 'red' }}>*</span>
            </Grid>
            <InputBase
              id="confirmPass  "
              sx={{ width: '100%' }}
              autoComplete="confirm-password"
              type="password"
              value={rePassword.value}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnRePasswordChange(e)}
            />
            <Typography
              variant="caption"
              sx={{
                width: '100%',
                height: '29px',
                fontFamily: 'SFPro-Regular',
                fontSize: '12px',
                color: '#ff453a',
                textAlign: 'left',
              }}
            >
              {rePassword.error}
            </Typography>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ width: '100%', height: '100px ' }}>
        <ButtonBase
          sx={{
            color: '#a4a4a5',
            height: '52px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'SFPro-Regular',
            fontSize: '17px',
            padding: '16px 24px',
          }}
          onClick={handleClose}
        >
          {t('common.cancel')}
        </ButtonBase>
        <ButtonBase
          sx={{
            marginRight: '32px ',
            height: '52px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#ffcc33',
            fontFamily: 'SFPro-Regular',
            fontSize: '17px',
            padding: '16px 24px',
          }}
          onClick={onSubmitChangePassword}
        >
          {t('common.save')}
        </ButtonBase>
      </DialogActions>
      <Spinner loading={loader} />
    </Dialog>
  );
};
export default ModifierPasswordDialog;
