import React, { ChangeEvent, useEffect, useState } from 'react';
import { RootStyle } from '../../../pages/systemusers/SystemUser.styles';
import { Box, Stack, Table, TableBody, TableContainer, TablePagination, Container, Button } from '@mui/material';
import SearchIcon from '../../svgComponents/searchIcon';
import TableHeadCustom from '../../table/table-head/CustomTableHead';
import { AdsUsersActionTypes, IAdsUsersResponse, IAdsUsersState } from '../../../store/adsUsers/slice/AdsUser-slice.types';
import AdsUsers from '../../../models/AdsUsers';
import AdsusersViewTableRow from './adsUsersView-row/AdsUsersViewTableRow';
import { TABLE_COLUMNS_CONFIG, initialPaginationState } from './AdsUsersView.constant';
import TableNoData from '../../table/TableNoData';
import TableLoadingData from '../../table/TableLoadingData';
import { TablePaginationActions } from '../../table/TablePaginationActions/TablePaginationActions';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '@uidotdev/usehooks';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../store/rootStore';
import { adsUserApi } from '../../../store/adsUsers/api/AdsUsers.api';
import useTable from '../../../hooks/useTable';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ApprovedAds } from '../../../models/ApprovedAds';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { AxiosResponse } from 'axios';
import AddDialog from '../../adds/menubar/approved/add-dialog/AddDialog';
import { ContainerStyle, CustomTitle, GridStyle, InputStyle } from '../../../utils/toolbar/CommonToolbar.styles';
import DateFilter from '../../../utils/DateFilter';

interface AdsUsersViewProps {
  selectedId: number;
  selectedName: string;
  onCloseDetails?: (isShow?: boolean) => void;
}

function AdsUsersView({ selectedId, selectedName, onCloseDetails }: AdsUsersViewProps) {
  const { t } = useTranslation();
  const [filterKeyword, setfilterKeyword] = useState('');
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AdsUsersActionTypes>>();
  const { users, isLoading } = useSelector<RootState, IAdsUsersState>((state: RootState) => state[adsUserApi.reducerPath]);
  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialPaginationState);
  const [isEyeIconClicked, setIsEyeIconClicked] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [currentApprovedAds, setCurrentApprovedAds] = useState<ApprovedAds | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const formattedStartDate = startDate ? formatDate(startDate) : '';
  const formattedEndDate = endDate ? formatDate(endDate) : '';

  useEffect(() => {
    dispatch(
      adsUserApi.endpoints.getParticularAdsUsers(
        {
          page,
          limit: rowsPerPage,
          orderBy,
          order,
          keyword: debouncedSearchTerm,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        },
        selectedId,
      ),
    );
  }, [order, orderBy, page, rowsPerPage, debouncedSearchTerm, dispatch, startDate, endDate]);

  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
    setPage(0);
  };

  const isFullFilled = !isLoading && users && users?.users?.length > 0;

  const handleClickBack = () => {
    if (onCloseDetails) {
      onCloseDetails(false);
    }
  };

  const onShowDetails = (id?: number, isShow?: boolean) => {
    sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.VIEW_PENDING_ADS}/${id}`, {}, true, {})
      .then((response: AxiosResponse) => {
        const responseBody = response.data.userPlan;
        const { name, planType, adsPost, price, plan_type, adsTitle, roleIds, websiteUrl, mail, description, adsBanner, adsPopup } = responseBody;
        const userData: ApprovedAds = {
          id: id,
          name: name,
          mail: mail,
          planType: planType,
          price: price,
          adsTitle: adsTitle,
          adsBanner: adsBanner,
          adsPost: adsPost,
          adsPopup: adsPopup,
          description: description,
          websiteUrl: websiteUrl,
          roleIds: roleIds,
          plan_type: plan_type,
        };
        setIsEyeIconClicked(isShow ?? false);
        setCurrentApprovedAds(userData);
      })
      .catch((err) => {
        console.log('error', err);
      });
    setAddDialogOpen(true);
  };

  const onCloseDialog = () => {
    setAddDialogOpen(false);
    setCurrentApprovedAds(undefined);
    setIsEyeIconClicked(false);
    setfilterKeyword('');
    dispatch(
      adsUserApi.endpoints.getParticularAdsUsers(
        {
          page,
          limit: rowsPerPage,
          orderBy,
          order,
          keyword: debouncedSearchTerm,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        },
        selectedId,
      ),
    );
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDate = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  return (
    <>
      <AddDialog
        open={addDialogOpen}
        onClose={onCloseDialog}
        currentApprovedAds={currentApprovedAds}
        isEyeIconClicked={isEyeIconClicked}
        isAdsUsers={true}
      />

      <RootStyle>
        <ContainerStyle style={{ padding: '0 24px' }}>
          <GridStyle>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ArrowBackIcon
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', marginRight: '7px', color: '#4182EB' }}
                onClick={handleClickBack}
              />
              <CustomTitle>
                <span style={{ fontWeight: 'bold' }}>{`${selectedName}'s`}</span>&nbsp;
                {t('adds.usersname_ads_details')}
              </CustomTitle>
            </div>
            <Stack direction="column" spacing={4}>
              <InputStyle
                value={filterKeyword}
                placeholder={t('common.search')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterKeyword(e.target.value)}
                InputProps={{
                  startAdornment: <SearchIcon />,
                }}
              />
            </Stack>
          </GridStyle>
        </ContainerStyle>
        <DateFilter
          startDate={startDate}
          endDate={endDate}
          handleClear={handleClear}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
        />
        <Container sx={{ minHeight: '70vh' }}>
          <div className="table-block">
            <TableContainer sx={{ minWidth: '800', position: 'relative' }}>
              <Table size={'medium'}>
                <TableHeadCustom order={order} orderBy={orderBy} columnsConfig={TABLE_COLUMNS_CONFIG} onSort={onSort} />
                <TableBody>
                  {isFullFilled &&
                    (users as IAdsUsersResponse)?.users?.map((row: AdsUsers) => {
                      return (
                        <AdsusersViewTableRow
                          key={row.id}
                          row={row}
                          selected={false}
                          columnsConfig={TABLE_COLUMNS_CONFIG}
                          onShowDetails={(id) => onShowDetails(id, true)}
                        />
                      );
                    })}
                  <TableNoData isNotFound={!isLoading && users?.users?.length === 0} title={t('adds.user_details_no_data')} />

                  <TableLoadingData isLoading={isLoading} />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {isFullFilled && (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={users ? users.total : 0}
                rowsPerPage={rowsPerPage}
                page={users && page ? page - 1 : 0}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t('common.rowsperpage')}
                sx={{
                  '& .MuiInputBase-root': {
                    maxWidth: '60px',
                  },
                }}
              />
            </Box>
          )}
        </Container>
      </RootStyle>
    </>
  );
}

export default AdsUsersView;
