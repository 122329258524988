/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { CONSTANTS, ENDPOINTS, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { PaginationActionTypes } from '../pagination/models';
import { GET_PAGE_NUMBER, GET_TOTAL_PAGES } from '../pagination/types';
import { RootState } from '../rootStore';
import { SnackBarActionTypes } from '../snackBar/models';
import { SHOW_SNACK_BAR } from '../snackBar/types';
import { DATA, flagsActionsTypes } from './models';
import { getStatusFlag, getTypeFlag } from '../pagination/actions';

import {
  DELETE_COMMENT,
  DELETE_PUBLICATION,
  DELETE_USER,
  GETTING_FLAG,
  GETTING_FLAGS,
  GET_FLAGS_ERROR,
  GET_FLAGS_SUCCESS,
  GET_FLAG_SUCCESS,
  PATCH_FLAGS_STATUS,
} from './types';

/**
 * ***** GET ALL PAGINATED FLAGS
 **/

export const getAllFlags =
  (page?: number, limit?: number, status?: string, type?: string, formattedStartDate?: string, formattedEndDate?: string) =>
  async (dispatch: ThunkDispatch<RootState, undefined, flagsActionsTypes | PaginationActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: GETTING_FLAGS,
    });
    dispatch(getStatusFlag(status || ''));
    dispatch(getTypeFlag(type || ''));
    await sendAsyncRequest(HttpMethod.Get, ENDPOINTS.FLAGS, {}, true, {
      page: page,
      limit: limit,
      status: status,
      filter: type,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    })
      .then((res: any) => {
        const data: DATA[] = [];
        const flagsFromWs = res;
        if (flagsFromWs && flagsFromWs.data.data?.length > 0) {
          dispatch({ type: GET_TOTAL_PAGES, total: res.data.total });
          flagsFromWs.data.data.map((item: any) => {
            const array = { ...item };
            if (item.flag) {
              array.flag = item.flag.name;
              array.description = item.flag.description;
            }
            if (item.user) {
              array.name = item.user.name;
              array.surname = item.user.surname;
              array.nickname = item.user.nickname;
            }
            data.push({ ...array });
          });
        }
        res = [...data];
        dispatch({
          type: GET_FLAGS_SUCCESS,
          payload: res,
        });
      })
      .catch((error) => {
        if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
          localStorage.clear();
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: CONSTANTS.SESSION_EXPIRED,
              severity: 'error',
              icon: 'ErrorICon',
              size: 'medium',
            },
          });
        } else {
          dispatch({
            type: GET_FLAGS_ERROR,
            error: error,
          });
        }
      });
  };

/**
 * ***** FLAGS/{id}
 **/

export const getFlagById =
  (id: string) => async (dispatch: ThunkDispatch<RootState, undefined, flagsActionsTypes | PaginationActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: GETTING_FLAG,
    });

    await sendAsyncRequest(HttpMethod.Get, ENDPOINTS.FLAGS + '/' + id, {}, true, {})
      .then((res: any) => {
        dispatch({
          type: GET_FLAG_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
          localStorage.clear();
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: CONSTANTS.SESSION_EXPIRED,
              severity: 'error',
              icon: 'ErrorICon',
              size: 'medium',
            },
          });
        } else {
          dispatch({
            type: GET_FLAGS_ERROR,
            error: error,
          });
        }
      });
  };

/* ****** /flags/{id}/status  ****** */
export const changestatusFlag =
  (id: string, pageNumber: number, perPage: number, status?: string, statusTable?: string, type?: string) =>
  async (
    dispatch: ThunkDispatch<RootState, undefined, flagsActionsTypes | PaginationActionTypes | SnackBarActionTypes>,
    getState: () => RootState,
  ) => {
    dispatch({
      type: GETTING_FLAG,
    });
    await sendAsyncRequest(HttpMethod.Patch, ENDPOINTS.FLAGS + '/' + id, { status }, true, {})
      .then((resultat: AxiosResponse) => {
        dispatch({
          type: PATCH_FLAGS_STATUS,
          bugs: resultat.data,
        });

        if (pageNumber && pageNumber != 0) {
          if (getState().flags.flages.length == 1) {
            dispatch(getAllFlags(pageNumber - 1, perPage, statusTable, type));
            dispatch({ type: GET_PAGE_NUMBER, pageNumber: pageNumber - 2 });
          } else {
            dispatch(getAllFlags(pageNumber, perPage, statusTable, type));
            dispatch({ type: GET_PAGE_NUMBER, pageNumber: pageNumber - 1 });
          }
        }
        if (pageNumber && pageNumber == 1 && getState().flags.flages.length == 1) {
          dispatch(getAllFlags(pageNumber, perPage, statusTable, type));
          dispatch({ type: GET_PAGE_NUMBER, pageNumber: pageNumber - 1 });
        }
        {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          status == 'treated' &&
            dispatch({
              type: SHOW_SNACK_BAR,
              payload: {
                message: 'Signalement traité avec succès',
                severity: 'success',
                icon: 'CheckIcon',
                size: 'medium',
              },
            });
        }
      })

      .catch((error) => {
        if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
          localStorage.clear();
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: CONSTANTS.SESSION_EXPIRED,
              severity: 'error',
              icon: 'ErrorICon',
              size: 'medium',
            },
          });
        } else {
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: 'Échec du traitement du signalement',
              severity: 'error',
              icon: 'ErrorICon',
              size: 'large',
            },
          });
        }
        console.log('error', error);
      });
  };

/**
 * *****  /publications/{id}
 **/
export const deletePublication = (id: string) => async (dispatch: ThunkDispatch<RootState, undefined, flagsActionsTypes | SnackBarActionTypes>) => {
  await sendAsyncRequest(HttpMethod.Delete, ENDPOINTS.PUBLICATIONS + id, {}, true, {})
    .then((resultat: AxiosResponse) => {
      dispatch(getAllFlags(0, 15));
      dispatch({
        type: DELETE_PUBLICATION,
        payload: resultat.data,
      });
    })
    .catch((error) => {
      if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
        localStorage.clear();
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: CONSTANTS.SESSION_EXPIRED,
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
      }
    });
};

/**
 * *****  /publications-comments/{id}
 **/
export const deleteCommentaire = (id: string) => async (dispatch: ThunkDispatch<RootState, undefined, flagsActionsTypes | SnackBarActionTypes>) => {
  await sendAsyncRequest(HttpMethod.Delete, ENDPOINTS.COMMENTS + id, {}, true, {})
    .then((resultat: AxiosResponse) => {
      dispatch(getAllFlags(0, 15));
      dispatch({
        type: DELETE_COMMENT,
        payload: resultat.data,
      });
    })
    .catch((error) => {
      if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
        localStorage.clear();
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: CONSTANTS.SESSION_EXPIRED,
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
      }
    });
};

/**
 * *****  /users/{id}
 **/
export const deleteuser = (id: string) => async (dispatch: ThunkDispatch<RootState, undefined, flagsActionsTypes | SnackBarActionTypes>) => {
  await sendAsyncRequest(HttpMethod.Delete, ENDPOINTS.USERSLIST + id, {}, true, {})
    .then((resultat: AxiosResponse) => {
      dispatch(getAllFlags(0, 15));
      dispatch({
        type: DELETE_USER,
        payload: resultat.data,
      });
    })
    .catch((error) => {
      if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
        localStorage.clear();
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: CONSTANTS.SESSION_EXPIRED,
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
      }
    });
};
