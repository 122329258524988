/* eslint-disable @typescript-eslint/no-explicit-any */
import { ImageList, ImageListItem } from '@mui/material';
import React from 'react';
import { DATA } from '../../../../store/flags/models';
interface FlagPublicationImageDialogProps {
  flag: DATA;
}

// Image Publication Dialog
export const FlagPublicationImageDialog = (props: FlagPublicationImageDialogProps) => {
  const flag = props.flag;
  return (
    <div>
      {flag.flaggedPublication.images.length == 1 ? (
        flag.flaggedPublication.images.map((image: any) => (
          <img
            loading="lazy"
            key={image.imageUrl}
            src={image.imageUrl && image.imageUrl}
            style={{ width: 376, height: 375, marginTop: '-5%', objectFit: 'contain' }}
          />
        ))
      ) : flag.flaggedPublication.images.length == 3 ? (
        <ImageList
          sx={{ width: 500, height: 300, alignContent: 'center', marginTop: '-4%', justifyContent: 'center', marginLeft: '20%' }}
          cols={5}
          rowHeight={164}
        >
          {flag.flaggedPublication.images.map((image: any) => (
            <ImageListItem key={image.imageUrl}>
              <img
                src={`${image.imageUrl}?w=180&h=180&fit=crop&auto=format`}
                srcSet={`${image.imageUrl}?w=180&h=180&fit=crop&auto=format&dpr=2 2x`}
                alt={'image'}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <ImageList sx={{ width: 500, height: 300, alignContent: 'center', marginTop: '-4%' }} cols={5} rowHeight={164}>
          {flag.flaggedPublication.images.map((image: any) => (
            <ImageListItem key={image.imageUrl}>
              <img
                src={`${image.imageUrl}?w=180&h=180&fit=crop&auto=format`}
                srcSet={`${image.imageUrl}?w=180&h=180&fit=crop&auto=format&dpr=2 2x`}
                alt={'image'}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </div>
  );
};

export default FlagPublicationImageDialog;
