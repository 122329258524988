import React, { useEffect, useState } from 'react';
import { Box, Container, Table, TableBody, TableContainer, TablePagination, Stack } from '@mui/material';
import { RootStyle } from '../../../../pages/systemusers/SystemUser.styles';
import TableHeadCustom from '../../../table/table-head/CustomTableHead';
import useTable from '../../../../hooks/useTable';
import { TABLE_COLUMNS_CONFIG, initialPaginationState } from './ApprovedLogs.constant';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../../store/rootStore';
import { IApprovedAdsResponse, IApprovedAdsState, ApprovedAdsActionTypes } from '../../../../store/approvedAds/slice/ApprovedAds-slice.types';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import SearchIcon from '../../../svgComponents/searchIcon';
import { TablePaginationActions } from '../../../table/TablePaginationActions/TablePaginationActions';
import ApprovedLogsTableRow from './approvedLogs-row/ApprovedLogsTableRow';
import TableNoData from '../../../table/TableNoData';
import TableLoadingData from '../../../table/TableLoadingData';
import { ApprovedAds } from '../../../../models/ApprovedAds';
import { approvedAdsApi } from '../../../../store/approvedAds/api/ApprovedAds.api';
import { CONSTANTS, ENDPOINTS, sendAsyncRequest } from '../../../../utils';
import { HttpMethod } from '../../../../utils/httpMethods';
import { AxiosResponse } from 'axios';
import AddDialog from './add-dialog/AddDialog';
import { ContainerStyle, CustomTitle, GridStyle, InputStyle } from '../../../../utils/toolbar/CommonToolbar.styles';
import DateFilter from '../../../../utils/DateFilter';

function ApprovedLogs() {
  const { t } = useTranslation();
  const [filterKeyword, setfilterKeyword] = useState('');
  const [isEyeIconClicked, setIsEyeIconClicked] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [currentApprovedAds, setCurrentApprovedAds] = useState<ApprovedAds | undefined>(undefined);
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, ApprovedAdsActionTypes>>();
  const { data, isLoading } = useSelector<RootState, IApprovedAdsState>((state: RootState) => state[approvedAdsApi.reducerPath]);
  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialPaginationState);
  const userId = localStorage.getItem(CONSTANTS.USER_ID) ?? '';
  const roles = localStorage.getItem(CONSTANTS.ROLE);
  const parsedRoles = roles ? JSON.parse(roles) : null;
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const formattedStartDate = startDate ? formatDate(startDate) : '';
  const formattedEndDate = endDate ? formatDate(endDate) : '';

  useEffect(() => {
    dispatch(
      approvedAdsApi.endpoints.getApprovedAdsUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
        userId: parseInt(userId),
        role: parsedRoles?.name,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }),
    );
  }, [order, orderBy, page, rowsPerPage, debouncedSearchTerm, dispatch, startDate, endDate]);

  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
    setPage(0);
  };

  const onShowDetails = (id?: number, isShow?: boolean) => {
    sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.VIEW_PENDING_ADS}/${id}`, {}, true, {})
      .then((response: AxiosResponse) => {
        const responseBody = response.data.userPlan;
        const { name, planType, plan_type, adsPost, adsTitle, price, roleIds, websiteUrl, mail, description, adsBanner, adsPopup } = responseBody;
        const userData: ApprovedAds = {
          id: id,
          name: name,
          mail: mail,
          planType: planType,
          adsTitle: adsTitle,
          adsBanner: adsBanner,
          adsPost: adsPost,
          adsPopup: adsPopup,
          description: description,
          websiteUrl: websiteUrl,
          roleIds: roleIds,
          price: price,
          plan_type: plan_type,
        };
        setIsEyeIconClicked(isShow ?? false);
        setCurrentApprovedAds(userData);
      })
      .catch((err) => {
        console.log('error', err);
      });
    setAddDialogOpen(true);
  };

  const onCloseDialog = () => {
    setAddDialogOpen(false);
    setCurrentApprovedAds(undefined);
    setIsEyeIconClicked(false);
    setfilterKeyword('');
    dispatch(
      approvedAdsApi.endpoints.getApprovedAdsUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
        userId: parseInt(userId),
        role: parsedRoles?.name,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }),
    );
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDate = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  const isFullFilled = !isLoading && data && data?.data?.length > 0;

  return (
    <>
      <AddDialog open={addDialogOpen} onClose={onCloseDialog} currentApprovedAds={currentApprovedAds} isEyeIconClicked={isEyeIconClicked} />
      <RootStyle>
        <ContainerStyle style={{ padding: '0 24px' }}>
          <GridStyle>
            <CustomTitle>{t('adds.approvedtitle')}</CustomTitle>
            <Stack direction="column" spacing={4}>
              <InputStyle
                value={filterKeyword}
                placeholder={t('common.search')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterKeyword(e.target.value)}
                InputProps={{
                  startAdornment: <SearchIcon />,
                }}
              />
            </Stack>
          </GridStyle>
        </ContainerStyle>
        <DateFilter
          startDate={startDate}
          endDate={endDate}
          handleClear={handleClear}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
        />
        <Container sx={{ minHeight: '70vh' }}>
          <div className="table-block">
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <TableHeadCustom order={order} orderBy={orderBy} columnsConfig={TABLE_COLUMNS_CONFIG} onSort={onSort} />
                <TableBody>
                  {isFullFilled &&
                    (data as IApprovedAdsResponse)?.data?.map((row: ApprovedAds) => {
                      return (
                        <ApprovedLogsTableRow
                          key={row.id}
                          row={row}
                          selected={false}
                          columnsConfig={TABLE_COLUMNS_CONFIG}
                          onShowDetails={(id) => onShowDetails(id, true)}
                        />
                      );
                    })}
                  <TableNoData isNotFound={!isLoading && data?.data?.length === 0} title={t('adds.approved_no_data')} />
                  <TableLoadingData isLoading={isLoading} />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {isFullFilled && (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={data ? data.total : 0}
                rowsPerPage={rowsPerPage}
                page={data && page ? page - 1 : 0}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t('common.rowsperpage')}
                sx={{
                  '& .MuiInputBase-root': {
                    maxWidth: '60px',
                  },
                }}
              />
            </Box>
          )}
        </Container>
      </RootStyle>
    </>
  );
}

export default ApprovedLogs;
