import { Grid } from '@mui/material';
import React from 'react';
import { DATA } from '../../../../store/flags/models';
import { ensureJson } from '../../../../utils';
interface FlagTextPublicationDialogProps {
  flag: DATA;
}

// Text Publication Dialog
function FlagTextPublicationDialog(props: FlagTextPublicationDialogProps) {
  const flag = props.flag;
  return (
    <>
      <Grid display="flex" style={{ justifyItems: 'center', alignContent: 'center', marginTop: '0%' }}>
        {ensureJson(flag.flaggedPublication.text).contentText}
      </Grid>
    </>
  );
}

export default FlagTextPublicationDialog;
