import { ApprovedAds } from '../../../models/ApprovedAds';

export interface IApprovedAdsResponse {
  total: number;
  next: number | null;
  previous: number | null;
  data: ApprovedAds[];
}

export interface IApprovedAdsState {
  isLoading: boolean;
  data: IApprovedAdsResponse | null;
  approvedUsers: ApprovedAds | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_APPROVED_ADS_SUCCESS = 'FETCH_APPROVED_ADS_SUCCESS',
  FETCH_APPROVED_ADS_FAILURE = 'FETCH_APPROVED_ADS_FAILURE',
  CREATE_APPROVED_ADS_SUCCESS = 'CREATE_APPROVED_ADS_SUCCESS',
}

export interface IApprovedAdsPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchApprovedAdsSuccessAction {
  type: typeof ActionTypes.FETCH_APPROVED_ADS_SUCCESS | typeof ActionTypes.CREATE_APPROVED_ADS_SUCCESS;
  payload: IApprovedAdsResponse | ApprovedAds;
}

export interface IFetchApprovedAdsRejectedAction {
  type: typeof ActionTypes.FETCH_APPROVED_ADS_FAILURE;
  payload: string;
}

export type ApprovedAdsActionTypes = IApprovedAdsPendingAction | IFetchApprovedAdsSuccessAction | IFetchApprovedAdsRejectedAction;
