import { masterClassAddsActionTypes, MasterClassAddsState } from './models';
import { GET_MASTER_CLASS_ADS_ATTEMPT, GET_MASTER_CLASS_ADS_ERROR, GET_MASTER_CLASS_ADS_SUCCESS } from './types';

const INITIAL_STATE: MasterClassAddsState = {
  loader: false,
  error: '',
  masterClassAdds: undefined,
};
export const masterClassAddsReducer = (state = INITIAL_STATE, action: masterClassAddsActionTypes): MasterClassAddsState => {
  switch (action.type) {
    case GET_MASTER_CLASS_ADS_ATTEMPT:
      return { ...state, loader: true, error: '' };
    case GET_MASTER_CLASS_ADS_SUCCESS:
      return { ...state, loader: false, masterClassAdds: action.masterClassAdds };
    case GET_MASTER_CLASS_ADS_ERROR:
      return { ...state, loader: false, error: action.error };

    default:
      return state;
  }
};
