import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { AppBar, Grid } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { menuBar } from '../../theme/menuBarTheme';
import { companiesHeader } from '../companies/companiesHeader';
import { CompagneFirstSection } from '../companies/compagnesFirstSection/compagneFirstSection';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { PaginationActionTypes } from '../../store/pagination/models';
import { RootState } from '../../store/rootStore';
import { getPageNumber, getType } from '../../store/pagination/actions';
import { getCompagnies } from '../../store/campagnes/actions';
import { compagniesActionTypes } from '../../store/campagnes/models';
import { CompagneTableBody } from '../companies/compagesTableBody/compagneTableBody';
import DateFilter from '../../utils/DateFilter';
import { useTranslation } from 'react-i18next';
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && <Box p={9}>{children}</Box>}
    </Grid>
  );
}

export const CompaniesMenu = ({ indexVal }: { indexVal: number }) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, compagniesActionTypes | PaginationActionTypes>>();
  const paginationState = useSelector((state: RootState) => state.pagination);
  const { pageNumber, perPage, type } = paginationState;
  const compagniesState = useSelector((state: RootState) => state.compagnies.AllCompagnies);
  const { loader, compagnies } = compagniesState;
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const formattedStartDate = startDate ? formatDate(startDate) : '';
  const formattedEndDate = endDate ? formatDate(endDate) : '';

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleNavigation = (compType: number) => {
    dispatch(getType(compType));
    dispatch(getPageNumber(0));
  };
  useEffect(() => {
    dispatch(getType(indexVal));
  }, []);

  useEffect(() => {
    handleClear();
  }, [indexVal]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(getCompagnies({ type: indexVal, page: pageNumber + 1, limit: perPage, startDate: formattedStartDate, endDate: formattedEndDate }));
    }
    return () => {
      mounted = false;
    };
  }, [type, perPage, pageNumber, indexVal, startDate, endDate]);

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDate = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  return (
    <>
      <ThemeProvider theme={menuBar}>
        <AppBar sx={{ position: 'sticky', top: '70px', zIndex: 5 }}></AppBar>
      </ThemeProvider>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        {indexVal === 0 ? (
          <TabPanel value={indexVal} index={indexVal} dir={theme.direction}>
            <CompagneFirstSection title={t('common.interstitials')} />
            <DateFilter
              startDate={startDate}
              endDate={endDate}
              handleClear={handleClear}
              handleStartDate={handleStartDate}
              handleEndDate={handleEndDate}
            />{' '}
            <CompagneTableBody headItems={companiesHeader} bodyData={compagnies} loading={loader} />
          </TabPanel>
        ) : (
          <TabPanel value={indexVal} index={indexVal} dir={theme.direction}>
            <CompagneFirstSection title={t('common.sponserPost')} />
            <DateFilter
              startDate={startDate}
              endDate={endDate}
              handleClear={handleClear}
              handleStartDate={handleStartDate}
              handleEndDate={handleEndDate}
            />{' '}
            <CompagneTableBody headItems={companiesHeader} bodyData={compagnies} loading={loader} />
          </TabPanel>
        )}
      </Grid>
    </>
  );
};
