/* eslint-disable react/react-in-jsx-scope */
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddDialogProps } from './add-dialog.types';
import { AddDialogStyle } from './add-dialog.styles';
import CloseIcon from '../../../../svgComponents/closeIcon';
import PendingAdsForm from '../form/PendindAdsForm';
import ViewPendingAdsForm from '../form/ViewPendingForm';

export default function AddDialog({ open, onClose, currentPendingAds, isUpdate, isEyeIconClicked }: AddDialogProps) {
  const { t } = useTranslation();
  return (
    <AddDialogStyle open={open} fullWidth sx={{ '& .MuiDialog-paper': { height: 'auto !important' } }} className="artgapi-wrapper">
      <Stack display={'flex'} direction={'row'} justifyContent={'space-between'} padding={2}>
        <Typography variant={'h5'}>
          {isEyeIconClicked ? t('adds.view_pending_ads_title') : isUpdate ? t('adds.update_pending_ads_title') : t('adds.add_pending_ads_title')}
        </Typography>
        <IconButton aria-label="close" color="primary" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      {isEyeIconClicked ? (
        <ViewPendingAdsForm onClose={onClose} currentPendingAds={currentPendingAds} isUpdate={isUpdate} isEyeIconClicked={isEyeIconClicked} />
      ) : (
        <PendingAdsForm onClose={onClose} currentPendingAds={currentPendingAds} isUpdate={isUpdate} isEyeIconClicked={isEyeIconClicked} />
      )}
    </AddDialogStyle>
  );
}
