/* eslint-disable @typescript-eslint/no-explicit-any */
import MasterClass from '../../../models/MasterClass';
import {
  ActionTypes,
  IFetchMasterClassRejectedAction,
  IFetchMasterClassSuccessAction,
  IMasterClassPendingAction,
  IMasterClassResponse,
  IMasterClassState,
  MasterClassActionTypes,
} from './MasterClass-slice.types';

export const initialState: IMasterClassState = {
  isLoading: false,
  error: null,
  data: null,
  masterclass: null,
};

export const masterClassReducer = (state = initialState, action: MasterClassActionTypes): IMasterClassState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_MASTER_CLASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload as IMasterClassResponse,
      };
    case ActionTypes.FETCH_MASTER_CLASS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_MASTER_CLASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        masterclass: action.payload as MasterClass,
      };
    default:
      return state;
  }
};

const matchPending = (): IMasterClassPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (
  data: IMasterClassResponse,
  action: 'fetch' | 'create' | 'delete' | 'update',
): IFetchMasterClassSuccessAction | MasterClass => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_MASTER_CLASS_SUCCESS : ActionTypes.CREATE_MASTER_CLASS_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchMasterClassRejectedAction => {
  return {
    type: ActionTypes.FETCH_MASTER_CLASS_FAILURE,
    payload: error,
  };
};

export const masterClassActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
