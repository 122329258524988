import React, { useEffect, useState } from 'react';
import { Box, Container, Table, TableBody, TableContainer, TablePagination, Stack } from '@mui/material';
import { RootStyle } from '../../../../pages/systemusers/SystemUser.styles';
import TableHeadCustom from '../../../table/table-head/CustomTableHead';
import useTable from '../../../../hooks/useTable';
import { TABLE_COLUMNS_CONFIG, initialPaginationState } from './PendingAds.constant';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../../store/rootStore';
import { IPendingAdsResponse, IPendingAdsState, PendingAdsActionTypes } from '../../../../store/pendingAds/slice/PendingAds-slice.types';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import SearchIcon from '../../../svgComponents/searchIcon';
import { TablePaginationActions } from '../../../table/TablePaginationActions/TablePaginationActions';
import PendingAdsTableRow from './pendingAds-row/PendingAdsTableRow';
import TableNoData from '../../../table/TableNoData';
import TableLoadingData from '../../../table/TableLoadingData';
import { pendingAdsApi } from '../../../../store/pendingAds/api/PendingAds.api';
import { PendingAds } from '../../../../models/PendingAds';
import { HttpMethod } from '../../../../utils/httpMethods';
import { CONSTANTS, ENDPOINTS, sendAsyncRequest } from '../../../../utils';
import { AxiosResponse } from 'axios';
import AddDialog from './add-dialog/AddDialog';
import ConfirmAcceptReject from './confirm-accept-reject-dialog/ConfirmAcceptRejectDialog';
import useAcceptRejectMutation from './hooks/useAcceptRejectMutation';
import useSnackBar from '../../../../hooks/useSnackBar';
import { ContainerStyle, CustomTitle, GridStyle, InputStyle } from '../../../../utils/toolbar/CommonToolbar.styles';
import DateFilter from '../../../../utils/DateFilter';

function PendingAdds() {
  const { t } = useTranslation();
  const [filterKeyword, setfilterKeyword] = useState('');
  const [currentPendingAds, setCurrentPendingAds] = useState<PendingAds | undefined>(undefined);
  const [isEyeIconClicked, setIsEyeIconClicked] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [confirmAcceptRejectOpen, setConfirmAcceptRejectOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const [isAcceptReject, setIsAcceptReject] = useState<number | undefined>(undefined);
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, PendingAdsActionTypes>>();
  const { data, isLoading } = useSelector<RootState, IPendingAdsState>((state: RootState) => state[pendingAdsApi.reducerPath]);
  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialPaginationState);
  const userId = localStorage.getItem(CONSTANTS.USER_ID) ?? '';
  const roles = localStorage.getItem(CONSTANTS.ROLE);
  const parsedRoles = roles ? JSON.parse(roles) : null;
  const { alertSuccess, alertError } = useSnackBar();
  const { acceptRejectPendingAds } = useAcceptRejectMutation();
  const [isEdit, setIsEdit] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const formattedStartDate = startDate ? formatDate(startDate) : '';
  const formattedEndDate = endDate ? formatDate(endDate) : '';

  useEffect(() => {
    dispatch(
      pendingAdsApi.endpoints.getPendingAdsUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }),
    );
  }, [order, orderBy, page, rowsPerPage, debouncedSearchTerm, dispatch, endDate, startDate]);

  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
    setPage(0);
  };

  const onCloseDialog = () => {
    setAddDialogOpen(false);
    setCurrentPendingAds(undefined);
    setIsEyeIconClicked(false);
    setIsEdit(false);
    setfilterKeyword('');
    dispatch(
      pendingAdsApi.endpoints.getPendingAdsUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }),
    );
  };

  const onOPenEditDialog = (id?: number) => {
    setIsEdit(true);
    sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.VIEW_PENDING_ADS}/${id}`, {}, true, {})
      .then((response: AxiosResponse) => {
        const responseBody = response.data.userPlan;
        const {
          name,
          phoneNb,
          planType,
          plan_type,
          price,
          isPaid,
          countryCode,
          adsPost,
          roleIds,
          adsTitle,
          websiteUrl,
          mail,
          description,
          adsBanner,
          adsPopup,
          servicePlanId,
        } = responseBody;

        const userData: PendingAds = {
          id: id,
          name: name,
          phoneNb: phoneNb,
          mail: mail,
          planType: planType,
          plan_type: plan_type,
          adsTitle: adsTitle,
          adsBanner: adsBanner,
          adsPost: adsPost,
          adsPopup: adsPopup,
          description: description,
          websiteUrl: websiteUrl,
          roleIds: roleIds,
          isPaid: isPaid,
          countryCode: countryCode,
          price: price,
          servicePlanId: servicePlanId,
        };
        setCurrentPendingAds(userData);
      })
      .catch((err) => {
        console.log('error', err);
      });
    setAddDialogOpen(true);
  };

  const onShowDetails = async (id?: number, isShow?: boolean) => {
    setIsEdit(false);
    try {
      const response = await sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.VIEW_PENDING_ADS}/${id}`, {}, true, {});
      const responseBody = response.data.userPlan;
      const { name, phoneNb, planType, plan_type, adsPost, adsTitle, price, roleIds, websiteUrl, mail, description, adsBanner, adsPopup } =
        responseBody;
      const userData: PendingAds = {
        id: id,
        name: name,
        phoneNb: phoneNb,
        mail: mail,
        planType: planType,
        plan_type: plan_type,
        price: price,
        adsTitle: adsTitle,
        adsBanner: adsBanner,
        adsPost: adsPost,
        adsPopup: adsPopup,
        description: description,
        websiteUrl: websiteUrl,
        roleIds: roleIds,
      };
      setIsEyeIconClicked(isShow ?? false);
      setCurrentPendingAds(userData);
      setAddDialogOpen(true);
    } catch (err) {
      console.log('error', err);
    }
  };

  const onConfirmAcceptReject = (id?: number, isAcceptRejected?: number) => {
    setSelectedId(id);
    setIsAcceptReject(isAcceptRejected);
    setConfirmAcceptRejectOpen(true);
  };

  const onCloseConfirmAcceptRejectDialog = () => {
    setSelectedId(undefined);
    setIsAcceptReject(undefined);
    setConfirmAcceptRejectOpen(false);
    setIsEyeIconClicked(false);
    setfilterKeyword('');
  };

  const onAcceptReject = async () => {
    try {
      if (selectedId && isAcceptReject) {
        const requestData = {
          id: selectedId,
          status: isAcceptReject,
          acceptedBy: parseInt(userId),
          acceptedByRole: parsedRoles?.name,
        };
        await acceptRejectPendingAds(requestData);
        onCloseConfirmAcceptRejectDialog();
        if (isAcceptReject == 1) {
          dispatch(alertSuccess(t('adds.successfull')));
        } else {
          dispatch(alertSuccess(t('adds.reject_message')));
        }
        if (data?.next === null && data?.total <= page * rowsPerPage) {
          setPage(page - 1);
        }
        dispatch(
          pendingAdsApi.endpoints.getPendingAdsUsers({
            page,
            limit: rowsPerPage,
            orderBy,
            order,
            keyword: debouncedSearchTerm,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          }),
        );
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
    }
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDate = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDate = (date: Date | null) => {
    setEndDate(date);
  };
  const isFullFilled = !isLoading && data && data?.data?.length > 0;

  return (
    <>
      <ConfirmAcceptReject
        open={confirmAcceptRejectOpen}
        onClose={onCloseConfirmAcceptRejectDialog}
        onAcceptReject={onAcceptReject}
        isAcceptReject={isAcceptReject ?? 2} // Provide a default value if undefined
      />
      <AddDialog
        open={addDialogOpen}
        onClose={onCloseDialog}
        currentPendingAds={currentPendingAds}
        isUpdate={isEdit}
        isEyeIconClicked={isEyeIconClicked}
      />
      <RootStyle>
        <ContainerStyle style={{ padding: '0 24px' }}>
          <GridStyle>
            <CustomTitle>{t('adds.pendingtitle')}</CustomTitle>
            <Stack direction="column" spacing={4}>
              <InputStyle
                value={filterKeyword}
                placeholder={t('common.search')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterKeyword(e.target.value)}
                InputProps={{
                  startAdornment: <SearchIcon />,
                }}
              />
            </Stack>
          </GridStyle>
        </ContainerStyle>
        <DateFilter
          startDate={startDate}
          endDate={endDate}
          handleClear={handleClear}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
        />
        <Container sx={{ minHeight: '70vh' }}>
          <div className="table-block">
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <TableHeadCustom order={order} orderBy={orderBy} columnsConfig={TABLE_COLUMNS_CONFIG} onSort={onSort} />
                <TableBody>
                  {isFullFilled &&
                    (data as IPendingAdsResponse)?.data?.map((row: PendingAds) => {
                      return (
                        <PendingAdsTableRow
                          key={row.id}
                          row={row}
                          selected={false}
                          onShowDetails={(id) => onShowDetails(id, true)}
                          columnsConfig={TABLE_COLUMNS_CONFIG}
                          onOPenEditDialog={onOPenEditDialog}
                          onConfirmAcceptReject={onConfirmAcceptReject}
                        />
                      );
                    })}
                  <TableNoData isNotFound={!isLoading && data?.data?.length === 0} title={t('adds.no_data')} />
                  <TableLoadingData isLoading={isLoading} />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {isFullFilled && (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={data ? data.total : 0}
                rowsPerPage={rowsPerPage}
                page={data && page ? page - 1 : 0}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t('common.rowsperpage')}
                sx={{
                  '& .MuiInputBase-root': {
                    maxWidth: '60px',
                  },
                }}
              />
            </Box>
          )}
        </Container>
      </RootStyle>
    </>
  );
}

export default PendingAdds;
