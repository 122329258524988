/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { University } from '../../../models/University';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { RootState } from '../../rootStore';
import { IFetchUniversitySuccessAction, UniversityActionTypes } from '../slice/university-slice.types';
import { universityActions, universityReducer } from '../slice/university.slice';
export const universityApi = {
  reducerPath: 'university',
  reducer: universityReducer,
  endpoints: {
    getUniversities: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, UniversityActionTypes>) => {
      dispatch(universityActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.UNIVERSITIES, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(universityActions.matchFulFilled(responseBody, 'fetch') as IFetchUniversitySuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(universityActions.matchRejected(error));
        });
    },
    createUniversity: (data: University) => async (dispatch: ThunkDispatch<RootState, undefined, UniversityActionTypes>) => {
      dispatch(universityActions.matchPending());
      sendAsyncRequest(HttpMethod.Post, ENDPOINTS.UNIVERSITIES, data, true, {})
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(universityActions.matchFulFilled(responseBody, 'create') as IFetchUniversitySuccessAction);
        })
        .catch((error) => {
          const responseEror = error.response.data;
          console.log('error', responseEror.message);
          dispatch(universityActions.matchRejected(responseEror));
        });
    },
  },
};
