/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { Box, Button, Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../theme/theme';
import { AdvertismentsFormProps } from './advertisements-form.types';
import Advertisements from '../../../models/Advertisments';
import AdminVideoControl from '../../adds/menubar/approved/form/VideoControl';
import FullScreenModal from '../../adds/menubar/approved/form/FullScreenModal';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import AddPhotoIcon from '../../svgComponents/AddPhotoIcon';

export default function ViewSubscriptionPlans({ currentAdvertisements, isUpdate }: AdvertismentsFormProps) {
  const customStyle = {
    marginBottom: '0.5rem',
    fontFamily: 'SFPro-Regular',
    color: theme.palette.secondary.dark,
  };
  const [postURL, setPostURL] = useState<string | undefined>(undefined);
  const [postType, setPostType] = useState<string | undefined>(undefined);
  const [post, setPost] = useState<string | undefined>(undefined);
  const [postError, setPostError] = useState<string | undefined>(undefined);

  const [videoURL, setVideoURL] = useState<string | undefined>(undefined);
  const [videoType, setVideoType] = useState<string | undefined>(undefined);
  const [largeImgUrl, setLargeImgUrl] = useState<string | undefined>(undefined);
  const [openImgModel, setOpenImgModal] = useState(false);
  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);

  const { t } = useTranslation();

  const defaultValues = useMemo<Advertisements>(
    () => ({
      title: currentAdvertisements?.title || '',
      videoOrImage: currentAdvertisements?.videoOrImage || '',
      type: currentAdvertisements?.type || '',
    }),
    [currentAdvertisements],
  );

  useEffect(() => {
    if (currentAdvertisements) {
      const posturl = currentAdvertisements?.videoOrImage || '';
      const postExtension = posturl.split('.').pop()?.toLowerCase() || '';
      setPostURL(posturl);
      setPostType(postExtension);
      setPost(posturl);
    }
  }, [currentAdvertisements]);

  const methods = useForm<Advertisements>({
    defaultValues,
    mode: 'all',
  });

  const { watch, reset } = methods;

  const values = watch();

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentAdvertisements]);

  useEffect(() => {
    const updatedDefaultValues = {
      title: currentAdvertisements?.title || '',
      videoOrImage: currentAdvertisements?.videoOrImage || '',
      type: currentAdvertisements?.type || '',
    };
    methods.reset(updatedDefaultValues);
  }, [currentAdvertisements, isUpdate]);

  const openVideoPopup = () => {
    setVideoURL(postURL);
    setVideoType(postType);

    setVideoPopupOpen(true);
  };

  const closeVideoPopup = () => {
    setVideoPopupOpen(false);
  };

  const handleClickOpenModal = (imageurl: string) => {
    setLargeImgUrl(imageurl || '');
    setOpenImgModal(true);
  };
  const handleClickCloseImgModal = () => {
    setLargeImgUrl('');
    setOpenImgModal(false);
  };

  return (
    <>
      <Grid className="artgapi-modal" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box sx={{ p: 3 }}>
            <Box sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 3,
                  rowGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                }}
              >
                <div className="col">
                  <Typography>{t('advertisements.form_fields.title.placeholder')}</Typography>
                  <label style={customStyle}>{values?.title}</label>
                </div>
                {values?.videoOrImage && (
                  <Grid>
                    <div>
                      <Typography>{t('advertisements.form_fields.video.placeholder')}</Typography>
                      <div style={{ display: 'flex', marginBottom: '0', alignItems: 'center' }}>
                        {postURL ? (
                          <>
                            <div>
                              <span>
                                {postType?.includes('mp4') ? (
                                  <>
                                    <div
                                      style={{
                                        width: '100px',
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative',
                                      }}
                                    >
                                      <video
                                        style={{
                                          cursor: 'pointer',
                                          width: '100%',
                                          objectFit: 'cover',
                                          height: '100%',
                                        }}
                                        src={postURL}
                                        onClick={openVideoPopup}
                                      ></video>

                                      <span
                                        style={{
                                          backgroundColor: 'white',
                                          display: 'flex',
                                          alignItems: 'center',
                                          borderRadius: '9999px',
                                          position: 'absolute',
                                          width: '1.5 rem',
                                          height: '1,5 rem',
                                          cursor: 'pointer',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <PlayCircleOutlineIcon className="text-base" onClick={openVideoPopup} />
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <img
                                    src={postURL}
                                    alt="post"
                                    style={{
                                      width: '100px',
                                      height: '100px',
                                      objectFit: 'contain',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => handleClickOpenModal(postURL || '')}
                                  />
                                )}
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '0px' }}>
                              <label
                                htmlFor="logos"
                                style={{
                                  width: '44px',
                                  height: '44px',
                                  minWidth: '44PX',
                                  backgroundColor: '#f0f0f0',
                                  border: '1px dashed #ccc',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <AddPhotoIcon />
                              </label>
                            </div>
                          </>
                        )}
                      </div>
                      {postError && <p style={{ color: '#d32f2f', marginTop: '0.5rem' }}>{postError}</p>}
                    </div>
                  </Grid>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {isVideoPopupOpen && (
        <AdminVideoControl
          isVideoPopupOpen={isVideoPopupOpen}
          closeVideoPopup={closeVideoPopup}
          videourl={videoURL || ''}
          selectedVideo={videoType || ''}
        />
      )}
      {openImgModel && (
        <FullScreenModal openImgModel={openImgModel} closeImgPopup={handleClickCloseImgModal} largeImgUrl={values?.videoOrImage || ''} />
      )}
    </>
  );
}
