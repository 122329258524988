/* eslint-disable react/react-in-jsx-scope */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  iconStyle: {
    width: 20,
    height: 20,
    marginTop: 1,
    flexShrink: 0,
    // marginRight: 16,
    // Add more styles as needed
  },
}));

const Iconify = ({ icon: IconComponent }: { icon: React.ElementType }) => {
  const classes = useStyles();

  return <IconComponent className={classes.iconStyle} />;
};

export default Iconify;
