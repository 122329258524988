/* eslint-disable react/react-in-jsx-scope */
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddDialogProps } from './add-dialog.types';
import { AddDialogStyle } from './add-dialog.styles';
import CloseIcon from '../../svgComponents/closeIcon';
import ViewSubscriptionPlans from '../form/ViewSubscriptionPlanClass';
import SubscriptionPlanForm from '../form/SubscriptionPlansForm';

export default function AddDialog({ open, onClose, currentSubscriptionPlan, isUpdate, isEyeIconClicked }: AddDialogProps) {
  const { t } = useTranslation();
  return (
    <AddDialogStyle open={open} fullWidth sx={{ '& .MuiDialog-paper': { height: 'auto !important' } }} className="artgapi-wrapper">
      <Stack display={'flex'} direction={'row'} justifyContent={'space-between'} padding={2}>
        <Typography variant={'h5'}>
          {isEyeIconClicked
            ? t('subscriptionPlan.view_subscription_plan_title')
            : isUpdate
            ? t('subscriptionPlan.update_subscription_plan_title')
            : t('subscriptionPlan.add_subscription_plan_title')}
        </Typography>
        <IconButton aria-label="close" color="primary" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      {isEyeIconClicked ? (
        <ViewSubscriptionPlans
          onClose={onClose}
          currentSubscriptionPlan={currentSubscriptionPlan}
          isUpdate={isUpdate}
          isEyeIconClicked={isEyeIconClicked}
        />
      ) : (
        <SubscriptionPlanForm
          onClose={onClose}
          currentSubscriptionPlan={currentSubscriptionPlan}
          isUpdate={isUpdate}
          isEyeIconClicked={isEyeIconClicked}
        />
      )}
    </AddDialogStyle>
  );
}
