import React, { useEffect, useState, ChangeEvent } from 'react';
import { Box, Container, Table, TableBody, TableContainer, TablePagination, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TablePaginationActions } from '../table/TablePaginationActions/TablePaginationActions';
import TableLoadingData from '../table/TableLoadingData';
import TableNoData from '../table/TableNoData';
import { TABLE_COLUMNS_CONFIG, initialPaginationState } from './MasterClass.constant';
import { RootStyle } from '../../pages/systemusers/SystemUser.styles';
import TableHeadCustom from '../table/table-head/CustomTableHead';
import { MasterClassActionTypes, IMasterClassResponse, IMasterClassState } from '../../store/masterClass/slice/MasterClass-slice.types';
import { useDebounce } from '@uidotdev/usehooks';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store/rootStore';
import useTable from '../../hooks/useTable';
import MasterClassTableRow from './masterClass-row/MasterClassTableRow';
import MasterClass from '../../models/MasterClass';
import { masterClassApi } from '../../store/masterClass/api/MasterClass.api';
import CommonToolbar from '../../utils/toolbar/CommonToolbar';
import AddDialog from './add-dialog/AddDialog';
import ConfirmDeleteDialog from '../../utils/confirm-delete-dialog/ConfirmDeleteDialog';
import useSnackBar from '../../hooks/useSnackBar';
import { ENDPOINTS, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { AxiosResponse } from 'axios';
import { clearNewTag } from '../../store/tags/actions';
import { tagsActionTypes } from '../../store/tags/models';
import { ContainerStyle, CustomTitle, GridStyle, InputStyle } from '../../utils/toolbar/CommonToolbar.styles';
import SearchIcon from '../svgComponents/searchIcon';

interface Tag {
  id: number;
  name: string;
}

interface LearningPoint {
  id: number;
  point: string;
  imageUrl: string;
}

function MasterClassListing() {
  const { t } = useTranslation();
  const [filterKeyword, setfilterKeyword] = useState('');
  const [isEyeIconClicked, setIsEyeIconClicked] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const [currentMasterClass, setCurrentMasterClass] = useState<MasterClass | undefined>(undefined);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, MasterClassActionTypes>>();
  const dispatchTags = useDispatch<ThunkDispatch<RootState, undefined, tagsActionTypes>>();
  const { data, isLoading } = useSelector<RootState, IMasterClassState>((state: RootState) => state[masterClassApi.reducerPath]);
  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialPaginationState);
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const [isEdit, setIsEdit] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    dispatch(
      masterClassApi.endpoints.getMasterClassUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  }, [order, orderBy, page, rowsPerPage, debouncedSearchTerm, dispatch]);

  const { alertSuccess, alertError } = useSnackBar();

  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
    setPage(1);
  };

  const onShowDetails = async (id?: number, isShow?: boolean) => {
    setIsEdit(false);
    try {
      const response = await sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.MASTERCLASS}/${id}`, {}, true, {});
      const responseBody = response.data.masterClass;
      const relatedMasterClasses = response.data.relatedMasterClasses;
      const { title, description, video, thumbnail, learningPoints, tags, instructorName, isPaid, MasterClassAd } = responseBody;
      const formattedLearningPoints = learningPoints?.map((lp: LearningPoint) => ({ point: lp.point, imageUrl: lp.imageUrl, id: lp.id }));
      console.log('fsdfdsfsdf', isPaid);
      const userData: MasterClass = {
        id: id,
        title: title,
        description: description,
        video: video,
        learningPoints: formattedLearningPoints,
        tagIds: tags,
        instructorName: instructorName,
        relatedMasterClasses: relatedMasterClasses,
        isPaid: isPaid,
        MasterClassAd: MasterClassAd,
        thumbnail: thumbnail,
      };
      setIsEyeIconClicked(isShow ?? false);
      setCurrentMasterClass(userData);
      setAddDialogOpen(true);
    } catch (err) {
      console.log('error', err);
    }
  };

  const onCloseDialog = () => {
    setAddDialogOpen(false);
    setCurrentMasterClass(undefined);
    setIsEyeIconClicked(false);
    setIsEdit(false);
    setfilterKeyword('');
    dispatchTags(clearNewTag());
    dispatch(
      masterClassApi.endpoints.getMasterClassUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  };

  const onOpenAddDialog = () => {
    setCurrentMasterClass(undefined);
    setAddDialogOpen(true);
    setIsEdit(false);
  };

  const onCloseConfirmDeleteDialog = () => {
    setSelectedId(undefined);
    setConfirmDeleteOpen(false);
    setIsEyeIconClicked(false);
    setfilterKeyword('');
  };

  const onDeleteUniversity = async () => {
    try {
      if (selectedId) {
        await dispatch(masterClassApi.endpoints.deleteMasterClassUser(selectedId));
        onCloseConfirmDeleteDialog();
        dispatch(alertSuccess(t('masterClass.delete_success_message')));
        const isLastPage = data?.next === null;
        const isOnlyItemOnPage = data?.masterClasses.length === 1;
        if (isLastPage && isOnlyItemOnPage && page > 1) {
          setPage(page - 1);
        }
        dispatch(
          masterClassApi.endpoints.getMasterClassUsers({
            page,
            limit: rowsPerPage,
            orderBy,
            order,
            keyword: debouncedSearchTerm,
          }),
        );
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
    }
  };

  const onOPenEditDialog = (id?: number) => {
    setIsEdit(true);
    sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.MASTERCLASS}/${id}`, {}, true, {})
      .then((response: AxiosResponse) => {
        const responseBody = response.data.masterClass;
        const { title, description, video, thumbnail, learningPoints, tags, instructorName, isPaid, masterClassAdsId } = responseBody;
        const formattedLearningPoints = learningPoints?.map((lp: LearningPoint) => ({ point: lp.point, imageUrl: lp.imageUrl, id: lp.id }));

        const userData: MasterClass = {
          id: id,
          title: title,
          description: description,
          video: video,
          learningPoints: formattedLearningPoints,
          tagIds: tags?.map((tag: Tag) => String(tag.id)),
          instructorName: instructorName,
          isPaid: isPaid,
          masterClassAdsId: masterClassAdsId,
          thumbnail: thumbnail,
        };
        setCurrentMasterClass(userData);
      })
      .catch((err) => {
        console.log('error', err);
      });
    setAddDialogOpen(true);
  };

  const onConfirmDelete = (id?: number) => {
    setSelectedId(id);
    setConfirmDeleteOpen(true);
  };

  const isFullFilled = !isLoading && data && data?.masterClasses?.length > 0;

  return (
    <>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        onClose={onCloseConfirmDeleteDialog}
        onDelete={onDeleteUniversity}
        confirm_delete_title={t('masterClass.confirm_delete_title')}
        confirm_delete_message={t('masterClass.confirm_delete_message')}
      />
      <AddDialog
        open={addDialogOpen}
        onClose={onCloseDialog}
        currentMasterClass={currentMasterClass}
        isUpdate={isEdit}
        isEyeIconClicked={isEyeIconClicked}
      />
      <RootStyle>
        {/* <CommonToolbar
          filterKeyword={filterKeyword}
          onFilterKeyword={(value: string) => onFilterKeyword(value)}
          onOpenAddDialog={onOpenAddDialog}
          title={t('masterClass.title')}
          message={t('masterClass.add')}
        /> */}

        <ContainerStyle>
          <GridStyle>
            <CustomTitle>{t('masterClass.title')}</CustomTitle>
            <Stack direction="column" spacing={4}>
              <InputStyle
                value={filterKeyword}
                placeholder={t('common.search')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterKeyword(e.target.value)}
                InputProps={{
                  startAdornment: <SearchIcon />,
                }}
              />
            </Stack>
          </GridStyle>
        </ContainerStyle>
        <Container sx={{ minHeight: '70vh' }}>
          <div className="table-block interest-panel">
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <TableHeadCustom order={order} orderBy={orderBy} columnsConfig={TABLE_COLUMNS_CONFIG} onSort={onSort} />
                <TableBody>
                  {isFullFilled &&
                    (data as IMasterClassResponse)?.masterClasses?.map((row: MasterClass) => {
                      return (
                        <MasterClassTableRow
                          key={row.id}
                          row={row}
                          selected={false}
                          columnsConfig={TABLE_COLUMNS_CONFIG}
                          onShowDetails={(id, isShow) => onShowDetails(id, true)}
                          onOPenEditDialog={onOPenEditDialog}
                          onConfirmDelete={onConfirmDelete}
                        />
                      );
                    })}
                  <TableNoData isNotFound={!isLoading && data?.masterClasses?.length === 0} title={t('masterClass.masterClass_no_data')} />

                  <TableLoadingData isLoading={isLoading} />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {isFullFilled && (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={data ? data.total : 0}
                rowsPerPage={rowsPerPage}
                page={data && page ? page - 1 : 0}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t('common.rowsperpage')}
                sx={{
                  '& .MuiInputBase-root': {
                    maxWidth: '60px',
                  },
                }}
              />
            </Box>
          )}
        </Container>
      </RootStyle>
    </>
  );
}

export default MasterClassListing;
