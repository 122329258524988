import Advertisements from '../../../models/Advertisments';

export interface IAdvertisementsResponse {
  total: number;
  next: number | null;
  previous: number | null;
  masterClassesAds: Advertisements[];
}

export interface IAdvertisementsState {
  isLoading: boolean;
  data: IAdvertisementsResponse | null;
  advertisements: Advertisements | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_ADVERTISEMENTS_SUCCESS = 'FETCH_ADVERTISEMENTS_SUCCESS',
  FETCH_ADVERTISEMENTS_FAILURE = 'FETCH_ADVERTISEMENTS_FAILURE',
  CREATE_ADVERTISEMENTS_SUCCESS = 'CREATE_ADVERTISEMENTS_SUCCESS',
}

export interface IAdvertisementsPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchAdvertisementsSuccessAction {
  type: typeof ActionTypes.FETCH_ADVERTISEMENTS_SUCCESS | typeof ActionTypes.CREATE_ADVERTISEMENTS_SUCCESS;
  payload: IAdvertisementsResponse | Advertisements;
}

export interface IFetchAdvertisementsRejectedAction {
  type: typeof ActionTypes.FETCH_ADVERTISEMENTS_FAILURE;
  payload: string;
}

export type AdvertisementsActionTypes = IAdvertisementsPendingAction | IFetchAdvertisementsSuccessAction | IFetchAdvertisementsRejectedAction;
