export interface Description {
  label: string;
  type: string;
  height: number;
  width: number;
  choices?: { label: string; value: string }[];
  value: string;
}

export const companiesHeader: Description[] = [
  {
    label: 'common.title',
    type: 'normal',
    height: 50,
    width: 200,
    value: 'title',
  },
  {
    label: 'common.platform',
    type: 'normal',
    height: 50,
    width: 190,
    value: 'platform',
  },
  {
    label: 'common.format',
    type: 'normal',
    height: 50,
    width: 150,
    value: 'format',
  },
  {
    label: 'common.Status',
    type: 'normal',
    height: 50,
    width: 150,
    value: 'status',
  },
  {
    label: 'common.begining',
    type: 'date',
    height: 50,
    width: 170,
    value: 'startDate',
  },
  {
    label: 'common.end',
    type: 'date',
    height: 50,
    width: 200,
    value: 'endDate',
  },
];
