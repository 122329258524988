import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { BugsActionTypes } from '../../store/bugs/models';
import { getOpportunities } from '../../store/opportunities/action';
import { GetOpportunityType, getPageNumber } from '../../store/pagination/actions';
import { PaginationActionTypes } from '../../store/pagination/models';
import { RootState } from '../../store/rootStore';
import { CONSTANTS } from '../../utils';
import CreatePreviewDialog from '../dialog/createDialog/CreatePreviewDialog';
import { OnTheTable } from '../table/onTheTable/OnTheTable';
import { TableFactory } from '../table/tableFactory/TableFactory';
import { OpportunitiesHeader } from './opportunitiesHeader';
import { useTranslation } from 'react-i18next';

/**
 * Component OpportunitiesList
 *
 * @component
 *
 * @example
 * return (
 *   <OpportunitiesList/>
 * )
 */
const OpportunitiesList = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, BugsActionTypes | PaginationActionTypes>>();
  const opportunitiesState = useSelector((state: RootState) => state.opportunities.opportunities);
  const loader = useSelector((state: RootState) => state.opportunities.loading);
  const paginationState = useSelector((state: RootState) => state.pagination);
  const totalNumberOfApplicants = useSelector((state: RootState) => state.counter?.getApplicants?.applicants);
  const [unseenApplicant, setUnseenApplicant] = React.useState(false);
  const { pageNumber, perPage, opportunityType } = paginationState;
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(opportunityType);
  const { t } = useTranslation();
  const openCreateDialog = () => {
    setOpen(true);
  };
  const closeCreateDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(GetOpportunityType(type ?? ''));
  }, [type]);

  useEffect(() => {
    setType(opportunityType);
  }, [opportunityType]);

  const selectType = (value: string) => {
    console.log('fdsfsfsf', value);
    if (value === CONSTANTS.EXPOSITION_TYPE || value === CONSTANTS.EXPOSITION_TYPE_EN) {
      setType(0);
    } else if (value === CONSTANTS.COLLABORATION_TYPE) {
      setType(1);
    } else {
      setType('');
    }
    dispatch(getPageNumber(0));
  };
  useEffect(() => {
    dispatch(getOpportunities(pageNumber + 1, perPage, type));
  }, [pageNumber + 1, perPage, type, unseenApplicant, totalNumberOfApplicants]);

  return (
    <>
      <Grid container direction="column" rowSpacing={5}>
        <Grid item>{'ok'}</Grid>
        <Grid item sx={{ width: 1 }} className="artgapi-resposive">
          <OnTheTable title={CONSTANTS.Opportunities} openDialog={openCreateDialog} />
          <div className="table-block">
            <TableFactory
              headItems={OpportunitiesHeader}
              bodyData={opportunitiesState.opportunities}
              name={CONSTANTS.Opportunities}
              loading={loader}
              handleFilter={selectType}
              unseenApplicant={unseenApplicant}
              setUnseenApplicant={setUnseenApplicant}
              type={type !== undefined ? String(type) : undefined} // Explicitly cast to string or undefined
            />
          </div>
        </Grid>
        {open && <CreatePreviewDialog show={open} closeDialog={closeCreateDialog} title={CONSTANTS.Opportunities} />}
      </Grid>
    </>
  );
};

export default OpportunitiesList;
