import React from 'react';

export const BugsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.6378 4.39513C16.4443 1.20162 11.247 1.20162 8.05348 4.39513C4.85998 7.58863 4.85998 12.7859 8.05348 15.9794C9.58762 17.5135 11.654 18.3902 13.8456 18.3902C21.1093 18.3589 24.7411 9.49847 19.6378 4.39513ZM13.8456 17.7327C11.8419 17.7327 9.93201 16.95 8.49181 15.5098C5.54877 12.5667 5.54877 7.77648 8.49181 4.83345C11.4348 1.89042 16.2251 1.89042 19.1681 4.83345C23.8958 9.56109 20.5457 17.7327 13.8456 17.7327Z"
        fill="white"
      />
      <path
        d="M11.9358 5.14663C12.3428 5.14663 12.7498 5.33448 13.0316 5.64757C12.3428 5.96066 11.9358 6.68076 11.9045 7.40086C11.8418 8.30882 12.4993 9.09154 13.8143 9.09154C15.2232 9.09154 15.7554 8.40275 15.7554 7.5261C15.7554 6.71207 15.2858 5.96066 14.6283 5.64757C14.9101 5.33448 15.3171 5.17793 15.7241 5.17793C15.8807 5.17793 16.0372 5.02139 16.0372 4.86485C16.0372 4.67699 15.8807 4.55176 15.7241 4.55176C15.0353 4.55176 14.3779 4.89616 13.9708 5.49102C13.8769 5.49102 13.783 5.49102 13.6891 5.49102C13.2821 4.89616 12.6246 4.55176 11.9358 4.55176C11.7479 4.55176 11.6227 4.67699 11.6227 4.86485C11.6227 5.02139 11.7479 5.14663 11.9358 5.14663Z"
        fill="white"
      />
      <path
        d="M6.20626 14.5708L2.63705 18.1087C1.7291 19.0167 1.7291 20.4569 2.63705 21.3648C3.54501 22.2728 4.98522 22.2728 5.89317 21.3648L9.43107 17.8269C8.1161 17.0442 6.95767 15.8858 6.20626 14.5708Z"
        fill="white"
      />
      <path
        d="M18.3854 12.3165C18.4793 12.4731 18.6672 12.5044 18.8237 12.4104C18.9803 12.3165 19.0116 12.1287 18.9177 11.9721L18.1975 10.7824C18.1349 10.6885 18.041 10.6258 17.9158 10.6258H17.0704C16.9139 9.87442 16.5382 9.18563 16.0685 8.65338C15.5989 9.4361 14.7223 9.68657 13.8143 9.68657C12.875 9.68657 12.1236 9.46741 11.6227 8.62207C11.1218 9.15432 10.746 9.84312 10.5895 10.6258H9.74416C9.65023 10.6258 9.525 10.6885 9.49369 10.7824L8.77359 11.9721C8.67966 12.1287 8.74228 12.3165 8.86751 12.4104C9.02406 12.5044 9.21191 12.4417 9.30584 12.3165L9.93201 11.2833H10.4956C10.4956 11.4399 10.4643 11.5964 10.4643 11.7216C10.4643 12.1913 10.5269 12.6609 10.6521 13.0679H9.74416C9.65023 13.0679 9.525 13.1305 9.49369 13.2245L8.77359 14.4142C8.67966 14.5708 8.74228 14.7586 8.86751 14.8525C9.02406 14.9465 9.21191 14.8838 9.30584 14.7586L9.93201 13.7254H10.9026C11.4975 14.9778 12.5933 15.8231 13.8769 15.8231C15.1606 15.8231 16.2564 14.9778 16.8513 13.7254H17.8218L18.448 14.7586C18.5419 14.9151 18.7298 14.9465 18.8863 14.8525C19.0429 14.7586 19.0742 14.5708 18.9803 14.4142L18.2602 13.2245C18.1976 13.1305 18.1036 13.0679 17.9784 13.0679H17.0704C17.1957 12.6296 17.2583 12.1913 17.2583 11.7216C17.2583 11.5651 17.2583 11.4086 17.227 11.2833H17.7905L18.3854 12.3165Z"
        fill="white"
      />
    </svg>
  );
};

export default BugsIcon;
