/* eslint-disable @typescript-eslint/no-explicit-any */
import SubscriptionPlans from '../../../models/SubscriptionPlans';
import {
  ActionTypes,
  IFetchSubscriptionPlansRejectedAction,
  IFetchSubscriptionPlansSuccessAction,
  ISubscriptionPlansPendingAction,
  ISubscrptionPlansResponse,
  ISubscrptionPlansState,
  SubscriptionPlansActionTypes,
} from './SubscriptionPlans-slice-types';

export const initialState: ISubscrptionPlansState = {
  isLoading: false,
  error: null,
  data: null,
  subscriptionPlans: null,
};

export const subscriptionPlansReducer = (state = initialState, action: SubscriptionPlansActionTypes): ISubscrptionPlansState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_SUBSCRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload as ISubscrptionPlansResponse,
      };
    case ActionTypes.FETCH_SUBSCRIPTION_PLANS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_SUBSCRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subscriptionPlans: action.payload as SubscriptionPlans,
      };
    default:
      return state;
  }
};

const matchPending = (): ISubscriptionPlansPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (
  data: ISubscrptionPlansResponse,
  action: 'fetch' | 'create' | 'delete' | 'update',
): IFetchSubscriptionPlansSuccessAction | SubscriptionPlans => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_SUBSCRIPTION_PLANS_SUCCESS : ActionTypes.CREATE_SUBSCRIPTION_PLANS_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchSubscriptionPlansRejectedAction => {
  return {
    type: ActionTypes.FETCH_SUBSCRIPTION_PLANS_FAILURE,
    payload: error,
  };
};

export const subscriptionPlansActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
