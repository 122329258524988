import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { CONSTANTS, ENDPOINTS, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { RootState } from '../rootStore';
import { SnackBarActionTypes } from '../snackBar/models';
import { SHOW_SNACK_BAR } from '../snackBar/types';
import { tagsActionTypes } from './models';
import { CLEAR_NEW_TAG, CREATE_TAG_ATTEMPT, CREATE_TAG_ERROR, CREATE_TAG_SUCCESS, GET_TAGS_ATTEMPT, GET_TAGS_ERROR, GET_TAGS_SUCCESS } from './types';

export const getTags = () => async (dispatch: ThunkDispatch<RootState, undefined, tagsActionTypes | SnackBarActionTypes>) => {
  dispatch({
    type: GET_TAGS_ATTEMPT,
  });

  sendAsyncRequest(HttpMethod.Get, ENDPOINTS.Tags, {}, true, null)
    .then((res: AxiosResponse) => {
      dispatch({
        type: GET_TAGS_SUCCESS,
        tags: res.data.masterClassTags,
      });
    })
    .catch((error) => {
      if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
        localStorage.clear();
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: CONSTANTS.SESSION_EXPIRED,
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
        dispatch({
          type: GET_TAGS_ERROR,
          error: error,
        });
      } else {
        dispatch({
          type: GET_TAGS_ERROR,
          error: error,
        });
      }
    });
};

export const createTag = (tagName: string) => async (dispatch: ThunkDispatch<RootState, undefined, tagsActionTypes | SnackBarActionTypes>) => {
  dispatch({
    type: CREATE_TAG_ATTEMPT,
  });

  sendAsyncRequest(HttpMethod.Post, ENDPOINTS.AddTags, { name: tagName }, true, null)
    .then((res: AxiosResponse) => {
      dispatch({
        type: CREATE_TAG_SUCCESS,
        newTag: res.data, // Assuming the API response includes the newly created tag
      });
      dispatch(getTags());

      dispatch({
        type: SHOW_SNACK_BAR,
        payload: {
          message: 'Tag created successfully!',
          severity: 'success',
          icon: 'SuccessIcon',
          size: 'medium',
        },
      });
    })
    .catch((error) => {
      if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
        localStorage.clear();
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: CONSTANTS.SESSION_EXPIRED,
            severity: 'error',
            icon: 'ErrorIcon',
            size: 'medium',
          },
        });
      }

      dispatch({
        type: CREATE_TAG_ERROR,
        error: error.message || 'Failed to create tag',
      });

      dispatch({
        type: SHOW_SNACK_BAR,
        payload: {
          message: error.message || 'Failed to create tag',
          severity: 'error',
          icon: 'ErrorIcon',
          size: 'medium',
        },
      });
    });
};

export const clearNewTag = () => async (dispatch: ThunkDispatch<RootState, undefined, tagsActionTypes | SnackBarActionTypes>) => {
  dispatch({
    type: CLEAR_NEW_TAG,
    newTag: null,
  });
};
