import React from 'react';

export const DatabaseIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 7C17.5228 7 22 5.88071 22 4.5C22 3.11929 17.5228 2 12 2C6.47715 2 2 3.11929 2 4.5C2 5.88071 6.47715 7 12 7Z" fill="white" />
      <path
        d="M12 8.6665C6.4775 8.6665 2 7.54734 2 6.1665V11.9998C2 13.3807 6.4775 14.4998 12 14.4998C17.5225 14.4998 22 13.3807 22 11.9998V6.1665C22 7.54734 17.5225 8.6665 12 8.6665ZM4.91667 11.9998C4.22667 11.9998 3.66667 11.4398 3.66667 10.7498C3.66667 10.0598 4.22667 9.49984 4.91667 9.49984C5.60667 9.49984 6.16667 10.0598 6.16667 10.7498C6.16667 11.4398 5.60667 11.9998 4.91667 11.9998Z"
        fill="white"
      />
      <path
        d="M12 16.1665C6.4775 16.1665 2 15.0473 2 13.6665V19.4998C2 20.8807 6.4775 21.9998 12 21.9998C17.5225 21.9998 22 20.8807 22 19.4998V13.6665C22 15.0473 17.5225 16.1665 12 16.1665ZM4.91667 19.4998C4.22667 19.4998 3.66667 18.9398 3.66667 18.2498C3.66667 17.5598 4.22667 16.9998 4.91667 16.9998C5.60667 16.9998 6.16667 17.5598 6.16667 18.2498C6.16667 18.9398 5.60667 19.4998 4.91667 19.4998Z"
        fill="white"
      />
    </svg>
  );
};

export default DatabaseIcon;
