import { Interests } from '../../../models/Interests';

export interface IInterestsResponse {
  total: number;
  next: number | null;
  previous: number | null;
  data: Interests[];
}

export interface IInterestsState {
  isLoading: boolean;
  data: IInterestsResponse | null;
  interestTypes: Interests | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_INTERESTS_SUCCESS = 'FETCH_INTERESTS_SUCCESS',
  FETCH_INTERESTS_FAILURE = 'FETCH_INTERESTS_FAILURE',
  CREATE_INTERESTS_SUCCESS = 'CREATE_INTERESTS_SUCCESS',
}

export interface IInterestsPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchInterestsSuccessAction {
  type: typeof ActionTypes.FETCH_INTERESTS_SUCCESS | typeof ActionTypes.CREATE_INTERESTS_SUCCESS;
  payload: IInterestsResponse | Interests;
}

export interface IFetchInterestsRejectedAction {
  type: typeof ActionTypes.FETCH_INTERESTS_FAILURE;
  payload: string;
}

export type InterestsActionTypes = IInterestsPendingAction | IFetchInterestsSuccessAction | IFetchInterestsRejectedAction;
