import { Avatar, Grid, Typography } from '@mui/material';
import React from 'react';
import { DATA } from '../../../../store/flags/models';
interface FlagUserDialogProps {
  flag: DATA;
}

// User Dialog
function FlagUserDialog(props: FlagUserDialogProps) {
  const flag = props.flag;
  return (
    <>
      {flag.flaggedUser.avatar ? (
        <Grid sx={{ display: 'flex', flexDirection: 'column', marginTop: '0%' }}>
          <Avatar src={flag.flaggedUser.avatar} style={{ width: 200, height: 200 }} />
          <Typography sx={{ textAlign: 'center', fontSize: '20px', fontFamily: 'SFPro', justifyContent: 'center', mt: 2 }}>
            {flag.flaggedUser.nickname}
          </Typography>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

export default FlagUserDialog;
