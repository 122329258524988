/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStatistic } from '../../store/dashboard/action';
import { Series } from 'devextreme-react/chart';
import { PieChart, Legend, Label, Size, Connector } from 'devextreme-react/pie-chart';
const PChart = () => {
  const dispatch = useDispatch();
  const statistic = useSelector((state) => state?.dashboard.dashboard);
  const chartOptions = {
    palette: ['#ffcc33', '#7737ff', '#26272c'],
  };
  const ListAbonnements = [
    {
      region: Math.round(statistic.standardUsersPurcentage * 100) / 100 + '% Standard',
      val: statistic.standardUsersPurcentage,
    },
    {
      region: Math.round(statistic.goldUsersPurcentage * 100) / 100 + '% Gold',
      val: statistic.goldUsersPurcentage,
    },
    {
      region: Math.round(statistic.premiumUsersPurcentage * 100) / 100 + '% Premium',
      val: statistic.premiumUsersPurcentage,
    },
  ];
  useEffect(() => {
    dispatch(getStatistic());
  }, []);
  return (
    <>
      <PieChart id="pie" type="doughnut" innerRadius={0.7} palette={chartOptions.palette} dataSource={ListAbonnements} centerTemplate="holeTemplate">
        <Size height={150} width={370} />
        <Series valueField="" name="" argumentField="region">
          <Label visible={false} format="millions">
            <Connector visible={true} />
          </Label>
        </Series>
        <Legend margin={35} horizontalAlignment="right" verticalAlignment="center" />
      </PieChart>
    </>
  );
};

export default PChart;
