import React, { useState, useEffect } from 'react';
import { Box, Container, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { RootStyle } from '../../../pages/systemusers/SystemUser.styles';
import { useTranslation } from 'react-i18next';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '@uidotdev/usehooks';
import { AxiosResponse } from 'axios';
import { Categories } from '../../../models/Categories';
import { RootState } from '../../../store/rootStore';
import { CategoriesActionTypes, ICategoriesResponse, ICategoriesState } from '../../../store/categories/slice/Categories-slice.types';
import useSnackBar from '../../../hooks/useSnackBar';
import { categoriesApi } from '../../../store/categories/api/Categories.api';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import TableHeadCustom from '../../table/table-head/CustomTableHead';
import TableNoData from '../../table/TableNoData';
import TableLoadingData from '../../table/TableLoadingData';
import { TablePaginationActions } from '../../table/TablePaginationActions/TablePaginationActions';
import CategoriesTableRow from './categories-row/CategoriesTableRow';
import { TABLE_COLUMNS_CONFIG, initialPaginationState } from './Categories.constant';
import useTable from '../../../hooks/useTable';
import ConfirmDeleteDialog from '../../../utils/confirm-delete-dialog/ConfirmDeleteDialog';
import AddDialog from './add-dialog/AddDialog';
import CommonToolbar from '../../../utils/toolbar/CommonToolbar';

function CategoriesModule() {
  const { t } = useTranslation();
  const [filterKeyword, setfilterKeyword] = useState('');
  const [currentCategories, setCategoriesUsers] = useState<Categories | undefined>(undefined);
  const [isEyeIconClicked, setIsEyeIconClicked] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, CategoriesActionTypes>>();
  const { data, isLoading } = useSelector<RootState, ICategoriesState>((state: RootState) => state[categoriesApi.reducerPath]);
  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialPaginationState);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { alertSuccess, alertError } = useSnackBar();

  useEffect(() => {
    dispatch(
      categoriesApi.endpoints.getCategoriesUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  }, [order, orderBy, page, rowsPerPage, debouncedSearchTerm, dispatch]);

  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
    setPage(0);
  };

  const onCloseDialog = () => {
    setAddDialogOpen(false);
    setCategoriesUsers(undefined);
    setIsEyeIconClicked(false);
    setIsEdit(false);
    setfilterKeyword('');

    dispatch(
      categoriesApi.endpoints.getCategoriesUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  };

  const onOPenEditDialog = (id?: number) => {
    setIsEdit(true);
    sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.CATEGORY_USERS}/${id}`, {}, true, {})
      .then((response: AxiosResponse) => {
        const responseBody = response.data.Categories;
        const { title, translations } = responseBody;
        const userData: Categories = {
          id: id,
          title: title,
          language: 45,
          translations: translations,
        };
        setCategoriesUsers(userData);
      })
      .catch((err) => {
        console.log('error', err);
      });
    setAddDialogOpen(true);
  };

  const onCloseConfirmDeleteDialog = () => {
    setSelectedId(undefined);
    setConfirmDeleteOpen(false);
    setIsEyeIconClicked(false);
    setfilterKeyword('');
  };

  const onDeleteCategory = async () => {
    try {
      if (selectedId) {
        await dispatch(categoriesApi.endpoints.deleteCategoryUser(selectedId));
        onCloseConfirmDeleteDialog();
        dispatch(alertSuccess(t('interests.delete_success_message')));
        if (data?.next === null && data?.total <= page * rowsPerPage) {
          setPage(page - 1);
        }
        dispatch(
          categoriesApi.endpoints.getCategoriesUsers({
            page,
            limit: rowsPerPage,
            orderBy,
            order,
            keyword: debouncedSearchTerm,
          }),
        );
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
    }
  };

  const onOpenAddDialog = () => {
    setCategoriesUsers(undefined);
    setAddDialogOpen(true);
    setIsEdit(false);
  };

  const onConfirmDelete = (id?: number) => {
    setSelectedId(id);
    setConfirmDeleteOpen(true);
  };

  const isFullFilled = !isLoading && data && data?.data?.length > 0;

  return (
    <>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        onClose={onCloseConfirmDeleteDialog}
        onDelete={onDeleteCategory}
        confirm_delete_title={t('interests.confirm_delete_title')}
        confirm_delete_message={t('interests.confirm_delete_message')}
      />
      <AddDialog
        open={addDialogOpen}
        onClose={onCloseDialog}
        currentCategories={currentCategories}
        isUpdate={isEdit}
        isEyeIconClicked={isEyeIconClicked}
      />
      <RootStyle>
        <CommonToolbar
          filterKeyword={filterKeyword}
          onFilterKeyword={(value: string) => onFilterKeyword(value)}
          onOpenAddDialog={onOpenAddDialog}
          title={t('interests.title')}
          message={t('interests.add')}
        />
        <Container sx={{ minHeight: '70vh' }}>
          <div className="table-block">
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <TableHeadCustom order={order} orderBy={orderBy} columnsConfig={TABLE_COLUMNS_CONFIG} onSort={onSort} />
                <TableBody>
                  {isFullFilled &&
                    (data as ICategoriesResponse)?.data?.map((row: Categories) => {
                      return (
                        <CategoriesTableRow
                          key={row.id}
                          row={row}
                          selected={false}
                          columnsConfig={TABLE_COLUMNS_CONFIG}
                          onOPenEditDialog={onOPenEditDialog}
                          onConfirmDelete={onConfirmDelete}
                        />
                      );
                    })}
                  <TableNoData isNotFound={!isLoading && data?.data?.length === 0} title={t('interests.no_data')} />
                  <TableLoadingData isLoading={isLoading} />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {isFullFilled && (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={data ? data.total : 0}
                rowsPerPage={rowsPerPage}
                page={data && page ? page - 1 : 0}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t('common.rowsperpage')}
                sx={{
                  '& .MuiInputBase-root': {
                    maxWidth: '60px',
                  },
                }}
              />
            </Box>
          )}
        </Container>
      </RootStyle>
    </>
  );
}
export default CategoriesModule;
