/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import HeaderProperty from '../models/Table';
import { colors } from '../theme/sharedTheme';
import { CONSTANTS } from './constants';
import { formatDate, formatDateHours } from './helpers';
import { Grid } from '@mui/material';
import ArrowDropDown from '../components/svgComponents/ArrowDropDown';
import articleImage from '../assets/images/articleImage.png';
import { styled } from '@mui/material/styles';
import { Badge, BadgeProps } from '@mui/material';
import ArrowUp from '../components/svgComponents/ArrowUp';
export const getFontSize = (cell: HeaderProperty) => (cell.label == CONSTANTS.TITRE || cell.label == CONSTANTS.RAISON ? '20px' : '15px');
export const isImportantColors = (cell: HeaderProperty, row: any) => {
  if (
    row[cell.value] == CONSTANTS.OPEN ||
    row[cell.value] == CONSTANTS.OPEN_FR ||
    row[cell.value] == CONSTANTS.REPORTED ||
    row[cell.value] == CONSTANTS.REPORTED_FR ||
    (cell.label == CONSTANTS.STATUS && row[cell.value] == 0) ||
    row[cell.value] == CONSTANTS.DRAFT_FR ||
    row[cell.value] == CONSTANTS.DRAFT
  ) {
    return colors.yellow;
  }
  if (
    row[cell.value] == CONSTANTS.CLOSED ||
    row[cell.value] == CONSTANTS.CLOSED_FR ||
    row[cell.value] == CONSTANTS.TREATED ||
    row[cell.value] == CONSTANTS.TREATED_FR ||
    (cell.label == CONSTANTS.STATUS && row[cell.value] == 1) ||
    row[cell.value] == CONSTANTS.PUBLISHED_FR ||
    row[cell.value] == CONSTANTS.PUBLISHED
  ) {
    return colors.green;
  }
  if (
    cell.label == CONSTANTS.EMAIL ||
    cell.label == CONSTANTS.DATE ||
    cell.label == CONSTANTS.DATE_PUB ||
    cell.label == CONSTANTS.TYPE ||
    cell.label == CONSTANTS.User ||
    cell.label == CONSTANTS.CATEGORY ||
    cell.label == CONSTANTS.START_DATE ||
    cell.label == CONSTANTS.END_DATE ||
    cell.label == 'phoneNumber' ||
    cell.label == 'mail'
  ) {
    return colors.MAIN_GREY;
  }
  if (row[cell.label] == CONSTANTS.DELETED_FR || row[cell.value] == CONSTANTS.DELETED || cell.label == CONSTANTS.STATUS) {
    return colors.Red;
  }
  return '';
};

export const translate = (value: any) => {
  switch (value) {
    case CONSTANTS.OPEN:
      return CONSTANTS.OPEN_FR;
    case CONSTANTS.CLOSED:
      return CONSTANTS.CLOSED_FR;
    case CONSTANTS.TREATED:
      return CONSTANTS.TREATED_FR;
    case CONSTANTS.REPORTED:
      return CONSTANTS.REPORTED_FR;
    case CONSTANTS.IGNORED:
      return CONSTANTS.IGNORED_FR;
    default:
      return value;
  }
};

export const translateArticleCategory = (value: string) =>
  value === 'ARTICLE' ? CONSTANTS.ARTICLE : value === 'PODCAST' ? CONSTANTS.PODCAST : value;
export const translateOpportunityType = (value: any) =>
  value === 1 ? CONSTANTS.COLLABORATION_TYPE : value === 0 ? CONSTANTS.EXPOSITION_TYPE : value;

export const translateArticleStatus = (value: any) => {
  switch (value) {
    case CONSTANTS.DRAFT:
      return CONSTANTS.DRAFT_FR;
    case CONSTANTS.PUBLISHED:
      return CONSTANTS.PUBLISHED_FR;
    case CONSTANTS.DELETED:
      return CONSTANTS.DELETED_FR;
    default:
      return value;
  }
};

export const renderCellContent = (cell: any, value: any, showAction: any, rowId: any, row: any, isRowOpen: boolean) => {
  let cellContent;
  const date = new Date(value);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };
  switch (cell.filterBy) {
    case CONSTANTS.DATE:
      cellContent = date.toLocaleDateString(undefined, options);
      break;
    case CONSTANTS.FLAGS_DATE:
      cellContent = formatDateHours(value);
      break;
    case CONSTANTS.Image:
      cellContent = <img src={value == null ? articleImage : value} alt="" style={{ width: '48px', height: '48px', paddingTop: '6px' }} />;
      break;
    case CONSTANTS.ARTICLE_CATEGORY:
      cellContent = translateArticleCategory(value);
      break;
    case CONSTANTS.ARTICLE_STATUS:
      cellContent = translateArticleStatus(value);
      break;
    case CONSTANTS.OPPORTUNITY_TYPE:
      cellContent = translateOpportunityType(value);
      break;
    case 'nbCandidats':
      cellContent = (
        <div style={{ display: 'flex', columnGap: '45px', alignItems: 'center' }}>
          <div
            style={{
              background: value > 0 ? '#F5F5F5' : 'none',
              borderRadius: '50px',
              width: '58px',
              height: '32px',
              display: 'flex',
              justifyContent: 'center',
              columnGap: '6px',
              padding: '6px',
            }}
          >
            {value > 0 && (
              <>
                <div>{value}</div>
                {isRowOpen ? <ArrowDropDown /> : <ArrowUp />}
              </>
            )}
          </div>
          <div style={{ marginBottom: 40 }}>
            {row.numberOfUnseenApplicants > 0 && <StyledBadge badgeContent={row.numberOfUnseenApplicants}></StyledBadge>}
          </div>
        </div>
      );
      break;
    default:
      cellContent = translate(value) || '-';
  }
  return <>{cellContent}</>;
};
const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 22,
    top: 22,
    backgroundColor: '#ffcc33',
    color: colors.BLACK,
    padding: '0 4px',
  },
}));
