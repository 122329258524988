import React from 'react';

export interface ContextProps {
  bugPageClicked: boolean;
  handleClick: () => void;
  handleChangeClick: () => void;
}

export const GlobalContext = React.createContext<ContextProps | null>(null);

const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const [bugPageClicked, setBugPageClicked] = React.useState(false);
  const handleClick = () => {
    setBugPageClicked(true);
  };
  const handleChangeClick = () => {
    setBugPageClicked(false);
  };

  return (
    <GlobalContext.Provider
      value={{
        bugPageClicked,
        handleClick,
        handleChangeClick,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };
