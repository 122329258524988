export const GET_PROFILE_ATTEMPT = 'GET_PROFILE_ATTEMPT';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';
export const UPDATE_PROFILE_ATTEMPT = 'UPDATE_PROFILE_ATTEMPT';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';
export const GET_SETTINGS_ATTEMPT = 'GET_SETTINGS_ATTEMPT';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTNGS_ERROR = 'GET_SETTNGS_ERROR';
export const UPDATE_SETTING_ATTEMPT = 'UPDATE_SETTING_ATTEMPT';
export const UPDATE_SETTING_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_SETTING_ERROR = 'UPDATE_PROFILE_ERROR';
