import React from 'react';
import { Grid } from '@mui/material';
import Articles from '../../components/articles/Articles';

/**
 * Component ArtilesPage
 *
 * @component
 *
 * @example
 * return (
 *   <ArticlesPage />
 * )
 */

const ArticlesPage = () => {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Articles />
    </Grid>
  );
};

export default ArticlesPage;
