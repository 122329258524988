import React from 'react';

export const AdsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.44077 11.8264H10.1615L9.79894 10.8643L9.44077 11.8264Z" fill="white" />
      <path
        d="M14.5814 10.1953H13.4531V13.3313H14.5814C15.4737 13.3313 16.1997 12.6292 16.1997 11.7662C16.1998 10.9 15.4737 10.1953 14.5814 10.1953Z"
        fill="white"
      />
      <path
        d="M23.3165 16.3714V7.18255C23.3165 6.53049 22.7861 6 22.1342 6H2.18247C1.53049 6 1 6.53049 1 7.18255V16.3714C1 17.0235 1.53049 17.554 2.18247 17.554H22.1342C22.7861 17.554 23.3165 17.0235 23.3165 16.3714ZM11.7546 14.3491C11.7539 14.3491 11.7535 14.3491 11.753 14.3491H11.3119C11.1917 14.3491 11.084 14.2746 11.0416 14.1621L10.5346 12.8165H9.07219L8.57182 14.1609C8.52973 14.274 8.42178 14.349 8.30121 14.349H7.86083C7.76626 14.349 7.67754 14.3027 7.62356 14.2249C7.56959 14.1473 7.55713 14.048 7.59006 13.9594L9.28206 9.41354C9.32414 9.30064 9.43176 9.2257 9.55217 9.22546L10.0389 9.22447H10.0396C10.1598 9.22447 10.2674 9.29891 10.3099 9.41148L12.0127 13.9303C12.0323 13.9694 12.0435 14.0134 12.0435 14.0601C12.0435 14.2197 11.9142 14.3491 11.7546 14.3491ZM14.5814 14.3215H12.7516C12.5921 14.3215 12.4628 14.1922 12.4628 14.0327V9.49367C12.4628 9.33415 12.5921 9.20483 12.7516 9.20483H14.5814C16.0198 9.20483 17.19 10.3538 17.19 11.7661C17.1901 13.1752 16.0198 14.3215 14.5814 14.3215Z"
        fill="white"
      />
    </svg>
  );
};

export default AdsIcon;
