export const profile = {
  email: 'Email',
  title: 'Profiles',
  no_profiles_title: 'Aucun utilisateur sélectionnée',
  no_profiles_description: 'Veuillez sélectionner un utilisateur pour afficher sa liste profils',
  mainProfile: {
    surname: {
      require_error_message: `Le nom de famille est requis`,
      invalid_error_message: 'Le nom de famille est invalide',
    },
    name: {
      require_error_message: `Le nom est requis`,
      invalid_error_message: 'Le nom est invalide',
    },
    mail: {
      require_error_message: `Le de l'adresse e-mail est obligatoire`,
      invalid_error_message: `Le d'adresse e-mail n'est pas valide`,
    },
    zip: {
      invalid_error_message: `Le code postal est invalide`,
      require_error_message: 'Le code postal est requis',
      min_require_error_message: 'Le code postal doit contenir au moins 5 caractéres',
    },
    city: {
      invalid_error_message: 'La ville est invalide',
      require_error_message: 'La ville est requise',
    },
    address: "L'adresse est requise",
    countryId: 'Le pays est requis',
    birthdate: {
      max_error_message: 'La date de naissance ne peut pas être dans le futur',
      require_error_message: 'La date de naissance est requise',
    },
  },
};
