/* eslint-disable @typescript-eslint/no-explicit-any */
import { Welcome } from '../../../models/Welcome';
import {
  ActionTypes,
  IFetchWelcomeVideoRejectedAction,
  IFetchWelcomeVideoSuccessAction,
  IWelcomeVideoPendingAction,
  IWelcomeVideoResponse,
  IWelcomeVideoState,
  WelcomeVideoActionTypes,
} from './WelcomeVideo-slice.types';

export const initialState: IWelcomeVideoState = {
  isLoading: false,
  error: null,
  data: null,
  welcomeData: null,
};

export const welcomeVideoReducer = (state = initialState, action: WelcomeVideoActionTypes): IWelcomeVideoState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_WELCOME_VIDEO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload as IWelcomeVideoResponse,
      };
    case ActionTypes.FETCH_WELCOME_VIDEO_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_WELCOME_VIDEO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        welcomeData: action.payload as Welcome,
      };
    default:
      return state;
  }
};

const matchPending = (): IWelcomeVideoPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (data: IWelcomeVideoResponse, action: 'fetch' | 'create' | 'delete' | 'update'): IFetchWelcomeVideoSuccessAction | Welcome => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_WELCOME_VIDEO_SUCCESS : ActionTypes.CREATE_WELCOME_VIDEO_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchWelcomeVideoRejectedAction => {
  return {
    type: ActionTypes.FETCH_WELCOME_VIDEO_FAILURE,
    payload: error,
  };
};

export const welcomeVideoActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
