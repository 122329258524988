import * as Yup from 'yup';

export const CreateUniversitySchema = Yup.object().shape({
  name: Yup.string().required('university.form_fields.name.required_error_message'),
  address: Yup.string().required('university.form_fields.address.required_error_message'),
  phoneNumber: Yup.string()
    .required('university.form_fields.phone_number.required_error_message')
    .min(7, 'university.form_fields.phone_number.invalid_error_message')
    .max(16, 'university.form_fields.phone_number.invalid_error_message'),
  email: Yup.string().required('university.form_fields.email.required_error_message').email('university.form_fields.email.invalid_error_message'),
});
