/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface AdminVideoControlProps {
  largeImgUrl: string;
  closeImgPopup: () => void;
  openImgModel: boolean;
}

const FullScreenModal: React.FC<AdminVideoControlProps> = ({ openImgModel, closeImgPopup, largeImgUrl }: AdminVideoControlProps) => {
  return (
    <>
      <Dialog open={openImgModel} onClose={closeImgPopup} fullWidth keepMounted>
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton onClick={closeImgPopup}>
              <CloseIcon color="primary" fontSize="large" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent style={{ paddingTop: '20px', textAlign: 'center', overflow: 'hidden' }}>
          <img src={largeImgUrl} alt="Full Screen" width={'30%'} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FullScreenModal;
