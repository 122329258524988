/* eslint-disable @typescript-eslint/no-explicit-any*/
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, ButtonBase, InputBase, InputLabel, Paper, Typography } from '@mui/material';
import { theme } from '../../../theme/theme';
import { ChangeEvent, useState } from 'react';
import { PATHS, isEmail, CONSTANTS } from '../../../utils';
import { useLogin } from '../../../store/authentication/actions';
import logo from '../../../assets/images/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/rootStore';
import Spinner from '../../../components/spinner';
import { validations } from '../../../locales/fr/validation';
import { ThunkDispatch } from 'redux-thunk';
import { AuthActionTypes } from '../../../store/authentication/models';
// import { useCookies } from 'react-cookie';

interface InputProps<T> {
  value: T;
  error: string | null;
}
function Login() {
  const { login } = useLogin();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AuthActionTypes>>();
  const loginState = useSelector((state: RootState) => state.auth.login);
  const { loader } = loginState;
  const [email, setEmail] = useState<InputProps<string>>({
    value: '',
    error: null,
  });

  const [password, setPassword] = useState<InputProps<string>>({
    value: '',
    error: null,
  });

  // const [cookies, setCookie] = useCookies([CONSTANTS.ADMIN_DATA]);
  // const [remember, setRemember] = useState(false);

  // useEffect(() => {
  //   if (cookies.adminEmail && cookies.adminPassword && cookies.rememberStatus) {
  //     setEmail({ value: cookies.adminEmail, error: null });
  //     setPassword({ value: cookies.adminPassword, error: null });
  //     setRemember(cookies.rememberStatus);
  //   }
  // }, [cookies]);

  const handleOnEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail({
      ...email,
      value: e.target.value,
      error: null,
    });
  };

  const handleOnPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword({
      ...password,
      value: e.target.value,
      error: null,
    });
  };
  const onClick = () => {
    if (email.value === '') setEmail({ ...email, error: validations.required });
    if (email.value !== '' && !isEmail(email.value)) setEmail({ ...email, error: validations.email });
    if (password.value === '') setPassword({ ...password, error: validations.required });
    if (password.value !== '' && password.value.length < CONSTANTS.MIN_PASSWORD_LENGTH)
      setPassword({
        ...password,
        error: validations.authInvalid,
      });
    if (isEmail(email.value) && email.value !== '' && password.value !== '' && password.value.length >= CONSTANTS.MIN_PASSWORD_LENGTH) {
      dispatch(
        login({
          mail: email.value,
          password: password.value,
        }),
      );

      // if (remember) {
      //   setCookie('adminEmail', email.value, {
      //     path: PATHS.Dashboard,
      //     expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      //   });
      //   setCookie('adminPassword', password.value, {
      //     path: PATHS.Dashboard,
      //     expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      //   });
      // }
    }
  };

  // useEffect(() => {
  //   setCookie('rememberStatus', remember, {
  //     path: PATHS.Dashboard,
  //     expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
  //   });
  // }, [remember]);

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    onClick();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        width: '100vw',
        height: '100vh',
        bgcolor: theme.palette.primary.main,
        color: 'white',
      }}
    >
      <Box
        component="img"
        sx={{
          width: '156.6px',
          height: '70px',
          marginBottom: '64px',
        }}
        alt="Your logo."
        src={logo}
      />
      <Paper
        sx={{
          width: '500px',
          height: '371px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '24px 24px 32px',
          bgcolor: theme.palette.secondary.main,
        }}
        variant="outlined"
        square
      >
        <Typography
          variant="caption"
          sx={{
            width: '145px',
            height: '29px',
            fontFamily: 'SFPro-Regular',
            fontSize: '24px',
            textAlign: 'left',
            color: theme.palette.primary.light,
          }}
        >
          CONNEXION
        </Typography>
        <Box
          sx={{
            width: '343px',
            display: 'grid',
            gridAutoFlow: 'column',
          }}
        >
          <form noValidate>
            <Box
              sx={{
                width: '343px',
                height: '69px',
                marginBottom: '16px',
              }}
            >
              <InputLabel htmlFor="email">Adresse e-mail</InputLabel>
              <InputBase id="email" autoComplete="email" value={email.value} onChange={handleOnEmailChange} />
              <Typography
                variant="inherit"
                my={'1px'}
                sx={{
                  width: '100%',
                  height: '29px',
                  fontFamily: 'SFPro-Regular',
                  fontSize: '12px',
                  color: '#ff453a',
                  textAlign: 'left',
                }}
              >
                {email.error}
              </Typography>
            </Box>

            <Box
              sx={{
                width: '343px',
                height: '69px',
              }}
            >
              <InputLabel htmlFor="password">Mot de passe</InputLabel>
              <InputBase id="password" autoComplete="current-password" value={password.value} type="password" onChange={handleOnPasswordChange} />
              <Typography
                variant="inherit"
                my={'1px'}
                sx={{
                  width: '100%',
                  height: '29px',
                  fontFamily: 'SFPro-Regular',
                  fontSize: '12px',
                  color: '#ff453a',
                  textAlign: 'left',
                }}
              >
                {password.error}
              </Typography>
            </Box>
          </form>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              checked={remember}
              onChange={() => {
                setRemember((prevState: any) => !prevState);
              }}
              className="h-4 w-4 text-sky focus:ring-sky border-gray-300 rounded cursor-pointer"
            />
            <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
              Souviens-toi de moi
            </label>
          </Box> */}
        </Box>
        <Typography
          variant="body2"
          align="left"
          sx={{ textDecoration: 'none', cursor: 'pointer', color: '#4182EB', fontWeight: '600' }}
          component={Link}
          to={PATHS.forgotpassword}
        >
          Mot de passe oublié?
        </Typography>
        <ButtonBase
          disabled={loader}
          sx={{
            width: '344px',
            height: '52px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#ffcc33',
            fontFamily: 'SFPro-Regular',
            fontSize: '17px',
            gap: '10px',
            padding: '16px 24px',
            opacity: loader ? 0.5 : 1,
          }}
          onClick={(e: React.FormEvent) => {
            submitForm(e);
          }}
        >
          Se connecter
        </ButtonBase>
      </Paper>
      <Spinner loading={loader} />
    </Box>
  );
}

export default Login;
