/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  InputBase,
  InputLabel,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fr } from 'date-fns/locale';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { config } from '../../../config/config';
import { AddArticle, UpdateArticle } from '../../../store/articles/action';
import { Article, ArticlesActionTypes } from '../../../store/articles/models';
import { AddOpportunity, UpdateOpportunity } from '../../../store/opportunities/action';
import { Opportunity } from '../../../store/opportunities/models';
import { PaginationActionTypes } from '../../../store/pagination/models';
import { RootState } from '../../../store/rootStore';
import { colors } from '../../../theme/sharedTheme';
import { CONSTANTS, formatDate, getLengthWysiwygText, isValidUrl } from '../../../utils';
import AddPhotoIcon from '../../svgComponents/AddPhotoIcon';
import DateIcon from '../../svgComponents/DateIcon';
import { TinyMceEditor } from '../../tinyMceEditor/TinyMceEditor';
import { ArticleDialogHeader } from '../dialogHeader/articleDialogHeader';
import SaveChangesModal from '../saveChangesModal/SaveChangesModal';
interface DetailsDialogProps {
  openSecondModal: boolean;
  closeSecondDialog: () => void;
  moduleName?: string;
  data?: any;
  isUpdate?: boolean;
  setData?: any;
  hasData?: boolean;
  closeDialog: () => void;
  saveChangesHandler?: any;
  changeSize: (value: number) => void;
  size: number;
  authorImageSize: number;
  changeAuthorImageSize: (value: number) => void;
}
const errorsMessages = {
  title: '',
  imageUrl: '',
  authorImageUrl: '',
  author: '',
  description: '',
  startDate: '',
  endDate: '',
  podcastUrl: '',
  articleUrl: '',
};

export const DetailsDialog = (props: DetailsDialogProps) => {
  const {
    openSecondModal,
    authorImageSize,
    closeSecondDialog,
    moduleName,
    closeDialog,
    data,
    isUpdate,
    setData,
    saveChangesHandler,
    size,
    changeSize,
    changeAuthorImageSize,
  } = props;
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, ArticlesActionTypes | PaginationActionTypes>>();
  const { t } = useTranslation();
  const [isClickedOutside, setIsClickedOutside] = React.useState(false);
  const [errors, setErrors] = React.useState(errorsMessages);
  const [hasData, setHasData] = React.useState(false);
  const modalRef = React.useRef<HTMLDivElement>(null);
  const opportunity = useSelector((state: RootState) => state.opportunities.opportunity);
  const article = useSelector((state: RootState) => state.articles.article);
  const dataById: Opportunity | Article = moduleName === CONSTANTS.ARTICLE ? article : opportunity;
  const httpsRegexs = /^https?:\/\//; // Checks if the URL starts with 'https://'

  const isValidArticleUrl = (url: string): boolean => {
    // const httpsRegex = /^https:\/\//;
    const httpsRegex = /^https?:\/\//; // Checks if the URL starts with 'http://' or 'https://'

    const wwwRegex = /www\./;
    return httpsRegex.test(url) && !wwwRegex.test(url);
  };

  const validateForm = () => {
    const { title, author, imageUrl, authorImageUrl, description, numChar, startDate, endDate, podcastUrl, articleUrl } = data;
    const newErrors = {
      title: '',
      author: '',
      imageUrl: '',
      authorImageUrl: '',
      description: '',
      startDate: '',
      endDate: '',
      podcastUrl: '',
      articleUrl: '',
    };
    const maxSize = config.FILE_MAX_SIZE;
    if (!title.trim()) {
      newErrors.title = t('validations.required_title');
    }

    if (moduleName == CONSTANTS.ARTICLE && !author.trim()) {
      newErrors.author = t('validations.required_author_name');
    }

    if (!imageUrl) {
      newErrors.imageUrl = t('validations.required_image');
    }
    if (moduleName == CONSTANTS.ARTICLE && !authorImageUrl) {
      newErrors.authorImageUrl = t('validations.required_author_image');
    }

    if (!description.trim()) {
      newErrors.description = t('validations.required_description');
    }
    if (!startDate && moduleName == CONSTANTS.Opportunities) {
      newErrors.startDate = t('validations.required_startDate');
    }
    if (!endDate && moduleName == CONSTANTS.Opportunities) {
      newErrors.endDate = t('validations.required_endDate');
    }
    if (formatDate(startDate) > formatDate(endDate) && moduleName == CONSTANTS.Opportunities) {
      newErrors.startDate = t('validations.date');
    }
    if (description !== '' && numChar > 3000) {
      newErrors.description = t('validations.max_character_limit');
    }
    if (!podcastUrl && data.type == '0' && moduleName == CONSTANTS.ARTICLE) {
      newErrors.podcastUrl = t('validations.required_podcast_url');
    }
    if (!articleUrl && data.type == '1' && moduleName == CONSTANTS.ARTICLE) {
      newErrors.articleUrl = t('validations.required_article_url');
    }
    if (!articleUrl && data.type == '1' && !isValidArticleUrl(articleUrl) && moduleName == CONSTANTS.ARTICLE) {
      newErrors.articleUrl = t('validations.invalid_url');
    } else if (articleUrl && !httpsRegexs.test(articleUrl)) {
      newErrors.articleUrl = t('validations.invalid_url');
    }

    const sizeInMegabytes = size / (1024 * 1024);

    if (maxSize !== undefined && sizeInMegabytes > 2) {
      newErrors.imageUrl = t('validations.size_image_max');
    }

    if (maxSize !== undefined && authorImageSize / (1024 * 1024) > 2) {
      newErrors.authorImageUrl = t('validations.size_image_max');
    }
    if (podcastUrl !== '' && podcastUrl !== null && !isValidUrl(podcastUrl) && moduleName == CONSTANTS.ARTICLE) {
      newErrors.podcastUrl = t('validations.invalid_url');
    }
    setErrors(newErrors);
    // Return true if there are no errors, false otherwise
    return !Object.values(newErrors).some((value) => value !== '');
  };
  const onClose = () => {
    closeSecondDialog();
    setErrors(errorsMessages);
    changeSize(0);
    changeAuthorImageSize(0);
  };
  const onSubmitHandler = () => {
    const isValid = validateForm();
    if (isValid) {
      if (isUpdate) {
        switch (moduleName) {
          case CONSTANTS.Opportunities:
            dispatch(UpdateOpportunity(data));
            break;
          case CONSTANTS.ARTICLE:
            dispatch(UpdateArticle(data));
            break;
        }
      } else {
        switch (moduleName) {
          case CONSTANTS.Opportunities:
            dispatch(AddOpportunity(data));
            break;
          case CONSTANTS.ARTICLE:
            dispatch(AddArticle(data, false));
            break;
        }
      }
      onClose();
      closeDialog();
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setIsClickedOutside(true);
    }
  };
  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isClickedOutside]);
  React.useEffect(() => {
    if (isUpdate && dataById) {
      const normalizedCategory = dataById?.category === CONSTANTS.PODCAST_TO_SEND ? '0' : '1';
      setData({
        ...data,
        id: dataById.id,
        numChar: getLengthWysiwygText(dataById?.description),
        description: dataById.description,
        title: dataById.title,
        author: dataById.author,
        imageUrl: dataById.minImage,
        authorImageUrl: dataById.authorMinImage,
        type: dataById?.type?.toString() || normalizedCategory,
        status: dataById.status,
        startDate: dataById.startDate,
        endDate: dataById.endDate,
        datePub: dataById.createdAt,
        podcastUrl: dataById.podcastUrl,
        articleUrl: dataById.articleUrl,
      });
    }
  }, [isUpdate, dataById]);

  const onChange = async (event: any, field: string | number) => {
    if (field == 'authorImageUrl') {
      const sizeFile = event.target.files[0]?.size;
      changeAuthorImageSize(sizeFile);
      const newData = data;
      newData.authorImage = event.target.files[0];
      newData.authorImageUrl = URL.createObjectURL(event.target.files[0]);
      event.target.value = '';
      setData(newData);
    } else if (field == 'imageUrl') {
      const sizeFile = event.target.files[0]?.size;
      changeSize(sizeFile);
      const newData = data;
      newData.file = event.target.files[0];
      newData.imageUrl = URL.createObjectURL(event.target.files[0]);
      event.target.value = '';
      setData(newData);
    } else if (field == 'startDate' || field == 'endDate') {
      setData({ ...data, [field]: event });
    } else if (field == 'title') {
      if (event.target.value.length <= 47) setData({ ...data, [field]: event.target.value });
    } else if (field == 'author') {
      if (event.target.value.length <= 47) setData({ ...data, [field]: event.target.value });
    } else {
      setData({ ...data, [field]: event.target.value });
    }
    // if (field == 'type' && data.type == '0' && data.podcastUrl != '') {
    //   setData({ ...data, podcastUrl: '' });
    // }
    // if (isAnyFieldNotEmpty(data.title, data.description, data.imageUrl)) setHasData(true);
    setErrors({ ...errors, [field]: '' });
  };
  return (
    <>
      <Dialog
        open={openSecondModal}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '1000px',
              minWidth: '1000px',
              borderRadius: 0,
            },
          },
        }}
      >
        <div ref={modalRef}>
          <ArticleDialogHeader onClose={onClose} data={data} moduleName={moduleName} onSubmitHandler={onSubmitHandler} isUpdate={isUpdate} />
          <DialogContent sx={{ backgroundColor: '#f5f5f0' }}>
            <form id="createForm">
              <Grid container direction="column" justifyContent="space-between" spacing={7}>
                <Grid item container xs={12} direction="row">
                  <Grid xs={4} sx={{ ml: 4 }}>
                    <Typography variant="h6">{'Photo'}</Typography>
                    <Typography style={{ color: '#A4A4A5', fontFamily: 'SF Pro', fontSize: '14px' }}>{t('articles.formats')}</Typography>
                    <Typography style={{ color: '#A4A4A5', fontFamily: 'SF Pro', fontSize: '14px' }}>{t('articles.max_size')}</Typography>
                  </Grid>
                  <Grid style={{ width: '180.5px', height: 'auto', display: 'flex', flexDirection: 'column' }}>
                    <Grid style={{ width: '180.5px', height: '180.5px', position: 'relative', border: '1px dashed #A4A4A5' }}>
                      {data.imageUrl !== '' && <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={data.imageUrl}></img>}
                      <Grid sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        {!data.imageUrl && <AddPhotoIcon />}
                      </Grid>
                    </Grid>
                    <Grid
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: '6px',
                      }}
                    >
                      <Button
                        style={{
                          width: '211px',
                          height: '46px',
                          border: 'none',
                          background: colors.WHITE,
                          color: colors.BLACK,
                          textTransform: 'capitalize',
                          boxShadow: 'inset 0px -1px 0px #E7E7E7',
                        }}
                        variant="contained"
                        component="label"
                      >
                        {t('articles.upload')}
                        <input type="file" accept="image/png, image/jpeg" hidden onChange={(event: any) => onChange(event, 'imageUrl')} />
                      </Button>
                    </Grid>
                    <Grid>
                      {errors?.imageUrl && (
                        <Typography
                          variant="caption"
                          sx={{
                            color: colors.Red,
                            marginBottom: '7px',
                            width: '100%',
                            height: '29px',
                            fontFamily: 'SFPro-Regular',
                            fontSize: '12px',
                            textAlign: 'left',
                          }}
                        >
                          {errors?.imageUrl}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 1, ml: 'auto', width: '95%' }} />
                <Grid item container direction="row" xs={12} sx={{ mt: -7 }}>
                  <Grid xs={4} sx={{ ml: 4 }}>
                    <Typography variant="h6">Description</Typography>
                  </Grid>
                  <Grid item container direction="column" xs={7}>
                    <Grid item sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                      <InputLabel htmlFor="title">{t('common.title')}</InputLabel>
                    </Grid>
                    <Grid>
                      <InputBase
                        sx={{ width: '100%' }}
                        placeholder={moduleName == CONSTANTS.ARTICLE ? "Titre de l'article" : "Titre de l'opportunité"}
                        value={data.title}
                        onChange={(event: any) => onChange(event, 'title')}
                      />
                    </Grid>
                    <Grid>
                      {errors?.title && (
                        <Typography
                          variant="caption"
                          sx={{
                            color: colors.Red,
                            marginBottom: '7px',
                            width: '100%',
                            height: '29px',
                            fontFamily: 'SFPro-Regular',
                            fontSize: '12px',
                            textAlign: 'left',
                          }}
                        >
                          {errors?.title}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                      <InputLabel htmlFor="title">
                        {moduleName == CONSTANTS.ARTICLE ? t('articles.content') : t('opportunities.description')}
                      </InputLabel>
                      <InputLabel htmlFor="title">{data.numChar + '/ 3000'}</InputLabel>
                    </Grid>
                    <Grid>
                      <TinyMceEditor
                        setData={setData}
                        errors={errors}
                        setErrors={setErrors}
                        data={data}
                        isUpdate={isUpdate}
                        moduleName={moduleName}
                      />
                    </Grid>
                    <Grid>
                      {errors?.description && (
                        <Typography
                          variant="caption"
                          sx={{
                            color: colors.Red,
                            marginBottom: '7px',
                            width: '100%',
                            height: '29px',
                            fontFamily: 'SFPro-Regular',
                            fontSize: '12px',
                            textAlign: 'left',
                          }}
                        >
                          {errors?.description}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 1, ml: 'auto', width: '95%' }} />
                <Grid item container xs={12} sx={{ mt: -4 }}>
                  <Grid xs={4}>
                    <Typography variant="h6" sx={{ ml: 4 }}>
                      {moduleName == CONSTANTS.ARTICLE ? t('articles.category') : t('opportunities.type')}
                    </Typography>
                  </Grid>
                  <Grid container xs={7} sx={{ ml: 4 }} display="flex" direction="column" justifyContent="space-between" spacing={1}>
                    <Grid item sx={{ display: 'flex' }} style={{ paddingLeft: 0 }}>
                      <InputLabel htmlFor="type"> {moduleName == CONSTANTS.ARTICLE ? t('articles.category') : t('opportunities.type')}</InputLabel>
                    </Grid>
                    <Grid item sx={{ display: 'flex' }} style={{ paddingLeft: 0 }}>
                      <ToggleButtonGroup fullWidth exclusive value={data.type} onChange={(event: any) => onChange(event, 'type')}>
                        <ToggleButton id="type" sx={{ textTransform: 'none' }} disabled={data.type === '1' ? true : false} value="1">
                          {moduleName == CONSTANTS.ARTICLE ? 'Article' : 'Collaboration'}
                        </ToggleButton>
                        <ToggleButton id="type" sx={{ textTransform: 'none' }} disabled={data.type === '0' ? false : false} value="0">
                          {moduleName == CONSTANTS.ARTICLE ? 'Podcast' : 'Exposition'}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                    {moduleName == CONSTANTS.ARTICLE && (
                      <>
                        <Grid item sx={{ display: 'flex' }} style={{ paddingLeft: 0 }}>
                          <InputLabel htmlFor="type"> {data.type === '0' ? t('articles.podcast_url') : t('articles.article_url')}</InputLabel>
                        </Grid>
                        <Grid item sx={{ display: 'flex' }} style={{ paddingLeft: 0 }}>
                          <InputBase
                            sx={{ width: '100%' }}
                            placeholder={data.type === '0' ? t('articles.podcast_url') : t('articles.article_url')}
                            value={data.type === '0' ? data.podcastUrl : data.articleUrl}
                            onChange={(event: any) => onChange(event, data.type === '0' ? 'podcastUrl' : 'articleUrl')}
                          />
                        </Grid>
                        <Grid>
                          {data.type === '0' && errors?.podcastUrl && (
                            <Typography
                              variant="caption"
                              sx={{
                                color: colors.Red,
                                marginBottom: '7px',
                                width: '100%',
                                height: '29px',
                                fontFamily: 'SFPro-Regular',
                                fontSize: '12px',
                                textAlign: 'left',
                              }}
                            >
                              {errors?.podcastUrl}
                            </Typography>
                          )}
                          {data.type === '1' && errors?.articleUrl && (
                            <Typography
                              variant="caption"
                              sx={{
                                color: colors.Red,
                                marginBottom: '7px',
                                width: '100%',
                                height: '29px',
                                fontFamily: 'SFPro-Regular',
                                fontSize: '12px',
                                textAlign: 'left',
                              }}
                            >
                              {errors?.articleUrl}
                            </Typography>
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                {moduleName == CONSTANTS.ARTICLE && (
                  <>
                    <Divider sx={{ mt: 1, ml: 'auto', width: '95%' }} />
                    <Grid item container xs={12} sx={{ mt: -4 }}>
                      <Grid xs={4}>
                        <Typography variant="h6" sx={{ ml: 4 }}>
                          {t('articles.author')}
                        </Typography>
                      </Grid>
                      <Grid container xs={7} sx={{ ml: 4 }} display="flex" direction="column" justifyContent="space-between" spacing={1}>
                        <Grid item sx={{ display: 'flex' }} style={{ paddingLeft: 0 }}>
                          <InputLabel htmlFor="type"> {t('articles.author')}</InputLabel>
                        </Grid>
                        <Grid>
                          <InputBase
                            sx={{ width: '100%' }}
                            placeholder={data.type === '1' ? "Auteur de l'article" : 'Auteur de podcast'}
                            value={data.author}
                            onChange={(event: any) => onChange(event, 'author')}
                          />
                        </Grid>
                        <Grid>
                          {errors?.author && (
                            <Typography
                              variant="caption"
                              sx={{
                                color: colors.Red,
                                marginBottom: '7px',
                                width: '100%',
                                height: '29px',
                                fontFamily: 'SFPro-Regular',
                                fontSize: '12px',
                                textAlign: 'left',
                              }}
                            >
                              {errors?.author}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 1, ml: 'auto', width: '95%' }} />
                    <Grid item container xs={12} direction="row">
                      <Grid xs={4} sx={{ ml: 4 }}>
                        <Typography variant="h6">{t('articles.author_image')}</Typography>
                        <Typography style={{ color: '#A4A4A5', fontFamily: 'SF Pro', fontSize: '14px' }}>{t('articles.formats')}</Typography>
                        <Typography style={{ color: '#A4A4A5', fontFamily: 'SF Pro', fontSize: '14px' }}>{t('articles.max_size')}</Typography>
                      </Grid>
                      <Grid style={{ width: '180.5px', height: 'auto', display: 'flex', flexDirection: 'column' }}>
                        <Grid style={{ width: '180.5px', height: '180.5px', position: 'relative', border: '1px dashed #A4A4A5' }}>
                          {data.authorImageUrl !== '' && (
                            <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={data.authorImageUrl}></img>
                          )}
                          <Grid sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            {!data.authorImageUrl && <AddPhotoIcon />}
                          </Grid>
                        </Grid>
                        <Grid
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: '6px',
                          }}
                        >
                          <Button
                            style={{
                              width: '211px',
                              height: '46px',
                              border: 'none',
                              background: colors.WHITE,
                              color: colors.BLACK,
                              textTransform: 'capitalize',
                              boxShadow: 'inset 0px -1px 0px #E7E7E7',
                            }}
                            variant="contained"
                            component="label"
                          >
                            {t('articles.upload')}
                            <input type="file" accept="image/png, image/jpeg" hidden onChange={(event: any) => onChange(event, 'authorImageUrl')} />
                          </Button>
                        </Grid>
                        <Grid>
                          {errors?.authorImageUrl && (
                            <Typography
                              variant="caption"
                              sx={{
                                color: colors.Red,
                                marginBottom: '7px',
                                width: '100%',
                                height: '29px',
                                fontFamily: 'SFPro-Regular',
                                fontSize: '12px',
                                textAlign: 'left',
                              }}
                            >
                              {errors?.authorImageUrl}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                {moduleName == CONSTANTS.ARTICLE && isUpdate && (
                  <Grid item container xs={12} sx={{ mt: -4 }}>
                    <Divider sx={{ mt: -3, ml: 'auto', width: '100%' }} />
                    <Grid xs={4}>
                      <Typography variant="h6" sx={{ ml: 4 }}>
                        {t('common.Status')}
                      </Typography>
                    </Grid>
                    <Grid container xs={7} sx={{ ml: 4 }} display="flex" justifyContent="space-between" spacing={1}>
                      <Grid item direction="column" sx={{ display: 'flex', mt: 1 }}>
                        <InputLabel htmlFor="type">{t('common.Status')}</InputLabel>
                        <Typography
                          style={{
                            color: data.status == CONSTANTS.DRAFT ? colors.yellow : data.status == CONSTANTS.PUBLISHED ? colors.green : colors.Red,
                          }}
                        >
                          {data.status == CONSTANTS.DRAFT
                            ? CONSTANTS.DRAFT_FR
                            : data.status == CONSTANTS.PUBLISHED
                            ? CONSTANTS.PUBLISHED_FR
                            : data.status == CONSTANTS.DELETED
                            ? CONSTANTS.DELETED_FR
                            : ''}
                        </Typography>
                        {data.status !== CONSTANTS.DRAFT && data.datePub && (
                          <Grid item direction="column" sx={{ display: 'flex', mt: 1 }}>
                            <InputLabel htmlFor="type">{t('common.publication_date')}</InputLabel>
                            <Typography sx={{ fontStyle: 'normal', fontSize: '17px' }}>{formatDate(data.datePub)}</Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {moduleName == CONSTANTS.Opportunities && (
                  <Grid item container xs={12} sx={{ mt: -5 }}>
                    <Divider sx={{ mb: 2, ml: 'auto', width: '100%' }} />
                    <Grid xs={4}>
                      <Typography variant="h6" sx={{ ml: 4 }}>
                        {CONSTANTS.PERIODE}
                      </Typography>
                    </Grid>
                    <Grid container xs={7} sx={{ ml: 4 }} display="flex" justifyContent="space-between">
                      <Grid item>
                        <InputLabel htmlFor="startDate">Date de début</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                          <DatePicker
                            disablePast
                            inputFormat="dd/MM/yyyy"
                            components={{ OpenPickerIcon: DateIcon }}
                            value={data.startDate}
                            onChange={(event: any) => onChange(event, 'startDate')}
                            renderInput={({ inputRef, inputProps, InputProps }) => (
                              <Stack
                                direction="row"
                                alignItems="center"
                                p={2}
                                sx={{
                                  width: 'fit-content',
                                  border: 'solid 1px #e7e7e7',
                                  backgroundColor: '#ffffff',
                                  input: {
                                    border: 'none',
                                  },
                                }}
                              >
                                <input
                                  style={{ border: 'none', backgroundColor: '#FFFFFF', height: '20px', fontSize: '17px', width: '140px' }}
                                  ref={inputRef}
                                  {...inputProps}
                                />
                                {InputProps?.endAdornment}
                              </Stack>
                            )}
                          />
                        </LocalizationProvider>
                        {errors?.startDate && (
                          <Typography
                            variant="caption"
                            sx={{
                              color: colors.Red,
                              marginBottom: '7px',
                              width: '100%',
                              height: '29px',
                              fontFamily: 'SFPro-Regular',
                              fontSize: '12px',
                              textAlign: 'left',
                            }}
                          >
                            {errors?.startDate}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item>
                        <InputLabel htmlFor="endDate">Date de fin</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                          <DatePicker
                            disablePast
                            inputFormat="dd/MM/yyyy"
                            components={{ OpenPickerIcon: DateIcon }}
                            value={data.endDate}
                            onChange={(event: any) => onChange(event, 'endDate')}
                            disabled={!data.startDate}
                            minDate={data.startDate}
                            renderInput={({ inputRef, inputProps, InputProps }) => (
                              <Stack
                                direction="row"
                                alignItems="center"
                                p={2}
                                sx={{
                                  width: 'fit-content',
                                  border: 'solid 1px #e7e7e7',
                                  backgroundColor: '#ffffff',
                                  input: {
                                    border: 'none',
                                  },
                                }}
                              >
                                <input
                                  style={{ border: 'none', backgroundColor: '#FFFFFF', height: '20px', fontSize: '17px', width: '140px' }}
                                  ref={inputRef}
                                  {...inputProps}
                                  disabled
                                />
                                {InputProps?.endAdornment}
                              </Stack>
                            )}
                          />
                        </LocalizationProvider>
                        {errors?.endDate && (
                          <Typography
                            variant="caption"
                            sx={{
                              color: colors.Red,
                              marginBottom: '7px',
                              width: '100%',
                              height: '29px',
                              fontFamily: 'SFPro-Regular',
                              fontSize: '12px',
                              textAlign: 'left',
                            }}
                          >
                            {errors?.endDate}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </form>
          </DialogContent>
        </div>
      </Dialog>
      {isClickedOutside && !isUpdate && moduleName === CONSTANTS.ARTICLE && (
        <SaveChangesModal
          openSaveChangesModal={isClickedOutside}
          setOpenSaveChangesModal={setIsClickedOutside}
          cancelNavigation={closeDialog}
          saveChangesHandler={saveChangesHandler}
        />
      )}
    </>
  );
};

export default DetailsDialog;
