/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */

import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
} from '@mui/material';
// components
import { useDebounce } from '@uidotdev/usehooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableLoadingData from '../../../components/table/TableLoadingData';
import TableNoData from '../../../components/table/TableNoData';
import CustomTableHead from '../../../components/table/table-head/CustomTableHead';
import useTable from '../../../hooks/useTable';
import useTabs from '../../../hooks/useTabs';
import { University } from '../../../models/University';
import useGetUniversityDetailsQuery from '../hooks/useGetUniversityDetailsQuery';
import UniversityUsersToolbar from './UniversityUsersToolbar';
import { UNIVERSITY_USERS_TABLE_HEAD, USERS_TYPE_OPTIONS, initialUniversityUsersPagination } from './university-users.constants';

// ----------------------------------------------------------------------

interface UniversityUsersProps {
  university?: University;
}

export default function UniversityUsers({ university }: UniversityUsersProps) {
  const { currentTab: filterType, onChangeTab: onChangeFilterType } = useTabs('all');
  const { t } = useTranslation();
  const [filterKeyword, setFilterKeyword] = useState<string>('');

  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialUniversityUsersPagination);

  const debouncedSearchTerm = useDebounce(filterKeyword, 500);

  const { data, error, total, isLoading, isFullFilled } = useGetUniversityDetailsQuery(
    filterType,
    {
      page,
      itemsPerPage: rowsPerPage,
      orderBy,
      order,
      keyword: debouncedSearchTerm,
    },
    university?.id,
  );

  const onFilterKeyword = (keyword: string) => {
    setFilterKeyword(keyword);
    setPage(0);
  };

  return (
    <Card sx={{ borderRadius: '0px' }}>
      <CardHeader title={t('university.users_section_title')} />
      <Container maxWidth={'lg'}>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={filterType}
          onChange={onChangeFilterType}
          sx={{ px: 2, bgcolor: 'background.neutral' }}
        >
          {USERS_TYPE_OPTIONS.map((tab) => (
            <Tab disableRipple key={tab} label={t(`university.users_filter.${tab}`)} value={tab} />
          ))}
        </Tabs>
        <Divider />
        <UniversityUsersToolbar keyWord={filterKeyword} onChangeKeyWord={onFilterKeyword} />
        <Box sx={{ flexGrow: 1, height: '100%' }}>
          <div className="table-block">
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <CustomTableHead order={order} orderBy={orderBy} columnsConfig={UNIVERSITY_USERS_TABLE_HEAD} onSort={onSort} sx={{ pt: 10 }} />
                <TableBody>
                  {isFullFilled &&
                    data?.map((user: any) => (
                      <TableRow key={user.id}>
                        <TableCell align={'left'} width={'30%'}>
                          {user.name}
                        </TableCell>
                        <TableCell align={'left'} width={'30%'}>
                          {user.surname}
                        </TableCell>
                        <TableCell align={'left'} width={'30%'}>
                          {user.mail}
                        </TableCell>
                        <TableCell align={'left'} width={'10%'} />
                      </TableRow>
                    ))}
                  <TableNoData isNotFound={!isLoading && data?.length === 0} title={t('university.no_users')} />
                  <TableLoadingData isLoading={isLoading} />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Box>
        {isFullFilled && (
          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Box>
        )}
      </Container>
    </Card>
  );
}
