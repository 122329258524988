import { Bug, BugsActionTypes, Bugstate, BugstatePaginated } from './models';
import { GETTING_BUGS, GET_BUGS_SUCCESS, GET_BUGS_ERROR, PATCH_BUGS_STATUS, GET_BUG_SUCCESS, GETTING_BUG } from './types';

const INITIAL_STATE: Bugstate = {
  bugs: [] as BugstatePaginated[],
  bug: {} as Bug,
  loading: true,
  loadingDetailBug: true,
  addLoading: false,
  error: '',
};
export const bugReducer = (state: Bugstate = INITIAL_STATE, action: BugsActionTypes): Bugstate => {
  switch (action.type) {
    case GETTING_BUGS:
      return {
        ...state,
        loading: true,
      };
    case GET_BUGS_SUCCESS:
      return {
        ...state,
        loading: false,
        bugs: action.payload,
      };
    case GETTING_BUG:
      return {
        ...state,
        loadingDetailBug: true,
      };
    case GET_BUG_SUCCESS:
      return {
        ...state,
        loadingDetailBug: false,
        bug: action.payload,
      };
    case GET_BUGS_ERROR:
      return { ...state, loading: false, error: action.error };
    case PATCH_BUGS_STATUS:
      return { ...state, loading: false, bug: action.payload };
    default:
      return state;
  }
};
