/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { CONSTANTS, ENDPOINTS, getErrorMessage, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { RootState } from '../rootStore';
import { SnackBarActionTypes } from '../snackBar/models';
import { SHOW_SNACK_BAR } from '../snackBar/types';
import { CounterActionTypes } from './models';
import {
  DECREMENT_BUGS,
  DECREMENT_NUMBER_OF_APPLICANTS,
  DECREMENT_REPORTINGS,
  INCREMENT_BUGS,
  INCREMENT_NUMBER_OF_APPLICANTS,
  INCREMENT_REPORTINGS,
  INITIALIZE_APPLICANTS_ATTEMPT,
  INITIALIZE_APPLICANTS_ERROR,
  INITIALIZE_APPLICANTS_SUCCESS,
  INITIALIZE_BUGS_ATTEMPT,
  INITIALIZE_BUGS_ERROR,
  INITIALIZE_BUGS_SUCCESS,
  INITIALIZE_REPORTINGS_ATTEMPT,
  INITIALIZE_REPORTINGS_ERROR,
  INITIALIZE_REPORTINGS_SUCCESS,
} from './types';
import { getOpportunities } from '../opportunities/action';

export const initializeBugs = () => async (dispatch: ThunkDispatch<RootState, undefined, CounterActionTypes | SnackBarActionTypes>) => {
  dispatch({
    type: INITIALIZE_BUGS_ATTEMPT,
  });

  sendAsyncRequest(HttpMethod.Get, ENDPOINTS.BUGS_COUNTER, {}, true, null)
    .then((res: AxiosResponse) => {
      dispatch({
        type: INITIALIZE_BUGS_SUCCESS,
        bugs: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
        localStorage.clear();
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: CONSTANTS.SESSION_EXPIRED,
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
        dispatch({
          type: INITIALIZE_BUGS_ERROR,
          error: error,
        });
      } else {
        dispatch({
          type: INITIALIZE_BUGS_ERROR,
          error: error,
        });
      }
    });
};
export const initializeReportings = () => async (dispatch: ThunkDispatch<RootState, undefined, CounterActionTypes | SnackBarActionTypes>) => {
  dispatch({
    type: INITIALIZE_REPORTINGS_ATTEMPT,
  });

  sendAsyncRequest(HttpMethod.Get, ENDPOINTS.REPORTINGS_COUNTER, {}, true, null)
    .then((res: AxiosResponse) => {
      dispatch({
        type: INITIALIZE_REPORTINGS_SUCCESS,
        reportings: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
        localStorage.clear();

        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: CONSTANTS.SESSION_EXPIRED,
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
        dispatch({
          type: INITIALIZE_REPORTINGS_ERROR,
          error: error,
        });
      } else {
        dispatch({
          type: INITIALIZE_REPORTINGS_ERROR,
          error: error,
        });
      }
    });
};

export const initializeApplicants = () => async (dispatch: ThunkDispatch<RootState, undefined, CounterActionTypes | SnackBarActionTypes>) => {
  dispatch({
    type: INITIALIZE_APPLICANTS_ATTEMPT,
  });

  sendAsyncRequest(HttpMethod.Get, ENDPOINTS.APPLICANTS_COUNTER, {}, true, null)
    .then((res: AxiosResponse) => {
      dispatch({
        type: INITIALIZE_APPLICANTS_SUCCESS,
        applicants: res.data?.countUnseenApplicant,
      });
    })
    .catch((error) => {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: {
          message: getErrorMessage(error),
          severity: 'error',
          icon: 'ErrorICon',
          size: 'medium',
        },
      });
    });
};

export const ChangeToSeenApplicantsByOpportunity =
  (id: any) => async (dispatch: ThunkDispatch<RootState, undefined, CounterActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: INITIALIZE_APPLICANTS_ATTEMPT,
    });
    const payloadToSend = {
      applicants: [id],
    };
    sendAsyncRequest(HttpMethod.Post, ENDPOINTS.SEEN_APPLICANTS, payloadToSend, true, null)
      .then((res: AxiosResponse) => {
        dispatch(decrementApplicants());
        dispatch(getOpportunities());
      })
      .catch((error) => {
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: getErrorMessage(error),
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
      });
  };
export const incrementBugs = (): CounterActionTypes => ({
  type: INCREMENT_BUGS,
});
export const incrementReportings = (): CounterActionTypes => ({
  type: INCREMENT_REPORTINGS,
});
export const decrementBugs = (): CounterActionTypes => ({
  type: DECREMENT_BUGS,
});
export const decrementReportings = (): CounterActionTypes => ({
  type: DECREMENT_REPORTINGS,
});

export const incrementApplicants = (): CounterActionTypes => ({
  type: INCREMENT_NUMBER_OF_APPLICANTS,
});
export const decrementApplicants = (): CounterActionTypes => ({
  type: DECREMENT_NUMBER_OF_APPLICANTS,
});
