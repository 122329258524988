/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Stack, CircularProgress, Backdrop, Button, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import CustomLoadingButton from '../../button/CustomLoadingButton';
import FormProvider from '../../hook-form/FormProvider';
import RHFTextField from '../../hook-form/RHFTextField';
import useSnackBar from '../../../hooks/useSnackBar';
import { RootState } from '../../../store/rootStore';
import { colors } from '../../../theme/sharedTheme';
import { AdvertismentsFormProps } from './advertisements-form.types';
import Advertisements from '../../../models/Advertisments';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import AddPhotoIcon from '../../svgComponents/AddPhotoIcon';
import AdminVideoControl from '../../adds/menubar/approved/form/VideoControl';
import FullScreenModal from '../../adds/menubar/approved/form/FullScreenModal';
import { CreateAdvertisementsSchema } from './create-advertisments.schema';
import { advertisementsApi } from '../../../store/advertisements/api/Advertisements.api';
import { AdvertisementsActionTypes } from '../../../store/advertisements/slice/Advertisments-slice-types';

const { createAdvertisements, updateAdvertisements } = advertisementsApi.endpoints;

export default function AdvertisementsForm({ currentAdvertisements, isUpdate, onClose, isEyeIconClicked }: AdvertismentsFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AdvertisementsActionTypes>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);

  const [postURL, setPostURL] = useState<string | undefined>(undefined);
  const [postType, setPostType] = useState<string | undefined>(undefined);
  const [post, setPost] = useState<string | undefined>(undefined);
  const [postError, setPostError] = useState<string | undefined>(undefined);
  const [type, setType] = useState<string | undefined>(undefined);

  const [largeImgUrl, setLargeImgUrl] = useState<string | undefined>(undefined);
  const [openImgModel, setOpenImgModal] = useState(false);
  const [videoURL, setVideoURL] = useState<string | undefined>(undefined);
  const [videoType, setVideoType] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isUpdate && currentAdvertisements) {
      const posturl = currentAdvertisements?.videoOrImage || '';
      const postExtension = posturl.split('.').pop()?.toLowerCase() || '';
      setPostURL(posturl);
      setPostType(postExtension);
      setPost(posturl);
      setType(currentAdvertisements?.type);
    }
  }, [currentAdvertisements, isUpdate]);

  const defaultValues = useMemo<Advertisements>(
    () => ({
      title: currentAdvertisements?.title || '',
      videoOrImage: currentAdvertisements?.videoOrImage || '',
    }),
    [currentAdvertisements],
  );

  const methods = useForm<Advertisements>({
    resolver: yupResolver(CreateAdvertisementsSchema),
    defaultValues,
    mode: 'all',
  });

  const { alertSuccess, alertError } = useSnackBar();

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
    clearErrors,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentAdvertisements]);

  useEffect(() => {
    const updatedDefaultValues = {
      title: currentAdvertisements?.title || '',
      videoOrImage: currentAdvertisements?.videoOrImage || '',
    };
    methods.reset(updatedDefaultValues);
  }, [currentAdvertisements, isUpdate]);

  const onSubmit = async () => {
    if (!isUpdate) {
      create();
    } else {
      update();
    }
  };

  const create = async () => {
    try {
      setLoading(true); // Start loading
      const isValid = await trigger();
      if (isValid) {
        const formData = new FormData();
        // const type = postType?.includes('video') ? 2 : 1;
        console.log('post', post);
        formData.append('title', values?.title || '');
        formData.append('videoOrImage', post || '');
        formData.append('type', String(type));
        await dispatch(createAdvertisements(formData));
        reset(defaultValues);
        dispatch(alertSuccess(t('advertisements.create_success_message')));
        onClose();
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    } finally {
      setLoading(false); // End loading
    }
  };

  const update = async () => {
    try {
      setLoading(true); // Start loading
      const isValid = await trigger();
      if (isValid && currentAdvertisements?.id && !postError) {
        const formData = new FormData();
        formData.append('title', values?.title || '');
        formData.append('videoOrImage', post || '');
        formData.append('type', String(type));
        await dispatch(updateAdvertisements(currentAdvertisements?.id, formData));
        reset(defaultValues);
        dispatch(alertSuccess(t('advertisements.update_success_message')));
        onClose();
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleClickOpenModal = (imageurl: string) => {
    setLargeImgUrl(imageurl || '');
    setOpenImgModal(true);
  };

  const openVideoPopup = () => {
    setVideoURL(postURL);
    setVideoType(postType);

    setVideoPopupOpen(true);
  };

  const closeVideoPopup = () => {
    setVideoPopupOpen(false);
  };

  const handleClickCloseImgModal = () => {
    setLargeImgUrl('');
    setOpenImgModal(false);
  };

  const handleLogoUpload = (e: any) => {
    const file = e.target.files[0];
    const fileType = file?.type.split('/')[1];
    if (file && file.type.includes('video/mp4')) {
      const imageUrl = URL.createObjectURL(file);
      setPost(file);
      setValue('videoOrImage', file);
      setPostType(fileType);
      setPostURL(imageUrl || undefined);
      setPostError(undefined);
      setType('2');
      clearErrors('videoOrImage'); // Clear form error for video
    } else {
      // Handle unsupported file types
      setPostError('Please choose an video file.');
      setPost(undefined);
      setPostURL(undefined);
      setPostType(undefined);
    }
  };
  console.log('adasdasdsdf', values);
  return (
    <>
      <Backdrop
        sx={{
          color: colors.yellow,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          zIndex: (theme) => theme.zIndex.drawer + 1, // Ensure this value is high enough
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <h2>{t('common.loading')}</h2>
      </Backdrop>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={`artgapi-modal`} container spacing={3}>
          <Grid className="artgapi-plan-form" item xs={12} md={12}>
            <Box sx={{ p: 3 }}>
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <RHFTextField
                    name={'title'}
                    placeholder={t('advertisements.form_fields.title.placeholder')}
                    label={t('advertisements.form_fields.title.label')}
                    disabled={isEyeIconClicked}
                  />

                  <Grid>
                    <div>
                      <Typography sx={{ marginBottom: '10px', fontWeight: 'normal!important' }}>
                        {t('advertisements.form_fields.video.label')}
                      </Typography>
                      <div style={{ display: 'flex', marginBottom: '0', alignItems: 'center' }}>
                        {postURL ? (
                          <>
                            <div>
                              <span>
                                {postType?.includes('mp4') ? (
                                  <>
                                    <div
                                      style={{
                                        width: '100px',
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative',
                                      }}
                                    >
                                      <video
                                        style={{
                                          cursor: 'pointer',
                                          width: '100%',
                                          objectFit: 'cover',
                                          height: '100%',
                                        }}
                                        src={postURL}
                                        onClick={openVideoPopup}
                                      ></video>

                                      <span
                                        style={{
                                          backgroundColor: 'white',
                                          display: 'flex',
                                          alignItems: 'center',
                                          borderRadius: '9999px',
                                          position: 'absolute',
                                          width: '1.5 rem',
                                          height: '1,5 rem',
                                          cursor: 'pointer',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <PlayCircleOutlineIcon className="text-base" onClick={openVideoPopup} />
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <img
                                    src={postURL}
                                    alt="post"
                                    style={{
                                      width: '100px',
                                      height: '100px',
                                      objectFit: 'contain',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => handleClickOpenModal(postURL || '')}
                                  />
                                )}
                              </span>
                              {/* <label className="text-[#5e5e5e] break-all !mb-0">{'gdfgdfh'}</label> */}
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '0px' }}>
                              <label
                                htmlFor="logos"
                                style={{
                                  width: '44px',
                                  height: '44px',
                                  minWidth: '44PX',
                                  backgroundColor: '#f0f0f0',
                                  border: '1px dashed #ccc',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <AddPhotoIcon />
                              </label>
                            </div>
                          </>
                        )}
                        {!isEyeIconClicked && (
                          <Button
                            className="artgapi-upload-btn"
                            style={{
                              textTransform: 'capitalize',
                              boxShadow: 'inset 0px -1px 0px #E7E7E7',
                              marginLeft: '15px',
                              backgroundColor: isEyeIconClicked ? '#ffcc33' : '#ffcc33',
                            }}
                            size="small"
                            variant="contained"
                            component="label"
                          >
                            {t('articles.upload')}
                            <input hidden type="file" name="" id="add-post" onChange={(e) => handleLogoUpload(e)} />
                          </Button>
                        )}
                      </div>
                      {errors.videoOrImage && !postError && <p style={{ color: 'red', marginTop: '8px' }}>{errors.videoOrImage.message}</p>}
                      {postError && <p style={{ color: '#d32f2f', marginTop: '0.5rem', fontSize: '0.75rem' }}>{postError}</p>}
                    </div>
                  </Grid>
                </Box>
              </Box>

              {/* Submit Button */}
              {!isEyeIconClicked && (
                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                  <CustomLoadingButton
                    loading={loading || isSubmitting}
                    label={!isUpdate ? t('interests.create_button_label') : t('interests.update_button_label')}
                    onClick={onSubmit}
                  />
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid>
      </FormProvider>
      {isVideoPopupOpen && (
        <AdminVideoControl
          isVideoPopupOpen={isVideoPopupOpen}
          closeVideoPopup={closeVideoPopup}
          videourl={videoURL || ''}
          selectedVideo={videoType || ''}
        />
      )}
      {openImgModel && <FullScreenModal openImgModel={openImgModel} closeImgPopup={handleClickCloseImgModal} largeImgUrl={largeImgUrl || ''} />}
    </>
  );
}
