import AdsUsers from '../../../models/AdsUsers';
import { PendingAds } from '../../../models/PendingAds';

export interface IAdsUsersResponse {
  total: number;
  next: number | null;
  previous: number | null;
  currentArtistOfTheWeekCount: number | 0;
  users: AdsUsers[];
}

export interface IAdsUsersState {
  isLoading: boolean;
  users: IAdsUsersResponse | null;
  adsusers: AdsUsers | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_ADS_USERS_SUCCESS = 'FETCH_ADS_USERS_SUCCESS',
  FETCH_ADS_USERS_FAILURE = 'FETCH_ADS_USERS_FAILURE',
  CREATE_ADS_USERS_SUCCESS = 'CREATE_ADS_USERS_SUCCESS',
}

export interface IAdsUsersPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchAdsUsersSuccessAction {
  type: typeof ActionTypes.FETCH_ADS_USERS_SUCCESS | typeof ActionTypes.CREATE_ADS_USERS_SUCCESS;
  payload: IAdsUsersResponse | AdsUsers;
}

export interface IFetchAdsUsersRejectedAction {
  type: typeof ActionTypes.FETCH_ADS_USERS_FAILURE;
  payload: string;
}

export type AdsUsersActionTypes = IAdsUsersPendingAction | IFetchAdsUsersSuccessAction | IFetchAdsUsersRejectedAction;
