import React, { useCallback } from 'react';
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootStore';
import { hideSnackBar } from '../../store/snackBar/actions';
import CheckIcon from '../svgComponents/CheckIcon';
import ErrorIcon from '../svgComponents/ErrorIcon';
import { createTheme } from '@mui/material/styles';
import { colors } from '../../theme/sharedTheme';
import { ThemeProvider } from '@mui/material/styles';

export const CostumeSnackBar = () => {
  // get redux snack bar state
  const snackBar = useSelector((state: RootState) => state.snackbar);
  // get dispatch hooks
  const dispatch = useDispatch();
  // handle close snack bar
  const snackBarTheme = createTheme({
    palette: {
      success: {
        main: colors.green,
      },
      error: {
        main: colors.Red,
      },
    },
    components: {
      MuiSnackbar: {
        styleOverrides: {
          root: {
            width: '38%',
            '& .MuiAlert-icon': {
              position: 'relative',
              left: '0',
              background: '#fff',
              width: '20px',
              height: '20px',
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '5px',
              marginLeft: '10px',
            },
            left: '80% !important',
            borderRadius: 0,
            '& .MuiAlert-action': { display: 'none' },
            '& .MuiAlert-message': { position: 'relative', left: '12px', fontSize: '14px' },
            '& .MuiAlert-root': {
              borderRadius: 0,
              paddingLeft: 0,
              alignItems: 'center',
              width: '100%',
              height: '45px',
              fontSize: '17px',
              fontFamily: 'SFPro',
              fontWeight: '400px',
              margin: -10,
              color: '#ffffff',
            },
          },
        },
      },
    },
  });

  const handleClose = useCallback(() => {
    dispatch(hideSnackBar());
  }, [dispatch]);
  return (
    <ThemeProvider theme={snackBarTheme}>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000000000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={snackBar.severity}
          icon={snackBar.icon === 'CheckIcon' ? <CheckIcon /> : <ErrorIcon />}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default Snackbar;
