import { Dashboard, DashboardActionsTypes, DashboardState } from './models';
import { GET_STATISTIC_ERROR, GET_STATISTIC_SUCCESS } from './types';

const INITIAL_STATE: DashboardState = {
  dashboard: {} as Dashboard,
  loading: true,
  addLoading: false,
  error: '',
};
export const DashboardReducer = (state: DashboardState = INITIAL_STATE, action: DashboardActionsTypes): DashboardState => {
  switch (action.type) {
    case GET_STATISTIC_SUCCESS:
      return {
        ...state,
        dashboard: action.payload,
      };
    case GET_STATISTIC_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};
