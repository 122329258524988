import { InitialPaginationParams } from '../../../../api/common/common.types';
import { Column } from '../../../table/table-head/CustomTableHead.types';

export const initialPaginationState: InitialPaginationParams = {
  defaultCurrentPage: 1,
  defaultOrderBy: 'id',
  defaultOrder: 'asc',
  defaultRowsPerPage: 25,
};

export const TABLE_COLUMNS_CONFIG: Column[] = [
  {
    id: 'plan_name',
    label: 'adds.name',
    width: '20%',
  },
  {
    id: 'plan_description',
    label: 'adds.description',
    width: '20%',
  },
  {
    id: 'actions',
    label: 'adds.actions',
    width: '10%',
    isNotSortable: true,
  },
];
