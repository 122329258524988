import { CONSTANTS } from '../../utils';

export interface Description {
  label: string;
  type: string;
  height: number;
  width: string;
  choices?: { label: string; value: string; id?: number }[];
  choicesTypes?: { label: string; id?: number }[];
  value: string;
  filterBy?: string;
}

export const flagsHeader: Description[] = [
  {
    label: 'common.reason',
    type: 'normal',
    height: 50,
    width: '30%',
    value: 'flag',
  },
  {
    label: 'common.type',
    type: 'select',
    height: 50,
    width: '15%',
    value: 'type',
    choicesTypes: [
      { label: 'common.all', id: 10 },
      { label: 'common.comment', id: 20 },
      { label: 'common.publication', id: 30 },
      { label: 'common.artists', id: 40 },
      { label: 'Article', id: 50 },
    ],
  },
  {
    label: 'common.user',
    type: 'normal',
    height: 50,
    width: '20%',
    value: 'nickname',
  },
  {
    label: 'common.Status',
    type: 'select',
    height: 50,
    width: '15%',
    value: 'status',
    choices: [
      { label: 'common.all', value: '10', id: 10 },
      { label: 'common.reported', value: '50', id: 50 },
      { label: 'common.treaty', value: '40', id: 40 },
    ],
  },
  {
    label: 'common.date',
    type: 'dateFlags',
    height: 50,
    width: '35%',
    value: 'createdAt',
    filterBy: 'dateFlags',
  },
];
