import React, { useEffect, useState, ChangeEvent } from 'react';
import { Box, Container, Table, Button, TableBody, TableContainer, TablePagination, Stack, InputLabel, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TableHeadCustom from '../../table/table-head/CustomTableHead';
import AdsReportTableRow from './adsReport-row/AdsReportTableRow';
import { useDebounce } from '@uidotdev/usehooks';
import { useDispatch, useSelector } from 'react-redux';
import { AdsUsersActionTypes, IAdsUsersResponse, IAdsUsersState } from '../../../store/adsUsers/slice/AdsUser-slice.types';
import { RootState } from '../../../store/rootStore';
import { ThunkDispatch } from 'redux-thunk';
import { adsUserApi } from '../../../store/adsUsers/api/AdsUsers.api';
import { TABLE_COLUMNS_CONFIG, initialPaginationState } from './AdsReport.constant';
import useTable from '../../../hooks/useTable';
import { RootStyle } from '../../../pages/systemusers/SystemUser.styles';
import SearchIcon from '../../svgComponents/searchIcon';
import AdsUsers from '../../../models/AdsUsers';
import TableNoData from '../../table/TableNoData';
import TableLoadingData from '../../table/TableLoadingData';
import { TablePaginationActions } from '../../table/TablePaginationActions/TablePaginationActions';
import { ApprovedAds } from '../../../models/ApprovedAds';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { AxiosResponse } from 'axios';
import { HttpMethod } from '../../../utils/httpMethods';
import AddDialog from '../../adds/menubar/approved/add-dialog/AddDialog';
import DownloadIcon from '@mui/icons-material/Download';
import { ContainerStyle, CustomTitle, GridStyle, InputStyle } from '../../../utils/toolbar/CommonToolbar.styles';
import DateFilter from '../../../utils/DateFilter';

function AdsReports() {
  const { t } = useTranslation();
  const [filterKeyword, setfilterKeyword] = useState('');
  const [isEyeIconClicked, setIsEyeIconClicked] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [currentApprovedAds, setCurrentApprovedAds] = useState<ApprovedAds | undefined>(undefined);
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AdsUsersActionTypes>>();
  const { users, isLoading } = useSelector<RootState, IAdsUsersState>((state: RootState) => state[adsUserApi.reducerPath]);
  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialPaginationState);
  const [exportUsers, setExportUsers] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const formattedStartDate = startDate ? formatDate(startDate) : '';
  const formattedEndDate = endDate ? formatDate(endDate) : '';

  useEffect(() => {
    dispatch(
      adsUserApi.endpoints.getAdsReportUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }),
    );
  }, [order, orderBy, page, rowsPerPage, debouncedSearchTerm, dispatch, endDate, startDate]);

  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
    setPage(0);
  };

  const onShowDetails = (id?: number, isShow?: boolean) => {
    sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.VIEW_PENDING_ADS}/${id}`, {}, true, {})
      .then((response: AxiosResponse) => {
        const responseBody = response.data.userPlan;
        const { name, planType, adsPost, price, plan_type, adsTitle, roleIds, websiteUrl, mail, description, adsBanner, adsPopup } = responseBody;
        const userData: ApprovedAds = {
          id: id,
          name: name,
          mail: mail,
          planType: planType,
          price: price,
          adsTitle: adsTitle,
          adsBanner: adsBanner,
          adsPost: adsPost,
          adsPopup: adsPopup,
          description: description,
          websiteUrl: websiteUrl,
          roleIds: roleIds,
          plan_type: plan_type,
        };
        setIsEyeIconClicked(isShow ?? false);
        setCurrentApprovedAds(userData);
      })
      .catch((err) => {
        console.log('error', err);
      });
    setAddDialogOpen(true);
  };

  const onCloseDialog = () => {
    setAddDialogOpen(false);
    setCurrentApprovedAds(undefined);
    setIsEyeIconClicked(false);
    setfilterKeyword('');
    dispatch(
      adsUserApi.endpoints.getAdsReportUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }),
    );
  };

  useEffect(() => {
    if (exportUsers) {
      window.open(exportUsers, '_blank');
    }
  }, [exportUsers]);

  const handleExportUsers = () => {
    sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.ACTIVE_USERS_CSV}`, {}, true, {})
      .then((response: AxiosResponse) => {
        const responseBody = response.data.fileUrl;
        setExportUsers(responseBody);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDate = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  const isFullFilled = !isLoading && users && users?.users?.length > 0;

  return (
    <>
      <AddDialog
        open={addDialogOpen}
        onClose={onCloseDialog}
        currentApprovedAds={currentApprovedAds}
        isEyeIconClicked={isEyeIconClicked}
        isAdsUsers={true}
      />
      <RootStyle>
        <ContainerStyle style={{ padding: '0 24px' }}>
          <GridStyle>
            <CustomTitle>{t('adds.adsReportstitle')}</CustomTitle>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={handleExportUsers}
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#1976d2',
                  color: '#fff',
                  marginRight: '10px',
                }}
                variant="contained"
                className={users && users?.users?.length <= 0 ? 'disabled-btn' : ''}
                disabled={users && users?.users?.length <= 0 ? true : false} // Ensure disabled is either true or undefined
              >
                <DownloadIcon />
                {t('common.export')}
              </Button>

              <Stack direction="column" spacing={4}>
                <InputStyle
                  value={filterKeyword}
                  placeholder={t('common.search')}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterKeyword(e.target.value)}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                  }}
                />
              </Stack>
            </div>
          </GridStyle>
        </ContainerStyle>
        <DateFilter
          startDate={startDate}
          endDate={endDate}
          handleClear={handleClear}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
        />
        <Container sx={{ minHeight: '70vh' }}>
          <div className="table-block">
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <TableHeadCustom order={order} orderBy={orderBy} columnsConfig={TABLE_COLUMNS_CONFIG} onSort={onSort} />
                <TableBody>
                  {isFullFilled &&
                    (users as IAdsUsersResponse)?.users?.map((row: AdsUsers) => {
                      return (
                        <AdsReportTableRow
                          key={row.id}
                          row={row}
                          selected={false}
                          columnsConfig={TABLE_COLUMNS_CONFIG}
                          onShowDetails={(id) => onShowDetails(id, true)}
                        />
                      );
                    })}
                  <TableNoData isNotFound={!isLoading && users?.users?.length === 0} title={t('adds.no_active_users')} />

                  <TableLoadingData isLoading={isLoading} />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {isFullFilled && (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={users ? users.total : 0}
                rowsPerPage={rowsPerPage}
                page={users && page ? page - 1 : 0}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                sx={{
                  '& .MuiInputBase-root': {
                    maxWidth: '60px',
                  },
                }}
              />
            </Box>
          )}
        </Container>
      </RootStyle>
    </>
  );
}

export default AdsReports;
