import { Button, styled } from '@mui/material';

export const ButtonStyle = styled(Button)({
  borderRadius: '0px',
  height: '52px',
  minWidth: '200px',
  fontFamily: 'SFPro-Regular',
  fontSize: '17px',
  textAlign: 'center',
  backgroundColor: '#ffcc33',
  justifySelf: 'end',
  '&:hover': {
    backgroundColor: '#ffcc33',
  },
});
