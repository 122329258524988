export const systemuser = {
  title: 'Sous-administrateur',
  add: 'CRÉER UN SOUS-ADMINISTRATEUR',
  status: 'statut',
  actions: 'Actions',
  no_data: 'Aucun sous-administrateur trouvé',
  add_systemuser_title: 'Créer un sous-administrateur',
  update_systemuser_title: 'Modifier un sous-administrateur',
  view_systemuser_title: 'Afficher les détails du sous-administrateur',
  confirm_delete_title: 'Confirmer la suppression',
  update_button_label: 'Mise à jour',
  create_button_label: 'Créer',
  delete_success_message: `Le sous-administrateur a été supprimé avec succès`,
  update_success_message: 'Le sous-administrateur a été modifié avec succès',
  create_success_message: 'Le sous-administrateur a été établi avec succès',
  status_success_message: 'Statut mis à jour avec succès',
  nickname_already_used: 'Pseudo déjà utilisé par un compte existant',
  email_already_used: 'Mail déjà utilisé par un compte existant',
  confirm_delete_message: 'Êtes-vous sûr de vouloir supprimer ce sous-administrateur?',
  form_fields: {
    name: {
      label: 'Nom*',
      placeholder: 'Nom',
      required_error_message: 'Le champ Nom est requis',
    },
    surname: {
      label: 'Nom de famille*',
      placeholder: 'Nom de famille',
      required_error_message: 'Le champ Nom de famille est requis',
    },
    birthdate: {
      label: 'Date de naissance*',
      placeholder: 'Date de naissance',
      required_error_message: 'Le champ Date de naissance est requis',
    },
    phone_number: {
      label: 'Numéro de téléphone*',
      placeholder: 'Numéro de téléphone',
      required_error_message: 'Le champ du numéro de téléphone est requis',
      invalid_error_message: 'Numéro de téléphone doit étre entre 7 et 16 caractères',
    },
    mail: {
      label: 'Email*',
      placeholder: 'E-mail',
      required_error_message: `Le champ de l'adresse e-mail est requis`,
      invalid_error_message: 'Adresse e-mail invalide',
    },
    email_Address: {
      label: 'Adresse e-mail*',
      placeholder: 'Adresse e-mail',
      required_error_message: `Le champ de l'adresse e-mail est requis`,
      invalid_error_message: 'Adresse e-mail invalide',
    },
    nickname: {
      label: 'Surnom*',
      placeholder: 'Surnom',
      required_error_message: 'Le champ pseudo est requis',
    },
    address: {
      label: 'Adresse*',
      placeholder: 'Adresse',
      required_error_message: `Le champ d'adresse est requis`,
    },
    city: {
      label: 'Ville*',
      placeholder: 'Ville',
      required_error_message: 'Le champ ville est requis',
    },
    zip: {
      label: 'Code postal*',
      placeholder: 'Code postal',
      required_error_message: 'Le champ Code postal est requis',
    },
    activate_deactivate_label: {
      label: 'Activer/Désactiver',
      placeholder: 'Activer/Désactiver',
    },
    countryId: {
      label: 'Pays*',
      placeholder: 'Pays',
      required_error_message: 'Le champ pays est requis',
    },
    UserPermissions: {
      label: `Contrôle d'accès*`,
      placeholder: `Contrôle d'accès`,
      required_error_message: 'permission is required',
    },
  },
};
