/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Button,
  IconButton,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { CategoriesFormProps } from './Categories-form.types';
import { categoriesApi } from '../../../../store/categories/api/Categories.api';
import { CategoriesActionTypes } from '../../../../store/categories/slice/Categories-slice.types';
import { Categories } from '../../../../models/Categories';
import useSnackBar from '../../../../hooks/useSnackBar';
import CustomLoadingButton from '../../../button/CustomLoadingButton';
import RHFTextField from '../../../hook-form/RHFTextField';
import { RootState } from '../../../../store/rootStore';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CancelIcon from '@mui/icons-material/Cancel';
import { getLanguages } from '../../../../store/languages/actions';
import { languageActionTypes } from '../../../../store/languages/models';
import Languages from '../../../../models/Languages';

const { updateCategories, createCategoryUser } = categoriesApi.endpoints;

export default function CategoriesForm({ currentCategories, isUpdate, onClose, isEyeIconClicked }: CategoriesFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, CategoriesActionTypes>>();

  const [showLanguage, setShowLanguage] = useState<{ id: number; title: string; languageName: string; languageCode: string }[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);
  const dispatchLanguages = useDispatch<ThunkDispatch<RootState, undefined, languageActionTypes>>();
  const languages = useSelector((state: RootState) => state.languages.languages || []); // Provide a default value of an empty array
  const [languagesList, setLanguagesList] = useState<Languages[]>([]); // Specify the type explicitly as Languages[]

  const defaultValues = useMemo<Categories>(
    () => ({
      title: '',
      language: languagesList.length > 0 ? languagesList[0]?.id || 45 : 45, // Default to the first language ID if available, otherwise default to English (ID: 45)
    }),
    [currentCategories, languagesList],
  );

  const schema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .max(60, t('interests.form_fields.title.max_length_error_message')) // Adding max length validation
      .required(t('interests.form_fields.title.required_error_message')),
    language: Yup.number().required(t('interests.form_fields.language.required_error_message')),
  });

  const methods = useForm<Categories>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'all',
  });

  const { alertSuccess, alertError } = useSnackBar();
  const {
    reset,
    handleSubmit,
    trigger,
    watch,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatchLanguages(getLanguages());
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (isUpdate) {
      const defaultTitle = defaultValues.title;
      methods.setValue('title', defaultTitle);
    }
  }, [defaultValues.title, isUpdate]);

  useEffect(() => {
    if (languages && currentCategories && currentCategories.translations && currentCategories.translations.length > 0) {
      const translatedLanguagesIds = currentCategories.translations.map((translation) => translation.languageId);
      const missingLanguages = languages.filter((language) => !translatedLanguagesIds.includes(language.id));

      if (isUpdate) {
        const existingLanguagesIds = showLanguage.map((lang) => lang.id);
        const newLanguages = missingLanguages.filter((lang) => !existingLanguagesIds.includes(lang.id));
        setLanguagesList(newLanguages);
      } else {
        setShowLanguage((prevShowLanguage) => [
          ...prevShowLanguage,
          ...missingLanguages.map((lang) => ({
            id: lang.id,
            title: '', // You might want to replace this with an appropriate default value
            languageName: lang.languageName,
            languageCode: lang.languageCode,
          })),
        ]);
      }
    } else if (languages) {
      setLanguagesList(languages);
    }
  }, [languages, isUpdate, currentCategories]);

  useEffect(() => {
    if (currentCategories && currentCategories.translations && currentCategories.translations.length > 0) {
      const transformedTranslations = currentCategories.translations.map((translation) => ({
        id: translation.languageId,
        title: translation.title,
        languageName: translation.language.languageName || 'English',
        languageCode: translation.language.languageCode || 'en',
      }));
      setShowLanguage(transformedTranslations);
    }
  }, [currentCategories]);

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentCategories]);

  useEffect(() => {
    // Reset error message when language changes
    setErrorMessage(null);
  }, [values.language]);

  useEffect(() => {
    // Update defaultValues whenever currentCategories or isUpdate changes
    const updatedDefaultValues = {
      title: '',
      language: languagesList.length > 0 ? languagesList[0].id : 45, // Default to the first language ID if available, otherwise default to English (ID: 45)
    };
    methods.reset(updatedDefaultValues);
  }, [currentCategories, isUpdate, languagesList]);

  const validateAdd = async () => {
    const isValid = await trigger('title');
    return isValid;
  };

  const onSubmit = async () => {
    if (!isUpdate) {
      if (showLanguage.length === (languages && languages.length)) {
        setFormErrorMessage(null);
        create();
      } else {
        setFormErrorMessage(t('interests.form_fields.language.required_error_message'));
      }
    } else {
      if (showLanguage.length === (languages && languages.length)) {
        setFormErrorMessage(null);
        update();
      } else {
        setFormErrorMessage(t('interests.form_fields.language.required_error_message'));
      }
    }
  };

  useEffect(() => {
    if (formErrorMessage) {
      dispatch(alertError(formErrorMessage));
      setFormErrorMessage(null); // Reset formErrorMessage after showing the alert
    }
  }, [formErrorMessage, dispatch]);
  const create = async () => {
    try {
      const englishTitle =
        showLanguage.find((item) => {
          const language = languages.find((lang) => lang.id === item.id);
          return language?.languageCode === 'en';
        })?.title || values.title;

      const translationData = {
        title: englishTitle,
        translations: showLanguage.map(({ id, title }) => ({ languageId: id, title })),
      };
      dispatch(createCategoryUser(translationData))
        .then(() => {
          reset(defaultValues);
          dispatch(alertSuccess(t('interests.create_success_message')));
          onClose(); // Close popup only when request succeeds
        })
        .catch((err) => {
          if (err.message === 'Category already exist') {
            dispatch(alertError(t('interests.category_exsist')));
          } else {
            dispatch(alertError(t('common.something_wrong_happend')));
          }
          console.error(err);
        });
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    }
  };

  const update = async () => {
    try {
      if (currentCategories?.id) {
        const englishTitle =
          showLanguage.find((item) => {
            const language = languages.find((lang) => lang.id === item.id);
            return language?.languageCode === 'en';
          })?.title || values.title;

        const translationData = {
          title: englishTitle,
          translations: showLanguage.map(({ id, title }) => ({ languageId: id, title })),
        };
        dispatch(updateCategories(currentCategories?.id, translationData))
          .then(() => {
            reset(defaultValues);
            dispatch(alertSuccess(t('interests.update_success_message')));
            onClose();
          })
          .catch((err) => {
            if (err.message === 'Category already exist') {
              dispatch(alertError(t('interests.category_exsist')));
            } else {
              dispatch(alertError(err.message)); // Show error message in alert
            }
            console.error(err);
          });
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    }
  };

  const handleAddValue = async () => {
    const isValid = await validateAdd();
    if (!isValid) {
      return;
    }

    const { title, language } = values;
    const selectedLanguage = languagesList.find((lang) => lang.id === Number(language));
    console.log('gdfgdf', selectedLanguage);
    if (selectedLanguage) {
      const alreadyExists = showLanguage.some((item) => item.id === Number(language));
      if (alreadyExists) {
        setErrorMessage(`${selectedLanguage.languageName} est déjà ajouté.`);
      } else {
        setShowLanguage((prevValues) => [
          ...prevValues,
          {
            id: selectedLanguage.id,
            languageName: selectedLanguage.languageName,
            languageCode: selectedLanguage.languageCode, // Include language code
            title,
          },
        ]);
        setErrorMessage(null);
        setLanguagesList((prevLanguages) => prevLanguages.filter((lang) => lang.id !== Number(language))); // Remove from languagesList
        reset({ ...defaultValues, title: '' }); // Reset the title field only
      }
    }
    if (showLanguage.length === (languages && languages.length)) {
      setFormErrorMessage(null);
    }
  };

  const handleRemoveValue = (id: number) => {
    const removedLanguage = showLanguage.find((item) => item.id === id);
    if (removedLanguage) {
      setShowLanguage((prevValues) => prevValues.filter((item) => item.id !== id));
      setLanguagesList((prevLanguages) => {
        const updatedLanguages = [...prevLanguages, { id: removedLanguage.id, languageName: removedLanguage.languageName } as Languages];
        return updatedLanguages.sort((a, b) => a.languageName.localeCompare(b.languageName));
      });
    }
  };

  return (
    <>
      {!currentCategories && isUpdate ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '70px' }}>
          <CircularProgress
            sx={{
              color: '#ffcc33',
            }}
          />
        </div>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid className="artgapi-modal" container spacing={3}>
              <Grid className="artgapi-plan-form" item xs={12} md={12}>
                <Box sx={{ p: 3 }}>
                  <Box sx={{ mb: 3 }}>
                    <Box
                      sx={{
                        display: 'grid',
                        columnGap: 2,
                        rowGap: 3,
                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                      }}
                    >
                      {isEyeIconClicked ? (
                        <div className="col">
                          <Typography>{t('interests.form_fields.title.placeholder')}</Typography>
                          {/* <label style={customStyle}>{currentCategories?.title}</label> */}
                        </div>
                      ) : (
                        <>
                          {languagesList?.length > 0 && (
                            <div className="category-langage-label">
                              <FormControl fullWidth sx={{ width: '48%' }}>
                                <InputLabel
                                  id="language-label"
                                  sx={{
                                    transform: 'translate(0px, 0px) scale(0.95)',
                                    position: 'relative !important',
                                    maxWidth: '0',
                                    minWidth: '100%',
                                    color: 'rgb(171, 171, 171)',
                                    marginBottom: '0.5rem',

                                    '& .css-to7sdl-MuiFormControl-root': {
                                      marginTop: '4px !important',
                                    },
                                  }}
                                >
                                  {t('interests.form_fields.language.label')}
                                </InputLabel>
                                <Controller
                                  name="language"
                                  control={methods.control}
                                  render={({ field, fieldState: { error } }) => (
                                    <>
                                      <Select
                                        {...field}
                                        sx={{
                                          width: '100%',
                                          height: '48px',
                                          borderRadius: '0',
                                          '& .MuiSelect-select': {
                                            width: '100%',
                                            height: '48px !important',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: '12.5px 14px',
                                            paddingRight: '110px !important',
                                          },
                                          '& .MuiOutlinedInput-root': {
                                            marginTop: '-8px !important',
                                          },
                                          '& .MuiOutlinedInput-input': {
                                            border: isEyeIconClicked ? '1px solid #e7e7e7' : '1px solid #0d0d0d',
                                            borderColor: error && '#d32f2f',
                                            borderRadius: '0',
                                          },
                                          '& .MuiSelect-select:hover': {
                                            borderColor: '#141415 !important',
                                          },
                                        }}
                                        labelId="language-label"
                                        id="id"
                                        value={field.value}
                                        onChange={(e) => field.onChange(e.target.value)}
                                        disabled={isEyeIconClicked}
                                      >
                                        {/* Map over the list of countries */}
                                        {languagesList?.map((country) => (
                                          <MenuItem key={country.id} value={country.id}>
                                            {country.languageName}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      {/* Display error message if there's an error for countryId */}
                                      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
                                    </>
                                  )}
                                />
                              </FormControl>

                              <RHFTextField
                                className="country-input"
                                name={'title'}
                                label={t('interests.form_fields.title.label')}
                                placeholder={t('interests.form_fields.title.placeholder')}
                              />

                              <Button className="country-add-btn" variant="contained" onClick={handleAddValue}>
                                Add
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                    </Box>

                    {showLanguage?.length > 0 && (
                      <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">{t('interests.listoflangauges')}:</Typography>
                        <ul className="country-box">
                          {showLanguage?.map((item, index) => (
                            <li key={item?.id} style={{ listStyle: 'none' }} className="country-wrapper">
                              <span className="country-container">
                                <span className="country-label">{item.languageName}:</span>
                                <span className="country-title">{item.title}</span>
                              </span>
                              <span className="delete-country-label">
                                <IconButton onClick={() => handleRemoveValue(item.id)} size="small" sx={{ ml: 1 }}>
                                  <CancelIcon fontSize="small" sx={{ color: 'red' }} />
                                </IconButton>
                              </span>
                            </li>
                          ))}
                        </ul>
                      </Box>
                    )}
                  </Box>

                  {/* Submit Button */}
                  {isEyeIconClicked ? (
                    ''
                  ) : (
                    <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                      <CustomLoadingButton
                        loading={isSubmitting}
                        label={!isUpdate ? t('interests.create_button_label') : t('interests.update_button_label')}
                        onClick={onSubmit}
                      />
                    </Stack>
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      )}
    </>
  );
}
