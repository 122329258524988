import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { CONSTANTS, ENDPOINTS, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { RootState } from '../rootStore';
import { SnackBarActionTypes } from '../snackBar/models';
import { SHOW_SNACK_BAR } from '../snackBar/types';
import { permissionActionTypes } from './models';
import { GET_PERMISSION_ATTEMPT, GET_PERMISSION_SUCCESS, GET_PERMISSION_ERROR } from './types';

export const getPermissions = (id?: string) => async (dispatch: ThunkDispatch<RootState, undefined, permissionActionTypes | SnackBarActionTypes>) => {
  dispatch({
    type: GET_PERMISSION_ATTEMPT,
  });

  sendAsyncRequest(HttpMethod.Get, ENDPOINTS.PERMISSION + (id ? `/${id}` : ''), {}, true, null)
    .then((res: AxiosResponse) => {
      dispatch({
        type: GET_PERMISSION_SUCCESS,
        userPermission: res.data.userPermission,
      });
    })
    .catch((error) => {
      if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
        localStorage.clear();
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: CONSTANTS.SESSION_EXPIRED,
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
        dispatch({
          type: GET_PERMISSION_ERROR,
          error: error,
        });
      } else {
        dispatch({
          type: GET_PERMISSION_ERROR,
          error: error,
        });
      }
    });
};
