/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Button } from '@mui/material';
import { OnTheTable } from '../../components/table/onTheTable/OnTheTable';
import { useStyles } from './dashbordStyle';
import { getStatistic } from '../../store/dashboard/action';
import ChartBar from '../../components/chart/ChartBar';
import PChart from '../../components/chart/PChart';
import DownloadIcon from '../../components/svgComponents/downloadIcon';
import { exportComponentAsPNG } from 'react-component-export-image';
import { CSVLink } from 'react-csv';
import { CONSTANTS } from '../../utils';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const statistic = useSelector((state) => state.dashboard.dashboard);
  const [click, setClick] = useState(false);
  const refCom = useRef();
  const ref = useRef();
  const { t } = useTranslation();
  const showListMenu = () => {
    setClick(true);
  };
  useEffect(() => {
    dispatch(getStatistic());
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (click && ref.current && !ref.current.contains(e.target)) {
        setClick(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [click]);

  const downloadBlob = (blob, filename) => {
    const objectUrl = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = objectUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
  };

  const downloadSVG = useCallback(() => {
    const svg = refCom.current.innerHTML;
    const blob = new Blob([svg], { type: 'image/svg+xml' });
    downloadBlob(blob, `Ventes.svg`);
  }, []);
  return (
    <>
      <Grid
        className="artgapi-dashboard-wrappper"
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '1%',
          marginTop: '2%',
          width: '100%',
          minHeight: '90vh',
          backgroundColor: '#f5f5f5',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          className="artgapi-dashboard-title"
          variant="caption"
          sx={{ fontSize: '24px', fontFamily: 'SFProText', position: 'static', top: '10%', left: '8%', marginTop: '2%' }}
        >
          {t('common.dashbaord')}
        </Typography>
        <Grid xs={6} container style={{ display: 'flex', flexDirection: 'row' }} className={classes.chart} id="artgapi-dashboard-chart-left">
          <Grid className="artgapi-weekly-block-wrapper" item container xs={12} sx={{ mt: 2.5, ml: 2 }}>
            <Grid xs={11}>
              <Typography sx={{ color: '#a4a4a5', fontSize: '15px', font: 'SFProText' }}> {t('common.weekly_sales')} </Typography>
            </Grid>
            <Grid
              xs={1}
              md={{ marginLeft: '0' }}
              style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'row',
                top: '0',
              }}
              className="artgapi-export-text"
            >
              <Typography sx={{ color: '#a4a4a5', fontSize: '15px', font: 'SFProText' }}> {t('common.export')} </Typography>
              <Button
                onClick={showListMenu}
                variant={click ? 'raised' : 'raised'}
                disableRipple
                sx={{
                  mt: -1,
                }}
                style={{ maxHeight: '40px', backgroundColor: click ? 'transparent' : 'transparent' }}
              >
                <DownloadIcon />
              </Button>
              {click ? (
                <>
                  <div
                    ref={ref}
                    style={{
                      width: '170px',
                      backgroundColor: '#26272c',
                      borderRadius: '6px',
                      marginLeft: '-180px',
                      marginTop: '30px',
                      fontSize: '14px',
                    }}
                  >
                    <Button
                      style={{ width: '170px', height: '28px', backgroundColor: '#26272c', color: '#ffffff', textTransform: 'capitalize' }}
                      onClick={downloadSVG}
                    >
                      {t('common.export_svg')}
                    </Button>
                    <Button
                      style={{ width: '170px', height: '28px', backgroundColor: '#26272c', color: '#ffffff', textTransform: 'capitalize' }}
                      onClick={() => exportComponentAsPNG(refCom, { fileName: 'Ventes' })}
                    >
                      {t('common.export_png')}
                    </Button>
                    <Button style={{ width: '170px', height: '28px', backgroundColor: '#26272c', color: '#ffffff', textTransform: 'capitalize' }}>
                      <CSVLink filename="Ventes" style={{ color: '#ffffff', textDecoration: 'none' }} data={statistic.soldArtLastWeek}>
                        {t('common.export_csv')}
                      </CSVLink>
                    </Button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          <Grid xs={12} sx={{ mt: '9', ml: 2, mb: 2 }}>
            <ChartBar refCom={refCom} />
          </Grid>
        </Grid>
        <Grid
          container
          zeroMinWidth
          xs={4}
          sx={{
            height: '60%',
            backgroundColor: '#f5f5f5',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '3%',
            alignContent: 'center',
            justifyContent: 'center',
          }}
          className={classes.typ}
          id="artgapi-dashboard-chart-right"
        >
          <div className="artgapi-chart-text-wrapper">
            <div className="artgapi-chart-text">
              <Grid
                container
                sx={{
                  padding: '15px',
                  width: '100%',
                  height: '15%',
                  backgroundColor: 'white',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '50px',
                }}
              >
                <Grid item>
                  <Typography> {t('common.total_work_sale')}</Typography>
                </Grid>
                <Grid item sx={{ color: 'black', size: '24px' }}>
                  <Typography>{statistic.sellableArtCount}</Typography>
                </Grid>
              </Grid>
              <Grid
                sx={{
                  width: '100%',
                  height: '15%',
                  backgroundColor: 'white',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '15px',
                  gap: '50px',
                }}
              >
                <Grid item>
                  <Typography>{t('common.total_work_sold')}</Typography>
                </Grid>
                <Grid item sx={{ color: 'black', size: '24px' }}>
                  <Typography> {statistic.soldArtCount} </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  height: '64%',
                  backgroundColor: 'white',
                  alignContent: 'center',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <Grid
                  container
                  item
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '15px',
                    justifyContent: 'space-between',
                    gap: '50px',
                  }}
                >
                  <Grid item>
                    <Typography> {t('common.number_of_subscriptions')} </Typography>
                  </Grid>
                  <Grid item sx={{ color: 'black', size: '24px' }}>
                    <Typography> {statistic.totalSubscriptionsCount} </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Grid item sx={{ padding: '12px' }} zeroMinWidth>
              <PChart />
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
