import { interestCategoriesActionTypes, InterestCategoriesState } from './models';
import { GET_INTEREST_CATEGORIES_ATTEMPT, GET_INTEREST_CATEGORIES_ERROR, GET_INTEREST_CATEGORIES_SUCCESS } from './types';

const INITIAL_STATE: InterestCategoriesState = {
  loader: false,
  error: '',
  categoriesList: undefined,
};
export const interestsCategoriesReducer = (state = INITIAL_STATE, action: interestCategoriesActionTypes): InterestCategoriesState => {
  switch (action.type) {
    case GET_INTEREST_CATEGORIES_ATTEMPT:
      return { ...state, loader: true, error: '' };
    case GET_INTEREST_CATEGORIES_SUCCESS:
      return { ...state, loader: false, categoriesList: action.categoriesList };
    case GET_INTEREST_CATEGORIES_ERROR:
      return { ...state, loader: false, error: action.error };

    default:
      return state;
  }
};
