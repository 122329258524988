/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig } from 'axios';
import { CONSTANTS } from './constants';
import { HttpMethod } from './httpMethods';
import axiosInstance from '../services/axios';
import { config as appConfiguration } from '../config/config';

/**
 * Confirms if there is a connected user or not
 *
 * @returns {boolean}
 */
export const isLoggedIn = () => {
  return localStorage.getItem(CONSTANTS.LOCAL_STORAGE_TOKEN) ? true : false;
};

export const sendAsyncRequest = async (
  method: HttpMethod,
  url: string,
  data: any,
  useToken: boolean,
  params: any,
  headers?: any,
  isFake?: boolean,
  id?: number, // Add id parameter here
) => {
  const config: AxiosRequestConfig = {
    method: method,
    url: isFake ? `${appConfiguration.FAKE_BASE_URL_API}` + url : `${appConfiguration.BASE_URL_API}` + url + (id ? `/${id}` : ''), // Append id to URL if provided
    timeout: CONSTANTS.TIMEOUT,
    params: undefined,
    data: null,
    headers: undefined,
  };
  if (params) {
    config.params = params;
  }
  if (data) {
    config.data = data;
  }
  headers = {
    ...headers,
    Accept: 'application/json',
  };
  if (useToken) {
    const token = await localStorage.getItem(CONSTANTS.LOCAL_STORAGE_TOKEN);
    if (token) {
      headers = {
        ...headers,
        Authorization: CONSTANTS.BEARER_KEY,
      };
    }
  }
  config.headers = headers;
  return axiosInstance(config);
};

/**
 * Return a valid date format
 *
 * @returns {string}
 */
export const formatDate = (date: string) => {
  const d = new Date(date);
  return ('0' + d.getDate()).slice(-2) + '/' + ('0' + (d.getMonth() + 1)).slice(-2) + '/' + d.getFullYear();
};

/**
 * Return a valid date + hours format
 *
 * @returns {boolean}
 */
export const formatDateHours = (date: string) => {
  const d = new Date(date);
  return (
    ('0' + d.getDate()).slice(-2) +
    '/' +
    ('0' + (d.getMonth() + 1)).slice(-2) +
    '/' +
    d.getFullYear() +
    ' à ' +
    ('0' + d.getHours()).slice(-2) +
    ':' +
    ('0' + d.getMinutes()).slice(-2)
  );
};

/**
 * Returns the first error from the ws response
 *
 * @param {any}
 *
 * @returns {string}
 */
export const getErrorMessage = (e: any): string => {
  const defaultError = 'error.unknow';
  let message = '';
  if (typeof e === 'object' && e.message) {
    message = e;
  } else if (typeof e === 'string') {
    message = e || defaultError;
  } else if (e && e && e.data) {
    const eData = e.data;
    if (eData) {
      message = eData.message;
    }
  } else {
    message = 'error.network';
  }
  return message || defaultError;
};

export const isEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isPassword = (password: string): boolean => {
  const pass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&#[\].`{}(:;),_/~^*+=-])[A-Za-z\d@$!%?&#[\].`{}(:;),_/~^*+=-]{8,}$/;
  return pass.test(String(password));
};
/**
 * Return a valid date format
 *
 * @returns {string}
 */
export const formatDateToSend = (date: string) => {
  const d = new Date(date);
  return d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2);
};
export const checkMediaSize = (size: number, format: string) => {
  return size / (1024 * 1024) > (format === 'image' ? 10 : 50);
};

/**
 * Return a JsonObject
 *
 * @returns {JsonObject}
 */

export const ensureJson = (value: any) => {
  const isString = typeof value === CONSTANTS.STRING_TYPE ? true : false;
  let result: any;
  isString ? (result = JSON.parse(value)) : (result = value);
  return result;
};

export const isAnyFieldNotEmpty = (title: string, description: string, imageUrl: string) => {
  const vals = { title, description, imageUrl };
  const values = Object.values(vals);
  return values.some((value) => value !== '');
};

export const getLengthWysiwygText = (text: any) => {
  // remove HTML tags
  const plainText = text?.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ');
  // trim whitespace and count characters
  const length = plainText?.trim().length;
  return length;
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};
