import React from 'react';

export const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5 24a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19z" stroke="#A4A4A5" strokeLinejoin="round" />
      <path d="m11.142 11.537 6.717 6.718M17.858 11.537l-6.717 6.718" stroke="#A4A4A5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CloseIcon;
