import React from 'react';

export const UserIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4468 6.48355C15.3698 7.06228 16.017 8.04162 16.1349 9.17841C16.5113 9.3543 16.9291 9.45544 17.3721 9.45544C18.9892 9.45544 20.2999 8.14476 20.2999 6.52789C20.2999 4.91078 18.9892 3.6001 17.3721 3.6001C15.7704 3.6006 14.4712 4.88786 14.4468 6.48355ZM12.5287 12.4782C14.1458 12.4782 15.4565 11.1672 15.4565 9.55036C15.4565 7.9335 14.1456 6.62281 12.5287 6.62281C10.9118 6.62281 9.6004 7.93375 9.6004 9.55061C9.6004 11.1675 10.9118 12.4782 12.5287 12.4782ZM13.7706 12.6777H11.2863C9.21923 12.6777 7.53759 14.3596 7.53759 16.4266V19.4648L7.54531 19.5124L7.75458 19.5779C9.72721 20.1943 11.441 20.3998 12.8516 20.3998C15.6067 20.3998 17.2037 19.6143 17.3021 19.5642L17.4976 19.4653H17.5186V16.4266C17.5193 14.3596 15.8377 12.6777 13.7706 12.6777ZM18.6145 9.65525H16.1493C16.1227 10.6416 15.7016 11.5297 15.0357 12.1685C16.8731 12.7148 18.2174 14.4186 18.2174 16.4311V17.3674C20.6514 17.2782 22.054 16.5883 22.1464 16.542L22.342 16.4428H22.3629V13.4037C22.3629 11.3369 20.6813 9.65525 18.6145 9.65525ZM7.39135 9.45594C7.9641 9.45594 8.497 9.28877 8.94842 9.00402C9.09192 8.06803 9.59368 7.25013 10.3104 6.69307C10.3134 6.63826 10.3186 6.58395 10.3186 6.52864C10.3186 4.91153 9.00772 3.60084 7.39135 3.60084C5.77399 3.60084 4.46355 4.91153 4.46355 6.52864C4.46355 8.14501 5.77399 9.45594 7.39135 9.45594ZM10.0207 12.1685C9.358 11.533 8.93846 10.6495 8.90782 9.6692C8.81638 9.66247 8.72595 9.65525 8.63277 9.65525H6.14868C4.08163 9.65525 2.39999 11.3369 2.39999 13.4037V16.4423L2.40772 16.4892L2.61699 16.5552C4.19947 17.0492 5.6118 17.2769 6.83853 17.3487V16.4311C6.83902 14.4186 8.18284 12.7153 10.0207 12.1685Z"
        fill="white"
      />
    </svg>
  );
};

export default UserIcon;
