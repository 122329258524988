/* eslint-disable react/react-in-jsx-scope */
// @mui
import { Box, CircularProgress, TableCell, TableRow } from '@mui/material';
//

// ----------------------------------------------------------------------

interface TableLoadingDataProps {
  isLoading: boolean;
}

export default function TableLoadingData({ isLoading }: TableLoadingDataProps) {
  return (
    <>
      {isLoading && (
        <TableRow sx={{ width: '100%' }}>
          <TableCell colSpan={9} sx={{ border: 'none' }}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} padding={1} paddingY={'15%'}>
              <CircularProgress
                sx={{
                  color: '#ffcc33',
                }}
              />
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
