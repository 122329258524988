/* eslint-disable react/react-in-jsx-scope */
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddDialogProps } from './add-dialog.types';
import { AddDialogStyle } from './add-dialog.styles';
import CloseIcon from '../../../../svgComponents/closeIcon';
import ApprovedAdsForm from '../form/ApprovedAdsForm';

export default function AddDialog({ open, onClose, currentApprovedAds, isEyeIconClicked, isAdsUsers }: AddDialogProps) {
  const { t } = useTranslation();
  return (
    <AddDialogStyle open={open} fullWidth sx={{ '& .MuiDialog-paper': { height: 'auto !important' } }} className="artgapi-wrapper">
      <Stack display={'flex'} direction={'row'} justifyContent={'space-between'} padding={2}>
        <Typography variant={'h5'}>{isEyeIconClicked && isAdsUsers ? t('adds.view_users_ads_title') : t('adds.view_approved_ads_title')}</Typography>
        <IconButton aria-label="close" color="primary" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <ApprovedAdsForm onClose={onClose} currentApprovedAds={currentApprovedAds} isEyeIconClicked={isEyeIconClicked} />
    </AddDialogStyle>
  );
}
