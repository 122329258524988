/* eslint-disable @typescript-eslint/no-explicit-any*/
import { ThunkDispatch } from 'redux-thunk';
import { CONSTANTS, ENDPOINTS, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { PasswordDialogActionTypes } from '../Dialog/models';
import { HIDE_DIALOG } from '../Dialog/types';
import { RootState } from '../rootStore';
import { SnackBarActionTypes } from '../snackBar/models';
import { SHOW_SNACK_BAR } from '../snackBar/types';
import { PasswordActionTypes, PasswordInterface } from './models';
import { PASSWORD_ATTEMPT, PASSWORD_ERROR, PASSWORD_SUCCESS } from './types';
export const useChangePassword = () => {
  const changePassword = (data: PasswordInterface) => {
    return (dispatch: ThunkDispatch<RootState, undefined, PasswordActionTypes | PasswordDialogActionTypes | SnackBarActionTypes>) => {
      {
        dispatch({
          type: PASSWORD_ATTEMPT,
        });
        sendAsyncRequest(HttpMethod.Patch, ENDPOINTS.CHANGE_PASSWORD, data, true, null)
          .then((res: any) => {
            dispatch({
              type: PASSWORD_SUCCESS,
            });

            dispatch({
              type: HIDE_DIALOG,
            });
            dispatch({
              type: PASSWORD_ERROR,
              error: '',
            });
          })
          .catch((error: any) => {
            let errorMessage = '';
            if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
              localStorage.clear();
              dispatch({
                type: SHOW_SNACK_BAR,
                payload: {
                  message: CONSTANTS.SESSION_EXPIRED,
                  severity: 'error',
                  icon: 'ErrorICon',
                  size: 'medium',
                },
              });
              dispatch({
                type: PASSWORD_ERROR,
                error: errorMessage,
              });
            } else if (error.response.data.message === 'Current password does not match') {
              errorMessage = 'Votre mot de passe actuel est incorrect';
              dispatch({
                type: PASSWORD_ERROR,
                error: errorMessage,
              });
            } else {
              dispatch({
                type: PASSWORD_ERROR,
                error: errorMessage,
              });
            }
          });
      }
    };
  };
  return {
    changePassword,
  };
};
