import { Stack, styled } from '@mui/material';

export const RootStyle = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'inherit',
  backgroundColor: '#f5f5f5',
  width: '100%',
  height: '100%',
  padding: '0 16px',
  [theme.breakpoints.up('md')]: {
    padding: '0 32px',
  },
}));
