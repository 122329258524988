import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Container,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Stack,
  Button,
  Grid,
  InputLabel,
  ThemeProvider,
  AppBar,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { useDebounce } from '@uidotdev/usehooks';
import { TABLE_COLUMNS_CONFIG, initialPaginationState } from './Transactions.constant';
import TableLoadingData from '../table/TableLoadingData';
import TableNoData from '../table/TableNoData';
import { TablePaginationActions } from '../table/TablePaginationActions/TablePaginationActions';
import TableHeadCustom from '../table/table-head/CustomTableHead';
import { ContainerStyle, CustomTitle, GridStyle, InputStyle } from '../../utils/toolbar/CommonToolbar.styles';
import DateFilter from '../../utils/DateFilter';
import SearchIcon from '../svgComponents/searchIcon';
import { RootState } from '../../store/rootStore';
import useTable from '../../hooks/useTable';
import TransactionsTableRow from './transactions-row/TransactionsTableRow';
import { theme } from '../../theme/theme';
import { menuBar } from '../../theme/menuBarTheme';
import { RootStyle } from '../../pages/systemusers/SystemUser.styles';
import { transactionReportApi } from '../../store/transactions/api/TransactionReports.api';
import {
  ITransactionReportResponse,
  ITransactionReportState,
  TransactionReportActionTypes,
} from '../../store/transactions/slice/TransactionReports-slice.types';
import { Transactions } from '../../models/Transactions';

function TransactionListing() {
  const { t } = useTranslation();
  const currentDate = new Date();

  const [filterKeyword, setfilterKeyword] = useState('');
  const [exportUsers, setExportUsers] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialPaginationState);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, TransactionReportActionTypes>>();
  const { data, isLoading } = useSelector<RootState, ITransactionReportState>((state: RootState) => state[transactionReportApi.reducerPath]);

  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    // Format startDate and endDate to include only the date part
    const formattedStartDate = startDate ? formatDate(startDate) : '';
    const formattedEndDate = endDate ? formatDate(endDate) : '';

    dispatch(
      transactionReportApi.endpoints.getTransactionReportsUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }),
    );
  }, [order, orderBy, page, rowsPerPage, debouncedSearchTerm, startDate, endDate, dispatch]);

  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
    setPage(1);
  };

  useEffect(() => {
    if (exportUsers) {
      window.open(exportUsers, '_blank');
    }
  }, [exportUsers]);

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDate = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  const isFullFilled = !isLoading && data && data?.data?.transactionData?.length > 0;

  return (
    <>
      {/**/}

      <RootStyle>
        <ContainerStyle>
          <GridStyle>
            <CustomTitle>{t('common.transaction_reports')}</CustomTitle>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Stack direction="column" spacing={4}>
                <InputStyle
                  value={filterKeyword}
                  placeholder={t('common.search')}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterKeyword(e.target.value)}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                  }}
                />
              </Stack>
            </div>
          </GridStyle>
        </ContainerStyle>

        <DateFilter
          startDate={startDate}
          endDate={endDate}
          handleClear={handleClear}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
        />

        <Container sx={{ minHeight: '70vh' }}>
          <div className="table-block">
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <TableHeadCustom order={order} orderBy={orderBy} columnsConfig={TABLE_COLUMNS_CONFIG} onSort={onSort} />
                <TableBody>
                  {isFullFilled &&
                    (data as ITransactionReportResponse)?.data?.transactionData?.map((row: Transactions) => {
                      return <TransactionsTableRow key={row.id} row={row} selected={false} columnsConfig={TABLE_COLUMNS_CONFIG} />;
                    })}
                  <TableNoData isNotFound={!isLoading && data?.data?.transactionData?.length === 0} title={t('masterClass.transaction_no_data')} />

                  <TableLoadingData isLoading={isLoading} />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {isFullFilled && (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={data ? data.total : 0}
                rowsPerPage={rowsPerPage}
                page={data && page ? page - 1 : 0}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t('common.rowsperpage')}
                sx={{
                  '& .MuiInputBase-root': {
                    maxWidth: '60px',
                  },
                }}
              />
            </Box>
          )}
        </Container>
      </RootStyle>
    </>
  );
}
export default TransactionListing;
