export const interests = {
  name: 'Name',
  actions: 'Actions',
  interests_title: 'Titre',
  interest_category_name: 'Nom de catégorie',
  confirm_delete_title: 'Confirmer la suppression',
  confirm_delete_message: `Veuillez vous assurer que lors de la suppression d'une catégorie, tous les centres d'intérêt associés sont également supprimés.`,
  title: 'Catégories',
  add: 'CRÉER UNE CATÉGORIE',
  no_data: 'Aucune catégorie trouvée',
  delete_success_message: 'La catégorie a été supprimée avec succès',
  update_success_message: 'La catégorie a été modifiée avec succès',
  update_button_label: 'Mise à jour',
  create_button_label: 'Créer',
  view_category_title: 'Voir les détails de la catégorie',
  update_category_title: 'Modifier une catégorie',
  add_category_title: 'Créer une catégorie',
  view_interest_title: 'Voir les détails des intérêts',
  update_interest_title: 'Modifier les intérêts',
  add_interest_title: 'Créer un intérêt',
  update_interest_success_message: 'Les intérêts ont été modifiés avec succès',
  delete_interest_success_message: "L'intérêt a été supprimé avec succès",
  confirm_interest_delete_message: 'Êtes-vous sûr de vouloir supprimer cet intérêt ?',
  interest_no_data: 'Aucun intérêts trouvé',
  interest_title: 'Intérêts',
  interest_add: 'CRÉER UN INTÉRÊT',
  create_success_message: 'La catégorie a été créée avec succès',
  create_interest_success_message: "L'intérêt a été créé avec succès",
  interstile_delete_message: 'Êtes-vous sûr de vouloir supprimer ces interstitiels ?',
  category_exsist: 'La catégorie existe déjà',
  listoflangauges: 'Liste des langues',
  interest_already_exists: `Le titre d'intérêt existe déjà`,
  form_fields: {
    title: {
      label: 'Titre*',
      placeholder: 'Titre',
      required_error_message: 'Le champ Titre est requis',
      max_length_error_message: 'Le titre ne peut pas comporter plus de 60 caractères',
    },
    category_id: {
      label: 'Catégorie*',
      placeholder: 'Catégorie',
      required_error_message: 'Le champ Catégorie est requis',
    },
    language: {
      label: 'Language*',
      placeholder: 'Language',
      required_error_message: 'Toutes les langues sont requises',
    },
  },
  artist: {
    delete_success_message: `L'artiste a été supprimé avec succès`,
  },
  art: {
    delete_success_message: `L'art a été supprimé avec succès`,
  },
};
