import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { ENDPOINTS, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { RootState } from '../rootStore';
import { SnackBarActionTypes } from '../snackBar/models';
import { interestCategoriesActionTypes } from './models';
import { GET_INTEREST_CATEGORIES_ATTEMPT, GET_INTEREST_CATEGORIES_ERROR, GET_INTEREST_CATEGORIES_SUCCESS } from './types';

export const getInterestCategories =
  () => async (dispatch: ThunkDispatch<RootState, undefined, interestCategoriesActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: GET_INTEREST_CATEGORIES_ATTEMPT,
    });

    sendAsyncRequest(HttpMethod.Get, ENDPOINTS.GET_CATEGORIES, {}, true, null)
      .then((res: AxiosResponse) => {
        dispatch({
          type: GET_INTEREST_CATEGORIES_SUCCESS,
          categoriesList: res.data.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: GET_INTEREST_CATEGORIES_ERROR,
          error: error,
        }),
      );
  };
