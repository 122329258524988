/* eslint-disable react/react-in-jsx-scope */
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddDialogProps } from './add-dialog.types';
import { AddDialogStyle } from './add-dialog.styles';
import CloseIcon from '../../../svgComponents/closeIcon';
import CategoriesForm from '../form/CategoriesForm';

export default function AddDialog({ open, onClose, currentCategories, isUpdate, isEyeIconClicked }: AddDialogProps) {
  const { t } = useTranslation();
  return (
    <AddDialogStyle open={open} fullWidth sx={{ '& .MuiDialog-paper': { height: 'auto !important' } }} className="artgapi-wrapper">
      <Stack display={'flex'} direction={'row'} justifyContent={'space-between'} padding={2}>
        <Typography variant={'h5'}>
          {isEyeIconClicked
            ? t('interests.view_category_title')
            : isUpdate
            ? t('interests.update_category_title')
            : t('interests.add_category_title')}
        </Typography>
        <IconButton aria-label="close" color="primary" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />

      <CategoriesForm onClose={onClose} currentCategories={currentCategories} isUpdate={isUpdate} isEyeIconClicked={isEyeIconClicked} />
    </AddDialogStyle>
  );
}
