/* eslint-disable react/react-in-jsx-scope */
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { Stack, TextFieldProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomLabel, RHFTextFieldStyle } from './RHFTextField.styles';

// ----------------------------------------------------------------------

type RHFTextFieldProps = {
  name: string;
} & TextFieldProps;

export default function RHFTextField({ name, label, ...other }: RHFTextFieldProps) {
  const { control } = useFormContext();
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Stack direction={'column'}>
          <CustomLabel htmlFor={name}>{label}</CustomLabel>
          <RHFTextFieldStyle {...field} fullWidth error={!!error} helperText={error ? t(`${error?.message}`) : ''} {...other} />
        </Stack>
      )}
    />
  );
}
