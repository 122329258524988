import React from 'react';
import { Grid } from '@mui/material';
import OpportunitiesList from '../../components/opportunities/opportunities';

/**
 * Component OpportunitiesPage
 *
 * @component
 *
 * @example
 * return (
 *   <OpportunitiesPage />
 * )
 */

const OpportunitiesPage = () => {
  return (
    <Grid className="artgapi-resposive" sx={{ width: 1 }} container alignItems="center" justifyContent="center">
      <OpportunitiesList />
    </Grid>
  );
};

export default OpportunitiesPage;
