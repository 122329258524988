import { TableCell, TableSortLabel, styled } from '@mui/material';
import { TableCellStyleProps } from './CustomTableHead.types';

export const TableCellStyle = styled(TableCell)(({ minwidth, width }: TableCellStyleProps) => ({
  width: width,
  minWidth: minwidth,
  borderBottom: 'none',
}));

export const TableSortLableStyle = styled(TableSortLabel)({
  textTransform: 'uppercase',
  color: '#a4a4a5',
  fontFamily: 'SFProText',
  '&.MuiTableSortLabel-root': {
    color: '#a4a4a5', // Change the color when root
  },
});
