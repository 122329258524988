import React from 'react';

export const ReportIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8 1H4.2C2.9864 1 2 1.92475 2 3.0625V16.9375C2 18.0752 2.9864 19 4.2 19H15.8C17.0136 19 18 18.0752 18 16.9375V3.0625C18 1.92475 17.0136 1 15.8 1ZM9 10.75V7.057C10.92 7.33075 12.4 8.875 12.4 10.75C12.4 12.8177 10.6056 14.5 8.4 14.5C7.5136 14.5 6.7016 14.2195 6.0376 13.7598L8.824 11.1475C8.9368 11.0425 9 10.8993 9 10.75ZM7.8 7.057V10.5175L5.1896 12.9648C4.6992 12.3423 4.4 11.581 4.4 10.75C4.4 8.875 5.88 7.33075 7.8 7.057ZM15 16.9375H5C4.6688 16.9375 4.4 16.6855 4.4 16.375C4.4 16.0645 4.6688 15.8125 5 15.8125H15C15.3312 15.8125 15.6 16.0645 15.6 16.375C15.6 16.6855 15.3312 16.9375 15 16.9375ZM15 14.5H13.8C13.4688 14.5 13.2 14.248 13.2 13.9375C13.2 13.627 13.4688 13.375 13.8 13.375H15C15.3312 13.375 15.6 13.627 15.6 13.9375C15.6 14.248 15.3312 14.5 15 14.5ZM15 12.4375H13.8C13.4688 12.4375 13.2 12.1855 13.2 11.875C13.2 11.5645 13.4688 11.3125 13.8 11.3125H15C15.3312 11.3125 15.6 11.5645 15.6 11.875C15.6 12.1855 15.3312 12.4375 15 12.4375ZM15 10.1875H13.8C13.4688 10.1875 13.2 9.9355 13.2 9.625C13.2 9.3145 13.4688 9.0625 13.8 9.0625H15C15.3312 9.0625 15.6 9.3145 15.6 9.625C15.6 9.9355 15.3312 10.1875 15 10.1875ZM15 8.125H13.8C13.4688 8.125 13.2 7.873 13.2 7.5625C13.2 7.252 13.4688 7 13.8 7H15C15.3312 7 15.6 7.252 15.6 7.5625C15.6 7.873 15.3312 8.125 15 8.125Z"
        fill="white"
      />
    </svg>
  );
};

export default ReportIcon;
