/* eslint-disable react/react-in-jsx-scope */
// form
import { FormProvider as Form } from 'react-hook-form';
import { FormProviderProps } from './form-provider.types';

// ----------------------------------------------------------------------

export default function FormProvider({ children, onSubmit, methods }: FormProviderProps) {
  return (
    <Form {...methods}>
      <form onSubmit={onSubmit}>{children}</form>
    </Form>
  );
}
