import Company from '../../models/Company';
import { compagniesActionTypes, CompagniesState } from './models';
import {
  CREATE_COMPANIES_ATTEMPT,
  CREATE_COMPANIES_ERROR,
  CREATE_COMPANIES_SUCCESS,
  DELETE_COMPANIES_ATTEMPT,
  DELETE_COMPANIES_ERROR,
  DELETE_COMPANIES_SUCCESS,
  GET_COMPANIES_ATTEMPT,
  GET_COMPANIES_ERROR,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIE_ATTEMPT,
  GET_COMPANIE_ERROR,
  GET_COMPANIE_SUCCESS,
  UPDATE_COMPANIE_ATTEMPT,
  UPDATE_COMPANIE_ERROR,
  UPDATE_COMPANIE_STATUS_ATTEMPT,
  UPDATE_COMPANIE_STATUS_ERROR,
  UPDATE_COMPANIE_STATUS_SUCCESS,
  UPDATE_COMPANIE_SUCCESS,
  RESET_COMPANIES_ERROR,
} from './types';

const INITIAL_STATE: CompagniesState = {
  AllCompagnies: { loader: false, error: '', compagnies: [] },
  newCompany: { loader: false, error: '', campany: undefined },
  deletedCompany: { loader: false, error: '', id: 0 },
  foundCampany: { loader: false, error: '', company: undefined },
  updatedCampany: { loader: false, error: '', campany: undefined },
  updateCampanyStatus: { loader: false, error: '', campany: undefined },
};
export const compagniesReducer = (state = INITIAL_STATE, action: compagniesActionTypes): CompagniesState => {
  switch (action.type) {
    case GET_COMPANIES_ATTEMPT:
      return { ...state, AllCompagnies: { ...state.AllCompagnies, loader: true, error: '' } };
    case RESET_COMPANIES_ERROR:
      return { ...state, newCompany: { ...state.newCompany, loader: false, error: '' } };
    case GET_COMPANIES_SUCCESS:
      return { ...state, AllCompagnies: { ...state.AllCompagnies, loader: false, compagnies: action.compagnies } };
    case GET_COMPANIES_ERROR:
      return { ...state, AllCompagnies: { ...state.AllCompagnies, loader: false, error: action.error } };
    case CREATE_COMPANIES_ATTEMPT:
      return { ...state, newCompany: { ...state.newCompany, loader: true, error: '' } };
    case CREATE_COMPANIES_SUCCESS:
      return {
        ...state,
        newCompany: { ...state.newCompany, loader: false, campany: action.campany },
        AllCompagnies: { ...state.AllCompagnies, compagnies: [...state.AllCompagnies.compagnies, action.campany] },
      };
    case CREATE_COMPANIES_ERROR:
      return { ...state, newCompany: { ...state.newCompany, loader: false, error: action.error } };
    case DELETE_COMPANIES_ATTEMPT:
      return { ...state, deletedCompany: { ...state.deletedCompany, loader: true, error: '' } };
    case DELETE_COMPANIES_SUCCESS: {
      const camps = state.AllCompagnies.compagnies.filter((item) => {
        return item.id !== action.id;
      });
      return {
        ...state,
        deletedCompany: { ...state.deletedCompany, loader: false, id: action.id },
        AllCompagnies: { ...state.AllCompagnies, compagnies: camps },
      };
    }
    case DELETE_COMPANIES_ERROR:
      return { ...state, deletedCompany: { ...state.deletedCompany, loader: false, error: action.error } };
    case GET_COMPANIE_ATTEMPT:
      return { ...state, foundCampany: { ...state.foundCampany, loader: true, error: '' } };
    case GET_COMPANIE_SUCCESS:
      return { ...state, foundCampany: { ...state.foundCampany, loader: false, company: action.foundCampany } };
    case GET_COMPANIE_ERROR:
      return { ...state, foundCampany: { ...state.foundCampany, loader: false, error: action.error } };
    case UPDATE_COMPANIE_ATTEMPT:
      return { ...state, updatedCampany: { ...state.updatedCampany, loader: true, error: '' } };
    case UPDATE_COMPANIE_SUCCESS: {
      const updateCamp = state.AllCompagnies.compagnies.map((item) => {
        if (item.id === action.campany.id && item.type === action.campany.type) {
          return {
            ...item,
            ...action.campany,
          };
        } else if (item.id === action.campany.id && item.type !== action.campany.type) {
          return state.AllCompagnies.compagnies.filter((itemToDelete) => {
            return itemToDelete.id !== action.campany.id;
          });
        } else return item;
      });
      return {
        ...state,
        updatedCampany: { ...state.updatedCampany, loader: false, campany: action.campany },
        AllCompagnies: { ...state.AllCompagnies, loader: false, compagnies: updateCamp as Company[] },
      };
    }
    case UPDATE_COMPANIE_ERROR:
      return { ...state, updatedCampany: { ...state.updatedCampany, loader: false, error: action.error } };
    case UPDATE_COMPANIE_STATUS_ATTEMPT:
      return { ...state, updateCampanyStatus: { ...state.updateCampanyStatus, loader: true, error: '' } };
    case UPDATE_COMPANIE_STATUS_SUCCESS: {
      const updateCamp = state.AllCompagnies.compagnies.map((item) => {
        if (item.id === action.campany.id) {
          return {
            ...item,
            active: action.campany.active,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        AllCompagnies: { ...state.AllCompagnies, loader: false, compagnies: updateCamp },
      };
    }
    case UPDATE_COMPANIE_STATUS_ERROR:
      return { ...state, updateCampanyStatus: { ...state.updateCampanyStatus, loader: false, error: action.error } };
    default:
      return state;
  }
};
