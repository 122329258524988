import React from 'react';
import Box from '@mui/material/Box';
import { AppBar, Grid } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { menuBar } from '../../../theme/menuBarTheme';
import ApprovedLogs from './approved/ApprovedLogs';
import { useTranslation } from 'react-i18next';
import PendingAdds from './pending/PendingAds';
import SubscriptionPlan from './susbscriptionPlan/SubscriptionPlan';
import HowItWorksDetails from './howItWorks/HowItWorks';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      className="artgapi-resposive"
      sx={{ width: 1 }}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={9}>{children}</Box>}
    </Grid>
  );
}

export const AddsMenu = ({ indexVal }: { indexVal: number }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  return (
    <>
      <ThemeProvider theme={menuBar}>
        <AppBar sx={{ position: 'sticky', top: '70px', zIndex: 5 }}></AppBar>
      </ThemeProvider>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        {indexVal === 0 ? (
          <TabPanel value={indexVal} index={indexVal} dir={theme.direction}>
            <PendingAdds />
          </TabPanel>
        ) : indexVal === 1 ? (
          <TabPanel value={indexVal} index={indexVal} dir={theme.direction}>
            <ApprovedLogs />
          </TabPanel>
        ) : indexVal === 2 ? (
          <TabPanel value={indexVal} index={indexVal} dir={theme.direction}>
            <SubscriptionPlan />
          </TabPanel>
        ) : (
          <TabPanel value={indexVal} index={indexVal} dir={theme.direction}>
            <HowItWorksDetails />
          </TabPanel>
        )}
      </Grid>
    </>
  );
};
