import MasterClass from '../../../models/MasterClass';

export interface IMasterClassResponse {
  total: number;
  next: number | null;
  previous: number | null;
  masterClasses: MasterClass[];
}

export interface IMasterClassState {
  isLoading: boolean;
  data: IMasterClassResponse | null;
  masterclass: MasterClass | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_MASTER_CLASS_SUCCESS = 'FETCH_MASTER_CLASS_SUCCESS',
  FETCH_MASTER_CLASS_FAILURE = 'FETCH_MASTER_CLASS_FAILURE',
  CREATE_MASTER_CLASS_SUCCESS = 'CREATE_MASTER_CLASS_SUCCESS',
}

export interface IMasterClassPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchMasterClassSuccessAction {
  type: typeof ActionTypes.FETCH_MASTER_CLASS_SUCCESS | typeof ActionTypes.CREATE_MASTER_CLASS_SUCCESS;
  payload: IMasterClassResponse | MasterClass;
}

export interface IFetchMasterClassRejectedAction {
  type: typeof ActionTypes.FETCH_MASTER_CLASS_FAILURE;
  payload: string;
}

export type MasterClassActionTypes = IMasterClassPendingAction | IFetchMasterClassSuccessAction | IFetchMasterClassRejectedAction;
