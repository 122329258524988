import { PendingAds } from '../../../models/PendingAds';

export interface IPendingAdsResponse {
  total: number;
  next: number | null;
  previous: number | null;
  data: PendingAds[];
}

export interface IPendingAdsState {
  isLoading: boolean;
  data: IPendingAdsResponse | null;
  pendingUsers: PendingAds | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_PENDING_ADS_SUCCESS = 'FETCH_PENDING_ADS_SUCCESS',
  FETCH_PENDING_ADS_FAILURE = 'FETCH_PENDING_ADS_FAILURE',
  CREATE_PENDING_ADS_SUCCESS = 'CREATE_PENDING_ADS_SUCCESS',
}

export interface IPendingAdsPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchPendingAdsSuccessAction {
  type: typeof ActionTypes.FETCH_PENDING_ADS_SUCCESS | typeof ActionTypes.CREATE_PENDING_ADS_SUCCESS;
  payload: IPendingAdsResponse | PendingAds;
}

export interface IFetchPendingAdsRejectedAction {
  type: typeof ActionTypes.FETCH_PENDING_ADS_FAILURE;
  payload: string;
}

export type PendingAdsActionTypes = IPendingAdsPendingAction | IFetchPendingAdsSuccessAction | IFetchPendingAdsRejectedAction;
