import { userActionTypes, UserState } from './models';
import {
  GET_PROFILE_ATTEMPT,
  GET_PROFILE_ERROR,
  GET_PROFILE_SUCCESS,
  UPDATE_PROFILE_ATTEMPT,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_SUCCESS,
  GET_SETTINGS_ATTEMPT,
  GET_SETTINGS_SUCCESS,
  GET_SETTNGS_ERROR,
  UPDATE_SETTING_ATTEMPT,
  UPDATE_SETTING_SUCCESS,
  UPDATE_SETTING_ERROR,
} from './types';

const INITIAL_STATE: UserState = {
  Profile: {
    loader: false,
    error: '',
    user: undefined,
  },
  NewProfile: {
    loader: false,
    error: '',
  },
  Settings: {
    loader: false,
    error: '',
    user: undefined,
  },
  NewSettings: {
    loader: false,
    error: '',
  },
};
export const userReducer = (state = INITIAL_STATE, action: userActionTypes): UserState => {
  switch (action.type) {
    case GET_PROFILE_ATTEMPT:
      return { ...state, Profile: { ...state.Profile, loader: true, error: '' } };
    case GET_PROFILE_SUCCESS:
      return { ...state, Profile: { ...state.Profile, loader: false, user: action.user } };
    case GET_PROFILE_ERROR:
      return { ...state, Profile: { ...state.Profile, loader: false, error: action.error } };
    case UPDATE_PROFILE_ATTEMPT:
      return { ...state, NewProfile: { ...state.NewProfile, loader: true, error: '' } };
    case UPDATE_PROFILE_SUCCESS:
      return { ...state, NewProfile: { ...state.NewProfile, loader: false } };
    case UPDATE_PROFILE_ERROR:
      return { ...state, NewProfile: { ...state.NewProfile, loader: false, error: action.error } };
    case GET_SETTINGS_ATTEMPT:
      return { ...state, Settings: { ...state.Settings, loader: true, error: '' } };
    case GET_SETTINGS_SUCCESS:
      return { ...state, Settings: { ...state.Settings, loader: false, user: action.user } };
    case GET_SETTNGS_ERROR:
      return { ...state, Settings: { ...state.Settings, loader: false, error: action.error } };
    case UPDATE_SETTING_ATTEMPT:
      return { ...state, NewSettings: { ...state.NewSettings, loader: true, error: '' } };
    case UPDATE_SETTING_SUCCESS:
      return { ...state, NewSettings: { ...state.NewSettings, loader: false } };
    case UPDATE_SETTING_ERROR:
      return { ...state, NewSettings: { ...state.NewSettings, loader: false, error: action.error } };
    default:
      return state;
  }
};
