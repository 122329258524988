export const GETTING_ARTICLES = 'GETTING_ARTICLES/loading';
export const GET_ARTICLES_SUCCESS = 'GET_ARTICLES_SUCCESS';

export const GETTING_ARTICLE = 'GETTING_ARTICLE/loading';
export const GET_ARTICLE_SUCCESS = 'GET_ARTICLE_SUCCESS';

export const UPDATE_ARTICLE = 'UPDATE_ARTICLE/loading';
export const DELETE_ARTICLE = 'DELETE_ARTICLE/SUCCESS';

export const ADD_ARTICLE = 'ADD_ARTICLE/SUCCESS';
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS';
export const GET_ARTICLES_ERROR = 'GET_ARTICLES_ERROR';

export const CHANGE_ARTICLE_STAUTS = 'CHANGE_ARTICLE_STAUTS';
