/* eslint-disable react/react-in-jsx-scope */

export default function ProfileIcon({ name }: { name: string }) {
  const renderIcon = (iconName: string) => <img src={`icons/profile_${iconName}.png`} width={25} height={25} />;

  if (!name || name === 'leadership' || name === 'artgaper') {
    return renderIcon('user');
  }
  if (name === 'user') {
    return renderIcon('artlover');
  }
  return renderIcon(name);
}
