/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { SubscriptionPlanFormProps } from './SubscriptionPlan-form.types';
import { SubscriptionPlanActionTypes } from '../../../../../store/subscriptionPlan/slice/SubscriptionPlan-slice.types';
import RHFTextField from '../../../../hook-form/RHFTextField';
import CustomLoadingButton from '../../../../button/CustomLoadingButton';
import { subscriptionPlanApi } from '../../../../../store/subscriptionPlan/api/SubscriptionPlan.api';
import { RootState } from '../../../../../store/rootStore';
import useSnackBar from '../../../../../hooks/useSnackBar';
import FormProvider from '../../../../hook-form/FormProvider';
import { SubscriptionPlan } from '../../../../../models/SubscriptionPlan';
import { CreateSubscriptionPLanSchema } from './craete-plan-schema';

const { updateSubscriptionPlan } = subscriptionPlanApi.endpoints;

export default function SubscriptionPlanForm({ currentSubscriptionPlan, isUpdate, onClose, isEyeIconClicked }: SubscriptionPlanFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, SubscriptionPlanActionTypes>>();

  const defaultValues = useMemo<SubscriptionPlan>(
    () => ({
      plan_name: currentSubscriptionPlan?.plan_name || '',
      plan_description: currentSubscriptionPlan?.plan_description || '',
      ServicePlans: currentSubscriptionPlan?.ServicePlans || [],
    }),
    [currentSubscriptionPlan],
  );

  const methods = useForm<SubscriptionPlan>({
    resolver: yupResolver(CreateSubscriptionPLanSchema) as Resolver<SubscriptionPlan>, // Cast resolver to Resolver<PendingAds>
    defaultValues,
    mode: 'all',
  });

  const { alertSuccess, alertError } = useSnackBar();

  const {
    reset,
    handleSubmit,
    trigger,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;
  const values = watch();

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentSubscriptionPlan]);

  useEffect(() => {
    // Update defaultValues whenever currentSubscriptionPlan or isUpdate changes
    const updatedDefaultValues = {
      plan_name: currentSubscriptionPlan?.plan_name || '',
      plan_description: currentSubscriptionPlan?.plan_description || '',
      ServicePlans: currentSubscriptionPlan?.ServicePlans || [],
    };
    methods.reset(updatedDefaultValues);
  }, [currentSubscriptionPlan, isUpdate]);

  const onSubmit = async () => {
    if (isUpdate) {
      update();
    }
  };

  const update = async () => {
    try {
      trigger().then(async (isValid) => {
        if (isValid && currentSubscriptionPlan?.id) {
          const updatedData = {
            id: currentSubscriptionPlan.id,
            plan_name: values?.plan_name,
            plan_description: values?.plan_description || '',
            ServicePlans: values?.ServicePlans?.map((servicePlan: any) => ({
              ...servicePlan,
              description: servicePlan.description, // Use the updated description from the form
            })),
          };
          dispatch(updateSubscriptionPlan(currentSubscriptionPlan?.id, updatedData))
            .then(() => {
              reset(defaultValues);
              dispatch(alertSuccess(t('adds.plan_update_success_message')));
              onClose();
            })
            .catch((err) => {
              dispatch(alertError(err.message)); // Show error message in alert
            });
        }
      });
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    }
  };

  return (
    <>
      {currentSubscriptionPlan ? (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid className="artgapi-plan-form" item xs={12} md={12}>
              <Box sx={{ p: 3 }}>
                <Box sx={{ mb: 3 }}>
                  <Box
                    sx={{
                      display: 'grid',
                      columnGap: 2,
                      rowGap: 3,
                      gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <RHFTextField
                      name={'plan_name'}
                      label={t('adds.form_fields.planname.label')}
                      placeholder={t('adds.form_fields.planname.placeholder')}
                      disabled={isEyeIconClicked}
                    />
                    <RHFTextField
                      name={'plan_description'}
                      placeholder={t('adds.form_fields.description.placeholder')}
                      label={t('adds.form_fields.description.label')}
                      disabled={isEyeIconClicked}
                    />
                  </Box>
                </Box>

                {values?.ServicePlans?.map((item: any, index: number) => {
                  return (
                    <>
                      <Box sx={{ mb: 3 }}>
                        <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                          {item.name}
                        </Typography>
                        <Box
                          sx={{
                            display: 'grid',
                            columnGap: 2,
                            rowGap: 3,
                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                          }}
                        >
                          <RHFTextField
                            name={`ServicePlans[${index}].price`}
                            label={t('adds.form_fields.planPrice.label')}
                            placeholder={t('adds.form_fields.planPrice.placeholder')}
                            disabled={isEyeIconClicked}
                            defaultValue={item.price}
                          />
                          <RHFTextField
                            name={`ServicePlans[${index}].description`}
                            label={t('adds.form_fields.planDescription.label')}
                            placeholder={t('adds.form_fields.planDescription.placeholder')}
                            disabled={isEyeIconClicked}
                            defaultValue={item.description}
                          />
                        </Box>
                      </Box>
                    </>
                  );
                })}
                {isEyeIconClicked ? (
                  ''
                ) : (
                  <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                    <CustomLoadingButton
                      loading={isSubmitting}
                      label={isUpdate ? t('adds.update_button_label') : t('adds.create_button_label')}
                      onClick={onSubmit}
                    />
                  </Stack>
                )}
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
      ) : (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
          <Grid item xs={3}>
            <CircularProgress
              sx={{
                color: '#ffcc33',
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
