export const colors = {
  BLACK: '#0d0d0d',
  WHITE: '#ffffff',
  BODY_GREY: '#f5f5f5',
  MAIN_GREY: '#a4a4a5',
  green: '#1ab53c',
  yellow: '#ffcc33',
  HOVER_COLOR: '#0000000a',
  purpule: '#7737ff',
  Red: '#FF453A',
};
