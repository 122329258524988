export const portfolioPlans = {
  title: 'Subscription Plan',
  add: 'CREATE A SUBSCRIPTION PLAN',
  listTitle: 'Title',
  description: 'Description',
  confirm_delete_title: 'Confirm deletion',
  confirm_delete_message: 'Are you sure you want to remove this subscription plan ?',
  no_data: 'No subscription plan found',
  delete_success_message: 'Subscription plan was successfully deleted',
  view_portfolio_title: 'View subscription plan details',
  update_portfolio_title: 'Edit a subscription plan',
  add_portfolio_title: 'Create a subscription plan',
  create_success_message: 'Subscription plan has been created successfully',
  update_success_message: 'Subscription plan has been updated successfully',
  form_fields: {
    title: {
      label: 'Title*',
      placeholder: 'Title',
      max_length_error_message: 'Title cannot be longer than 50 characters',
      required_error_message: 'Title field is required',
    },
    description: {
      label: 'Description*',
      placeholder: 'Description',
      required_error_message: 'Description field is required',
    },
    ispaid: {
      label: 'IsPaid*',
      placeholder: 'IsPaid',
      required_error_message: 'IsPaid field is required',
    },
    price: {
      label: 'Amount*',
      placeholder: 'Amount',
      required_error_message: 'Amount field is required',
    },
    duration: {
      label: 'Duration*',
      placeholder: 'Duration',
      required_error_message: 'Duration field is required',
    },
  },
};
