import { Opportunities, OpportunitiesActionTypes, OpportunitiesState, Opportunity } from './models';
import {
  ADD_OPPORTUNITY,
  CREATE_OPPORTUNITY_SUCCESS,
  DELETE_OPPORTUNITY,
  GETTING_OPPORTUNITIES,
  GET_OPPORTUNITIES_SUCCESS,
  GET_OPPORTUNITY_SUCCESS,
} from './types';

const INITIAL_STATE: OpportunitiesState = {
  opportunities: {} as Opportunities,
  opportunity: {} as Opportunity,
  loading: true,
  loadingDetailOpportunity: true,
  addLoading: false,
  error: '',
};
export const opportunitiesReducer = (state: OpportunitiesState = INITIAL_STATE, action: OpportunitiesActionTypes): OpportunitiesState => {
  switch (action.type) {
    case GETTING_OPPORTUNITIES:
      return {
        ...state,
        loading: true,
      };
    case GET_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        opportunities: action.payload,
      };
    case GET_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        loading: false,
        opportunity: action.payload,
      };
    case ADD_OPPORTUNITY:
      return {
        ...state,
        loading: false,
      };
    case CREATE_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        opportunity: action.opportunity,
      };
    case DELETE_OPPORTUNITY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
