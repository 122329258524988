import { tagsActionTypes, TagsState } from './models';
import { CLEAR_NEW_TAG, CREATE_TAG_ATTEMPT, CREATE_TAG_ERROR, CREATE_TAG_SUCCESS, GET_TAGS_ATTEMPT, GET_TAGS_ERROR, GET_TAGS_SUCCESS } from './types';

const INITIAL_STATE: TagsState = {
  loader: false,
  error: '',
  tags: [],
  newTag: null,
};

export const tagsReducer = (state = INITIAL_STATE, action: tagsActionTypes): TagsState => {
  switch (action.type) {
    case GET_TAGS_ATTEMPT:
      return { ...state, loader: true, error: '' };
    case GET_TAGS_SUCCESS:
      return { ...state, loader: false, tags: action.tags };
    case GET_TAGS_ERROR:
      return { ...state, loader: false, error: action.error };
    case CREATE_TAG_ATTEMPT:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case CREATE_TAG_SUCCESS:
      return {
        ...state,
        loader: false,
        newTag: action.newTag, // Store the newly created tag separately
      };
    case CREATE_TAG_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case CLEAR_NEW_TAG:
      return {
        ...state,
        newTag: null, // Clear the newTag after use
      };
    default:
      return state;
  }
};
