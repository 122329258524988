/* eslint-disable @typescript-eslint/no-explicit-any */
import Advertisements from '../../../models/Advertisments';
import {
  ActionTypes,
  IFetchAdvertisementsRejectedAction,
  IFetchAdvertisementsSuccessAction,
  IAdvertisementsPendingAction,
  IAdvertisementsResponse,
  IAdvertisementsState,
  AdvertisementsActionTypes,
} from './Advertisments-slice-types';

export const initialState: IAdvertisementsState = {
  isLoading: false,
  error: null,
  data: null,
  advertisements: null,
};

export const advertisementsReducer = (state = initialState, action: AdvertisementsActionTypes): IAdvertisementsState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_ADVERTISEMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload as IAdvertisementsResponse,
      };
    case ActionTypes.FETCH_ADVERTISEMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_ADVERTISEMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        advertisements: action.payload as Advertisements,
      };
    default:
      return state;
  }
};

const matchPending = (): IAdvertisementsPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (
  data: IAdvertisementsResponse,
  action: 'fetch' | 'create' | 'delete' | 'update',
): IFetchAdvertisementsSuccessAction | Advertisements => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_ADVERTISEMENTS_SUCCESS : ActionTypes.CREATE_ADVERTISEMENTS_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchAdvertisementsRejectedAction => {
  return {
    type: ActionTypes.FETCH_ADVERTISEMENTS_FAILURE,
    payload: error,
  };
};

export const advertisementsActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
