/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Grid, ThemeProvider } from '@mui/material';
import { Bug, BugstatePaginated } from '../../../store/bugs/models';
import { dialogHeaderTheme } from '../../../theme/headerDialogTheme';
import { CONSTANTS } from '../../../utils';
import { DATA } from '../../../store/flags/models';
import BugDialogHeader from './bugDialogHeader';
import FlagDialogHeader from './flagDialogHeader';

interface DialogHeaderProps {
  title: string;
  onClose: () => void;
  data: BugstatePaginated;
  status: string;
  bug?: Bug;
  flag?: DATA;
  name?: string;
  type?: string;
  deletePubUserOrCommentFlag?: any;
  statusTable?: string;
  loadingState?: boolean;
}

export const DialogHeader = (props: DialogHeaderProps) => {
  const { title, type, onClose, bug, name, flag, deletePubUserOrCommentFlag, statusTable, loadingState } = props;
  return (
    <>
      <ThemeProvider theme={dialogHeaderTheme}>
        {name === CONSTANTS.Bugs ? (
          <Grid container display="row">
            <BugDialogHeader loadingState={loadingState} bug={bug} statusTable={statusTable} onClose={onClose} title={title} />
          </Grid>
        ) : name === CONSTANTS.Flags ? (
          <Grid container display="row">
            <FlagDialogHeader
              loadingState={loadingState}
              flag={flag}
              deletePubUserOrCommentFlag={deletePubUserOrCommentFlag}
              onClose={onClose}
              title={title}
              type={type}
              statusTable={statusTable}
            />
          </Grid>
        ) : (
          <></>
        )}
      </ThemeProvider>
    </>
  );
};

export default DialogHeader;
