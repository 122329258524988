import { createTheme } from '@mui/material/styles';
import { colors } from './sharedTheme';

export const dialogTheme = createTheme({
  palette: {
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },

  typography: {
    allVariants: {
      textTransform: 'none',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'SFPro-Regular',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      letterSpacing: 'normal',
      lineHeight: 'normal',
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            marginTop: 100,
            minWidth: '1096px',
            maxHeight: '698px',
            height: '550px',
            borderRadius: 0,
            overflowY: 'hidden',
          },
          '&.MuiDialog-container': {
            overflowY: 'hidden',
          },
          '.validate_week': {
            color: colors.MAIN_GREY,
            fontSize: '16px',
          },
          '.icon': {
            fill: colors.MAIN_GREY,
            height: '24px',
            width: '24px',
          },
          '.head_icon': {
            position: 'absolute',
          },
          '.background': {
            '& .MuiFilledInput-root': {
              backgroundColor: 'white',
              padding: '0px 0px 0px 0px',
            },
          },
          '.header_menu_item_selected': {
            fontSize: '17px',
            fontFamily: 'SFPro-Regular !important',

            textDecoration: 'underline',
            textDecorationThickness: '2px',
            textUnderlineOffset: '6px',
          },
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        h6: {
          fontSize: '24px',
          fontFamily: 'SFPro',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          color: '#26272c',
        },
        h5: {
          fontSize: '12px',
          fontFamily: 'SFPro',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          color: '#a4a4a5',
        },
        h4: {
          fontSize: '17px',
          fontFamily: 'SFPro',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          color: 'black',
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormControl-root': {
            width: '150%',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          width: '87%',
          marginLeft: '11%',
          backgroundColor: '#f5f5f5',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingTop: 15,
          button: {
            fontSize: 16,
            fontFamily: 'SFPro-Regular',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
        },
      },
    },
  },
});
