import { CounterActionTypes, CounterState } from './models';
import {
  DECREMENT_BUGS,
  DECREMENT_NUMBER_OF_APPLICANTS,
  DECREMENT_REPORTINGS,
  INCREMENT_BUGS,
  INCREMENT_NUMBER_OF_APPLICANTS,
  INCREMENT_REPORTINGS,
  INITIALIZE_APPLICANTS_ATTEMPT,
  INITIALIZE_APPLICANTS_ERROR,
  INITIALIZE_APPLICANTS_SUCCESS,
  INITIALIZE_BUGS_ATTEMPT,
  INITIALIZE_BUGS_ERROR,
  INITIALIZE_BUGS_SUCCESS,
  INITIALIZE_REPORTINGS_ATTEMPT,
  INITIALIZE_REPORTINGS_ERROR,
  INITIALIZE_REPORTINGS_SUCCESS,
} from './types';

const INITIAL_STATE: CounterState = {
  getBugs: {
    loader: false,
    error: '',
    bugs: 0,
  },
  getReportings: {
    loader: false,
    error: '',
    reportings: 0,
  },
  getApplicants: {
    loader: false,
    error: '',
    applicants: 0,
  },
};
export const counterReducer = (state = INITIAL_STATE, action: CounterActionTypes): CounterState => {
  switch (action.type) {
    case INITIALIZE_BUGS_ATTEMPT:
      return { ...state, getBugs: { ...state.getBugs, loader: true, error: '' } };
    case INITIALIZE_BUGS_SUCCESS:
      return { ...state, getBugs: { ...state.getBugs, loader: false, bugs: action.bugs } };
    case INITIALIZE_BUGS_ERROR:
      return { ...state, getBugs: { ...state.getBugs, loader: false, error: action.error } };
    case INCREMENT_BUGS:
      return { ...state, getBugs: { ...state.getBugs, bugs: state.getBugs.bugs + 1 } };
    case DECREMENT_BUGS:
      return { ...state, getBugs: { ...state.getBugs, bugs: state.getBugs.bugs - 1 } };
    case INITIALIZE_REPORTINGS_ATTEMPT:
      return { ...state, getReportings: { ...state.getReportings, loader: true, error: '' } };
    case INITIALIZE_REPORTINGS_SUCCESS:
      return { ...state, getReportings: { ...state.getReportings, loader: false, reportings: action.reportings } };
    case INITIALIZE_REPORTINGS_ERROR:
      return { ...state, getReportings: { ...state.getReportings, loader: false, error: action.error } };
    case INCREMENT_REPORTINGS:
      return { ...state, getReportings: { ...state.getReportings, reportings: state.getReportings.reportings + 1 } };
    case DECREMENT_REPORTINGS:
      return { ...state, getReportings: { ...state.getReportings, reportings: state.getReportings.reportings - 1 } };
    case INITIALIZE_APPLICANTS_ATTEMPT:
      return { ...state, getApplicants: { ...state.getApplicants, loader: true, error: '' } };
    case INITIALIZE_APPLICANTS_SUCCESS:
      return { ...state, getApplicants: { ...state.getApplicants, loader: false, applicants: action.applicants } };
    case INITIALIZE_APPLICANTS_ERROR:
      return { ...state, getApplicants: { ...state.getApplicants, loader: false, error: action.error } };
    case INCREMENT_NUMBER_OF_APPLICANTS:
      return { ...state, getApplicants: { ...state.getApplicants, applicants: state.getApplicants.applicants + 1 } };
    case DECREMENT_NUMBER_OF_APPLICANTS:
      return { ...state, getApplicants: { ...state.getApplicants, applicants: state.getApplicants.applicants - 1 } };
    default:
      return state;
  }
};
