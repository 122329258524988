import { TableCell, styled } from '@mui/material';

export const TableCellStyle = styled(TableCell)(({ theme }) => ({
  fontSize: '17px',
  fontWeight: 'normal',
  fontFamily: 'SFPro-Regular',
  color: theme.palette.text.secondary,
  borderTop: `10px solid ${theme.palette.grey[100]}`,
  borderBottom: `10px solid ${theme.palette.grey[100]}`,
  padding: '10px 16px',
}));
