export const advertisements = {
  confirm_delete_title: 'Confirm deletion',
  confirm_delete_message: 'Are you sure you want to remove this advertisement plan ?',
  title: 'Advertisements',
  add: 'CREATE A ADVERTISEMENTS',
  actions: 'Actions',
  video: 'Video Or Image',
  listTitle: 'Title',
  advertisements_no_data: 'No advertisement found',
  delete_success_message: 'Advertisement was successfully deleted',
  create_success_message: 'Advertisement plan has been created successfully',
  update_success_message: 'Advertisement plan has been updated successfully',
  file_size_error: 'The video exceeds the maximum allowed duration of 10 seconds.',
  view_advertisement_title: 'View advertisement details',
  update_advertisement_title: 'Edit an advertisement',
  add_advertisement_title: 'Create an advertisement',
  master_file_size_error: 'The video should be greater than 10 seconds.',
  form_fields: {
    title: {
      label: 'Title*',
      placeholder: 'Title',
      max_length_error_message: 'Title cannot be longer than 50 characters',
      required_error_message: 'Title field is required',
    },
    video: {
      label: 'Video Or Image*',
      placeholder: 'Video Or Image',
      required_error_message: 'Video Or Image field is required',
    },
  },
};
