export const common = {
  published: 'Publier',
  discard: 'Annuler',
  save_and_publish: 'Enregistrer & Publier',
  search: 'Rechercher',
  actions: 'Actions',
  delete: 'Supprimer',
  cancel: 'Annuler',
  confirm: 'confirmer',
  user_reports: `Rapports d'utilisateurs`,
  revenue_reports: `Rapports de revenus`,
  transaction_reports: 'Rapports de transactions',
  ads_users_report: 'Rapports sur les annonces actives',
  Logout: 'Se déconnecter',
  confirm_logout_title: 'Confirmer la déconnexion',
  confirm_logout_message: 'Êtes-vous sûr de vouloir vous déconnecter ?',
  filter: 'Choisir une catégorie',
  masterClassAds: 'Sélectionnez une publicité',
  clear: 'Claire',
  no_image_found: 'Aucune image trouvée',
  arts_of_week: 'Arts de la semaine',
  artists_of_week: 'Artiste de la semaine',
  no_data: 'Aucune donnée disponible',
  classroom_reports: 'Rapports de classe',
  transaction_id: 'ID de transaction',
  plan_name: 'Nom du Régime',
  only_5_boxes: 'Vous pouvez sélectionner au maximum 5 arts de la semaine uniquement.',
  created_five_artists: 'Artistes de la semaine ajoutés avec succès',
  created_five_arts: 'Arts de la semaine ajoutés avec succès',
  rowsperpage: 'Lignes par page',
  export: 'Exporter',
  confirm_delete_artist_message: 'Etes-vous sûr de vouloir supprimer cet Artiste ?',
  confirm_delete_art_message: 'Êtes-vous sûr de vouloir supprimer cet art ?',
  something_wrong_happend: `Quelque chose s'est mal passé`,
  title: 'Titre',
  price: 'Prix',
  image: 'Image',
  loading: 'chargement',
  S: 'Afficher la vidéo',
  selectDuration: 'Sélectionnez la durée',
  dashbaord: 'Tableau de bord',
  welcome_video: 'Vidéo de bienvenue',
  utilisateurs: 'Utilisateurs',
  signalements: 'Signalements',
  transactions: 'Transactions',
  campagnes: 'Campagnes',
  annonces: 'Annonces',
  interests: 'Intérêts',
  mastercclass: 'Cours de maître',
  portfolio: 'Portfolio',
  opportunities: 'Opportunités',
  articles: 'Des articles',
  database: 'Base de données',
  bugs: 'Insectes',
  subadmin: 'Sous-administrateur',
  settings: 'Paramètres',
  profils: 'Profils',
  adminstrator: 'Administrateurs',
  artists: 'Artistes',
  artsweek: 'Arts de la semaine',
  artistweek: 'Artiste de la semaine',
  adusers: "Utilisateurs d'annonces",
  userReports: "Rapports d'utilisateurs",
  incomeReports: 'Rapports de revenus',
  adReports: 'Rapports sur les annonces',
  classReports: 'Rapports de classe',
  interstitials: 'Interstitiels',
  sponserPost: 'Posts Sponsorisees',
  pendingAds: 'Annonces en attente',
  approvedAds: 'Annonces approuvées',
  subscriptionPlan: "Plan d'abonnement",
  howItWorks: 'Comment ça fonctionne',
  categories: 'Catégories',
  advertisements: 'Publicités',
  save: 'Enregistrer',
  password: 'Mot de passe',
  change_password: 'Changer le mot de passe',
  my_profile: 'Mon profil',
  current_password: 'Mot de passe actuel',
  new_password: 'Nouveau mot de passe',
  confirm_password: 'Confirmer nouveau mot de passe',
  list_of_users: 'Liste des utilisateurs par mail',
  profile_of: 'Profils de',
  force_update: 'Forcer la mise à jour',
  app_version: 'Version de l`application',
  no_announcements: "Nombre d'annonces",
  yes: 'Oui',
  no: 'Non',
  weekly_sales: 'Ventes hebdomadaire',
  export_svg: 'Exporter SVG',
  export_png: 'Exporter PNG',
  export_csv: 'Exporter CSV',
  total_work_sale: "Nombre d'oeuvres totales en ventes",
  total_work_sold: "Nombre d'oeuvres totales vendues",
  number_of_subscriptions: "Nombre d'abonnements",
  dim: 'Dim.',
  lun: 'Lun.',
  mar: 'Mar.',
  mer: 'Mer.',
  jeu: 'Jeu.',
  ven: 'Ven.',
  sam: 'Sam.',
  start_date: 'Date de début',
  end_date: 'Date de fin',
  frst_last_name: 'NOM ET PRÉNOM',
  user_name: "NOM D'UTILISATEUR",
  subscription: 'ABONNEMENT',
  name: 'Nom',
  photo: 'Photo',
  type: 'Taper',
  candidates: 'NB. CANDIDATS',
  platform: 'Plateforme',
  format: 'Format',
  Status: 'Statut',
  begining: 'Début',
  end: 'Fin',
  create_new_campagin: 'Créer une nouvelle compagne',
  reason: 'Raison',
  user: 'Utilisateur',
  date: 'Date',
  email: 'E-mail',
  all: 'Tous',
  open: 'Ouvert',
  close: 'Fermé',
  category: 'CATEGORIE',
  publish_date: 'DATE DE PUBL.',
  publication_date: 'Date de publication',
  collab: 'Collaboration',
  expose: 'Exposition',
  master_transaction: 'Transactions de classe principale',
  comment: 'Commentaire',
  publication: 'Publication',
  article: 'Article',
  reported: 'Signalé',
  treaty: 'Traité',
};
