import React from 'react';
import { CircularProgress } from '@mui/material';

interface SpinnerProps {
  loading: boolean;
}

export const Spinner = (props: SpinnerProps) => {
  const { loading } = props;
  return (
    <CircularProgress
      sx={{
        position: 'fixed',
        left: '50%',
        top: '50%',
        zIndex: 999999,
        color: '#ffcc33',
        display: loading ? 'flex' : 'none',
      }}
    />
  );
};
export default Spinner;
