export const systemuser = {
  title: 'Sub-administrator',
  add: 'CREATE A SUB-ADMINISTRATOR',
  status: 'Status',
  actions: 'Actions',
  no_data: 'No sub-admins found',
  add_systemuser_title: 'Create a subadministrator',
  update_systemuser_title: 'Edit a subadmin',
  view_systemuser_title: 'View subadmin details',
  confirm_delete_title: 'Confirm deletion',
  update_button_label: 'Update',
  create_button_label: 'Create',
  delete_success_message: `Subadmin was successfully deleted`,
  update_success_message: 'Subadmin has been successfully changed',
  create_success_message: 'Subadmin has been successfully created',
  status_success_message: 'Status updated successfully',
  nickname_already_used: 'Nickname already used by an existing account',
  email_already_used: 'Email already used by an existing account',
  confirm_delete_message: 'Are you sure you want to remove this subadmin?',
  form_fields: {
    name: {
      label: 'Name*',
      placeholder: 'Name',
      required_error_message: 'Name field is required',
    },
    surname: {
      label: 'Surname*',
      placeholder: 'Surname',
      required_error_message: 'Last Name field is required',
    },
    phone_number: {
      label: 'Phone number*',
      placeholder: 'Phone number',
      required_error_message: 'Phone number field is required',
      invalid_error_message: 'Phone number must be between 7 and 16 characters',
    },
    mail: {
      label: 'Email*',
      placeholder: 'Email',
      required_error_message: `Email address field is required`,
      invalid_error_message: 'Invalid email address',
    },
    email_Address: {
      label: 'Email Address*',
      placeholder: 'Email Address',
      required_error_message: `Email address field is required`,
      invalid_error_message: 'Invalid email address',
    },
    nickname: {
      label: 'Nickname*',
      placeholder: 'Nickname',
      required_error_message: 'The nickname field is required',
    },
    address: {
      label: 'Address*',
      placeholder: 'Adresse',
      required_error_message: `Address field is required`,
    },
    city: {
      label: 'City*',
      placeholder: 'City',
      required_error_message: 'The city field is required',
    },
    zip: {
      label: 'Postal code*',
      placeholder: 'Postal code',
      required_error_message: 'Postal Code field is required',
    },
    activate_deactivate_label: {
      label: 'Enable/Disable',
      placeholder: 'Enable/Disable',
    },
    countryId: {
      label: 'Country*',
      placeholder: 'Country',
      required_error_message: 'The country field is required',
    },
    UserPermissions: {
      label: `Access control*`,
      placeholder: `Access control`,
      required_error_message: 'permission is required',
    },
  },
};
