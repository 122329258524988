import { Avatar, Grid, Typography } from '@mui/material';
import React from 'react';
import { DATA } from '../../../../store/flags/models';
import { colors } from '../../../../theme/sharedTheme';
interface FlagCommentResponseDialogProps {
  flag: DATA;
}

// Response Dialog
export const FlagCommentResponseDialog = (props: FlagCommentResponseDialogProps) => {
  const flag = props.flag;
  return (
    <>
      {flag.flaggedPublicationCommentResponse.text ? (
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '300px',
            justifyContent: 'center',
            marginLeft: '0px !important',
            marginTop: '10%',
          }}
        >
          <Avatar src={flag?.flaggedPublicationCommentResponse?.user?.avatar} style={{ width: 25, height: 25 }} />
          <Typography style={{ marginLeft: '3%', fontFamily: 'SFProText-Medium', fontSize: '14px', fontWeight: 500 }}>
            {flag.flaggedPublicationCommentResponse?.user?.nickname}
          </Typography>
          <Typography style={{ marginLeft: '3%', fontFamily: 'SFProText-Medium', color: colors.MAIN_GREY, fontSize: '14px' }}>
            {flag.flaggedPublicationCommentResponse.text}
          </Typography>
        </Grid>
      ) : (
        ''
      )}
    </>
  );
};

export default FlagCommentResponseDialog;
