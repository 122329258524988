/* eslint-disable react/react-in-jsx-scope */
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../components/svgComponents/closeIcon';
import { AddDialogProps } from './add-dialog.types';
import { AddDialogStyle } from './add-dialog.styles';
import SubAdminForm from '../form/SubAdminForm';
import ViewSystemUser from '../form/ViewSystemUser';

export default function AddDialog({ open, onClose, currentSystemUser, isUpdate, isEyeIconClicked }: AddDialogProps) {
  const { t } = useTranslation();
  return (
    <AddDialogStyle open={open} fullWidth sx={{ '& .MuiDialog-paper': { height: 'auto !important' } }} className="artgapi-wrapper">
      <Stack display={'flex'} direction={'row'} justifyContent={'space-between'} padding={2}>
        <Typography variant={'h5'}>
          {isEyeIconClicked
            ? t('systemuser.view_systemuser_title')
            : isUpdate
            ? t('systemuser.update_systemuser_title')
            : t('systemuser.add_systemuser_title')}
        </Typography>
        <IconButton aria-label="close" color="primary" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      {isEyeIconClicked ? (
        <ViewSystemUser onClose={onClose} currentSystemUser={currentSystemUser} isUpdate={isUpdate} isEyeIconClicked={isEyeIconClicked} />
      ) : (
        <SubAdminForm onClose={onClose} currentSystemUser={currentSystemUser} isUpdate={isUpdate} isEyeIconClicked={isEyeIconClicked} />
      )}
    </AddDialogStyle>
  );
}
