import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from '../layaout';
import { Dashboard, SignIn } from '../pages';
import ActivateAccount from '../pages/ActivateAccount/ActivateAccount';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import ResetPasswordSuccess from '../pages/ResetPasswordSuccess/ResetPasswordSuccess';
import { routes, RouteType } from '../utils/routes';
import GuestRoute from './GuestRoutes';
import PrivateRoute from './PrivateRoutes';
import ForgotPassword from '../pages/auth/signIn/forgotPassword';
import { CONSTANTS, PATHS } from '../utils';
import { RootState } from '../store/rootStore';
import { MenuInterface, SubMenuItem } from '../components/interfaces/menu';
import { menuAdmin } from '../utils/menus';
import getRouteElement from '../utils/subadminRoutes';
import Profil from '../pages/Profil/Profil';
import { useSelector } from 'react-redux';

const menuNavbar = menuAdmin;

function RoutesList() {
  const userGetState = useSelector((state: RootState) => state.permissions.Permission);
  const { userPermission: userGetPermissions } = userGetState;
  const roles = localStorage.getItem(CONSTANTS.ROLE);
  const parsedRoles = roles ? JSON.parse(roles) : null;

  const userGetPermissionsArray = Array.isArray(userGetPermissions) ? userGetPermissions : [];

  const filteredMenu = menuNavbar?.filter((item: MenuInterface) => {
    const permission = userGetPermissionsArray.find((p) => p.moduleName === item.subtitle);
    return permission && permission.permission === 1;
  });

  const createSubmenuRoutes = (menuItem: MenuInterface) => {
    if (menuItem.isSubMenu) {
      return (
        menuItem.subItems?.map((items: SubMenuItem) => ({
          path: items.url ? items.url : '',
          element: items.subtitle ? getRouteElement(items.subtitle) : <div>No element found</div>,
        })) || []
      );
    }
    return [];
  };

  // Create an array of routes including both single routes and arrays of routes for submenus
  const routesArray =
    filteredMenu
      ?.map((menuItem: MenuInterface) => ({
        path: menuItem.url ? menuItem.url : '',
        element: getRouteElement(menuItem.subtitle ? menuItem.subtitle : ''),
      }))
      .concat(filteredMenu?.flatMap((menuItem: MenuInterface) => createSubmenuRoutes(menuItem))) || [];

  // Flatten the routes array
  const flattenedRoutes = routesArray.flat();

  flattenedRoutes.unshift({
    path: PATHS.Dashboard,
    element: <Dashboard />,
  });
  flattenedRoutes.push({
    path: PATHS.Profile,
    element: <Profil />,
  });

  return (
    <Routes>
      {parsedRoles?.name === 'admin'
        ? routes.map((route: RouteType, index: number) => (
            <Route
              key={index}
              path={route.path}
              element={
                <PrivateRoute>
                  <Layout>{route.element} </Layout>
                </PrivateRoute>
              }
            />
          ))
        : flattenedRoutes?.map((route: RouteType, index: number) => (
            <Route
              key={index}
              path={route.path}
              element={
                <PrivateRoute>
                  <Layout>{route.element} </Layout>
                </PrivateRoute>
              }
            />
          ))}
      <Route
        path={PATHS.SignIn}
        element={
          <GuestRoute>
            <SignIn />
          </GuestRoute>
        }
      />
      {/* // <Route path={`${PATHS.Adsusers}/:id`} element={<AdsUsersView />} /> */}
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/activate-account" element={<ActivateAccount />} />
      <Route path="/reset-password-success" element={<ResetPasswordSuccess />} />
      <Route path="*" element={<Navigate replace to={PATHS.Dashboard} />} />
    </Routes>
  );
}

export default RoutesList;
