import { University } from '../../../models/University';

export interface IUniversityResponse {
  total: number;
  nextPage: number | null;
  previousPage: number | null;
  data: University[];
}

export interface IUniversityState {
  isLoading: boolean;
  data: IUniversityResponse | null;
  university: University | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_UNIVERSITY_SUCCESS = 'FETCH_UNIVERSITY_SUCCESS',
  FETCH_UNIVERSITY_FAILURE = 'FETCH_UNIVERSITY_FAILURE',
  CREATE_UNIVERSITY_SUCCESS = 'CREATE_UNIVERSITY_SUCCESS',
}

export interface IUniversityPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchUniversitySuccessAction {
  type: typeof ActionTypes.FETCH_UNIVERSITY_SUCCESS | typeof ActionTypes.CREATE_UNIVERSITY_SUCCESS;
  payload: IUniversityResponse | University;
}

export interface IFetchUniversityRejectedAction {
  type: typeof ActionTypes.FETCH_UNIVERSITY_FAILURE;
  payload: string;
}

export type UniversityActionTypes = IUniversityPendingAction | IFetchUniversitySuccessAction | IFetchUniversityRejectedAction;
