import React from 'react';

export const ArrowDropDown = () => {
  return (
    <svg width="8" height="5" style={{ marginTop: '5px' }} viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 0.599975L8 4.59998L4.76837e-07 4.59997L4 0.599975Z" fill="#1C1B1F" />
    </svg>
  );
};

export default ArrowDropDown;
