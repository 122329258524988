import React from 'react';

export const ProfileIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5423 21.6C20.0648 21.6 21.1223 20.1 20.6235 18.66C19.4085 15.165 16.086 12.6562 12.1748 12.6562C8.26353 12.6562 4.94103 15.165 3.72603 18.66C3.22353 20.1 4.28478 21.6 5.80728 21.6H18.5423Z"
        fill="white"
      />
      <path
        d="M12.1747 10.7699C14.4861 10.7699 16.3597 8.89621 16.3597 6.5849C16.3597 4.27359 14.4861 2.3999 12.1747 2.3999C9.86343 2.3999 7.98975 4.27359 7.98975 6.5849C7.98975 8.89621 9.86343 10.7699 12.1747 10.7699Z"
        fill="white"
      />
    </svg>
  );
};

export default ProfileIcon;
