import { config } from '../../config/config';

export const validations = {
  required: 'Veuillez remplir ce champ',
  email: 'Veuillez saisir une adresse mail valide',
  min_length: 'Ce champ doit comporter entre 7 et 32 caractères,au moins 1 lettre minuscule, 1 lettre majuscule, 1 caractère spécial et 1 chiffre',
  match: 'Le mot de passe ne correspond pas',
  actual_password: 'Ce mot de passe est incorrect',
  authInvalid: 'Email ou mot de passe invalide',
  max_character_limit: 'le nombre de caractères saisis ne doit pas dépasser 3000 caractères.',
  size_image_max: `Taille maximale du photo a dépassée (${config.FILE_MAX_SIZE} Mo)`,
  required_title: 'Le titre est requis',
  required_image: "L'image est requis",
  required_author_image: "L'image de l'auteur est requise",
  required_description: 'La description est requis',
  required_startDate: ' Date de début est requis',
  required_endDate: ' Date de fin est requis',
  required_podcast_url: 'Podcast url est requis',
  required_article_url: "L'url de l'article est requis",
  date: 'La date de début doit être inférieure ou égale à la date de fin.',
  invalid_url: "L'url est invalide ",
  required_author_name: "Le nom de l'auteur est requis",
  invalid_author_name: "Le nom de l'auteur est invalide",
};
