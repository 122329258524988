/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../theme/theme';
import { SubscriptionPlansFormProps } from './SubscriptionPlans-form.types';
import SubscriptionPlans from '../../../models/SubscriptionPlans';

export default function ViewSubscriptionPlans({ currentSubscriptionPlan, isUpdate }: SubscriptionPlansFormProps) {
  const customStyle = {
    marginBottom: '0.5rem',
    fontFamily: 'SFPro-Regular',
    color: theme.palette.secondary.dark,
  };

  const { t } = useTranslation();

  const defaultValues = useMemo<SubscriptionPlans>(
    () => ({
      title: currentSubscriptionPlan?.title || '',
      description: currentSubscriptionPlan?.description || '',
      price: currentSubscriptionPlan?.price || 0,
    }),
    [currentSubscriptionPlan],
  );

  const methods = useForm<SubscriptionPlans>({
    defaultValues,
    mode: 'all',
  });

  const { watch, reset } = methods;

  const values = watch();

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentSubscriptionPlan]);

  useEffect(() => {
    const updatedDefaultValues = {
      title: currentSubscriptionPlan?.title || '',
      description: currentSubscriptionPlan?.description || '',
      price: currentSubscriptionPlan?.price || 0,
    };
    methods.reset(updatedDefaultValues);
  }, [currentSubscriptionPlan, isUpdate]);

  return (
    <>
      <Grid className="artgapi-modal" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box sx={{ p: 3 }}>
            <Box sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 3,
                  rowGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                }}
              >
                <div className="col">
                  <Typography>{t('subscriptionPlan.form_fields.title.placeholder')}</Typography>
                  <label style={customStyle}>{values?.title}</label>
                </div>
                <div className="col">
                  <Typography>{t('subscriptionPlan.form_fields.description.placeholder')}</Typography>
                  <div className="artgapi-description" dangerouslySetInnerHTML={{ __html: values.description }} />
                </div>
                <div className="col">
                  <Typography>{t('subscriptionPlan.form_fields.price.placeholder')}</Typography>
                  <label style={customStyle}>{values?.price}</label>
                </div>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
