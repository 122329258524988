/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';

export default function useDeleteSystemUserMutation() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const deleteSystemuser = async (id: number) => {
    try {
      setIsLoading(true);
      const response = await sendAsyncRequest(HttpMethod.Delete, `${ENDPOINTS.SYSTEMUSERS}/${id}`, {}, true, {});
      setData(response.data);
    } catch (err: any) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isLoading,
    error,
    deleteSystemuser,
  };
}
