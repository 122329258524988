/* eslint-disable @typescript-eslint/no-explicit-any */
import { SystemUser } from '../../../models/SystemUser';
import {
  ActionTypes,
  IFetchSystemUserRejectedAction,
  IFetchSystemUserSuccessAction,
  ISystemUserPendingAction,
  ISystemUserResponse,
  ISystemUserState,
  SystemUserActionTypes,
} from './systemuser-slice.types';

export const initialState: ISystemUserState = {
  isLoading: false,
  error: null,
  data: null,
  systemuser: null,
};

export const systemUserReducer = (state = initialState, action: SystemUserActionTypes): ISystemUserState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_SYSTEMUSER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload as ISystemUserResponse,
      };
    case ActionTypes.FETCH_SYSTEMUSER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_SYSTEMUSER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        systemuser: action.payload as SystemUser,
      };
    default:
      return state;
  }
};

const matchPending = (): ISystemUserPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (
  data: ISystemUserResponse,
  action: 'fetch' | 'create' | 'update' | 'updateStatus',
): IFetchSystemUserSuccessAction | SystemUser => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_SYSTEMUSER_SUCCESS : ActionTypes.CREATE_SYSTEMUSER_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchSystemUserRejectedAction => {
  return {
    type: ActionTypes.FETCH_SYSTEMUSER_FAILURE,
    payload: error,
  };
};

export const systemUserActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
