/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';

export default function useGetUniversityDetailsQuery(filterType: string, paginationParams: any, id?: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState<[] | null>(null);
  const { page, limit, order, orderBy, keyword, itemsPerPage } = paginationParams;
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [total, setTotal] = useState(0);

  const fetchUniversity = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.UNIVERSITIES}/${id}`, {}, true, {
        ...paginationParams,
        type: filterType,
      });
      setData(response.data.data);
      setNextPage(response.data.next);
      setPreviousPage(response.data.previous);
      setTotal(response.data.total);
    } catch (err: any) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, [id, filterType, page, limit, order, orderBy, keyword, itemsPerPage]);

  useEffect(() => {
    if (id) {
      fetchUniversity();
    }
  }, [id, filterType, page, limit, order, orderBy, keyword, itemsPerPage]);

  const isFullFilled = !isLoading && !error && data && data?.length > 0;

  return {
    isFullFilled,
    isLoading,
    error,
    data,
    nextPage,
    previousPage,
    total,
  };
}
