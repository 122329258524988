/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useTranslation } from 'react-i18next';
import { CONSTANTS, toolbar, getLengthWysiwygText } from '../../utils';
import './TinyMceEditor.css';
import { config } from '../../config/config';
export const TinyMceEditor = (props: any) => {
  const { data, setData, errors, setErrors, moduleName } = props;
  const { t } = useTranslation();

  const handleEditorChange = (contentt: string | unknown) => {
    const content = contentt as string;
    const characterCount = getLengthWysiwygText(content);
    setData({ ...data, description: content.trim(), numChar: characterCount });
    setErrors({ ...errors, description: '' });
  };
  const placeholder = moduleName === CONSTANTS.ARTICLE ? t('articles.description') : t('opportunities.description_placeholder');

  return (
    <>
      <Editor
        apiKey={'2pnevqcdkli8ted7tuvx3lf1r3o60i279jfi1wpe0kzza6db' || ''}
        init={{
          placeholder: placeholder,
          toolbar: toolbar,
          plugins: 'advlist autolink lists image charmap preview anchor pagebreak code visualchars wordcount',
          fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
          height: 240,
          menubar: false,
          statusbar: false,
          toolbar_location: 'bottom',
          content_css: './tinyMceEditor.css',
        }}
        value={data.description}
        onEditorChange={handleEditorChange}
      />
    </>
  );
};
