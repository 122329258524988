/* eslint-disable @typescript-eslint/no-explicit-any*/
import { ThunkDispatch } from 'redux-thunk';
import { AuthActionTypes, ForgotPasswordInterface } from './models';
import { RootState } from '../rootStore';
import { FORGOTPASSWORD_ATTEMPT, FORGOTPASSWORD_SUCCESS, FORGOTPASSWORD_ERROR } from './types';
import { ENDPOINTS, PATHS, sendAsyncRequest } from '../../utils';
import { CONSTANTS } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { HttpMethod } from '../../utils/httpMethods';
import { SHOW_SNACK_BAR } from '../snackBar/types';
import { SnackBarActionTypes } from '../snackBar/models';

export const useForgotPassword = () => {
  const navigate = useNavigate();
  const forgotPasswordFun = (data: ForgotPasswordInterface) => {
    return (dispatch: ThunkDispatch<RootState, undefined, AuthActionTypes | SnackBarActionTypes>) => {
      {
        dispatch({
          type: FORGOTPASSWORD_ATTEMPT,
        });
        sendAsyncRequest(HttpMethod.Post, ENDPOINTS.FORGOTPASSWORD, data, false, null)
          .then((res: any) => {
            dispatch({
              type: SHOW_SNACK_BAR,
              payload: {
                message: `si l'adresse email est valide, un message sera envoyé`,
                severity: 'success',
                icon: 'CheckIcon',
                size: 'medium',
              },
            });
            dispatch({
              type: FORGOTPASSWORD_SUCCESS,
            });
            navigate(PATHS.SignIn, { replace: true });
            // window.location.replace(PATHS.Dashboard);
          })

          .catch((error) => {
            if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
              localStorage.clear();
              dispatch({
                type: SHOW_SNACK_BAR,
                payload: {
                  message: CONSTANTS.SESSION_EXPIRED,
                  severity: 'error',
                  icon: 'ErrorICon',
                  size: 'medium',
                },
              });
              dispatch({
                type: FORGOTPASSWORD_ERROR,
                error: error,
              });
            } else if (error.message == 'Request failed with status code 404') {
              dispatch({
                type: SHOW_SNACK_BAR,
                payload: {
                  message: 'Le mot de passe est incorrect',
                  severity: 'error',
                  icon: 'ErrorICon',
                  size: 'large',
                },
              });
              dispatch({
                type: FORGOTPASSWORD_ERROR,
                error: error,
              });
            } else if (error.message == 'Request failed with status code 429') {
              dispatch({
                type: SHOW_SNACK_BAR,
                payload: {
                  message: "Trop de demandes.Merci d'essayer plus tard",
                  severity: 'error',
                  icon: 'ErrorICon',
                  size: 'large',
                },
              });
              dispatch({
                type: FORGOTPASSWORD_ERROR,
                error: error,
              });
            } else {
              dispatch({
                type: FORGOTPASSWORD_ERROR,
                error: error,
              });
            }
          });
      }
    };
  };
  return {
    forgotPasswordFun,
  };
};
