import React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { AppBar, Grid } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import UserReports from './userReports/UserReport';
import { menuBar } from '../../theme/menuBarTheme';
import RevenueReport from './revenueReports/RevenueReports';
import AdsReports from './adsReport/AdsReport';
import ClassroomReports from './classroomReport/ClassroomReports';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      sx={{ width: 1 }}
      role="tabpanel"
      className="artgapi-resposive"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={9}>{children}</Box>}
    </Grid>
  );
}

export const SignamlmentMenu = ({ indexVal }: { indexVal: number }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  // const [value, setValue] = React.useState(0);

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  return (
    <>
      <ThemeProvider theme={menuBar}>
        <AppBar sx={{ position: 'sticky', top: '70px', zIndex: 5 }}></AppBar>
      </ThemeProvider>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        {indexVal === 0 ? (
          <TabPanel value={indexVal} index={indexVal} dir={theme.direction}>
            <UserReports />
          </TabPanel>
        ) : indexVal === 1 ? (
          <TabPanel value={indexVal} index={indexVal} dir={theme.direction}>
            <RevenueReport />
          </TabPanel>
        ) : indexVal === 2 ? (
          <TabPanel value={indexVal} index={indexVal} dir={theme.direction}>
            <AdsReports />
          </TabPanel>
        ) : (
          <TabPanel value={indexVal} index={indexVal} dir={theme.direction}>
            <ClassroomReports />
          </TabPanel>
        )}
      </Grid>
    </>
  );
};
