import React from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/logo.png';
import 'animate.css';
import './ActivateAccount.css';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  icon: {
    marginTop: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 0,
    display: 'block',
    width: 100,
  },
  image: {
    width: 450,
    maxWidth: '80%',
  },
  text: {
    marginTop: 20,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 60,
    textAlign: 'center',
    fontSize: '1.25em',
    color: 'white',
  },
});
const ActivateAccount = () => {
  const location = useLocation();
  const status = new URLSearchParams(location.search).get('status');

  const classes = useStyles();
  return (
    <div className="activate-account-container">
      <img alt="logo" className={`${classes.image} animate__animated animate__fadeInDown`} src={logo} />
      {status === 'success' ? (
        <>
          <svg className={classes.icon} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
            <polyline
              className="path check"
              fill="none"
              stroke="#73AF55"
              strokeWidth="6"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="100.2,40.2 51.5,88.8 29.8,67.5 "
            />
          </svg>
          <p className={`${classes.text} animate__animated animate__fadeInUp`}>Votre compte a bien été activé!</p>
        </>
      ) : (
        <>
          <svg className={classes.icon} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
            <line
              className="path line"
              fill="none"
              stroke="#D06079"
              strokeWidth="6"
              strokeLinecap="round"
              strokeMiterlimit="10"
              x1="34.4"
              y1="37.9"
              x2="95.8"
              y2="92.3"
            />
            <line
              className="path line"
              fill="none"
              stroke="#D06079"
              strokeWidth="6"
              strokeLinecap="round"
              strokeMiterlimit="10"
              x1="95.8"
              y1="38"
              x2="34.4"
              y2="92.2"
            />
          </svg>
          <p className={`${classes.text} animate__animated animate__fadeInUp`}>Une erreur est survenue lors de l activation de votre compte!</p>
        </>
      )}
    </div>
  );
};

export default ActivateAccount;
