import React from 'react';

export const TrashIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 8H23L20.4436 23.3916C20.2884 24.326 19.5944 25 18.7875 25H11.2125C10.4056 25 9.71158 24.326 9.55639 23.3916L7 8Z"
        stroke="#A4A4A5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5 8H25" stroke="#A4A4A5" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 4C17.2091 4 19 5.75495 19 7.9198V8H11V7.9198C11 5.75495 12.7909 4 15 4Z"
        stroke="#A4A4A5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15 12L15 20.5" stroke="#A4A4A5" strokeLinecap="round" />
      <path d="M18.5 12L18 17.5" stroke="#A4A4A5" strokeLinecap="round" />
      <path d="M11.5 12L12 17.5" stroke="#A4A4A5" strokeLinecap="round" />
    </svg>
  );
};

export default TrashIcon;
