/* eslint-disable react/react-in-jsx-scope */
import { useTranslation } from 'react-i18next';
import { ContainerStyle, CustomTitle, GridStyle } from './profile-list-toolbar.styles';

export default function ProfileListToolbar() {
  const { t } = useTranslation();
  return (
    <ContainerStyle>
      <GridStyle>
        <CustomTitle>{t('profile.title')}</CustomTitle>
      </GridStyle>
    </ContainerStyle>
  );
}
