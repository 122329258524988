/* eslint-disable @typescript-eslint/no-explicit-any */
import { University } from '../../../models/University';
import {
  ActionTypes,
  IFetchUniversityRejectedAction,
  IFetchUniversitySuccessAction,
  IUniversityPendingAction,
  IUniversityResponse,
  IUniversityState,
  UniversityActionTypes,
} from './university-slice.types';

export const initialState: IUniversityState = {
  isLoading: false,
  error: null,
  data: null,
  university: null,
};

export const universityReducer = (state = initialState, action: UniversityActionTypes): IUniversityState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_UNIVERSITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload as IUniversityResponse,
      };
    case ActionTypes.FETCH_UNIVERSITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_UNIVERSITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        university: action.payload as University,
      };
    default:
      return state;
  }
};

const matchPending = (): IUniversityPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (data: IUniversityResponse, action: 'fetch' | 'create'): IFetchUniversitySuccessAction | University => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_UNIVERSITY_SUCCESS : ActionTypes.CREATE_UNIVERSITY_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchUniversityRejectedAction => {
  return {
    type: ActionTypes.FETCH_UNIVERSITY_FAILURE,
    payload: error,
  };
};

export const universityActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
