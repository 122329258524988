export const opportunities = {
  create_Opportunity: 'Create an opportunity',
  description_placeholder: 'Opportunity Description',
  collaboration: 'Collaboration',
  exposition: 'Exposition',
  opportunity_success_deleted: 'Opportunity removed successfully',
  type: 'Type',
  delete_message: 'Are you sure you want to remove this opportunity?',
  description: 'Description',
  creation_failure: 'Failed to create an opportunity',
  update_failure: 'Failed to edit an opportunity',
};
