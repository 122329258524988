import PortfolioPlan from '../../../models/Portfolio';

export interface IPortfolioPlanResponse {
  total: number;
  next: number | null;
  previous: number | null;
  subscriptionPortfolio: PortfolioPlan[];
}

export interface IPortfolioPlanState {
  isLoading: boolean;
  data: IPortfolioPlanResponse | null;
  portfolioData: PortfolioPlan | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_PORTFOLIO_PLAN_SUCCESS = 'FETCH_PORTFOLIO_PLAN_SUCCESS',
  FETCH_PORTFOLIO_PLAN_FAILURE = 'FETCH_PORTFOLIO_PLAN_FAILURE',
  CREATE_PORTFOLIO_PLAN_SUCCESS = 'CREATE_PORTFOLIO_PLAN_SUCCESS',
}

export interface IPortfolioPlanPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchPortfolioPlanSuccessAction {
  type: typeof ActionTypes.FETCH_PORTFOLIO_PLAN_SUCCESS | typeof ActionTypes.CREATE_PORTFOLIO_PLAN_SUCCESS;
  payload: IPortfolioPlanResponse | PortfolioPlan;
}

export interface IFetchPortfolioPlanRejectedAction {
  type: typeof ActionTypes.FETCH_PORTFOLIO_PLAN_FAILURE;
  payload: string;
}

export type PortfolioActionTypes = IPortfolioPlanPendingAction | IFetchPortfolioPlanSuccessAction | IFetchPortfolioPlanRejectedAction;
