/* eslint-disable @typescript-eslint/no-explicit-any*/
import React, { useEffect, useContext, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import { Badge, BadgeProps, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import arthapiLogo from '../../assets/images/artgapiLogo.png';
import profilIcon from '../../assets/icons/profilIcon.png';
import { menuAdmin } from '../../utils/menus';
import { CONSTANTS, ENDPOINTS, PATHS } from '../../utils';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store/rootStore';
import { CounterActionTypes } from '../../store/counters/models';
import { useDispatch, useSelector } from 'react-redux';
import {
  decrementApplicants,
  decrementBugs,
  decrementReportings,
  incrementApplicants,
  incrementBugs,
  incrementReportings,
  initializeApplicants,
  initializeBugs,
  initializeReportings,
} from '../../store/counters/actions';
import { config } from '../../config/config';
import GlobalContext from '../../context/globalContext';
import './Header.css';
//
// import * as React from 'react';
// import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
// import AppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import { Button } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { useTranslation } from 'react-i18next';
import { useLogout } from '../../store/authentication/actions';
import { AuthActionTypes } from '../../store/authentication/models';
import { PasswordDialogActionTypes } from '../../store/Dialog/models';
import { userActionTypes } from '../../store/user/models';
import ConfirmLogoutDialog from './confirm-logout-dialog/ConfirmLogoutDialog';
import i18n from '../../i18n';

const menuNavbar = menuAdmin;

const drawerWidth = 260;

export default function Header() {
  const { t } = useTranslation();

  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, CounterActionTypes>>();
  const dispatchLogout = useDispatch<ThunkDispatch<RootState, undefined, AuthActionTypes | PasswordDialogActionTypes | userActionTypes>>();

  const { logout } = useLogout();
  const [openSubmenus, setOpenSubmenus] = useState<{ [key: string]: boolean }>({});
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const counterState = useSelector((state: RootState) => state.counter);
  const gContext = useContext(GlobalContext);
  const [retryCount, setRetryCount] = React.useState(0);
  const {
    getBugs: { loader: bugsLoader, bugs, error: bugsError },
    getReportings: { loader: flagsLoader, reportings, error: flagsError },
    getApplicants: { loader, applicants, error },
  } = counterState;
  useEffect(() => {
    const evtSource = new EventSource(config.BASE_URL_API + ENDPOINTS.SSEVENTS);
    const handleTimeout = () => {
      setRetryCount(retryCount + 1);
      evtSource.close();
      setTimeout(() => {
        executeEventSource();
      }, 500);
    };
    const handleEvent = (event: MessageEvent) => {
      switch (event.data) {
        case 'NEW-BUG':
          dispatch(incrementBugs());
          break;
        case 'NEW-REPORT':
          dispatch(incrementReportings());
          break;
        case 'CLOSED-BUG':
          dispatch(decrementBugs());
          break;
        case 'TREATED-REPORT':
          dispatch(decrementReportings());
          break;
        case 'NEW-APPLICANT':
          dispatch(incrementApplicants());
          break;
        case 'SHOWED-APPLICANT':
          dispatch(decrementApplicants());
          break;
      }
      handleTimeout();
    };
    const executeEventSource = () => {
      dispatch(initializeBugs());
      dispatch(initializeReportings());
      dispatch(initializeApplicants());
      evtSource.onmessage = handleEvent;
      evtSource.onerror = handleTimeout;
    };

    executeEventSource();
    return () => {
      evtSource.close();
    };
  }, [retryCount]);

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 22,
      top: 22,
      backgroundColor: '#ffcc33',
      color: theme.palette.primary.main,
      padding: '0 4px',
    },
  }));

  const handleSubmenuClick = (index: number) => {
    setOpenSubmenus({
      ...openSubmenus,
      [index]: !openSubmenus[index],
    });
  };

  const onLogout = () => {
    setOpenLogoutDialog(true);
  };

  const onCloseConfirmLogoutDialog = () => {
    setOpenLogoutDialog(false);
  };

  const handleLogout = () => {
    dispatchLogout(logout());
  };

  const languageOptions = [
    { value: 'fr', label: 'French' },
    { value: 'en', label: 'English' },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0].value);

  // Function to handle language change
  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
    i18n.changeLanguage(newLanguage); // Change the language in i18n
  };
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar className="artgapi-header-wrapper" position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
          <Toolbar>
            <NavLink className="logoBox" to={PATHS.Dashboard}>
              <Box
                component="img"
                sx={{
                  width: '120px',
                  height: 'auto',
                }}
                alt="Your logo."
                src={arthapiLogo}
              />
            </NavLink>
            <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <Select
                value={selectedLanguage} // Use the state variable here
                onChange={handleLanguageChange}
                size="small"
                sx={{
                  width: '100px',
                  borderRadius: '0',
                  '& .MuiOutlinedInput-root': {
                    width: '34px', // Control the select box width directly here
                  },
                  '& .MuiSelect-select': {
                    width: '100%',
                    height: '34px !important',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '5px 10px',
                    textAlign: 'center',
                  },
                }}
              >
                {languageOptions?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <NavLink to={PATHS.Profile} className="artgapi-user-logo" style={{ marginLeft: '5px' }}>
                <Box
                  component="img"
                  sx={{
                    width: '34px',
                    height: '34px',
                  }}
                  alt="Your logo."
                  src={profilIcon}
                />
              </NavLink>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          className="artgapi-drawer"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Divider />
          <div className="artgapi-divider">
            <List className="nav-items artgapi-lst-items">
              {menuNavbar.map((item, index) => (
                <ListItem key={`menu-item-${index}`}>
                  <div
                    className={`borders-box ${location.pathname === item.url ? 'active' : ''} ${
                      item?.subItems?.some((subItem) => location.pathname === subItem.url) ? 'subitem-active' : ''
                    }`}
                  >
                    <Button
                      key={`menu-item-${index}`}
                      component={NavLink}
                      to={item.url ? item.url : ''}
                      onClick={item.title === 'common.database' ? () => window.open('https://phpmyadmin.itss.dev/') : () => gContext?.handleClick()}
                      fullWidth
                      sx={{ textTransform: 'none', justifyContent: 'flex-start', textAlign: 'left' }}
                      className={item?.isSubMenu ? 'level-one' : 'level-zero'}
                    >
                      <StyledBadge
                        badgeContent={
                          item.badge === CONSTANTS.FLAGS_BADGE
                            ? reportings
                            : item.badge === CONSTANTS.BUGS_BADGE
                            ? bugs
                            : item.badge === CONSTANTS.OPPORTUNITIES_BADGE
                            ? applicants
                            : ''
                        }
                        invisible={
                          item.badge === CONSTANTS.FLAGS_BADGE || item.badge === CONSTANTS.BUGS_BADGE || item.badge === CONSTANTS.OPPORTUNITIES_BADGE
                            ? false
                            : true
                        }
                      >
                        {item.isSubMenu ? (
                          <>
                            <ListItemButton onClick={() => handleSubmenuClick(index)}>
                              {item.icon}
                              <ListItemText
                                primary={t(item.title)}
                                className={`submenu-normal-text ${location.pathname === item.url ? 'submenu-text-active' : ''}`}
                              />
                              {openSubmenus[index] ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={openSubmenus[index]} timeout="auto" unmountOnExit>
                              <List disablePadding className="nav-items">
                                {item?.subItems?.map((subItem, subIndex) => (
                                  <Button
                                    key={`menu-item-${subIndex}`}
                                    component={NavLink}
                                    to={subItem.url}
                                    onClick={
                                      subItem.title === 'common.database'
                                        ? () => window.open('https://phpmyadmin.itss.dev/')
                                        : () => gContext?.handleClick()
                                    }
                                    fullWidth
                                    sx={{ textTransform: 'none', justifyContent: 'flex-start', textAlign: 'left' }}
                                  >
                                    <ListItemText
                                      sx={{ color: location.pathname === subItem.url ? '#ffcc33' : 'inherit' }}
                                      primary={t(subItem.title)}
                                    />
                                  </Button>
                                ))}
                              </List>
                            </Collapse>
                          </>
                        ) : (
                          <>
                            {item.icon}
                            <ListItemText primary={t(item.title)} className={`normal-text ${location.pathname === item.url ? 'text-active' : ''}`} />
                          </>
                        )}
                      </StyledBadge>
                    </Button>
                  </div>
                </ListItem>
              ))}
            </List>

            <Button className="button-logout" onClick={onLogout}>
              {t('common.Logout')}
            </Button>
          </div>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
          <Toolbar />
          <Typography></Typography>
          <Typography></Typography>
        </Box>
      </Box>
      <ConfirmLogoutDialog open={openLogoutDialog} onClose={onCloseConfirmLogoutDialog} handleLogout={handleLogout} />
    </>
  );
}
