import React from 'react';

export const DeleteIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="74" height="74" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#FF453A" d="M0 0h84v84H0z" />
      <path
        clipRule="evenodd"
        d="M34 35h16l-2.556 15.392c-.156.934-.85 1.608-1.657 1.608h-7.575c-.806 0-1.5-.674-1.656-1.608L34 35z"
        stroke="#F5F5F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M32 35h20" stroke="#F5F5F5" strokeWidth="2" strokeLinecap="round" />
      <path
        clipRule="evenodd"
        d="M42 31c2.21 0 4 1.755 4 3.92V35h-8v-.08c0-2.165 1.79-3.92 4-3.92z"
        stroke="#F5F5F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DeleteIcon;
