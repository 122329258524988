import { AuthActionTypes, AuthState } from './models';
import { LOGIN_ATTEMPT, LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT_ATTEMPT, LOGOUT_ERROR, LOGOUT_SUCCESS } from './types';

const INITIAL_STATE: AuthState = {
  login: {
    loader: false,
    error: '',
  },
  logout: {
    loader: false,
    error: '',
  },
};
export const authReducer = (state = INITIAL_STATE, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case LOGIN_ATTEMPT:
      return { ...state, login: { ...state.login, loader: true, error: '' } };
    case LOGIN_SUCCESS:
      return { ...state, login: { ...state.login, loader: false } };
    case LOGIN_ERROR:
      return { ...state, login: { ...state.login, loader: false } };
    case LOGOUT_ATTEMPT:
      return { ...state, logout: { ...state.logout, loader: true, error: '' } };
    case LOGOUT_SUCCESS:
      return { ...state, logout: { ...state.logout, loader: false } };
    case LOGOUT_ERROR:
      return { ...state, logout: { ...state.logout, loader: false } };
    default:
      return state;
  }
};
