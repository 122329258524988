export const articles = {
  articles: 'ARTICLES',
  picture: 'PHOTO',
  title: 'TITRE',
  category: 'Catégorie',
  status: 'STATUS',
  create_Article: 'Créer un article',
  description: "Description de l'article",
  podcast_url: 'URL du podcast',
  all: 'Tous',
  category_article: 'Article',
  category_podcast: 'Podcast',
  draft_status: 'Brouillon',
  published_status: 'Publié',
  deleted_status: 'Supprimé',
  article_success_deleted: 'Article supprimé avec succées',
  article_status_success_changed: 'Article supprimé avec succées',
  delete_message: 'Voulez-vous vraiment supprimer cette média ?',
  content: 'Contenu',
  upload: 'Upload',
  save_article: 'Enregistrer et quitter',
  do_not_save: 'Ne pas enregistrer',
  creation_failure: "Échec de création d'un article",
  update_failure: "Échec de modification d'un article",
  author: 'Auteur',
  author_image: "Image de l'auteur",
  article_url: "URL de l'article",
  articleurl: `L'URL de l'article n'est pas valide`,
  formats: 'Formats acceptées: jpeg, png',
  max_size: 'Taille maximum: 2 Mo',
};
