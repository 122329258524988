export const university = {
  title: 'Universities',
  name: 'Name',
  address: 'Address',
  phone_number: 'Phone number',
  email: 'Email',
  add: 'Add a university',
  about_section_title: 'About',
  users_section_title: 'Users',
  university_details_title: 'University Details',
  create_button_label: 'Create',
  update_button_label: 'Edit Changes',
  add_university_title: 'Add a new university',
  update_university_title: 'Edit a university',
  no_users: 'No users found at this university',
  no_data: 'No universities found',
  create_success_message: 'The university was successfully established',
  update_success_message: 'The university has been successfully modified',
  something_wrong_happend: 'An error has occurred',
  confirm_delete_title: 'Confirm deletion',
  confirm_delete_message: 'Are you sure you want to delete this university?',
  delete_success_message: 'The university was successfully removed',
  users_filter: {
    all: 'All',
    students: 'Students',
    teachers: 'Teachers',
  },
  users_list: {
    first_name: 'First name',
    last_name: 'Name',
    email: 'Email',
  },
  form_fields: {
    name: {
      label: 'Name',
      placeholder: 'Name of the university',
      required_error_message: 'University name is required',
    },
    address: {
      label: 'Address',
      placeholder: 'University address',
      required_error_message: 'University address is required',
    },
    phone_number: {
      label: 'Phone number',
      placeholder: 'University telephone number',
      required_error_message: 'Phone number is required',
      invalid_error_message: 'Phone number must be between 7 and 16 characters',
    },
    email: {
      label: 'Email',
      placeholder: 'University email',
      required_error_message: 'University email is required',
      invalid_error_message: 'University email is invalid',
    },
  },
};
