/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Stack, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AlertIcon } from '../../assets/icons/alert-danger.svg';
import { ConfirmDeleteDialogProps } from './confirm-delete-dialog.types';
import Iconify from '../../components/Iconify';
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDeleteDialog({ onClose, open, onDelete, confirm_delete_message, confirm_delete_title }: ConfirmDeleteDialogProps) {
  const { t } = useTranslation();
  return (
    <>
      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={onClose} fullWidth>
        <DialogTitle>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {confirm_delete_title}
            </Typography>
            <Iconify icon={AlertIcon} />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{confirm_delete_message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onDelete}
            sx={{
              backgroundColor: '#ff574d',
              borderRadius: '0px',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#ff574d',
                color: '#FFFFFF',
              },
            }}
          >
            {t('common.delete')}
          </Button>
          <Button
            onClick={onClose}
            sx={{
              backgroundColor: '#ebe5e5',
              borderRadius: '0px',
              color: '#000000',
              '&:hover': {
                backgroundColor: '#ebe5e5',
                color: '#000000',
              },
            }}
          >
            {t('common.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
