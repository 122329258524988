/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  ThemeProvider,
  IconButton,
  SwitchProps,
  Switch,
  Grid,
  Box,
  Tooltip,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import { tableTheme } from '../../../theme/table-theme';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../store/rootStore';
import { useDispatch, useSelector } from 'react-redux';
import { PaginationActionTypes } from '../../../store/pagination/models';
import { getPageNumber, getPerPage } from '../../../store/pagination/actions';
import { HeadTable } from '../../table/tableHead/HeadTable';
import { TablePaginationActions } from '../../table/TablePaginationActions/TablePaginationActions';
import { CONSTANTS, ENDPOINTS, formatDate, sendAsyncRequest } from '../../../utils';
import { styled } from '@mui/material/styles';
import EyeIcon from '../../svgComponents/EyeIcon';
import TrashIcon from '../../svgComponents/Trash';
import { getCampany, getCompagnies, updateCompanyStatus, useDeleteCompany } from '../../../store/campagnes/actions';
import { compagniesActionTypes } from '../../../store/campagnes/models';
import Spinner from '../../spinner';
import { PasswordDialogActionTypes } from '../../../store/Dialog/models';
import UpdateCampagneDialog from '../updateCompagneDialog/UpdateCampagneDialog';
import { NoDataFoundComponent } from '../../shared/NoDataFoundComponent/NoDataFoundComponent';
import { rowTheme } from '../../../theme/rowtheme';
import HeaderProperty from '../../../models/Table';
import ConfirmDeleteDialog from '../../../utils/confirm-delete-dialog/ConfirmDeleteDialog';
import { useTranslation } from 'react-i18next';
import TableNoData from '../../table/TableNoData';
import TableLoadingData from '../../table/TableLoadingData';
import { HttpMethod } from '../../../utils/httpMethods';
import { AxiosResponse } from 'axios';
import { DELETE_COMPANIES_ERROR, DELETE_COMPANIES_SUCCESS } from '../../../store/campagnes/types';
import { SHOW_SNACK_BAR } from '../../../store/snackBar/types';
import { SnackBarActionTypes } from '../../../store/snackBar/models';
interface TableFactoryProps {
  loading: boolean;
  bodyData?: any | undefined;
  headItems: HeaderProperty[];
}

/**
 *component returning all forms of tables except planning table
 *
 * @returns {ExoticComponent<() => JSX.Element>}
 *
 */
const IOSSwitch = styled((props: SwitchProps) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
  width: 40,
  height: 22,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#1ab53c',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#ff453a',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
export const CompagneTableBody = (props: TableFactoryProps) => {
  const { t } = useTranslation();

  const { deleteCompany } = useDeleteCompany();
  const [showActionId, setShowActionId] = useState<number>(-1);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, PaginationActionTypes | compagniesActionTypes | PasswordDialogActionTypes>>();
  const paginationState = useSelector((state: RootState) => state.pagination);
  const { total, pageNumber, perPage, type } = paginationState;
  const { loading, bodyData, headItems } = props;
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const dispatchCompanies = useDispatch<ThunkDispatch<RootState, undefined, compagniesActionTypes | SnackBarActionTypes>>();

  const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(getPageNumber(newPage));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(getPerPage(parseInt(event.target.value, 10)));
    dispatch(getPageNumber(0));
  };
  const handleDeleteCompany = (id: number) => {
    setSelectedId(id);
    setConfirmDeleteOpen(true);
  };
  const onDeleteCategory = () => {
    if (selectedId !== undefined) {
      sendAsyncRequest(HttpMethod.Delete, ENDPOINTS.COMPANIES + '/' + selectedId, {}, true, null)
        .then((res: AxiosResponse) => {
          dispatchCompanies({
            type: DELETE_COMPANIES_SUCCESS,
            id: selectedId,
          });
          dispatchCompanies({
            type: SHOW_SNACK_BAR,
            payload: {
              message: 'Campagne supprimée avec succès',
              severity: 'success',
              icon: 'CheckIcon',
              size: 'medium',
            },
          });
          dispatchCompanies(getCompagnies({ type: type, page: pageNumber + 1, limit: perPage }));
          onCloseConfirmDeleteDialog();
        })
        .catch((error) => {
          if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
            localStorage.clear();
            dispatchCompanies({
              type: SHOW_SNACK_BAR,
              payload: {
                message: CONSTANTS.SESSION_EXPIRED,
                severity: 'error',
                icon: 'ErrorICon',
                size: 'medium',
              },
            });
            dispatchCompanies({
              type: DELETE_COMPANIES_ERROR,
              error: error,
            });
          } else {
            dispatchCompanies({
              type: SHOW_SNACK_BAR,
              payload: {
                message: 'Échec de suppression de campagne',
                severity: 'error',
                icon: 'ErrorICon',
                size: 'large',
              },
            });
            dispatchCompanies({
              type: DELETE_COMPANIES_ERROR,
              error: error,
            });
          }
        });
    }
  };

  const onCloseConfirmDeleteDialog = () => {
    setSelectedId(undefined);
    setConfirmDeleteOpen(false);
  };

  const handleSwitchCampanyStatus = (id: number) => {
    dispatch(updateCompanyStatus(id));
  };
  const showUpdateCompanyDialog = (id: number) => {
    dispatch(getCampany(id));
    setOpenUpdateDialog(!openUpdateDialog);
  };

  const isFullFilled = !loading && bodyData && bodyData?.length > 0;

  return (
    <>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        onClose={onCloseConfirmDeleteDialog}
        onDelete={onDeleteCategory}
        confirm_delete_title={t('interests.confirm_delete_title')}
        confirm_delete_message={t('interests.confirm_interest_delete_message')}
      />
      <UpdateCampagneDialog openUpdateDialog={openUpdateDialog} setOpenUpdateDialog={setOpenUpdateDialog} />

      <Container sx={{ minHeight: '100vh' }}>
        <ThemeProvider theme={tableTheme}>
          <TableContainer elevation={0} component={Paper}>
            <Table>
              <HeadTable items={headItems} />
              <TableBody>
                {isFullFilled && (
                  <>
                    {bodyData.map((row: any, index: number) => {
                      return (
                        <ThemeProvider theme={rowTheme} key={index}>
                          <TableRow
                            className={'icon_hover'}
                            style={{ backgroundColor: '#ffffff' }}
                            onMouseEnter={() => {
                              setShowActionId(row.id);
                            }}
                            onMouseLeave={() => setShowActionId(-1)}
                          >
                            {headItems.map((cell: any, headIndex: number) => {
                              return (
                                <TableCell
                                  style={{
                                    maxWidth: '300px',
                                    color:
                                      row[cell.value] == 'En cours' && row.active
                                        ? '#1ab53c'
                                        : row[cell.value] == 'En cours' && !row.active
                                        ? '#ff453a'
                                        : row[cell.value] == 'Terminée'
                                        ? '#0d0d0d'
                                        : cell.label == 'Titre'
                                        ? '#000000'
                                        : '#a4a4a5',
                                  }}
                                  key={headIndex}
                                >
                                  {row.id !== showActionId && cell.type === 'date' && row[cell.value] ? (
                                    formatDate(row[cell.value])
                                  ) : row.id === showActionId && cell.label === 'Fin' ? (
                                    <Grid sx={{ display: 'flex', alignSelf: 'end', alignItems: 'center' }}>
                                      {row.status === 'Terminée' ? (
                                        <></>
                                      ) : (
                                        <IOSSwitch
                                          sx={{ m: 1 }}
                                          checked={row.active ? true : false}
                                          onClick={() => handleSwitchCampanyStatus(row.id)}
                                        />
                                      )}
                                      <Tooltip title="View Details" arrow>
                                        <IconButton onClick={() => showUpdateCompanyDialog(row.id)}>
                                          <EyeIcon />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Delete" arrow>
                                        <IconButton onClick={() => handleDeleteCompany(row.id)}>
                                          <TrashIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  ) : row.id === showActionId && cell.label === 'Début' && row[cell.value] ? (
                                    <></>
                                  ) : (
                                    row[cell.value]
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </ThemeProvider>
                      );
                    })}
                  </>
                )}
                <TableNoData isNotFound={!loading && bodyData?.length === 0} title={t('adds.userreports_no_data')} />
                <TableLoadingData isLoading={loading} />
              </TableBody>
            </Table>
          </TableContainer>
          {isFullFilled && (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={total || 0}
                rowsPerPage={perPage}
                page={pageNumber}
                labelRowsPerPage={t('common.rowsperpage')}
                sx={{
                  '& .MuiInputBase-root': {
                    maxWidth: '60px',
                  },
                  '& .MuiTablePagination-selectLabel': {
                    display: 'flex !important',
                  },
                  '& .MuiToolbar-regular ': {
                    paddingRight: '2px',
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Box>
          )}
        </ThemeProvider>
      </Container>
    </>
  );
};
