import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PATHS, isLoggedIn } from '../utils';

function PrivateRoute({ children }: { children: JSX.Element }) {
  const location = useLocation();

  if (!isLoggedIn()) {
    // Redirect them to the home page if they are not logged
    return <Navigate to={PATHS.SignIn} state={{ from: location }} />;
  }

  return children;
}

export default PrivateRoute;
