/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AddArticle } from '../../../store/articles/action';
import { ArticlesActionTypes } from '../../../store/articles/models';
import { PaginationActionTypes } from '../../../store/pagination/models';
import { RootState } from '../../../store/rootStore';
import { theme } from '../../../theme/theme';
import { CONSTANTS } from '../../../utils';
import CloseIcon from '../../svgComponents/closeIcon';
import DetailsDialog from '../detailsDialog/DetailsDialog';

interface CreatePreviewDialogProps {
  show: boolean;
  title: string;
  closeDialog: () => void;
}

export const CreatePreviewDialog = (props: CreatePreviewDialogProps) => {
  const { show, title, closeDialog } = props;
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, ArticlesActionTypes | PaginationActionTypes>>();
  const { t } = useTranslation();
  const [size, setSize] = useState(0);
  const [authorImageSize, setAuthorImageSize] = useState(0);
  const [data, setData] = useState({
    title: '',
    author: '',
    status: CONSTANTS.DRAFT,
    imageUrl: '',
    authorImageUrl: '',
    description: '',
    file: '',
    authorImage: '',
    numChar: 0,
    podcastUrl: '',
    startDate: '',
    endDate: '',
    type: '1',
  });
  const [errors, setErrors] = useState({ title: '' });
  const [openSecondModal, setOpenSecondModal] = useState(false);

  const openSecondDialog = () => {
    if (data.title.trim() === '') {
      setErrors({ ...errors, title: t('validations.required_title') });
    } else {
      setOpenSecondModal(true);
      setErrors({ ...errors, title: '' });
    }
  };

  const closeSecondDialog = () => {
    setOpenSecondModal(false);
  };

  const saveChangesHandler = () => {
    dispatch(AddArticle(data, true));
    closeDialog();
  };

  const onChangeTitle = (event: any) => {
    if (event.target.value.length <= 47) {
      setData({ ...data, title: event.target.value });
    }
    setErrors({ ...errors, title: '' });
  };

  const changeSize = (value: number) => {
    setSize(value);
  };

  const onChangeAuthorImageSize = (value: number) => {
    setAuthorImageSize(value);
  };

  return (
    <>
      <Dialog
        open={show}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              height: '430px',
              maxHeight: 'calc(100vh - 40px)',
              width: '500px',
              minWidth: '500px',
              borderRadius: 0,
            },
          },
        }}
      >
        <Box
          sx={{
            width: '350px',
            color: theme.palette.primary.main,
            fontFamily: 'SFPro-Regular',
            backgroundColor: theme.palette.secondary.main,
            textAlign: 'center',
          }}
        >
          <IconButton sx={{ position: 'absolute', right: 5, top: 7 }} onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </Box>
        <div>
          <DialogContent sx={{ backgroundColor: '#f5f5f5' }}>
            <Grid
              container
              sx={{
                ml: 50,
                justifyContent: 'center',
                marginLeft: '0px !important',
                marginTop: '10%',
                fontFamily: 'SF Pro',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '24px',
                lineHeight: '29px',
                textTransform: 'uppercase',
              }}
            >
              {title === CONSTANTS.ARTICLE ? 'Nouvel Article' : 'Nouvelle Opportunité'}
            </Grid>
            <form id="createForm">
              <Grid item sx={{ marginTop: '30px ' }}>
                <Grid item sx={{ display: 'flex' }}>
                  <InputLabel htmlFor="type">{title === CONSTANTS.ARTICLE ? 'Catégorie' : 'Type'}</InputLabel>
                </Grid>
                <Grid item sx={{ display: 'flex' }}>
                  <ToggleButtonGroup
                    fullWidth
                    exclusive
                    value={data.type}
                    onChange={(event, newType) => {
                      if (newType !== null) {
                        setData({ ...data, type: newType });
                      }
                    }}
                  >
                    <ToggleButton id="type" sx={{ textTransform: 'none' }} value="1">
                      {title === CONSTANTS.ARTICLE ? 'Article' : 'Collaboration'}
                    </ToggleButton>
                    <ToggleButton id="type" sx={{ textTransform: 'none' }} value="0">
                      {title === CONSTANTS.ARTICLE ? 'Podcast' : 'Exposition'}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid item sx={{ marginTop: '20px ' }}>
                <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <InputLabel htmlFor="title">Titre</InputLabel>
                </Grid>
                <InputBase
                  id="title"
                  sx={{ width: '100%' }}
                  placeholder={title === CONSTANTS.ARTICLE ? "Titre de l'article" : "Titre de l'opportunité"}
                  value={data.title}
                  onChange={(event: any) => onChangeTitle(event)}
                />
                {errors.title && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#ff453a',
                      marginBottom: '7px',
                      width: '100%',
                      height: '29px',
                      fontFamily: 'SFPro-Regular',
                      fontSize: '12px',
                      textAlign: 'left',
                    }}
                  >
                    {errors.title}
                  </Typography>
                )}
              </Grid>
            </form>
          </DialogContent>
          <DialogActions sx={{ height: '100px', marginTop: 0 }}>
            <ButtonBase
              sx={{
                color: '#a4a4a5',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'SFPro-Regular',
                fontSize: '17px',
                padding: '10px 24px',
              }}
              onClick={closeDialog}
            >
              {t('common.cancel')}
            </ButtonBase>
            <ButtonBase
              form="createForm"
              className={'isRealsed'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#ffcc33',
                height: ' 52px',
                width: '93px',
                fontFamily: 'SFPro-Regular',
                fontSize: '17px',
                color: '#0d0d0d',
                border: 'none',
              }}
              onClick={openSecondDialog}
            >
              {'Créer'}
            </ButtonBase>
          </DialogActions>
        </div>
      </Dialog>
      {openSecondModal && (
        <DetailsDialog
          openSecondModal={openSecondModal}
          closeSecondDialog={closeSecondDialog}
          data={data}
          setData={setData}
          moduleName={title}
          closeDialog={closeDialog}
          saveChangesHandler={saveChangesHandler}
          isUpdate={false}
          changeSize={changeSize}
          size={size}
          authorImageSize={authorImageSize}
          changeAuthorImageSize={onChangeAuthorImageSize}
        />
      )}
    </>
  );
};

export default CreatePreviewDialog;
