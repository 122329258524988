import { createTheme } from '@mui/material/styles';

export const rowTheme = createTheme({
  typography: {
    allVariants: {
      textTransform: 'none',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'SFPro-Regular',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '17px',
      letterSpacing: 'normal',
      lineHeight: 'normal',
    },
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          borderCollapse: 'separate',
          borderSpacing: '0px 10px',
          marginBottom: '10px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderTop: '10px solid #f5f5f5 ',
          borderBottom: '10px solid #f5f5f5 ',
          height: 60,
          padding: '0px 16px',
        },
      },
    },
  },
});
