/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Grid,
  Stack,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  FormGroup,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { SystemUser } from '../../../models/SystemUser';
import { RootState } from '../../../store/rootStore';
import { SystemUserFormProps } from './systemuser-form.types';
import { countriesActionTypes } from '../../../store/countries/models';
import { getCountries } from '../../../store/countries/actions';
import { CreateSystemUserSchema } from './create-systemuser.schema';
import { theme } from '../../../theme/theme';

export default function ViewSystemUser({ currentSystemUser, isUpdate, onClose, isEyeIconClicked }: SystemUserFormProps) {
  const { t } = useTranslation();
  const dispatchCountries = useDispatch<ThunkDispatch<RootState, undefined, countriesActionTypes>>();
  const countries = useSelector((state: RootState) => state.countries.countries);
  const modulePermissions = [
    { moduleId: 1, moduleName: 'Articles', permission: 0 }, // Assuming 0 for denied permission
    { moduleId: 2, moduleName: 'Annonces', permission: 0 },
    { moduleId: 3, moduleName: 'Bugs', permission: 0 },
    { moduleId: 4, moduleName: 'Campagnes', permission: 0 },
    { moduleId: 5, moduleName: 'Profiles', permission: 0 },
    { moduleId: 6, moduleName: 'Opportunities', permission: 0 },
    { moduleId: 7, moduleName: 'Settings', permission: 0 },
    { moduleId: 8, moduleName: 'Signalments', permission: 0 },
    { moduleId: 9, moduleName: 'Universites', permission: 0 },
    { moduleId: 10, moduleName: 'Users', permission: 0 },
  ];

  const [UserPermissions, setAccessPermissions] = useState<SystemUser['UserPermissions']>(currentSystemUser?.UserPermissions || modulePermissions);
  const customStyle = {
    marginBottom: '0.5rem',
    fontFamily: 'SFPro-Regular',
    color: theme.palette.secondary.dark,
  };
  useEffect(() => {
    if (isUpdate && currentSystemUser) {
      const permissionValues = modulePermissions?.map((permission) => {
        const existingPermission = currentSystemUser.UserPermissions.find((userPermission) => userPermission.moduleId === permission.moduleId);
        return {
          ...permission,
          permission: existingPermission ? existingPermission.permission : 0,
        };
      });
      setAccessPermissions([...permissionValues]);
      setValue('UserPermissions', permissionValues);
      trigger('UserPermissions');
    }
  }, [currentSystemUser, isUpdate]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatchCountries(getCountries());
    }
    return () => {
      mounted = false;
    };
  }, []);

  const defaultValues = useMemo<SystemUser>(
    () => ({
      name: currentSystemUser?.name || '',
      surname: currentSystemUser?.surname || '',
      phoneNb: currentSystemUser?.phoneNb || '',
      mail: currentSystemUser?.mail || '',
      nickname: currentSystemUser?.nickname || '',
      address: currentSystemUser?.address || '',
      city: currentSystemUser?.city || '',
      zip: currentSystemUser?.zip || '',
      countryId: currentSystemUser?.countryId || 0,
      UserPermissions: UserPermissions,
    }),
    [currentSystemUser, UserPermissions],
  );

  const methods = useForm<SystemUser>({
    resolver: yupResolver(CreateSystemUserSchema),
    defaultValues,
    mode: 'all',
  });

  const { reset, trigger, watch, setValue } = methods;

  const values = watch();

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentSystemUser]);

  useEffect(() => {
    // Update defaultValues whenever currentSystemUser or isUpdate changes
    const updatedDefaultValues = {
      name: currentSystemUser?.name || '',
      surname: currentSystemUser?.surname || '',
      phoneNb: currentSystemUser?.phoneNb || '',
      mail: currentSystemUser?.mail || '',
      nickname: currentSystemUser?.nickname || '',
      address: currentSystemUser?.address || '',
      city: currentSystemUser?.city || '',
      zip: currentSystemUser?.zip || '',
      countryId: currentSystemUser?.countryId || 0,
      UserPermissions: currentSystemUser?.UserPermissions || modulePermissions, // Update UserPermissions based on currentSystemUser
    };
    methods.reset(updatedDefaultValues);
  }, [currentSystemUser, isUpdate]);
  const selectedCountry = countries?.find((country) => country.id === currentSystemUser?.countryId);
  const selectedCountryName = selectedCountry ? selectedCountry.name : '';

  const modulesWithPermissionOne = UserPermissions.filter((module) => module.permission === 1).map((module) => module.moduleName);
  return (
    <Grid className="artgapi-modal" container spacing={3}>
      <Grid item xs={12} md={12}>
        <Box sx={{ p: 3 }}>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" sx={{ marginBottom: '10px' }}>
              {t('adds.personal_info')}
            </Typography>
            <Box
              sx={{
                display: 'grid',
                columnGap: 3,
                rowGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
              }}
            >
              <div className="col">
                <Typography>{t('systemuser.form_fields.name.label')}</Typography>
                <label style={customStyle}>{values?.name}</label>
              </div>
              <div className="col">
                <Typography>{t('systemuser.form_fields.surname.label')}</Typography>
                <label style={customStyle}>{values?.surname}</label>
              </div>
              <div className="col">
                <Typography>{t('systemuser.form_fields.mail.label')}</Typography>
                <label style={customStyle}>{values?.mail}</label>
              </div>
              <div className="col">
                <Typography>{t('systemuser.form_fields.phone_number.label')}</Typography>
                <label style={customStyle}>{values?.phoneNb}</label>
              </div>
              <div className="col">
                <Typography>{t('systemuser.form_fields.nickname.label')}</Typography>
                <label style={customStyle}>{values?.nickname}</label>
              </div>
              <div className="col">
                <Typography>{t('systemuser.form_fields.address.label')}</Typography>
                <label style={customStyle}>{values?.address}</label>
              </div>
              <div className="col">
                <Typography>{t('systemuser.form_fields.city.label')}</Typography>
                <label style={customStyle}>{values?.city}</label>
              </div>
              <div className="col">
                <Typography>{t('systemuser.form_fields.zip.label')}</Typography>
                <label style={customStyle}>{values?.zip}</label>
              </div>
              <div className="col">
                <Typography>{t('systemuser.form_fields.countryId.label')}</Typography>
                <label style={customStyle}>{selectedCountryName}</label>
              </div>
            </Box>
          </Box>
          <Box sx={{ mt: 3, mb: 0 }}>
            <Typography variant="subtitle1">{t('systemuser.form_fields.UserPermissions.label')}</Typography>
            <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
              {modulesWithPermissionOne?.map((name, index) => (
                <div key={index} style={{ marginTop: '0', marginRight: '10px' }}>
                  <Button variant="outlined" sx={{ cursor: 'default', marginBottom: '10px' }}>
                    {name}
                  </Button>
                </div>
              ))}
            </FormGroup>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
