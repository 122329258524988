import React from 'react';
import { MenuInterface } from '../components/interfaces/menu';
import { PATHS } from './Paths';
import DashboardIcon from '../components/svgComponents/Dashboard';
import DatabaseIcon from '../components/svgComponents/Database';
import ProfileIcon from '../components/svgComponents/Profiles';
import SubadminIcon from '../components/svgComponents/Subadmin';
import BugsIcon from '../components/svgComponents/Bugs';
// import UniversityIcon from '../components/svgComponents/University';
import OpportunitiesIcon from '../components/svgComponents/Opportunities';
import ArticleIcon from '../components/svgComponents/Article';
import ReportsIcon from '../components/svgComponents/Reports';
import UserIcon from '../components/svgComponents/Users';
import AdsIcon from '../components/svgComponents/Ads';
import SettingsIcon from '../components/svgComponents/Settings';
import CampaignIcon from '../components/svgComponents/Campaign';
import InterestIcon from '../components/svgComponents/InterestIcon';
import MasterClassIcon from '../components/svgComponents/MasterClassIcon';
// import SubscriptionPlanIcon from '../components/svgComponents/SubscriptionPlan';
import TransactionIcon from '../components/svgComponents/Transaction';
import WelcomeVideoIcon from '../components/svgComponents/WelcomeVideo';
import PortfolioPlanIcon from '../components/svgComponents/PortfolioPlanIcon';

export const menuAdmin: MenuInterface[] = [
  {
    title: 'common.dashbaord',
    badge: 'DASHBOARD',
    subtitle: 'Dashboard',
    isSubMenu: false,
    url: PATHS.Dashboard,
    icon: <DashboardIcon />,
  },
  // {
  //   title: 'common.welcome_video',
  //   badge: 'WELCOME_VIDEO',
  //   subtitle: 'WelcomeVideo',
  //   isSubMenu: false,
  //   url: PATHS.welcome,
  //   icon: <WelcomeVideoIcon />,
  // },
  {
    title: 'common.utilisateurs',
    badge: 'UTILISATEURS',
    subtitle: 'Users',
    isSubMenu: true,
    icon: <UserIcon />,
    subItems: [
      {
        title: 'common.adminstrator',
        url: PATHS.Admins,
        badge: 'ADMINS',
        subtitle: 'Admins',
      },
      // { title: 'Artistes', url: PATHS.Artistes, badge: 'ARTISTES', subtitle: 'Artistes' },
      // { title: 'Galeries', url: PATHS.Galleries, badge: 'GALERIES', subtitle: 'Galeries' },
      // { title: 'Art Lovers', url: PATHS.Artlovers, badge: 'ART LOVERS', subtitle: 'Artlovers' },
      { title: 'common.artists', url: PATHS.Artusers, badge: 'ARTS', subtitle: 'Artusers' },
      { title: 'common.artsweek', url: PATHS.Arts, badge: 'ARTS', subtitle: 'Arts' },
      { title: 'common.artistweek', url: PATHS.Artworks, badge: 'ARTSWORKS', subtitle: 'Artsworks' },
      { title: `common.adusers`, url: PATHS.AdsUsers, badge: 'ADS USERS', subtitle: 'Adsusers' },
    ],
  },
  {
    title: 'common.signalements',
    badge: 'SIGNALEMENTS',
    subtitle: 'Signalments',
    isSubMenu: true,
    icon: <ReportsIcon />,
    subItems: [
      {
        title: 'common.userReports',
        url: PATHS.UserReports,
        badge: 'SIGNALEMENTS',
        subtitle: 'Signalments',
      },
      { title: 'common.incomeReports', url: PATHS.RevenueReports, badge: 'REVENUEREPORTS', subtitle: 'RevenueReports' },
      { title: 'common.adReports', url: PATHS.AdsReports, badge: 'ADSREPORTS', subtitle: 'AdsReports' },
      { title: 'common.classReports', url: PATHS.ClassroomReports, badge: 'CLASSROOMREPORTS', subtitle: 'ClassroomReports' },
    ],
  },
  {
    title: 'common.transactions',
    badge: 'TRANSACTIONS',
    subtitle: 'Transactions',
    isSubMenu: true,
    icon: <TransactionIcon />,
    subItems: [{ title: 'common.master_transaction', url: PATHS.Transactions, badge: 'Transactions', subtitle: 'Transactions' }],
  },
  {
    title: 'common.campagnes',
    badge: 'CAMPAGNES',
    subtitle: 'Campagnes',
    isSubMenu: true,
    icon: <CampaignIcon />,

    subItems: [
      { title: 'common.interstitials', url: PATHS.Interstiles, badge: 'Interstitiles', subtitle: 'Interstitiles' },
      { title: 'common.sponserPost', url: PATHS.Sponserspost, badge: 'Post Sponsers', subtitle: 'Post Sponsers' },
    ],
  },
  {
    title: 'common.annonces',
    badge: 'ANNONCES',
    subtitle: 'Annonces',
    isSubMenu: true,
    icon: <AdsIcon />,
    subItems: [
      { title: 'common.pendingAds', url: PATHS.Pendingads, badge: 'Pending ads', subtitle: 'Pending ads' },
      { title: 'common.approvedAds', url: PATHS.Approvedads, badge: 'Approved ads', subtitle: 'Approved ads' },
      { title: `common.subscriptionPlan`, url: PATHS.AdsSubscriptionPlan, badge: 'Subscription plan', subtitle: 'Subscription plan' },
      { title: 'common.howItWorks', url: PATHS.Howitworks, badge: 'How it works', subtitle: 'How it works' },
    ],
  },
  {
    title: 'common.interests',
    badge: 'INTERESTS',
    subtitle: 'Interests',
    isSubMenu: true,
    icon: <InterestIcon />,
    subItems: [
      { title: 'common.categories', url: PATHS.Categories, badge: 'Categories', subtitle: 'Categories' },
      { title: 'common.interests', url: PATHS.Interests, badge: 'InterestsData', subtitle: 'InterestsData' },
    ],
  },
  {
    title: 'common.mastercclass',
    badge: 'MASTERCLASS',
    subtitle: 'MasterClass',
    isSubMenu: true,
    icon: <MasterClassIcon />,
    subItems: [
      { title: 'common.mastercclass', url: PATHS.MasterClass, badge: 'MASTERCLASSDATA', subtitle: 'MasterClassData' },
      { title: `common.subscriptionPlan`, url: PATHS.SubscriptionPlan, badge: 'SUBSCRIPTIONPLAN', subtitle: 'SubscriptionPlan' },
      { title: `common.advertisements`, url: PATHS.Advertisements, badge: 'ADVERTISEMENTS', subtitle: 'Advertisements' },
    ],
  },
  // {
  //   title: 'common.portfolio',
  //   badge: 'PORTFOLIO',
  //   subtitle: 'Portfolio',
  //   isSubMenu: true,
  //   icon: <PortfolioPlanIcon />,
  //   subItems: [
  //     {
  //       title: `common.subscriptionPlan`,
  //       url: PATHS.PortfolioSubscriptionPlan,
  //       badge: 'PORTFOLIOSUBSCRIPTIONPLAN',
  //       subtitle: 'PortfolioSubscriptionPlan',
  //     },
  //   ],
  // },

  {
    title: 'common.opportunities',
    url: PATHS.Opportunities,
    badge: 'OPPORTUNITIES',
    subtitle: 'Opportunities',
    isSubMenu: false,
    icon: <OpportunitiesIcon />,
  },
  {
    title: 'common.articles',
    url: PATHS.Articles,
    badge: 'ARTICLES',
    subtitle: 'Articles',
    isSubMenu: false,
    icon: <ArticleIcon />,
  },
  {
    title: 'common.database',
    url: PATHS.Db,
    badge: 'BASE DE DONNEE',
    subtitle: 'Database',
    isSubMenu: false,
    icon: <DatabaseIcon />,
  },
  {
    title: 'common.bugs',
    url: PATHS.Bugs,
    badge: 'BUGS',
    subtitle: 'Bugs',
    isSubMenu: false,
    icon: <BugsIcon />,
  },
  // {
  //   title: 'Universités',
  //   url: PATHS.Universities,
  //   badge: 'UNIVERSITES',
  //   subtitle: 'Universites',
  //   isSubMenu: false,
  //   icon: <UniversityIcon />,
  // },

  {
    title: 'common.subadmin',
    url: PATHS.Systemusers,
    badge: 'SYSTEMUSERS',
    subtitle: 'Subadmin',
    isSubMenu: false,
    icon: <SubadminIcon />,
  },
  {
    title: 'common.settings',
    url: PATHS.Settings,
    badge: 'SETTINGS',
    subtitle: 'Settings',
    isSubMenu: false,
    icon: <SettingsIcon />,
  },
  {
    title: 'common.profils',
    url: PATHS.Profiles,
    badge: 'PROFILES',
    subtitle: 'Profiles',
    isSubMenu: false,
    icon: <ProfileIcon />,
  },
];
