/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import { ThunkDispatch } from 'redux-thunk';
import { CONSTANTS, ENDPOINTS, getErrorMessage, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { PaginationActionTypes } from '../pagination/models';
import { GET_TOTAL_PAGES } from '../pagination/types';
import { RootState } from '../rootStore';
import { SnackBarActionTypes } from '../snackBar/models';
import { SHOW_SNACK_BAR } from '../snackBar/types';
import { ArticlesActionTypes } from './models';
import {
  CHANGE_ARTICLE_STAUTS,
  CREATE_ARTICLE_SUCCESS,
  DELETE_ARTICLE,
  GETTING_ARTICLES,
  GET_ARTICLES_ERROR,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLE_SUCCESS,
} from './types';

export const getArticles =
  (page?: number, limit?: number, status?: string, category?: string) =>
  async (dispatch: ThunkDispatch<RootState, undefined, ArticlesActionTypes | PaginationActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: GETTING_ARTICLES,
    });

    sendAsyncRequest(
      HttpMethod.Get,
      ENDPOINTS.ARTICLES,
      {},
      true,
      {
        page: page,
        limit: limit,
        status: status,
        category: category,
      },
      true,
      false,
    )
      .then((res: AxiosResponse) => {
        dispatch({ type: GET_TOTAL_PAGES, total: res.data.total });
        dispatch({
          type: GET_ARTICLES_SUCCESS,
          payload: res.data?.articles,
        });
      })
      .catch((error) => {
        if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
          localStorage.clear();
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: CONSTANTS.SESSION_EXPIRED,
              severity: 'error',
              icon: 'ErrorICon',
              size: 'medium',
            },
          });
          dispatch({
            type: GET_ARTICLES_ERROR,
            error: error,
          });
        } else {
          dispatch({
            type: GET_ARTICLES_ERROR,
            error: error,
          });
        }
      });
  };

export const AddArticle =
  (data: any, isDraft?: boolean) =>
  async (dispatch: ThunkDispatch<RootState, undefined, ArticlesActionTypes | PaginationActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: GETTING_ARTICLES,
    });
    const categoryToSend = data.type == 0 ? CONSTANTS.PODCAST_TO_SEND : CONSTANTS.ARTICLE_TO_SEND;
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('author', data.author);
    formData.append('category', categoryToSend);
    formData.append('status', isDraft ? CONSTANTS.DRAFT : CONSTANTS.PUBLISHED);
    formData.append('image', data.file ? data.file : '');
    formData.append('authorImage', data.authorImage ? data.authorImage : '');
    formData.append('description', data.description);
    formData.append('articleUrl', data.articleUrl);
    if (data.podcastUrl) formData.append('podcastUrl', data.podcastUrl);
    sendAsyncRequest(HttpMethod.Post, ENDPOINTS.ARTICLES, formData, true, {}, true, false)
      .then((res: AxiosResponse) => {
        const article: any = { ...res.data.data };
        dispatch({
          type: CREATE_ARTICLE_SUCCESS,
          article: article,
        });
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: 'Article crée avec succès',
            severity: 'success',
            icon: 'CheckIcon',
            size: 'medium',
          },
        });
        dispatch(getArticles(1));
      })
      .catch((error: any) => {
        if (error?.response?.data?.message === 'Validation error: Article url is invalid') {
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: getErrorMessage(t('articles.articleurl')),
              severity: 'error',
              icon: 'ErrorICon',
              size: 'medium',
            },
          });
        } else {
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: getErrorMessage(t('articles.creation_failure')),
              severity: 'error',
              icon: 'ErrorICon',
              size: 'medium',
            },
          });
        }
      });
  };

export const getArticleById = (id: string) => async (dispatch: ThunkDispatch<RootState, undefined, ArticlesActionTypes | SnackBarActionTypes>) => {
  await sendAsyncRequest(HttpMethod.Get, ENDPOINTS.ARTICLES + '/' + id, {}, true, {})
    .then((res: any) => {
      dispatch({
        type: GET_ARTICLE_SUCCESS,
        payload: res.data?.article,
      });
    })
    .catch((error) => {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: {
          message: getErrorMessage(error),
          severity: 'error',
          icon: 'ErrorICon',
          size: 'medium',
        },
      });
    });
};

export const UpdateArticle =
  (data: any) => async (dispatch: ThunkDispatch<RootState, undefined, ArticlesActionTypes | PaginationActionTypes | SnackBarActionTypes>) => {
    const formData = new FormData();
    const categoryToSend = data.type == 0 ? CONSTANTS.PODCAST_TO_SEND : CONSTANTS.ARTICLE_TO_SEND;
    formData.append('title', data.title);
    formData.append('author', data.author);
    formData.append('category', categoryToSend);
    formData.append('status', CONSTANTS.PUBLISHED);
    formData.append('image', data.file ? data.file : '');
    formData.append('authorImage', data.authorImage ? data.authorImage : '');
    formData.append('description', data.description);
    formData.append('articleUrl', data.articleUrl);
    if (data.podcastUrl) formData.append('podcastUrl', data.podcastUrl);
    dispatch({
      type: GETTING_ARTICLES,
    });
    sendAsyncRequest(HttpMethod.Patch, ENDPOINTS.ARTICLES + '/' + data.id, formData, true, {}, true, false)
      .then((res: AxiosResponse) => {
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: 'Article modifiée avec succès',
            severity: 'success',
            icon: 'CheckIcon',
            size: 'medium',
          },
        });
        dispatch(getArticles(1));
      })
      .catch((error) => {
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: getErrorMessage(t('articles.update_failure')),
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
      });
  };

export const deleteArticle =
  (id: number) => async (dispatch: ThunkDispatch<RootState, undefined, ArticlesActionTypes | PaginationActionTypes | SnackBarActionTypes>) => {
    await sendAsyncRequest(HttpMethod.Delete, ENDPOINTS.ARTICLES + '/' + id, {}, true, {})
      .then((resultat: AxiosResponse) => {
        dispatch(getArticles(0, 15));
        dispatch({
          type: DELETE_ARTICLE,
          payload: resultat.data,
        });
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: t('articles.article_success_deleted'),
            severity: 'success',
            icon: 'CheckIcon',
            size: 'medium',
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: getErrorMessage(error),
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
      });
  };

export const changeArticleStatus =
  (id: number) => async (dispatch: ThunkDispatch<RootState, undefined, ArticlesActionTypes | PaginationActionTypes | SnackBarActionTypes>) => {
    await sendAsyncRequest(HttpMethod.Get, ENDPOINTS.CHANGE_ARTICLE_STATUS + id + CONSTANTS.DELETE_STATUS, {}, true, {})
      .then((resultat: AxiosResponse) => {
        dispatch(getArticles(0, 15));
        dispatch({
          type: CHANGE_ARTICLE_STAUTS,
          payload: resultat.data.article,
        });
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: t('articles.article_status_success_changed'),
            severity: 'success',
            icon: 'CheckIcon',
            size: 'medium',
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: getErrorMessage(error),
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
      });
  };
