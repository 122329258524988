import React, { useEffect, useState, ChangeEvent } from 'react';
import { Box, Container, Table, TableBody, TableContainer, TablePagination, Stack, Select, SelectChangeEvent, MenuItem, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TablePaginationActions } from '../table/TablePaginationActions/TablePaginationActions';
import TableLoadingData from '../table/TableLoadingData';
import TableNoData from '../table/TableNoData';
import { TABLE_COLUMNS_CONFIG, TABLE_COLUMNS_CONFIG_ARTISTS, initialPaginationState, TABLE_COLUMNS_CONFIG_NO } from './ArtWorkUsers.constant';
import { RootStyle } from '../../pages/systemusers/SystemUser.styles';
import SearchIcon from '../svgComponents/searchIcon';
import TableHeadCustom from '../table/table-head/CustomTableHead';
import { AdsUsersActionTypes, IAdsUsersResponse, IAdsUsersState } from '../../store/adsUsers/slice/AdsUser-slice.types';
import AdsUsers from '../../models/AdsUsers';
import { adsUserApi } from '../../store/adsUsers/api/AdsUsers.api';
import { useDebounce } from '@uidotdev/usehooks';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store/rootStore';
import useTable from '../../hooks/useTable';
import { ContainerStyle, CustomTitle, GridStyle, InputStyle } from '../../utils/toolbar/CommonToolbar.styles';
import ArtWorkusersTableRow from './artWorkUsers-row/ArtWorkUsersTableRow';
import useSnackBar from '../../hooks/useSnackBar';
import ConfirmDeleteDialog from '../../utils/confirm-delete-dialog/ConfirmDeleteDialog';

const { addArtistsOfWeek } = adsUserApi.endpoints;
const count = 3;
function ArtWorkUsersDetails() {
  const { t } = useTranslation();
  const [filterKeyword, setfilterKeyword] = useState('');
  const [selectedOption, setSelectedOption] = useState('all');
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AdsUsersActionTypes>>();
  const { users, isLoading } = useSelector<RootState, IAdsUsersState>((state: RootState) => state[adsUserApi.reducerPath]);
  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialPaginationState);
  const isFullFilled = !isLoading && users && users?.users?.length > 0;
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]);
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    if (selectedOption === 'all') {
      dispatch(
        adsUserApi.endpoints.getAllArtists({
          page,
          limit: rowsPerPage,
          orderBy,
          order,
          keyword: debouncedSearchTerm,
        }),
      );
    } else if (selectedOption === 'week') {
      dispatch(
        adsUserApi.endpoints.getArtistOfTheWeek({
          page,
          limit: rowsPerPage,
          orderBy,
          order,
          keyword: debouncedSearchTerm,
        }),
      );
      setSelectedCheckboxes([]);
    }
  }, [selectedOption, order, orderBy, page, rowsPerPage, debouncedSearchTerm, dispatch]);

  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
    setPage(0);
  };

  const handleCheckboxChange = (checkboxId: number | undefined) => {
    const updatedSelectedIds = selectedCheckboxes.includes(checkboxId || 0)
      ? selectedCheckboxes.filter((ids) => ids !== checkboxId)
      : [...selectedCheckboxes, checkboxId || 0];

    setSelectedCheckboxes(updatedSelectedIds.filter((id) => typeof id === 'number'));
  };
  const { alertSuccess, alertError } = useSnackBar();

  useEffect(() => {
    if (
      selectedOption === 'all' &&
      users &&
      users.currentArtistOfTheWeekCount !== 5 &&
      selectedCheckboxes?.length >= 5 - ((users && users.currentArtistOfTheWeekCount) ?? 0)
    ) {
      dispatch(alertSuccess(`You can select maximum ${5 - ((users && users.currentArtistOfTheWeekCount) ?? 0)} artists of the week only.`));
    }
  }, [selectedCheckboxes, selectedOption, users?.currentArtistOfTheWeekCount]);

  const handleApplyChange = () => {
    const data = {
      artistIds: selectedCheckboxes,
    };
    dispatch(addArtistsOfWeek(data))
      .then(() => {
        dispatch(alertSuccess(t('common.created_five_artists')));
        setSelectedCheckboxes([]);
        dispatch(
          adsUserApi.endpoints.getAllArtists({
            page,
            limit: rowsPerPage,
            orderBy,
            order,
            keyword: debouncedSearchTerm,
          }),
        );
      })
      .catch((err) => {
        dispatch(alertError(err.message)); // Show error message in alert
      });
  };

  const onOPenDeleteDialog = (id?: number) => {
    setSelectedId(id);
    setConfirmDeleteOpen(true);
  };

  const onCloseConfirmDeleteDialog = () => {
    setSelectedId(undefined);
    setConfirmDeleteOpen(false);
    setfilterKeyword('');
  };

  const onDeleteAristst = async () => {
    try {
      if (selectedId) {
        const data = {
          artistIds: [selectedId],
        };
        await dispatch(adsUserApi.endpoints.deleteArtistUser(data));
        onCloseConfirmDeleteDialog();
        dispatch(alertSuccess(t('interests.artist.delete_success_message')));
        if (users?.next === null && users?.total <= page * rowsPerPage) {
          setPage(page - 1);
        }
        dispatch(
          adsUserApi.endpoints.getArtistOfTheWeek({
            page,
            limit: rowsPerPage,
            orderBy,
            order,
            keyword: debouncedSearchTerm,
          }),
        );
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
    }
  };

  return (
    <>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        onClose={onCloseConfirmDeleteDialog}
        onDelete={onDeleteAristst}
        confirm_delete_title={t('systemuser.confirm_delete_title')}
        confirm_delete_message={t('common.confirm_delete_artist_message')}
      />
      <RootStyle>
        <ContainerStyle style={{ padding: '0 24px' }}>
          <GridStyle>
            <CustomTitle>{t('common.artists_of_week')}</CustomTitle>
            <Stack direction="column" spacing={4}>
              <InputStyle
                value={filterKeyword}
                placeholder={t('common.search')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterKeyword(e.target.value)}
                InputProps={{
                  startAdornment: <SearchIcon />,
                }}
              />
            </Stack>
          </GridStyle>
        </ContainerStyle>
        <div style={{ marginLeft: 'auto', marginBottom: '10px' }}>
          <Select
            value={selectedOption}
            onChange={(event: SelectChangeEvent) => {
              setSelectedOption(event.target.value);
              setPage(0);
            }}
            style={{ minWidth: 120 }}
          >
            <MenuItem value={'all'}>All Artists</MenuItem>
            <MenuItem value={'week'}>Artists of the Week</MenuItem>
          </Select>
          {selectedOption === 'all' && (
            <Button
              variant="contained"
              style={{
                cursor: 'pointer',
                backgroundColor: selectedCheckboxes?.length <= 0 ? '#0000001f' : '#1976d2',
                color: '#fff',
                marginLeft: '10px',
                paddingBottom: '16px',
                paddingTop: '17px',
                height: '58px',
                marginTop: '-2px',
              }}
              onClick={handleApplyChange}
              disabled={selectedCheckboxes?.length <= 0}
            >
              Apply
            </Button>
          )}
        </div>
        <Container sx={{ minHeight: '70vh' }}>
          <div className="table-block">
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  columnsConfig={
                    selectedOption === 'all' && isFullFilled
                      ? TABLE_COLUMNS_CONFIG
                      : selectedOption === 'week'
                      ? TABLE_COLUMNS_CONFIG_ARTISTS
                      : TABLE_COLUMNS_CONFIG_NO
                  }
                  onSort={onSort}
                />
                <TableBody>
                  {isFullFilled &&
                    (users as IAdsUsersResponse)?.users?.map((row: AdsUsers) => {
                      return (
                        <ArtWorkusersTableRow
                          key={row.id}
                          handleCheckboxChange={handleCheckboxChange}
                          selectedCheckboxes={selectedCheckboxes}
                          row={row}
                          selected={false}
                          artistCount={users.currentArtistOfTheWeekCount ?? 0}
                          onOPenDeleteDialog={onOPenDeleteDialog}
                          columnsConfig={selectedOption === 'all' ? TABLE_COLUMNS_CONFIG : TABLE_COLUMNS_CONFIG_ARTISTS}
                        />
                      );
                    })}
                  <TableNoData isNotFound={!isLoading && users?.users?.length === 0} title={t('common.no_data')} />
                  <TableLoadingData isLoading={isLoading} />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {isFullFilled && (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={users ? users.total : 0}
                rowsPerPage={rowsPerPage}
                page={users && page ? page - 1 : 0}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t('common.rowsperpage')}
                sx={{
                  '& .MuiInputBase-root': {
                    maxWidth: '60px',
                  },
                }}
              />
            </Box>
          )}
        </Container>
      </RootStyle>
    </>
  );
}

export default ArtWorkUsersDetails;
