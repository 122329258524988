/* eslint-disable react/react-in-jsx-scope */
// @mui
import { IconButton, Stack, TableRow, Tooltip } from '@mui/material';
// components
import { ReactComponent as EditIcon } from '../../../../assets/icons/pencil-outline.svg';
import { TableCellStyle } from './CategoriesRow.styles';
import { CategoriesRowProps } from './CategoriesRow.types';
import { Column } from '../../../table/table-head/CustomTableHead.types';
import Iconify from '../../../Iconify';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/ic_trash.svg';
// ----------------------------------------------------------------------

export default function CategoriesTableRow({ row, selected, columnsConfig, onOPenEditDialog, onConfirmDelete }: CategoriesRowProps) {
  const { id } = row;

  return (
    <TableRow key={id} hover selected={selected} sx={{ backgroundColor: '#ffffff' }}>
      {columnsConfig.map((column: Column) => (
        <TableCellStyle key={column.id} align={column.align || 'left'} width={column.width}>
          {column.id !== 'actions' ? (
            String(row[column.id as keyof typeof row])
          ) : (
            <Stack direction="row" spacing={2}>
              <Tooltip title="Edit" arrow>
                <IconButton onClick={() => onOPenEditDialog(id)}>
                  <Iconify icon={EditIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <IconButton onClick={() => onConfirmDelete(id)}>
                  <Iconify icon={DeleteIcon} />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </TableCellStyle>
      ))}
    </TableRow>
  );
}
