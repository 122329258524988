/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { DATA } from '../../../../store/flags/models';

interface FlagProjetDialogProps {
  flag: DATA;
}
// ART (Project) Publication Dialog
function FlagProjetDialog(props: FlagProjetDialogProps) {
  const flag = props.flag;
  return (
    <>
      {flag.flaggedPublication.art.images.map((image: any) => (
        <img
          loading="lazy"
          key={image.minImageUrl}
          src={image.minImageUrl && image.minImageUrl}
          style={{ width: 376, height: 375, marginTop: '-5%', objectFit: 'contain' }}
        />
      ))}
    </>
  );
}

export default FlagProjetDialog;
