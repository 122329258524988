import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import UserRequest from '../../models/UserRequest';
import { CONSTANTS, ENDPOINTS, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { RootState } from '../rootStore';
import { SnackBarActionTypes } from '../snackBar/models';
import { SHOW_SNACK_BAR } from '../snackBar/types';
import { userActionTypes } from './models';
import {
  GET_PROFILE_ATTEMPT,
  GET_PROFILE_ERROR,
  GET_PROFILE_SUCCESS,
  UPDATE_PROFILE_ATTEMPT,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_SUCCESS,
  GET_SETTINGS_ATTEMPT,
  GET_SETTINGS_SUCCESS,
  GET_SETTNGS_ERROR,
  UPDATE_SETTING_ATTEMPT,
  UPDATE_SETTING_SUCCESS,
  UPDATE_SETTING_ERROR,
} from './types';
import UserSettingRequest from '../../models/UserSettingRequest';

export const getProfile = () => async (dispatch: ThunkDispatch<RootState, undefined, userActionTypes | SnackBarActionTypes>) => {
  dispatch({
    type: GET_PROFILE_ATTEMPT,
  });

  sendAsyncRequest(HttpMethod.Get, ENDPOINTS.PROFILE, {}, true, null)
    .then((res: AxiosResponse) => {
      dispatch({
        type: GET_PROFILE_SUCCESS,
        user: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
        localStorage.clear();
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: CONSTANTS.SESSION_EXPIRED,
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
        dispatch({
          type: GET_PROFILE_ERROR,
          error: error,
        });
      } else {
        dispatch({
          type: GET_PROFILE_ERROR,
          error: error,
        });
      }
    });
};

export const getSettings = () => async (dispatch: ThunkDispatch<RootState, undefined, userActionTypes | SnackBarActionTypes>) => {
  dispatch({
    type: GET_SETTINGS_ATTEMPT,
  });

  sendAsyncRequest(HttpMethod.Get, ENDPOINTS.SETTINGS, {}, true, null)
    .then((res: AxiosResponse) => {
      dispatch({
        type: GET_SETTINGS_SUCCESS,
        user: res.data,
      });
    })
    .catch((error) => {
      if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
        localStorage.clear();
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: CONSTANTS.SESSION_EXPIRED,
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
        dispatch({
          type: GET_SETTNGS_ERROR,
          error: error,
        });
      } else {
        dispatch({
          type: GET_SETTNGS_ERROR,
          error: error,
        });
      }
    });
};

export const updateProfile = (data: UserRequest) => async (dispatch: ThunkDispatch<RootState, undefined, userActionTypes | SnackBarActionTypes>) => {
  dispatch({
    type: UPDATE_PROFILE_ATTEMPT,
  });

  sendAsyncRequest(HttpMethod.Patch, ENDPOINTS.PROFILE, data, true, null)
    .then((res: AxiosResponse) => {
      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
      });
      dispatch(getProfile());
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: {
          message: CONSTANTS.SAVE_SUCCESS,
          severity: 'success',
          icon: 'CheckIcon',
          size: 'medium',
        },
      });
    })
    .catch((error) => {
      if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
        localStorage.clear();
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: CONSTANTS.SESSION_EXPIRED,
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
        dispatch({
          type: UPDATE_PROFILE_ERROR,
          error: error,
        });
      } else {
        dispatch({
          type: UPDATE_PROFILE_ERROR,
          error: error,
        });
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: CONSTANTS.ECHEC_MAJ_PROFIL,
            severity: 'error',
            icon: 'ErrorICon',
            size: 'medium',
          },
        });
      }
    });
};

export const updateSettings =
  (data: UserSettingRequest, id?: string) => async (dispatch: ThunkDispatch<RootState, undefined, userActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: UPDATE_SETTING_ATTEMPT,
    });
    sendAsyncRequest(HttpMethod.Patch, ENDPOINTS.SETTINGS + (id ? `/${id}` : ''), data, true, null)
      .then((res: AxiosResponse) => {
        dispatch({
          type: UPDATE_SETTING_SUCCESS,
        });
        dispatch(getSettings());
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: CONSTANTS.SETTING_SAVE_SUCCESS,
            severity: 'success',
            icon: 'CheckIcon',
            size: 'medium',
          },
        });
      })
      .catch((error) => {
        if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
          localStorage.clear();
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: CONSTANTS.SESSION_EXPIRED,
              severity: 'error',
              icon: 'ErrorICon',
              size: 'medium',
            },
          });
          dispatch({
            type: UPDATE_SETTING_ERROR,
            error: error,
          });
        } else {
          dispatch({
            type: UPDATE_SETTING_ERROR,
            error: error,
          });
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: CONSTANTS.ECHEC_MAJ_SETTINGS,
              severity: 'error',
              icon: 'ErrorICon',
              size: 'medium',
            },
          });
        }
      });
  };
