import { createTheme } from '@mui/material/styles';

export const dialogHeaderTheme = createTheme({
  palette: {
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  typography: {
    allVariants: {
      textTransform: 'none',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'SFPro',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontSize: '24px',
          fontFamily: 'SFPro',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          color: '#26272c',
        },
      },
    },
  },
});
