import { config } from '../../config/config';

async function getSelf(token) {
  let res = await fetch(config.BASE_URL_API + 'self/', {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });

  return res.json();
}

async function loginApi(mail, password) {
  let data = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=utf-8',
      'Access-Control-Allow-Credentials': 'true',
    },
    credentials: 'include',
    body: JSON.stringify({
      mail: mail,
      password: password,
    }),
  };

  let res = await fetch(config.BASE_URL_API + 'auth', data);
  return res.json();
}

export async function adminLogin(mail, password) {
  var res = await loginApi(mail, password);
  var token = res.token;

  if (res.code !== 201) return res;

  res = await getSelf(token);

  if (res.code !== 200) return res;

  if (res.user.Role.id !== 4)
    return {
      code: 403,
      message: "Vous n'avez pas l'autorisation de vous connecter ici.",
    };
  else return { code: 200, message: 'Connexion réuissie!', token: token };
}
export const resetPassword = async (password, confirmPassword, userId, token) => {
  let data = {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=utf-8',
      'Access-Control-Allow-Credentials': 'true',
    },
    credentials: 'include',
    body: JSON.stringify({
      newPassword: password,
      confirmPassword: confirmPassword,
    }),
  };
  let res = await fetch(`${config.BASE_URL_API}reset-password/${userId}/${token} `, data);
  return res.json();
};
