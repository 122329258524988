import SubscriptionPlans from '../../../models/SubscriptionPlans';

export interface ISubscrptionPlansResponse {
  total: number;
  next: number | null;
  previous: number | null;
  data: SubscriptionPlans[];
}

export interface ISubscrptionPlansState {
  isLoading: boolean;
  data: ISubscrptionPlansResponse | null;
  subscriptionPlans: SubscriptionPlans | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_SUBSCRIPTION_PLANS_SUCCESS = 'FETCH_SUBSCRIPTION_PLANS_SUCCESS',
  FETCH_SUBSCRIPTION_PLANS_FAILURE = 'FETCH_SUBSCRIPTION_PLANS_FAILURE',
  CREATE_SUBSCRIPTION_PLANS_SUCCESS = 'CREATE_SUBSCRIPTION_PLANS_SUCCESS',
}

export interface ISubscriptionPlansPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchSubscriptionPlansSuccessAction {
  type: typeof ActionTypes.FETCH_SUBSCRIPTION_PLANS_SUCCESS | typeof ActionTypes.CREATE_SUBSCRIPTION_PLANS_SUCCESS;
  payload: ISubscrptionPlansResponse | SubscriptionPlans;
}

export interface IFetchSubscriptionPlansRejectedAction {
  type: typeof ActionTypes.FETCH_SUBSCRIPTION_PLANS_FAILURE;
  payload: string;
}

export type SubscriptionPlansActionTypes =
  | ISubscriptionPlansPendingAction
  | IFetchSubscriptionPlansSuccessAction
  | IFetchSubscriptionPlansRejectedAction;
