/* eslint-disable react/react-in-jsx-scope */
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../components/svgComponents/closeIcon';
import UniversityForm from '../form/UniversityForm';
import { AddDialogStyle } from './add-dialog.styles';
import { AddDialogProps } from './add-dialog.types';

export default function AddDialog({ open, onClose, currentUniversity, isUpdate }: AddDialogProps) {
  const { t } = useTranslation();
  return (
    <AddDialogStyle open={open} fullWidth>
      <Stack display={'flex'} direction={'row'} justifyContent={'space-between'} padding={2}>
        <Typography variant={'h5'}>{isUpdate ? t('university.update_university_title') : t('university.add_university_title')}</Typography>
        <IconButton aria-label="close" color="primary" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <UniversityForm onClose={onClose} currentUniversity={currentUniversity} isUpdate={isUpdate} />
    </AddDialogStyle>
  );
}
