import { PasswordDialogActionTypes } from './models';
import { HIDE_DIALOG, SHOW_DIALOG } from './types';

export const hidePasswordDialog = (): PasswordDialogActionTypes => ({
  type: HIDE_DIALOG,
});

export const showPasswordDialog = (): PasswordDialogActionTypes => ({
  type: SHOW_DIALOG,
});
