/* eslint-disable react/react-in-jsx-scope */

import { Box, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../components/svgComponents/closeIcon';
import UniversityUsers from '../university-users/UniversityUsers';
import UniversityAbout from './UniversityAbout';
import { DetailsDialogStyle } from './details-dialog.styles';
import { DetailsDialogProps } from './details-dialog.types';

export default function DetailsDialog({ open, onClose, currentUniversity }: DetailsDialogProps) {
  const { t } = useTranslation();
  return (
    <DetailsDialogStyle open={open} fullScreen>
      <Stack display={'flex'} direction={'row'} justifyContent={'space-between'} padding={2}>
        <Typography variant={'h5'}>
          {`${t('university.university_details_title')}`} &nbsp; {currentUniversity?.name}
        </Typography>
        <Box sx={{ flex: '1 1 auto' }} />
        <IconButton aria-label="close" color="primary" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Stack spacing={3} m={2}>
            <UniversityAbout university={currentUniversity} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={9}>
          <Stack spacing={3} m={2}>
            <UniversityUsers university={currentUniversity} />
          </Stack>
        </Grid>
      </Grid>
    </DetailsDialogStyle>
  );
}
