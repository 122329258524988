export const GETTING_FLAGS = 'GETTING_FLAGS';
export const GETTING_FLAG = 'GETTING_FLAG';
export const GET_FLAGS_SUCCESS = 'GET_FLAGS_SUCCESS';
export const GET_FLAG_SUCCESS = 'GET_FLAG_SUCCESS';
export const GET_FLAGS_ERROR = 'GET_FLAGS_ERROR';
export const GET_FLAG_ERROR = 'GET_FLAG_ERROR';
export const PATCH_FLAGS_STATUS = 'PATCH_FLAGS_STATUS';
export const DELETE_PUBLICATION = 'DELETE_PUBLICATION';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETE_USER = 'DELETE_USER';
