import HowitWorks from '../../../models/HowItWorks';

export interface IHowItWorksResponse {
  total: number;
  next: number | null;
  previous: number | null;
  howItWorksList: {
    rows: HowitWorks[];
  };
}

export interface IHowItWorksState {
  isLoading: boolean;
  howItWorksList: IHowItWorksResponse | null;
  workUsers: HowitWorks | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_HOWITWORKS_SUCCESS = 'FETCH_HOWITWORKS_SUCCESS',
  FETCH_HOWITWORKS_FAILURE = 'FETCH_HOWITWORKS_FAILURE',
  CREATE_HOWITWORKS_SUCCESS = 'CREATE_HOWITWORKS_SUCCESS',
}

export interface iHowItWorksPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchHowItWorksSuccessAction {
  type: typeof ActionTypes.FETCH_HOWITWORKS_SUCCESS | typeof ActionTypes.CREATE_HOWITWORKS_SUCCESS;
  payload: IHowItWorksResponse | HowitWorks;
}

export interface IFetchHowItWorksRejectedAction {
  type: typeof ActionTypes.FETCH_HOWITWORKS_FAILURE;
  payload: string;
}

export type HowItWorksActionTypes = iHowItWorksPendingAction | IFetchHowItWorksSuccessAction | IFetchHowItWorksRejectedAction;
