/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { RootState } from '../../rootStore';
import { IFetchInterestsSuccessAction, InterestsActionTypes } from '../slice/Interests-slice.types';
import { interestsActions, interestsReducer } from '../slice/Interests-slice';
import { Interests } from '../../../models/Interests';

export const interestsApi = {
  reducerPath: 'interestTypes',
  reducer: interestsReducer,
  endpoints: {
    getInterestsUsers: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, InterestsActionTypes>) => {
      dispatch(interestsActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.INTEREST_TYPES, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(interestsActions.matchFulFilled(responseBody, 'fetch') as IFetchInterestsSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(interestsActions.matchRejected(error));
        });
    },
    updateInterests: (id: number, data: any) => async (dispatch: ThunkDispatch<RootState, undefined, InterestsActionTypes>) => {
      dispatch(interestsActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Patch, `${ENDPOINTS.INTEREST_TYPES}/${id}`, data, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(interestsActions.matchFulFilled(responseBody, 'update') as IFetchInterestsSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(interestsActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },
    createInterestUser: (data: any) => async (dispatch: ThunkDispatch<RootState, undefined, InterestsActionTypes>) => {
      dispatch(interestsActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Post, ENDPOINTS.INTEREST_TYPES, data, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(interestsActions.matchFulFilled(responseBody, 'create') as IFetchInterestsSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(interestsActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },
    deleteInterestUser: (id: number) => async (dispatch: ThunkDispatch<RootState, undefined, InterestsActionTypes>) => {
      dispatch(interestsActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Delete, `${ENDPOINTS.INTEREST_TYPES}/${id}`, {}, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(interestsActions.matchFulFilled(responseBody, 'delete') as IFetchInterestsSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(interestsActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },
  },
};
