/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { RootState } from '../../rootStore';
import { IFetchAdsUsersSuccessAction, AdsUsersActionTypes } from '../slice/AdsUser-slice.types';
import { adsUsersActions, adsUsersReducer } from '../slice/AdsUser-slice';

export const adsUserApi = {
  reducerPath: 'adsusers',
  reducer: adsUsersReducer,
  endpoints: {
    getAdsUsers: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, AdsUsersActionTypes>) => {
      dispatch(adsUsersActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.ADS_USERS, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(adsUsersActions.matchFulFilled(responseBody, 'fetch') as IFetchAdsUsersSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(adsUsersActions.matchRejected(error));
        });
    },
    getParticularAdsUsers: (params: any, id: number) => async (dispatch: ThunkDispatch<RootState, undefined, AdsUsersActionTypes>) => {
      dispatch(adsUsersActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.PARTICULAR_USERS}/${id}`, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(adsUsersActions.matchFulFilled(responseBody, 'fetch') as IFetchAdsUsersSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(adsUsersActions.matchRejected(error));
        });
    },
    getAdsReportUsers: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, AdsUsersActionTypes>) => {
      dispatch(adsUsersActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.ACTIVE_ADS_USERS}`, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(adsUsersActions.matchFulFilled(responseBody, 'fetch') as IFetchAdsUsersSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(adsUsersActions.matchRejected(error));
        });
    },
    getArtOfTheWeek: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, AdsUsersActionTypes>) => {
      dispatch(adsUsersActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.ART_OF_WEEK, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(adsUsersActions.matchFulFilled(responseBody, 'fetch') as IFetchAdsUsersSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(adsUsersActions.matchRejected(error));
        });
    },
    getArtistOfTheWeek: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, AdsUsersActionTypes>) => {
      dispatch(adsUsersActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.ARTIST_OF_WEEK, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(adsUsersActions.matchFulFilled(responseBody, 'fetch') as IFetchAdsUsersSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(adsUsersActions.matchRejected(error));
        });
    },
    getAllArts: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, AdsUsersActionTypes>) => {
      dispatch(adsUsersActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.ALL_ARTS, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(adsUsersActions.matchFulFilled(responseBody, 'fetch') as IFetchAdsUsersSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(adsUsersActions.matchRejected(error));
        });
    },
    getAllArtists: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, AdsUsersActionTypes>) => {
      dispatch(adsUsersActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.ALL_ARTISTS, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(adsUsersActions.matchFulFilled(responseBody, 'fetch') as IFetchAdsUsersSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(adsUsersActions.matchRejected(error));
        });
    },
    addArtistsOfWeek: (data: any) => async (dispatch: ThunkDispatch<RootState, undefined, AdsUsersActionTypes>) => {
      dispatch(adsUsersActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Post, `${ENDPOINTS.CREATE_ARTISTS_OF_WEEK}`, data, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(adsUsersActions.matchFulFilled(responseBody, 'create') as IFetchAdsUsersSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(adsUsersActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },
    addArtsOfWeek: (data: any) => async (dispatch: ThunkDispatch<RootState, undefined, AdsUsersActionTypes>) => {
      dispatch(adsUsersActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Post, `${ENDPOINTS.CREATE_ARTS_OF_WEEK}`, data, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(adsUsersActions.matchFulFilled(responseBody, 'create') as IFetchAdsUsersSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(adsUsersActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },
    deleteArtistUser: (data: any) => async (dispatch: ThunkDispatch<RootState, undefined, AdsUsersActionTypes>) => {
      dispatch(adsUsersActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Delete, `${ENDPOINTS.CREATE_ARTISTS_OF_WEEK}`, data, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(adsUsersActions.matchFulFilled(responseBody, 'delete') as IFetchAdsUsersSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(adsUsersActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },
    getAllArtUsers: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, AdsUsersActionTypes>) => {
      dispatch(adsUsersActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.ALL_ARTS, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(adsUsersActions.matchFulFilled(responseBody, 'fetch') as IFetchAdsUsersSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(adsUsersActions.matchRejected(error));
        });
    },
    getArtUsers: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, AdsUsersActionTypes>) => {
      dispatch(adsUsersActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.ALL_ARTS, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(adsUsersActions.matchFulFilled(responseBody, 'fetch') as IFetchAdsUsersSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(adsUsersActions.matchRejected(error));
        });
    },
    getArtWorkUsers: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, AdsUsersActionTypes>) => {
      dispatch(adsUsersActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.ALL_ARTS, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(adsUsersActions.matchFulFilled(responseBody, 'fetch') as IFetchAdsUsersSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(adsUsersActions.matchRejected(error));
        });
    },
  },
};
