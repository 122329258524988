import React from 'react';

export const ValidateTraitedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="74" height="74" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#1ab53c" d="M0 0h84v84H0z" />
      <path d="M35 43.556 40.668 48 49 35" stroke="#0D0D0D" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default ValidateTraitedIcon;
