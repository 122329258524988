/* eslint-disable @typescript-eslint/no-explicit-any */
import AdsUsers from '../../../models/AdsUsers';
import {
  ActionTypes,
  IFetchArtsUsersRejectedAction,
  IFetchArtsUsersSuccessAction,
  IArtsUsersPendingAction,
  IArtsUsersResponse,
  IArtsUsersState,
  ArtsUsersActionTypes,
} from './ArtUser-slice.types';

export const initialState: IArtsUsersState = {
  isLoading: false,
  error: null,
  arts: null,
  artUsers: null,
};

export const artsUsersReducer = (state = initialState, action: ArtsUsersActionTypes): IArtsUsersState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_ARTS_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        arts: action.payload as IArtsUsersResponse,
      };
    case ActionTypes.FETCH_ARTS_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_ARTS_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        artUsers: action.payload as AdsUsers,
      };
    default:
      return state;
  }
};

const matchPending = (): IArtsUsersPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (data: IArtsUsersResponse, action: 'fetch' | 'create' | 'delete'): IFetchArtsUsersSuccessAction | AdsUsers => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_ARTS_USERS_SUCCESS : ActionTypes.CREATE_ARTS_USERS_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchArtsUsersRejectedAction => {
  return {
    type: ActionTypes.FETCH_ARTS_USERS_FAILURE,
    payload: error,
  };
};

export const artsUsersActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
