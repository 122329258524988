export const GETTING_OPPORTUNITIES = 'GETTING_OPPORTUNITIES/loading';
export const GET_OPPORTUNITIES_SUCCESS = 'GET_OPPORTUNITIES_SUCCESS';

export const GETTING_OPPORTUNITY = 'GETTING_OPPORTUNITY/loading';
export const GET_OPPORTUNITY_SUCCESS = 'GET_OPPORTUNITY_SUCCESS';

export const UPDATE_OPPORTUNITY = 'UPDATE_OPPORTUNITY/loading';
export const DELETE_OPPORTUNITY = 'DELETE_OPPORTUNITY/SUCCESS';

export const ADD_OPPORTUNITY = 'ADD_OPPORTUNITY/SUCCESS';
export const CREATE_OPPORTUNITY_SUCCESS = 'CREATE_OPPORTUNITY_SUCCESS';
export const GET_OPPORTUNITIES_ERROR = 'GET_OPPORTUNITIES_ERROR';
