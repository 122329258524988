/* eslint-disable react/react-in-jsx-scope */
// @mui
import { Card, CardHeader, Stack, Typography } from '@mui/material';
// components
import { useTranslation } from 'react-i18next';
import { ReactComponent as MailIcon } from '../../../assets/icons/mail-2569.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone-ringing-505.svg';
import { ReactComponent as PinIcon } from '../../../assets/icons/pin-48.svg';
import Iconify from '../../../components/Iconify';
import { University } from '../../../models/University';

// ----------------------------------------------------------------------

interface UniversityAboutProps {
  university?: University;
}

export default function UniversityAbout({ university }: UniversityAboutProps) {
  const { t } = useTranslation();
  return (
    <Card sx={{ borderRadius: '0px' }}>
      <CardHeader title={t('university.about_section_title')} />
      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row">
          <Iconify icon={PinIcon} />
          <Typography variant="body2">{university?.address}</Typography>
        </Stack>
        <Stack direction="row">
          <Iconify icon={MailIcon} />
          <Typography variant="body2">{university?.email}</Typography>
        </Stack>
        <Stack direction="row">
          <Iconify icon={PhoneIcon} />
          <Typography variant="body2">{university?.phoneNumber}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
