/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { SystemUser } from '../../../models/SystemUser';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';

export default function useUpdateSystemuserMutation() {
  const [data, setData] = useState<SystemUser | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateSystemuser = async (id: number, isActive: boolean) => {
    try {
      setIsLoading(true);
      const response = await sendAsyncRequest(HttpMethod.Patch, `${ENDPOINTS.SYSTEMUSERS}/statusChange/${id}`, { isActive }, true, {});
      setData(response.data);
    } catch (err: any) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isLoading,
    error,
    updateSystemuser,
  };
}
