import React from 'react';

export const InterestIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0048 0C8.12802 0 4.97357 3.15445 4.97357 7.03125C4.97357 10.908 8.12802 14.0625 12.0048 14.0625C15.8816 14.0625 19.0361 10.908 19.0361 7.03125C19.0361 3.15445 15.8816 0 12.0048 0ZM9.19232 4.21875H10.5986V5.625H9.19232V4.21875ZM10.3926 9.63773L9.39829 8.64347L13.617 4.42472L14.6113 5.41898L10.3926 9.63773ZM14.8173 9.84375H13.4111V8.4375H14.8173V9.84375Z"
        fill="white"
      />
      <path
        d="M22.0889 12.6562C20.1302 12.6562 18.3401 13.7633 17.464 15.5152L15.3776 19.6875H9.19232V18.2812H14.5097L15.9157 15.4688H7.67343C6.73823 15.4688 5.83185 15.8485 5.2221 16.4767L1.91113 19.4334L6.39904 24L8.16098 22.5H17.0805C21.8781 12.8871 21.4425 13.9209 22.0889 12.6562Z"
        fill="white"
      />
    </svg>
  );
};

export default InterestIcon;
