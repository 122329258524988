import React, { useState } from 'react';
import { TableRow, Checkbox, Button, IconButton, Stack, Tooltip } from '@mui/material';
import { TableCellStyle } from './ArtUsersRow.styles';
import { ArtUsersRowProps } from './ArtUsersRow.types';
import Iconify from '../../Iconify';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/ic_trash.svg';
import FullScreenModal from '../../adds/menubar/approved/form/FullScreenModal';
import AdminVideoControl from '../../adds/menubar/approved/form/VideoControl';

export default function ArtusersTableRow({
  row,
  selected,
  columnsConfig,
  selectedCheckboxes,
  artCount,
  handleCheckboxChange,
  onOPenDeleteDialog,
}: ArtUsersRowProps) {
  const { id, image, video } = row;
  const [openImgModel, setOpenImgModal] = useState(false);
  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);

  const handleChange = () => {
    handleCheckboxChange(id);
  };
  const totalCount = 5;

  const handleClickOpenModal = () => {
    setOpenImgModal(true);
  };

  const handleClickCloseImgModal = () => {
    setOpenImgModal(false);
  };

  const openVideoPopup = () => {
    setVideoPopupOpen(true);
  };

  const closeVideoPopup = () => {
    setVideoPopupOpen(false);
  };

  const getVideoType = (url: string) => {
    const extension = url.split('.').pop()?.toLowerCase();
    return extension || 'mp4';
  };
  return (
    <>
      <TableRow key={id} hover selected={selected} sx={{ backgroundColor: '#ffffff' }}>
        {columnsConfig.map((column) => (
          <TableCellStyle key={column.id} align={column.align || 'left'} width={column.width}>
            {column.id === 'checkbox' ? (
              <Checkbox
                checked={selectedCheckboxes.includes(id || 0)} // If id is undefined, use 0
                onChange={handleChange} // If id is undefined, use 0
                disabled={selectedCheckboxes.length >= totalCount - artCount && !selectedCheckboxes.includes(id || 0)} // Disable if already five selected and current checkbox not selected
              />
            ) : column.id === 'image' && image?.imageUrl ? (
              <img
                src={image?.imageUrl}
                alt="post"
                style={{
                  width: '40px',
                  height: '40px',
                  objectFit: 'cover',
                  cursor: 'pointer',
                }}
                onClick={handleClickOpenModal}
              />
            ) : column.id === 'image' && video?.videoUrl ? (
              <video
                style={{
                  cursor: 'pointer',
                  width: '40px',
                  objectFit: 'cover',
                  height: '40px',
                }}
                src={video?.videoUrl}
                onClick={openVideoPopup}
              ></video>
            ) : column.id === 'actions' ? (
              <Stack direction="row" spacing={2}>
                <Tooltip title="Delete" arrow>
                  <IconButton onClick={() => onOPenDeleteDialog(id)}>
                    <Iconify icon={DeleteIcon} />
                  </IconButton>
                </Tooltip>
              </Stack>
            ) : (
              String(row[column.id as keyof typeof row])
            )}
          </TableCellStyle>
        ))}
      </TableRow>
      {isVideoPopupOpen && (
        <AdminVideoControl
          isVideoPopupOpen={isVideoPopupOpen}
          closeVideoPopup={closeVideoPopup}
          videourl={video?.videoUrl || ''}
          selectedVideo={getVideoType(video?.videoUrl || '')} // Pass the selected video type
        />
      )}
      {openImgModel && <FullScreenModal openImgModel={openImgModel} closeImgPopup={handleClickCloseImgModal} largeImgUrl={image?.imageUrl || ''} />}
    </>
  );
}
