export const advertisements = {
  confirm_delete_title: 'Confirmer la suppression',
  confirm_delete_message: 'Etes-vous sûr de vouloir supprimer ce plan publicitaire ?',
  title: 'Publicités',
  add: 'CRÉER UNE PUBLICITÉ',
  actions: 'Actions',
  video: 'Vidéo Ou Image',
  listTitle: 'Titre',
  advertisements_no_data: 'Aucune publicité trouvée',
  delete_success_message: `L'annonce a été supprimée avec succès`,
  create_success_message: 'Le plan de publicité a été créé avec succès',
  update_success_message: 'Le plan de publicité a été mis à jour avec succès',
  file_size_error: 'La vidéo dépasse la durée maximale autorisée de 10 secondes.',
  view_advertisement_title: `Afficher les détails de l'publicité`,
  update_advertisement_title: 'Modifier une publicité',
  add_advertisement_title: 'Créer une publicité',
  master_file_size_error: 'La vidéo doit durer plus de 10 secondes.',
  form_fields: {
    title: {
      label: 'Titre*',
      placeholder: 'Titre',
      max_length_error_message: 'Le titre ne peut pas comporter plus de 50 caractères',
      required_error_message: 'Le champ Titre est requis',
    },
    video: {
      label: 'Vidéo Ou Image*',
      placeholder: 'Vidéo Ou Image',
      required_error_message: 'Le champ Vidéo ou Image est requis',
    },
  },
};
