/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { DATA } from '../../../store/flags/models';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../store/rootStore';
import { changestatusFlag } from '../../../store/flags/action';
import { Button, Container, Grid, Typography } from '@mui/material';
import Spinner from '../../spinner';
import DeleteIcon from '../../svgComponents/deleteIcon';
import ValidateSigIcon from '../../svgComponents/validateSigIcon';
import ValidateTraitedIcon from '../../svgComponents/validateTraitedIcon';
import { BugsActionTypes } from '../../../store/bugs/models';
import { colors } from '../../../theme/sharedTheme';
import { useTranslation } from 'react-i18next';

interface FlagDialogHeaderProps {
  flag?: DATA;
  title: string;
  type?: string;
  loadingState?: boolean;
  statusTable?: string;
  onClose: () => void;
  deletePubUserOrCommentFlag: any;
}
/* ***** FLAG DIALOG HEADER ****** */
export const FlagDialogHeader = (props: FlagDialogHeaderProps) => {
  const { onClose, flag, type, deletePubUserOrCommentFlag, statusTable, loadingState } = props;
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, BugsActionTypes>>();
  const paginationState = useSelector((state: RootState) => state.pagination);
  const { pageNumber, perPage } = paginationState;
  const { t } = useTranslation();
  const changeStatusFlag = () => {
    if (flag && flag.status === 'reported') {
      flag.id && dispatch(changestatusFlag(flag.id, pageNumber + 1, perPage, 'treated', statusTable, type));
    }
    if (flag && flag.status === 'treated') {
      flag.id && dispatch(changestatusFlag(flag.id, pageNumber + 1, perPage, 'reported', statusTable, type));
    }
    onClose();
  };
  return (
    <>
      {loadingState ? (
        <Container sx={{ minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
          <Spinner loading={loadingState} />
        </Container>
      ) : (
        <>
          <Grid item xs={9}>
            <Typography variant="h6" sx={{ ml: 4, mt: 2 }}>
              {flag?.flag?.name}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ pl: 2.3 }} container display="row">
            <Button style={{ color: '#a4a4a5', width: '87px', height: '84px', borderRadius: 0 }} onClick={onClose}>
              {t('common.cancel')}
            </Button>
            {flag &&
            flag.status === 'treated' &&
            flag.flaggedPublication?.deletedAt !== null &&
            flag &&
            flag.status === 'treated' &&
            flag?.flaggedPublicationComment?.deletedAt !== null &&
            flag &&
            flag.status === 'treated' &&
            flag?.flaggedPublicationCommentResponse?.deletedAt !== null &&
            flag &&
            flag.status === 'treated' &&
            flag?.flaggedUser?.deletedAt !== null ? (
              <>
                <Button
                  style={{ backgroundColor: '#FF453A', opacity: '0.5', color: 'black', width: '84.1px', height: '84px', borderRadius: 0 }}
                  onClick={() => {
                    deletePubUserOrCommentFlag(flag);
                  }}
                  disabled={true}
                >
                  <DeleteIcon />
                </Button>
                <Button
                  style={{ backgroundColor: colors.yellow, color: 'black', opacity: '0.5', width: '84.1px', height: '84px', borderRadius: 0 }}
                  onClick={() => {
                    changeStatusFlag();
                  }}
                  disabled={true}
                >
                  <ValidateSigIcon />
                </Button>
              </>
            ) : flag &&
              flag.status === 'treated' &&
              flag.flaggedPublication?.deletedAt == null &&
              flag &&
              flag.status === 'treated' &&
              flag.flaggedPublicationComment?.deletedAt == null &&
              flag &&
              flag.status === 'treated' &&
              flag.flaggedPublicationCommentResponse?.deletedAt == null &&
              flag &&
              flag.status === 'treated' &&
              flag.flaggedUser?.deletedAt == null ? (
              <>
                <Button
                  style={{ backgroundColor: colors.Red, opacity: '0.5', color: 'black', width: '84.1px', height: '84px', borderRadius: 0 }}
                  onClick={() => {
                    deletePubUserOrCommentFlag(flag);
                  }}
                  disabled={true}
                >
                  <DeleteIcon />
                </Button>
                <Button
                  style={{ backgroundColor: colors.yellow, color: 'black', width: '84.1px', height: '84px', borderRadius: 0 }}
                  onClick={() => {
                    changeStatusFlag();
                  }}
                >
                  <ValidateSigIcon />
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{ backgroundColor: colors.Red, color: 'black', width: '84.1px', height: '84px', borderRadius: 0 }}
                  onClick={() => {
                    deletePubUserOrCommentFlag(flag);
                  }}
                >
                  <DeleteIcon />
                </Button>
                <Button
                  style={{ backgroundColor: colors.green, color: 'black', width: '84.1px', height: '84px', borderRadius: 0 }}
                  onClick={() => {
                    changeStatusFlag();
                  }}
                >
                  <ValidateTraitedIcon />
                </Button>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default FlagDialogHeader;
