import React, { useState, useEffect } from 'react';
import { Box, Container, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { RootStyle } from '../../../pages/systemusers/SystemUser.styles';
import { useTranslation } from 'react-i18next';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '@uidotdev/usehooks';
import { AxiosResponse } from 'axios';
import { RootState } from '../../../store/rootStore';
import useSnackBar from '../../../hooks/useSnackBar';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import TableHeadCustom from '../../table/table-head/CustomTableHead';
import TableNoData from '../../table/TableNoData';
import TableLoadingData from '../../table/TableLoadingData';
import { TablePaginationActions } from '../../table/TablePaginationActions/TablePaginationActions';
import { TABLE_COLUMNS_CONFIG, initialPaginationState } from './Interests.constant';
import useTable from '../../../hooks/useTable';
import ConfirmDeleteDialog from '../../../utils/confirm-delete-dialog/ConfirmDeleteDialog';
import AddDialog from './add-dialog/AddDialog';
import CommonToolbar from '../../../utils/toolbar/CommonToolbar';
import { Interests } from '../../../models/Interests';
import { IInterestsResponse, IInterestsState, InterestsActionTypes } from '../../../store/interests/slice/Interests-slice.types';
import { interestsApi } from '../../../store/interests/api/Interests.api';
import InterestsTableRow from './interests-row/InterestsTableRow';
import { interestCategoriesActionTypes } from '../../../store/interestCategories/models';
import { getInterestCategories } from '../../../store/interestCategories/actions';

function InterestsModule() {
  const { t } = useTranslation();
  const [filterKeyword, setfilterKeyword] = useState('');
  const [currentInterests, setInterestsUsers] = useState<Interests | undefined>(undefined);
  const [isEyeIconClicked, setIsEyeIconClicked] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, InterestsActionTypes>>();
  const dispatchCountries = useDispatch<ThunkDispatch<RootState, undefined, interestCategoriesActionTypes>>();
  const categoriesList = useSelector((state: RootState) => state.categoriesList.categoriesList);
  const [filterValue, setFilterValue] = useState<number>(0);
  const { data, isLoading } = useSelector<RootState, IInterestsState>((state: RootState) => state[interestsApi.reducerPath]);
  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialPaginationState);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { alertSuccess, alertError } = useSnackBar();

  useEffect(() => {
    dispatch(
      interestsApi.endpoints.getInterestsUsers({
        interestCategoryId: filterValue ? filterValue : '',
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  }, [order, orderBy, page, rowsPerPage, debouncedSearchTerm, dispatch, filterValue]);

  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
    setPage(0);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatchCountries(getInterestCategories());
    }
    return () => {
      mounted = false;
    };
  }, []);

  const onCloseDialog = () => {
    setAddDialogOpen(false);
    setInterestsUsers(undefined);
    setIsEyeIconClicked(false);
    setIsEdit(false);
    setfilterKeyword('');

    dispatch(
      interestsApi.endpoints.getInterestsUsers({
        interestCategoryId: filterValue ? filterValue : '',
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  };

  const onOPenEditDialog = (id?: number) => {
    setIsEdit(true);

    sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.INTEREST_TYPES}/${id}`, {}, true, {})
      .then((response: AxiosResponse) => {
        const responseBody = response.data.Categories;
        const { title, translations, interestCategoryId } = responseBody;
        const userData: Interests = {
          id: id,
          title: title,
          interestCategoryId: interestCategoryId,
          language: 0,
          translations: translations,
        };
        setInterestsUsers(userData);
      })
      .catch((err) => {
        console.log('error', err);
      });
    setAddDialogOpen(true);
  };

  const onCloseConfirmDeleteDialog = () => {
    setSelectedId(undefined);
    setConfirmDeleteOpen(false);
    setIsEyeIconClicked(false);
    setfilterKeyword('');
  };

  const onDeleteCategory = async () => {
    try {
      if (selectedId) {
        await dispatch(interestsApi.endpoints.deleteInterestUser(selectedId));
        onCloseConfirmDeleteDialog();
        dispatch(alertSuccess(t('interests.delete_interest_success_message')));
        if (data?.next === null && data?.total <= page * rowsPerPage) {
          setPage(page - 1);
        }
        dispatch(
          interestsApi.endpoints.getInterestsUsers({
            interestCategoryId: filterValue ? filterValue : '',
            page,
            limit: rowsPerPage,
            orderBy,
            order,
            keyword: debouncedSearchTerm,
          }),
        );
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
    }
  };

  const onOpenAddDialog = () => {
    setInterestsUsers(undefined);
    setAddDialogOpen(true);
    setIsEdit(false);
  };

  const onConfirmDelete = (id?: number) => {
    setSelectedId(id);
    setConfirmDeleteOpen(true);
  };

  const isFullFilled = !isLoading && data && data?.data?.length > 0;

  const filterdOptions = categoriesList?.map((category) => ({
    title: category.title || '', // Use title property
    id: category.id || 0, // Ensure id is a number, default to 0 if undefined
  }));

  const handleFilterOptions = (id: number) => {
    setFilterValue(id);
  };
  const handleClearFilter = () => {
    setFilterValue(0);
  };

  return (
    <>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        onClose={onCloseConfirmDeleteDialog}
        onDelete={onDeleteCategory}
        confirm_delete_title={t('interests.confirm_delete_title')}
        confirm_delete_message={t('interests.confirm_interest_delete_message')}
      />
      <AddDialog
        open={addDialogOpen}
        onClose={onCloseDialog}
        currentInterests={currentInterests}
        isUpdate={isEdit}
        isEyeIconClicked={isEyeIconClicked}
      />
      <RootStyle>
        <CommonToolbar
          filterKeyword={filterKeyword}
          onFilterKeyword={(value: string) => onFilterKeyword(value)}
          onOpenAddDialog={onOpenAddDialog}
          title={t('interests.interest_title')}
          message={t('interests.interest_add')}
          filterOptions={filterdOptions || []}
          handleFilterOptions={handleFilterOptions}
          isFilterOptions={true}
          filterValue={filterValue}
          handleClearFilter={handleClearFilter}
        />

        <Container sx={{ minHeight: '70vh' }}>
          <div className="table-block interest-panel">
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <TableHeadCustom order={order} orderBy={orderBy} columnsConfig={TABLE_COLUMNS_CONFIG} onSort={onSort} />
                <TableBody>
                  {isFullFilled &&
                    (data as IInterestsResponse)?.data?.map((row: Interests) => {
                      return (
                        <InterestsTableRow
                          key={row.id}
                          row={row}
                          selected={false}
                          columnsConfig={TABLE_COLUMNS_CONFIG}
                          onOPenEditDialog={onOPenEditDialog}
                          onConfirmDelete={onConfirmDelete}
                        />
                      );
                    })}
                  <TableNoData isNotFound={!isLoading && data?.data?.length === 0} title={t('interests.interest_no_data')} />
                  <TableLoadingData isLoading={isLoading} />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {isFullFilled && (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={data ? data.total : 0}
                rowsPerPage={rowsPerPage}
                page={data && page ? page - 1 : 0}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t('common.rowsperpage')}
                sx={{
                  '& .MuiInputBase-root': {
                    maxWidth: '60px',
                  },
                }}
              />
            </Box>
          )}
        </Container>
      </RootStyle>
    </>
  );
}
export default InterestsModule;
