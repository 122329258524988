/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/react-in-jsx-scope */

import { Box, Container, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useDebounce } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import TableLoadingData from '../../components/table/TableLoadingData';
import TableNoData from '../../components/table/TableNoData';
import TableHeadCustom from '../../components/table/table-head/CustomTableHead';
import AddDialog from '../../features/university/addDialog/AddDialog';
import ConfirmDeleteDialog from '../../features/university/confirm-delete-dialog/ConfirmDeleteDialog';
import DetailsDialog from '../../features/university/detailsDialog/DetailsDialog';
import useDeleteUniversityMutation from '../../features/university/hooks/useDeleteUniversityMutation';
import UniversityListToolbar from '../../features/university/toolbar/UniversityListToolbar';
import UniversityTableRow from '../../features/university/university-row/UniversityTableRow';
import useSnackBar from '../../hooks/useSnackBar';
import useTable from '../../hooks/useTable';
import { University } from '../../models/University';
import { RootState } from '../../store/rootStore';
import { universityApi } from '../../store/university/api/university.api';
import { IUniversityResponse, IUniversityState, UniversityActionTypes } from '../../store/university/slice/university-slice.types';
import { TABLE_COLUMNS_CONFIG, initialPaginationState } from './UniversityList.constants';
import { RootStyle } from './UniversityList.styles';

export default function UniversityList() {
  // Hooks
  const [filterKeyword, setfilterKeyword] = useState('');
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, UniversityActionTypes>>();
  const { data, isLoading, error } = useSelector<RootState, IUniversityState>((state: RootState) => state[universityApi.reducerPath]);
  const [currentUniversity, setCurrentUniversity] = useState<University | undefined>(undefined);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  // Custom hooks
  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialPaginationState);
  useEffect(() => {
    dispatch(
      universityApi.endpoints.getUniversities({
        page,
        itemsPerPage: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  }, [order, orderBy, page, rowsPerPage, debouncedSearchTerm, dispatch]);

  const { alertSuccess, alertError } = useSnackBar();

  const { deleteUniversity } = useDeleteUniversityMutation();

  // Functions
  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
    setPage(0);
  };

  const onShowDetails = (id?: number) => {
    const selectedUniversity = data?.data.find((university: University) => university.id === id);
    setCurrentUniversity(selectedUniversity);
    setDetailsDialogOpen(true);
  };

  const onOpenAddDialog = () => {
    setCurrentUniversity(undefined);
    setAddDialogOpen(true);
  };

  const onCloseDialog = () => {
    setAddDialogOpen(false);
    setCurrentUniversity(undefined);
    dispatch(
      universityApi.endpoints.getUniversities({
        page,
        itemsPerPage: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  };

  const onCloseDetailsDialog = () => {
    setDetailsDialogOpen(false);
  };

  const onOPenEditDialog = (id?: number) => {
    const selectedUniversity = data?.data.find((university: University) => university.id === id);
    setCurrentUniversity(selectedUniversity);
    setAddDialogOpen(true);
  };

  const onConfirmDelete = (id?: number) => {
    setSelectedId(id);
    setConfirmDeleteOpen(true);
  };

  const onCloseConfirmDeleteDialog = () => {
    setSelectedId(undefined);
    setConfirmDeleteOpen(false);
  };

  const onDeleteUniversity = async () => {
    try {
      if (selectedId) {
        await deleteUniversity(selectedId);
        onCloseConfirmDeleteDialog();
        dispatch(alertSuccess(t('university.delete_success_message')));
        if (data?.nextPage === null && data?.total <= page * rowsPerPage) {
          setPage(page - 1);
        }
        dispatch(
          universityApi.endpoints.getUniversities({
            page,
            itemsPerPage: rowsPerPage,
            orderBy,
            order,
            keyword: debouncedSearchTerm,
          }),
        );
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
    }
  };

  const isFullFilled = !isLoading && !error && data && data?.data?.length > 0;

  // Render
  return (
    <>
      <ConfirmDeleteDialog open={confirmDeleteOpen} onClose={onCloseConfirmDeleteDialog} onDelete={onDeleteUniversity} />
      <DetailsDialog open={detailsDialogOpen} onClose={onCloseDetailsDialog} currentUniversity={currentUniversity} />
      <AddDialog open={addDialogOpen} onClose={onCloseDialog} currentUniversity={currentUniversity} isUpdate={!!currentUniversity} />
      <RootStyle>
        <UniversityListToolbar
          filterKeyword={filterKeyword}
          onFilterKeyword={(value: string) => onFilterKeyword(value)}
          onOpenAddDialog={onOpenAddDialog}
        />
        <Container sx={{ minHeight: '70vh' }}>
          <div className="table-block">
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <TableHeadCustom order={order} orderBy={orderBy} columnsConfig={TABLE_COLUMNS_CONFIG} onSort={onSort} />
                <TableBody>
                  {isFullFilled &&
                    (data as IUniversityResponse)?.data.map((row: University) => {
                      return (
                        <UniversityTableRow
                          key={row.id}
                          row={row}
                          selected={false}
                          onShowDetails={onShowDetails}
                          columnsConfig={TABLE_COLUMNS_CONFIG}
                          onOPenEditDialog={onOPenEditDialog}
                          onConfirmDelete={onConfirmDelete}
                        />
                      );
                    })}
                  <TableNoData isNotFound={!isLoading && data?.data?.length === 0} title={t('university.no_data')} />
                  <TableLoadingData isLoading={isLoading} />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {isFullFilled && (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={data ? data.total : 0}
                rowsPerPage={rowsPerPage}
                page={data && page ? page - 1 : 0}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
            </Box>
          )}
        </Container>
      </RootStyle>
    </>
  );
}
