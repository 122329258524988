import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Container, Table, TableBody, TableContainer, TablePagination, Stack, Button, Grid, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { useDebounce } from '@uidotdev/usehooks';
import SearchIcon from '../../svgComponents/searchIcon';
import { TABLE_COLUMNS_CONFIG, initialPaginationState } from './RevenueReport.constant';
import { RootState } from '../../../store/rootStore';
import useTable from '../../../hooks/useTable';
import {
  IRevenueReportsResponse,
  IRevenueReportsState,
  RevenueReportsActionTypes,
} from '../../../store/revenueReports/slice/RevenueReports-slice.types';
import { revenuReportsApi } from '../../../store/revenueReports/api/RevenueReports.api';
import TableLoadingData from '../../table/TableLoadingData';
import TableNoData from '../../table/TableNoData';
import { TablePaginationActions } from '../../table/TablePaginationActions/TablePaginationActions';
import { RevenueReport } from '../../../models/RevenueReport';
import RevenueReportTableRow from './revenueReport-row/RevenueReportTableRow';
import TableHeadCustom from '../../table/table-head/CustomTableHead';
import DownloadIcon from '@mui/icons-material/Download';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { AxiosResponse } from 'axios';
import { ContainerStyle, CustomTitle, GridStyle, InputStyle } from '../../../utils/toolbar/CommonToolbar.styles';
import DateFilter from '../../../utils/DateFilter';

function RevenueReports() {
  const { t } = useTranslation();
  const currentDate = new Date();

  const [filterKeyword, setfilterKeyword] = useState('');
  const [exportUsers, setExportUsers] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialPaginationState);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, RevenueReportsActionTypes>>();
  const { users, isLoading } = useSelector<RootState, IRevenueReportsState>((state: RootState) => state[revenuReportsApi.reducerPath]);

  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    // Format startDate and endDate to include only the date part
    const formattedStartDate = startDate ? formatDate(startDate) : '';
    const formattedEndDate = endDate ? formatDate(endDate) : '';

    dispatch(
      revenuReportsApi.endpoints.getRevenueReportsUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }),
    );
  }, [order, orderBy, page, rowsPerPage, debouncedSearchTerm, startDate, endDate, dispatch]);

  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
    setPage(0);
  };

  useEffect(() => {
    if (exportUsers) {
      window.open(exportUsers, '_blank');
    }
  }, [exportUsers]);

  const handleExportUsers = () => {
    const formattedStartDate = startDate ? formatDate(startDate) : '';
    const formattedEndDate = endDate ? formatDate(endDate) : '';
    const params = {
      limit: rowsPerPage,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
    sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.GET_REVENUE_REPORTS_CSV}`, {}, true, { ...params })
      .then((response: AxiosResponse) => {
        const responseBody = response.data.fileUrl;
        setExportUsers(responseBody);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDate = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  const isFullFilled = !isLoading && users && users?.users?.length > 0;

  return (
    <>
      <ContainerStyle style={{ padding: '0 24px' }}>
        <GridStyle>
          <CustomTitle>{t('common.revenue_reports')}</CustomTitle>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={handleExportUsers}
              style={{
                cursor: 'pointer',
                backgroundColor: '#1976d2',
                color: '#fff',
                marginRight: '10px',
              }}
              variant="contained"
              className={users && users?.users?.length <= 0 ? 'disabled-btn' : ''}
              disabled={users && users?.users?.length <= 0 ? true : false} // Ensure disabled is either true or undefined
            >
              <DownloadIcon />
              {t('common.export')}
            </Button>

            <Stack direction="column" spacing={4}>
              <InputStyle
                value={filterKeyword}
                placeholder={t('common.search')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterKeyword(e.target.value)}
                InputProps={{
                  startAdornment: <SearchIcon />,
                }}
              />
            </Stack>
          </div>
        </GridStyle>
      </ContainerStyle>

      <DateFilter startDate={startDate} endDate={endDate} handleClear={handleClear} handleStartDate={handleStartDate} handleEndDate={handleEndDate} />

      <Container sx={{ minHeight: '70vh' }}>
        <div className="table-block">
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size={'medium'}>
              <TableHeadCustom order={order} orderBy={orderBy} columnsConfig={TABLE_COLUMNS_CONFIG} onSort={onSort} />
              <TableBody>
                {isFullFilled &&
                  (users as IRevenueReportsResponse)?.users?.map((row: RevenueReport) => {
                    return <RevenueReportTableRow key={row.id} row={row} selected={false} columnsConfig={TABLE_COLUMNS_CONFIG} />;
                  })}
                <TableNoData isNotFound={!isLoading && users?.users?.length === 0} title={t('adds.revenue_no_data')} />

                <TableLoadingData isLoading={isLoading} />
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {isFullFilled && (
          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={users ? users.count : 0}
              rowsPerPage={rowsPerPage}
              page={users && page ? page - 1 : 0}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage={t('common.rowsperpage')}
              sx={{
                '& .MuiInputBase-root': {
                  maxWidth: '60px',
                },
              }}
            />
          </Box>
        )}
      </Container>
    </>
  );
}
export default RevenueReports;
