import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { ENDPOINTS, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { RootState } from '../rootStore';
import { SnackBarActionTypes } from '../snackBar/models';
import { GET_MASTER_CLASS_ADS_ATTEMPT, GET_MASTER_CLASS_ADS_ERROR, GET_MASTER_CLASS_ADS_SUCCESS } from './types';
import { masterClassAddsActionTypes } from './models';

export const getMasterClassAdds = () => async (dispatch: ThunkDispatch<RootState, undefined, masterClassAddsActionTypes | SnackBarActionTypes>) => {
  dispatch({
    type: GET_MASTER_CLASS_ADS_ATTEMPT,
  });

  sendAsyncRequest(HttpMethod.Get, ENDPOINTS.MASTERCLASSLISTING, {}, true, null)
    .then((res: AxiosResponse) => {
      dispatch({
        type: GET_MASTER_CLASS_ADS_SUCCESS,
        masterClassAdds: res.data.masterClassesAds,
      });
    })
    .catch((error) =>
      dispatch({
        type: GET_MASTER_CLASS_ADS_ERROR,
        error: error,
      }),
    );
};
