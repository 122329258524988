import React from 'react';

export const SearchIcon = () => {
  return (
    <svg width="32" height="34" viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.762 22.464c3.052 1.926 6.94.809 8.685-2.496s.686-7.545-2.365-9.47c-3.051-1.927-6.94-.81-8.685 2.495s-.686 7.545 2.365 9.471z"
        stroke="#A4A4A5"
        strokeLinejoin="round"
      />
      <path d="m11.793 22-3.94 6.054" stroke="#A4A4A5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SearchIcon;
