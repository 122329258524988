import { createTheme } from '@mui/material';

export const searchBarTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: '343px',
        },
        input: {
          height: '48px',
          boxSizing: 'border-box',
          border: 'solid 1px #e7e7e7',
          backgroundColor: '#ffffff',
          paddingLeft: '13px',
          color: '#0d0d0d',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '343px',
          '& .MuiInputBase-root': {
            width: '343px',

            backgroundColor: '#ffffff',
            input: { border: 'none', paddingLeft: '42px' },
            '& .MuiInputAdornment-root': { marginLeft: 0 },
          },
        },
      },
    },
  },
});
