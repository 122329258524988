/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Grid,
  Stack,
  FormControlLabel,
  Checkbox,
  Typography,
  FormGroup,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import CustomLoadingButton from '../../../components/button/CustomLoadingButton';
import FormProvider from '../../../components/hook-form/FormProvider';
import RHFTextField from '../../../components/hook-form/RHFTextField';
import useSnackBar from '../../../hooks/useSnackBar';
import { SystemUser } from '../../../models/SystemUser';
import { RootState } from '../../../store/rootStore';
import { systemUserApi } from '../../../store/systemuser/api/systemuser.api';
import { SystemUserActionTypes } from '../../../store/systemuser/slice/systemuser-slice.types';
import { SystemUserFormProps } from './systemuser-form.types';
import { countriesActionTypes } from '../../../store/countries/models';
import { getCountries } from '../../../store/countries/actions';
import { CreateSystemUserSchema } from './create-systemuser.schema';

const { createSystemUser, updateSystemUser } = systemUserApi.endpoints;

export default function SubAdminForm({ currentSystemUser, isUpdate, onClose, isEyeIconClicked }: SystemUserFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, SystemUserActionTypes>>();
  const dispatchCountries = useDispatch<ThunkDispatch<RootState, undefined, countriesActionTypes>>();
  const countries = useSelector((state: RootState) => state.countries.countries);
  const modulePermissions = [
    { moduleId: 1, moduleName: 'Articles', permission: 0 }, // Assuming 0 for denied permission
    { moduleId: 2, moduleName: 'Annonces', permission: 0 },
    { moduleId: 3, moduleName: 'Bugs', permission: 0 },
    { moduleId: 4, moduleName: 'Campagnes', permission: 0 },
    { moduleId: 5, moduleName: 'Interests', permission: 0 },
    { moduleId: 6, moduleName: 'Profiles', permission: 0 },
    { moduleId: 7, moduleName: 'Opportunities', permission: 0 },
    { moduleId: 8, moduleName: 'Settings', permission: 0 },
    { moduleId: 9, moduleName: 'Signalments', permission: 0 },
    { moduleId: 10, moduleName: 'Users', permission: 0 },
    { moduleId: 11, moduleName: 'MasterClass', permission: 0 },
    // { moduleId: 12, moduleName: 'WelcomeVideo', permission: 0 },
  ];

  const [UserPermissions, setAccessPermissions] = useState<SystemUser['UserPermissions']>(currentSystemUser?.UserPermissions || modulePermissions);

  useEffect(() => {
    if (isUpdate && currentSystemUser) {
      const permissionValues = modulePermissions?.map((permission) => {
        const existingPermission = currentSystemUser.UserPermissions.find((userPermission) => userPermission.moduleId === permission.moduleId);
        return {
          ...permission,
          permission: existingPermission ? existingPermission.permission : 0,
        };
      });
      setAccessPermissions([...permissionValues]);
      setValue('UserPermissions', permissionValues);
      trigger('UserPermissions');
    }
  }, [currentSystemUser, isUpdate]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatchCountries(getCountries());
    }
    return () => {
      mounted = false;
    };
  }, []);

  const handleAccessPermissionsChange = (moduleId: number, moduleName: string, isChecked: boolean) => {
    const permissionValues = UserPermissions.map((permission) => {
      if (permission.moduleId === moduleId) {
        return {
          ...permission,
          permission: isChecked ? 1 : 0,
        };
      }
      return permission;
    });

    setAccessPermissions([...permissionValues]);
    setValue('UserPermissions', permissionValues);
    trigger('UserPermissions');
    // setAccessPermissions((prevPermissions) => {
    //   return prevPermissions.map((permission) => {
    //     if (permission.moduleId === moduleId) {
    //       return {
    //         ...permission,
    //         permission: isChecked ? 1 : 0,
    //       };
    //     }
    //     return permission;
    //   });
    // });
    // clearErrors('UserPermissions');
  };

  const defaultValues = useMemo<SystemUser>(
    () => ({
      name: currentSystemUser?.name || '',
      surname: currentSystemUser?.surname || '',
      phoneNb: currentSystemUser?.phoneNb || '',
      mail: currentSystemUser?.mail || '',
      nickname: currentSystemUser?.nickname || '',
      address: currentSystemUser?.address || '',
      city: currentSystemUser?.city || '',
      zip: currentSystemUser?.zip || '',
      countryId: currentSystemUser?.countryId || 0,
      UserPermissions: UserPermissions,
    }),
    [currentSystemUser, UserPermissions],
  );

  const methods = useForm<SystemUser>({
    resolver: yupResolver(CreateSystemUserSchema),
    defaultValues,
    mode: 'all',
  });

  const { alertSuccess, alertError } = useSnackBar();

  const {
    reset,
    handleSubmit,
    trigger,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  useEffect(() => {
    // if (isUpdate && currentSystemUser) {
    //   reset(defaultValues);
    // }
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentSystemUser]);

  useEffect(() => {
    // Update defaultValues whenever currentSystemUser or isUpdate changes
    const updatedDefaultValues = {
      name: currentSystemUser?.name || '',
      surname: currentSystemUser?.surname || '',
      phoneNb: currentSystemUser?.phoneNb || '',
      mail: currentSystemUser?.mail || '',
      nickname: currentSystemUser?.nickname || '',
      address: currentSystemUser?.address || '',
      city: currentSystemUser?.city || '',
      zip: currentSystemUser?.zip || '',
      countryId: currentSystemUser?.countryId || 0,
      UserPermissions: currentSystemUser?.UserPermissions || modulePermissions, // Update UserPermissions based on currentSystemUser
    };
    methods.reset(updatedDefaultValues);
  }, [currentSystemUser, isUpdate]);

  const onSubmit = async () => {
    if (!isUpdate) {
      create();
    } else {
      update();
    }
  };

  const create = async () => {
    try {
      trigger().then(async (isValid) => {
        if (isValid) {
          const formData = { ...values, UserPermissions };
          dispatch(createSystemUser(formData))
            .then(() => {
              reset(defaultValues);
              dispatch(alertSuccess(t('systemuser.create_success_message')));
              onClose(); // Close popup only when request succeeds
            })
            .catch((err) => {
              if (err.message === 'Nickname already used by an existing account') {
                dispatch(alertError(t('systemuser.nickname_already_used')));
              } else if (err.message === 'Mail already used by an existing account') {
                dispatch(alertError(t('systemuser.email_already_used')));
              } else {
                dispatch(alertError(err.message)); // Show error message in alert
              }
            });
        }
      });
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    }
  };

  const update = async () => {
    try {
      trigger().then(async (isValid) => {
        if (isValid && currentSystemUser?.id) {
          const formData = { ...values, UserPermissions: UserPermissions }; // Use UserPermissions from state
          dispatch(updateSystemUser(currentSystemUser?.id, formData))
            .then(() => {
              reset(defaultValues);
              dispatch(alertSuccess(t('systemuser.update_success_message')));
              onClose(); // Close popup only when request succeeds
            })
            .catch((errs) => {
              if (errs.message === 'Nickname already used by an existing account') {
                dispatch(alertError(t('systemuser.nickname_already_used')));
              } else if (errs.message === 'Mail already used by an existing account') {
                dispatch(alertError(t('systemuser.email_already_used')));
              } else {
                dispatch(alertError(errs.message)); // Show error message in alert
              }
            });
        }
      });
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    }
  };

  return (
    <>
      {!currentSystemUser && (isUpdate || isEyeIconClicked) ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '70px' }}>
          <CircularProgress
            sx={{
              color: '#ffcc33',
            }}
          />
        </div>
      ) : (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid className="artgapi-plan-form" item xs={12} md={12}>
              <Box sx={{ p: 3 }}>
                <Box
                  sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <RHFTextField
                    name={'name'}
                    placeholder={t('systemuser.form_fields.name.placeholder')}
                    label={t('systemuser.form_fields.name.label')}
                    disabled={isEyeIconClicked}
                  />
                  <RHFTextField
                    name={'surname'}
                    placeholder={t('systemuser.form_fields.surname.placeholder')}
                    label={t('systemuser.form_fields.surname.label')}
                    disabled={isEyeIconClicked}
                  />
                  <RHFTextField
                    name={'mail'}
                    placeholder={t('systemuser.form_fields.mail.placeholder')}
                    label={t('systemuser.form_fields.mail.label')}
                    disabled={isEyeIconClicked}
                  />
                  <RHFTextField
                    name={'phoneNb'}
                    placeholder={t('systemuser.form_fields.phone_number.placeholder')}
                    label={t('systemuser.form_fields.phone_number.label')}
                    disabled={isEyeIconClicked}
                  />
                  <RHFTextField
                    name={'nickname'}
                    placeholder={t('systemuser.form_fields.nickname.placeholder')}
                    label={t('systemuser.form_fields.nickname.label')}
                    disabled={isEyeIconClicked}
                  />
                  <RHFTextField
                    name={'address'}
                    placeholder={t('systemuser.form_fields.address.placeholder')}
                    label={t('systemuser.form_fields.address.label')}
                    disabled={isEyeIconClicked}
                  />
                  <RHFTextField
                    name={'city'}
                    placeholder={t('systemuser.form_fields.city.placeholder')}
                    label={t('systemuser.form_fields.city.label')}
                    disabled={isEyeIconClicked}
                  />
                  <RHFTextField
                    name={'zip'}
                    placeholder={t('systemuser.form_fields.zip.placeholder')}
                    label={t('systemuser.form_fields.zip.label')}
                    disabled={isEyeIconClicked}
                  />
                  <FormControl
                    fullWidth
                    // sx={{
                    //   mt: 1,
                    //   '& .css-to7sdl-MuiFormControl-root': {
                    //     marginTop: '0 !important',
                    //   },
                    // }}
                  >
                    <InputLabel
                      id="countryId-label"
                      sx={{
                        transform: 'translate(0px, 0px) scale(0.95)',
                        position: 'relative !important',
                        maxWidth: '0',
                        minWidth: '100%',
                        color: 'rgb(171, 171, 171)',
                        marginBottom: '0.5rem',

                        '& .css-to7sdl-MuiFormControl-root': {
                          marginTop: '4px !important',
                        },
                      }}
                    >
                      {t('systemuser.form_fields.countryId.label')}
                    </InputLabel>
                    <Controller
                      name="countryId"
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <Select
                            {...field}
                            sx={{
                              width: '100%',
                              height: '48px',
                              borderRadius: '0',
                              '& .MuiSelect-select': {
                                width: '100%',
                                height: '48px !important',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '12.5px 14px',
                              },
                              '& .MuiOutlinedInput-root': {
                                marginTop: '-8px !important',
                              },
                              '& .MuiOutlinedInput-input': {
                                border: isEyeIconClicked ? '1px solid #e7e7e7' : '1px solid #0d0d0d',
                                borderColor: error && '#d32f2f',
                                borderRadius: '0',
                              },
                              '& .MuiSelect-select:hover': {
                                borderColor: '#141415 !important',
                              },
                            }}
                            labelId="countryId-label"
                            id="countryId"
                            value={field.value || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                            disabled={isEyeIconClicked}
                          >
                            {/* Map over the list of countries */}

                            <MenuItem key="select" selected value="0" disabled>
                              {t('systemuser.form_fields.countryId.placeholder')}
                            </MenuItem>
                            {countries?.map((country) => (
                              <MenuItem key={country.id} value={country.id}>
                                {`${country.name} (${country.code})`}
                              </MenuItem>
                            ))}
                          </Select>
                          {/* Display error message if there's an error for countryId */}
                          {error && (
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: '0.75rem',
                                lineHeight: '1.66',
                                letterSpacing: '0.03333em',
                                textAlign: 'left',
                                marginTop: '3px',
                                marginRight: '14px',
                                marginBottom: '0',
                                marginLeft: '14px',
                                color: '#d32f2f',
                              }}
                            >
                              {t(`${error?.message}`)}
                            </Typography>
                          )}
                        </>
                      )}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Typography variant="subtitle1">{t('systemuser.form_fields.UserPermissions.label')}</Typography>
                  <FormGroup>
                    {UserPermissions?.map(({ moduleId, moduleName, permission }) => (
                      <FormControlLabel
                        key={moduleId}
                        control={
                          <Controller
                            name={`UserPermissions`}
                            control={methods.control}
                            render={({ field }) => (
                              <Checkbox
                                {...field}
                                checked={permission === 1}
                                onChange={(event) => handleAccessPermissionsChange(moduleId, moduleName, event.target.checked)}
                                name={moduleName}
                                disabled={isEyeIconClicked}
                              />
                            )}
                          />
                        }
                        label={moduleName}
                      />
                    ))}
                    {errors.UserPermissions && (
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: '0.75rem',
                          lineHeight: '1.66',
                          letterSpacing: '0.03333em',
                          textAlign: 'left',
                          marginTop: '3px',
                          marginRight: '14px',
                          marginBottom: '0',
                          marginLeft: '14px',
                          color: '#d32f2f',
                        }}
                      >
                        {errors.UserPermissions.message}
                      </Typography>
                    )}
                  </FormGroup>
                </Box>
                {isEyeIconClicked ? (
                  ''
                ) : (
                  <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                    <CustomLoadingButton
                      loading={isSubmitting}
                      label={isUpdate ? t('systemuser.update_button_label') : t('systemuser.create_button_label')}
                      onClick={onSubmit}
                    />
                  </Stack>
                )}
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
      )}
    </>
  );
}
