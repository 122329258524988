import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Bugs from '../../components/bugs/bugs';
import GlobalContext from '../../context/globalContext';
import { getBugs } from '../../store/bugs/action';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootStore';
import { BugsActionTypes } from '../../store/bugs/models';
import { ThunkDispatch } from 'redux-thunk';
import { PaginationActionTypes } from '../../store/pagination/models';

/**
 * Component BugsPage
 *
 * @component
 *
 * @example
 * return (
 *   <BugsPage />
 * )
 */

const BugsPage = () => {
  const gContext = useContext(GlobalContext);
  const paginationState = useSelector((state: RootState) => state.pagination);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, BugsActionTypes | PaginationActionTypes>>();
  const { pageNumber, perPage } = paginationState;

  useEffect(() => {
    if (gContext?.bugPageClicked) {
      dispatch(getBugs(pageNumber + 1, perPage, ''));
    }
    gContext?.handleChangeClick();
  }, [gContext?.bugPageClicked]);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Bugs />
    </Grid>
  );
};

export default BugsPage;
