import * as yup from 'yup';

export const ModulePermissionSchema = yup.object().shape({
  moduleId: yup.number().required('Module ID is required'),
  moduleName: yup.string().required('Module name is required'),
  permission: yup.number().required('Permission level is required'),
});

export const CreateSystemUserSchema = yup.object().shape({
  name: yup.string().required('systemuser.form_fields.name.required_error_message'),
  surname: yup.string().required('systemuser.form_fields.surname.required_error_message'),
  phoneNb: yup
    .string()
    .required('systemuser.form_fields.phone_number.required_error_message')
    .min(7, 'systemuser.form_fields.phone_number.invalid_error_message')
    .max(16, 'systemuser.form_fields.phone_number.invalid_error_message'),
  mail: yup.string().email('systemuser.form_fields.mail.invalid_error_message').required('systemuser.form_fields.mail.required_error_message'),
  nickname: yup.string().required('systemuser.form_fields.nickname.required_error_message'),
  address: yup.string().required('systemuser.form_fields.address.required_error_message'),
  city: yup.string().required('systemuser.form_fields.city.required_error_message'),
  zip: yup.string().required('systemuser.form_fields.zip.required_error_message'),
  // countryId: yup.number().required('systemuser.form_fields.countryId.required_error_message'),
  countryId: yup
    .number()
    .min(1, ({ min }) => 'systemuser.form_fields.countryId.required_error_message')
    .required('systemuser.form_fields.countryId.required_error_message'),
  UserPermissions: yup
    .array()
    .of(ModulePermissionSchema)
    .required('User permissions are required')
    .test('at-least-one-permission', `Au moins une sélection d'autorisation est requise`, function (value) {
      return value.some((permission) => permission.permission === 1);
    }),
});
