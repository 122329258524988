import { config } from '../../config/config';

export const validations = {
  required: 'Please fill out this field',
  email: 'Please enter a valid email address',
  min_length:
    'This field must contain between 7 and 32 characters, at least 1 lowercase letter, 1 uppercase letter, 1 special character and 1 number',
  match: 'Password does not match',
  actual_password: 'Current password is incorrect',
  authInvalid: 'Invalid email or password',
  max_character_limit: 'the number of characters entered must not exceed 3000 characters.',
  size_image_max: `Maximum photo size exceeded (${config.FILE_MAX_SIZE} Mb)`,
  required_title: 'Title is required',
  required_image: 'Image is required',
  required_author_image: "The author's image is required",
  required_description: 'Description is required',
  required_startDate: 'Start date is required',
  required_endDate: 'End date is required',
  required_podcast_url: 'Podcast url is required',
  required_article_url: 'Article URL is required',
  date: 'The start date must be less than or equal to the end date.',
  invalid_url: 'The URL is invalid',
  required_author_name: 'Author name is required',
  invalid_author_name: 'Author name is invalid',
};
