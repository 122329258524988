import { InitialPaginationParams } from '../../api/common/common.types';
import { Column } from '../table/table-head/CustomTableHead.types';

export const initialPaginationState: InitialPaginationParams = {
  defaultCurrentPage: 1,
  defaultOrderBy: 'id',
  defaultOrder: 'desc',
  defaultRowsPerPage: 25,
};

export const TABLE_COLUMNS_CONFIG: Column[] = [
  {
    id: 'title',
    label: 'advertisements.listTitle',
    width: '20%',
  },

  {
    id: 'videoOrImage',
    label: 'advertisements.video',
    width: '20%',
    isNotSortable: true,
  },

  {
    id: 'actions',
    label: 'advertisements.actions',
    width: '10%',
    isNotSortable: true,
  },
];
