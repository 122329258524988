/* eslint-disable react/react-in-jsx-scope */
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddDialogProps } from './add-dialog.types';
import { AddDialogStyle } from './add-dialog.styles';
import CloseIcon from '../../../../svgComponents/closeIcon';
import HowItWorksForm from '../form/HowItWorksForm';
import ViewHowItWorks from '../form/ViewHowItWorks';

export default function AddDialog({ open, onClose, currentWorkData, isUpdate, isEyeIconClicked }: AddDialogProps) {
  const { t } = useTranslation();
  return (
    <AddDialogStyle open={open} fullWidth sx={{ '& .MuiDialog-paper': { height: 'auto !important' } }} className="artgapi-wrapper">
      <Stack display={'flex'} direction={'row'} justifyContent={'space-between'} padding={2}>
        <Typography variant={'h5'}>
          {isEyeIconClicked ? t('adds.how_works_title') : isUpdate ? t('adds.update_works_title') : t('adds.add_pending_ads_title')}
        </Typography>

        <IconButton aria-label="close" color="primary" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      {isEyeIconClicked ? (
        <ViewHowItWorks onClose={onClose} currentWorkData={currentWorkData} isUpdate={isUpdate} isEyeIconClicked={isEyeIconClicked} />
      ) : (
        <HowItWorksForm onClose={onClose} currentWorkData={currentWorkData} isUpdate={isUpdate} isEyeIconClicked={isEyeIconClicked} />
      )}
    </AddDialogStyle>
  );
}
