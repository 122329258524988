import React, { useState } from 'react';
import { TableRow, Checkbox, Button, IconButton, Stack, Tooltip } from '@mui/material';
import { TableCellStyle } from './ArtWorkUsersRow.styles';
import { ArtWorkUsersRowProps } from './ArtWorkUsersRow.types';
import Iconify from '../../Iconify';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/ic_trash.svg';

export default function ArtWorkusersTableRow({
  row,
  selected,
  columnsConfig,
  onOPenDeleteDialog,
  handleCheckboxChange,
  selectedCheckboxes,
  artistCount,
}: ArtWorkUsersRowProps) {
  const { id } = row;
  const handleChange = () => {
    handleCheckboxChange(id);
  };
  const totalCount = 5;
  return (
    <TableRow key={id} hover selected={selected} sx={{ backgroundColor: '#ffffff' }}>
      {columnsConfig.map((column) => (
        <TableCellStyle key={column.id} align={column.align || 'left'} width={column.width}>
          {column.id === 'checkbox' ? (
            <Checkbox
              checked={selectedCheckboxes.includes(id || 0)}
              onChange={handleChange}
              disabled={selectedCheckboxes.length >= totalCount - artistCount && !selectedCheckboxes.includes(id || 0)} // Disable if already five selected and current checkbox not selected
            />
          ) : column.id === 'actions' ? (
            <Stack direction="row" spacing={2}>
              <Tooltip title="Delete" arrow>
                <IconButton onClick={() => onOPenDeleteDialog(id)}>
                  <Iconify icon={DeleteIcon} />
                </IconButton>
              </Tooltip>
            </Stack>
          ) : (
            String(row[column.id as keyof typeof row])
          )}
        </TableCellStyle>
      ))}
    </TableRow>
  );
}
