/* eslint-disable @typescript-eslint/no-explicit-any */
import { Categories } from '../../../models/Categories';
import {
  ActionTypes,
  IFetchCategoriesRejectedAction,
  IFetchCategoriesSuccessAction,
  ICategoriesPendingAction,
  ICategoriesResponse,
  ICategoriesState,
  CategoriesActionTypes,
} from './Categories-slice.types';

export const initialState: ICategoriesState = {
  isLoading: false,
  error: null,
  data: null,
  categoryUsers: null,
};

export const categoriesReducer = (state = initialState, action: CategoriesActionTypes): ICategoriesState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload as ICategoriesResponse,
      };
    case ActionTypes.FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categoryUsers: action.payload as Categories,
      };
    default:
      return state;
  }
};

const matchPending = (): ICategoriesPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (data: ICategoriesResponse, action: 'fetch' | 'update' | 'create' | 'delete'): IFetchCategoriesSuccessAction | Categories => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_CATEGORIES_SUCCESS : ActionTypes.CREATE_CATEGORIES_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchCategoriesRejectedAction => {
  return {
    type: ActionTypes.FETCH_CATEGORIES_FAILURE,
    payload: error,
  };
};

export const categoriesActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
