/* eslint-disable react/react-in-jsx-scope */
import { Container, Grid } from '@mui/material';
import { useDebounce } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGetListUsersQuery from '../../features/profile/hooks/useGetListUsersQuery';
import useGetUserProfilesQuery from '../../features/profile/hooks/useGetUserProfilesQuery';
import ProfileListToolbar from '../../features/profile/toolbar/ProfileListToolbar';
import UsersList from '../../features/profile/user-list/UsersList';
import UserProfiles from '../../features/profile/user-profiles/UserProfiles';
import { UserProfile } from '../../features/profile/user-profiles/user-profiles.types';
import useTable from '../../hooks/useTable';
import { initialPaginationState } from './profiles.constants';
import { RootStyle } from './profiles.styles';

export default function Profiles() {
  const { t } = useTranslation();
  const [filterKeyword, setfilterKeyword] = useState('');
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(undefined);
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const [mainProfile, setmainProfile] = useState<UserProfile | undefined>(undefined);
  // ______________________________________________________

  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
  };

  const { page, order, orderBy, rowsPerPage } = useTable({ ...initialPaginationState, defaultRowsPerPage: 6 });

  const {
    data,
    error,
    loading: isFetchingUsers,
  } = useGetListUsersQuery({ page, itemsPerPage: rowsPerPage, orderBy, order, keyword: debouncedSearchTerm });
  const { getUserProfiles, userProfiles, loading: isFetchingProfiles } = useGetUserProfilesQuery();

  const onSelectUser = (id: number) => {
    setSelectedUserId(id);
    getUserProfiles(id);
  };

  useEffect(() => {
    const mainProfil = userProfiles?.find((profile) => profile.mail !== null);
    setmainProfile(mainProfil);
  }, [userProfiles]);

  return (
    <RootStyle>
      <ProfileListToolbar />
      <Container sx={{ minHeight: '78vh' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <UsersList
              users={data}
              filterKeyword={filterKeyword}
              onFilterKeyword={onFilterKeyword}
              selectedUserId={selectedUserId}
              onSelectUser={onSelectUser}
              isFetchingUsers={isFetchingUsers}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <UserProfiles profiles={userProfiles} mainProfile={mainProfile} isFetchingProfiles={isFetchingProfiles} />
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
