/* eslint-disable @typescript-eslint/no-explicit-any */
import { Transactions } from '../../../models/Transactions';
import {
  ActionTypes,
  IFetchTransactionReportRejectedAction,
  ITransactionReportSuccessAction,
  ITransactionReportPendingAction,
  ITransactionReportResponse,
  ITransactionReportState,
  TransactionReportActionTypes,
} from './TransactionReports-slice.types';

export const initialState: ITransactionReportState = {
  isLoading: false,
  error: null,
  data: null,
  transactionUsers: null,
};

export const transactionReportReducer = (state = initialState, action: TransactionReportActionTypes): ITransactionReportState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_TRANSACTION_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload as ITransactionReportResponse,
      };
    case ActionTypes.FETCH_TRANSACTION_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_TRANSACTION_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transactionUsers: action.payload as Transactions,
      };
    default:
      return state;
  }
};

const matchPending = (): ITransactionReportPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (data: ITransactionReportResponse, action: 'fetch'): ITransactionReportSuccessAction | Transactions => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_TRANSACTION_REPORT_SUCCESS : ActionTypes.CREATE_TRANSACTION_REPORT_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchTransactionReportRejectedAction => {
  return {
    type: ActionTypes.FETCH_TRANSACTION_REPORT_FAILURE,
    payload: error,
  };
};

export const transactionReportActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
