import React from 'react';
import { Grid } from '@mui/material';
import { DATA } from '../../../../store/flags/models';
import { CONSTANTS } from '../../../../utils/constants';
import { ensureJson } from '../../../../utils';

interface FlagLinkPublicationDialogProps {
  flag: DATA;
}

// Link Publication Dialog
function FlagLinkPublicationDialog(props: FlagLinkPublicationDialogProps) {
  const flag = props.flag;
  return (
    <div>
      <>
        <Grid display="flex" style={{ justifyItems: 'center', alignContent: 'center', marginTop: '10%' }}>
          <a
            style={{ marginTop: '50%', cursor: 'pointer' }}
            onClick={() => {
              window.open(ensureJson(flag.flaggedPublication.text).url);
            }}
          >
            {ensureJson(flag.flaggedPublication.text).url}
          </a>
        </Grid>
      </>
    </div>
  );
}

export default FlagLinkPublicationDialog;
