/* eslint-disable @typescript-eslint/no-explicit-any */
import { PendingAds } from '../../../models/PendingAds';
import {
  ActionTypes,
  IFetchPendingAdsRejectedAction,
  IFetchPendingAdsSuccessAction,
  IPendingAdsPendingAction,
  IPendingAdsResponse,
  IPendingAdsState,
  PendingAdsActionTypes,
} from './PendingAds-slice.types';

export const initialState: IPendingAdsState = {
  isLoading: false,
  error: null,
  data: null,
  pendingUsers: null,
};

export const pendingAdsReducer = (state = initialState, action: PendingAdsActionTypes): IPendingAdsState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_PENDING_ADS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload as IPendingAdsResponse,
      };
    case ActionTypes.FETCH_PENDING_ADS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_PENDING_ADS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pendingUsers: action.payload as PendingAds,
      };
    default:
      return state;
  }
};

const matchPending = (): IPendingAdsPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (data: IPendingAdsResponse, action: 'fetch' | 'update'): IFetchPendingAdsSuccessAction | PendingAds => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_PENDING_ADS_SUCCESS : ActionTypes.CREATE_PENDING_ADS_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchPendingAdsRejectedAction => {
  return {
    type: ActionTypes.FETCH_PENDING_ADS_FAILURE,
    payload: error,
  };
};

export const pendingAdsActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
