import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
// import { useDispatch } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { PATHS } from '../../../utils';
import { FormikHelpers } from 'formik';
import { theme } from '../../../theme/theme';
import logo from '../../../assets/images/logo.png';
import { Spinner } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/rootStore';
import { useForgotPassword } from '../../../store/forgotpassword/actions';
import { AuthActionTypes } from '../../../store/forgotpassword/models';
import { ThunkDispatch } from 'redux-thunk';
import { useTranslation } from 'react-i18next';

interface FormValues {
  mail: string;
}

const ForgetPassword: React.FC = () => {
  const { t } = useTranslation();

  const { forgotPasswordFun } = useForgotPassword();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AuthActionTypes>>();
  const forgotPassword = useSelector((state: RootState) => state.forgotpass.forgotPassword);
  const { loader } = forgotPassword;

  const initialValues: FormValues = {
    mail: '',
  };

  const validationSchema = Yup.object({
    mail: Yup.string().trim().email(t('adds.form_fields.mail.invalid_error_message')).required(t('adds.form_fields.mail.required_error_message')),
  });

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    dispatch(forgotPasswordFun(values));
    setSubmitting(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Container maxWidth="xs" className="artgapi-forgot-password">
        <Box
          sx={{
            textAlign: 'center',
            mb: 4,
          }}
        >
          <Box
            component="img"
            sx={{
              width: '156.6px',
              height: '70px',
              marginBottom: '32px',
            }}
            alt="Your logo."
            src={logo}
          />
          <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#fff' }}>
            Mot de passe oublié?
          </Typography>
        </Box>

        <Box
          sx={{
            bgcolor: '#f5f5f5',
            p: 4,
            borderRadius: '0px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}
        >
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <Typography variant="subtitle1" gutterBottom>
                  Adresse e-mail
                </Typography>
                <Field
                  as={TextField}
                  name="mail"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="large"
                  autoComplete="off"
                  style={{
                    '& .MuiOutlinedInput-root': {
                      width: '100% !important',
                    },
                  }}
                  helperText={
                    <div style={{ color: '#ff453a' }}>
                      <ErrorMessage name="mail" component="div" />
                    </div>
                  }
                />

                <Button
                  type="submit"
                  style={{ opacity: loader ? 0.5 : 1 }}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={isSubmitting}
                  sx={{
                    mt: 2,
                    backgroundColor: '#4182EB',
                    opacity: '1',
                    '&:hover': {
                      backgroundColor: '#4182EB !important',
                    },
                  }}
                >
                  Envoyer un lien
                </Button>

                <Button
                  component={RouterLink}
                  to={PATHS.SignIn}
                  variant="outlined"
                  size="large"
                  fullWidth
                  sx={{ mt: 2, opacity: '1', '&:hover': { border: '1px solid rgba(20, 20, 21, 0.5) !important' } }}
                >
                  {t('common.cancel')}
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
      <Spinner loading={loader} />
    </Box>
  );
};

export default ForgetPassword;
