/* eslint-disable @typescript-eslint/no-explicit-any */
import { CONSTANTS } from '../../utils';

export interface Description {
  label: string;
  type: string;
  height: number;
  width: string;
  choices?: { label: string; value: string; id?: number }[];
  choicesTypes?: { label: string; value: string; id?: number }[];
  filterBy?: string;
  value: string;
}

export const CandidatsHeader: any = [
  {
    label: 'avatar',
    type: 'image',
    height: 50,
    width: '5%',
    value: 'avatar',
  },
  {
    label: 'lastName',
    type: 'normal',
    height: 50,
    width: '25%',
    value: 'surname',
  },
  {
    label: 'firstName',
    type: 'normal',
    height: 50,
    width: '25%',
    value: 'name',
  },
  {
    label: 'mail',
    type: 'normal',
    height: 50,
    width: '20%',
    value: 'mail',
  },
  {
    label: 'lab',
    type: 'normal',
    height: 50,
    width: '35%',
    value: 'lab',
  },
  {
    label: 'phoneNumber',
    type: 'normal',
    height: 50,
    width: '20%',
    value: 'phoneNb',
  },
];
