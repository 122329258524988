/* eslint-disable @typescript-eslint/no-explicit-any*/
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import Admin from '../../models/Admin';
import { CONSTANTS, ENDPOINTS, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { PaginationActionTypes } from '../pagination/models';
import { GET_TOTAL_PAGES } from '../pagination/types';
import { RootState } from '../rootStore';
import { SnackBarActionTypes } from '../snackBar/models';
import { SHOW_SNACK_BAR } from '../snackBar/types';
import { usersActionTypes, UsersPaginated } from './models';
import { GET_USERS_ATTEMPT, GET_USERS_ERROR, GET_USERS_SUCCESS } from './types';

export const getUsers =
  (data: UsersPaginated) => async (dispatch: ThunkDispatch<RootState, undefined, usersActionTypes | PaginationActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: GET_USERS_ATTEMPT,
    });

    sendAsyncRequest(HttpMethod.Get, ENDPOINTS.USERS, {}, true, { ...data })
      .then((resultat: AxiosResponse) => {
        const adminTable: Admin[] = [];

        if (resultat.data.users && resultat.data.users?.length > 0) {
          dispatch({
            type: GET_TOTAL_PAGES,
            total: resultat.data.total,
          });
          resultat.data.users.map((item: any) => {
            const user: any = { ...item };

            if (user) {
              user.fullname = item.fullname;
              user.nickname = item.nickname;
              const adminSubscriptions: string[] = [];
              if (user.Subscriptions && user.Subscriptions?.length > 0) {
                user.Subscriptions.map((sub: any) => {
                  const abonnements: any = { ...sub };
                  adminSubscriptions.push(abonnements.Plan.title);
                });
              } else if (user.Subscriptions && user.Subscriptions?.length === 0) {
                adminSubscriptions.push('Standard');
              }
              user.Subscriptions = adminSubscriptions;
            }
            adminTable.push({ ...user });
          });
        } else {
          dispatch({
            type: GET_TOTAL_PAGES,
            total: resultat.data.total,
          });
        }
        dispatch({
          type: GET_USERS_SUCCESS,
          users: [...adminTable],
        });
      })
      .catch((error) => {
        if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
          localStorage.clear();
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: CONSTANTS.SESSION_EXPIRED,
              severity: 'error',
              icon: 'ErrorICon',
              size: 'medium',
            },
          });
          dispatch({
            type: GET_USERS_ERROR,
            error: error,
          });
        } else {
          dispatch({
            type: GET_USERS_ERROR,
            error: error,
          });
        }
      });
  };
