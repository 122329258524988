import React from 'react';

export const DateIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5" y="7" width="20" height="18" rx="2" stroke="#A4A4A5" strokeWidth="2" />
      <path d="M5 12.61h20" stroke="#A4A4A5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 4v3M19 4v3" stroke="#A4A4A5" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default DateIcon;
