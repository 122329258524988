import React from 'react';
import { Button, TableRow, Stack, IconButton, Tooltip } from '@mui/material';
import { TableCellStyle } from './AdsUsersViewRow.styles';
import { AdsUsersRowProps } from './AdsUsersViewRow.types';
import EyeIcon from '../../../svgComponents/EyeIcon';
import { useTranslation } from 'react-i18next';

export default function AdsusersViewTableRow({ row, selected, columnsConfig, onShowDetails }: AdsUsersRowProps) {
  const { id, endTime, startTime, status, isActive } = row;
  const { t } = useTranslation();

  const formatDate = (dateString: string) => {
    const dateTime = new Date(dateString);
    const formattedDate = `${dateTime.toLocaleDateString()} à ${dateTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`;
    return formattedDate;
  };

  return (
    <TableRow key={id} hover selected={selected} sx={{ backgroundColor: '#ffffff' }}>
      {columnsConfig.map((column) => (
        <TableCellStyle key={column.id} align={column.align || 'left'} width={column.width}>
          {column.id === 'status' ? (
            <Button
              variant="contained"
              size="small"
              style={{
                color: '#fff',
                backgroundColor:
                  status === 1 && isActive === 1
                    ? '#1AB53C'
                    : status === 2 && isActive === 0
                    ? '#DF3131'
                    : status === 0 && isActive === 0
                    ? '#FFD45A'
                    : '#AEAEAE',
              }}
              disabled
            >
              {status === 1 && isActive === 1
                ? t('adds.active')
                : status === 2 && isActive === 0
                ? t('adds.rejected')
                : status === 0 && isActive === 0
                ? t('adds.pending')
                : t('adds.expired')}
            </Button>
          ) : column.id === 'endTime' && endTime ? (
            formatDate(endTime)
          ) : column.id === 'startTime' && startTime ? (
            formatDate(startTime)
          ) : column.id !== 'actions' ? (
            String(row[column.id as keyof typeof row])
          ) : (
            <Stack direction="row" spacing={2}>
              <Tooltip title="View Details" arrow>
                <IconButton
                  onClick={() => onShowDetails(id, true)}
                  sx={{
                    padding: '0px !important',
                  }}
                >
                  <EyeIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </TableCellStyle>
      ))}
    </TableRow>
  );
}
