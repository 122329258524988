import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import './App.css';
import './custom.css';
import { CostumeSnackBar } from './components/snackbar/CostumeSnackBar';
import RoutesList from './routes/routes';
import { getCountries } from './store/countries/actions';
import { countriesActionTypes } from './store/countries/models';
import { RootState } from './store/rootStore';
import { useLocation } from 'react-router-dom';

function App() {
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, countriesActionTypes>>();
  const location = useLocation(); // Get current location

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(getCountries());
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, [location.pathname]); // Trigger on pathname change

  return (
    <div className="App">
      <CostumeSnackBar />
      <RoutesList />
    </div>
  );
}

export default App;
