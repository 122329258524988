/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Container, Dialog, DialogContent, Grid, ThemeProvider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { deleteCommentaire, deletePublication, deleteuser } from '../../../store/flags/action';
import { DATA, flagsActionsTypes } from '../../../store/flags/models';
import { RootState } from '../../../store/rootStore';
import { dialogTheme } from '../../../theme/dialogTheme';
import { CONSTANTS } from '../../../utils';
import DialogHeader from '../dialogHeader/dialogHeader';
import Spinner from '../../spinner';
import { FlagPubicationDialog } from './flagPublication/flagPubicationDialog';
import FlagCommentDialog from './flagComment/flagCommentDialog';
import FlagCommentResponseDialog from './flagComment/flagCommentResponse';
import FlagUserDialog from './flagUser/flagUserDialog';
import FlagArticleCommentDialog from './flagArticleComment/flagArticleComment';

interface FlagDialogProps {
  show: boolean;
  data: any;
  setOpenEdit: any;
  statusTable?: string;
  type?: string;
  loadingState?: boolean;
}

export const FlagDialog = (props: FlagDialogProps) => {
  const { show, data, setOpenEdit, statusTable, loadingState, type } = props;
  const flag = useSelector((state: RootState) => state.flags.flag);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, flagsActionsTypes>>();
  const closeModal = () => {
    setOpenEdit(!show);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const deletePubUserOrCommentFlag = (flag: DATA) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    flag && flag?.flaggedPublication
      ? dispatch(deletePublication(flag?.flaggedPublication.id))
      : flag && flag?.flaggedUser
      ? dispatch(deleteuser(flag?.flaggedUser.id))
      : flag && flag.flaggedPublicationComment
      ? dispatch(deleteCommentaire(flag?.flaggedPublicationComment.id))
      : '';
    closeModal();
  };
  // const conditionalClass = !flag?.flaggedPublication?.images ? 'no-image-found' : '';

  return (
    <>
      <ThemeProvider theme={dialogTheme}>
        <Dialog open={show}>
          <DialogHeader
            onClose={closeModal}
            title={''}
            data={data}
            status={''}
            flag={flag}
            name={CONSTANTS.Flags}
            deletePubUserOrCommentFlag={deletePubUserOrCommentFlag}
            statusTable={statusTable}
            loadingState={loadingState}
            type={type}
          />

          <DialogContent sx={{ backgroundColor: '#f5f5f5' }} className={'no-image-found'}>
            {loadingState ? (
              <Container sx={{ minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
                <Spinner loading={loadingState} />
              </Container>
            ) : (
              <Grid container sx={{ ml: 50, justifyContent: 'center', marginLeft: '0px !important' }}>
                {flag.flaggedPublication ? (
                  <FlagPubicationDialog flag={flag} />
                ) : flag.flaggedPublicationComment ? (
                  <FlagCommentDialog flag={flag} />
                ) : flag.flaggedPublicationCommentResponse ? (
                  <FlagCommentResponseDialog flag={flag} />
                ) : flag.flaggedUser ? (
                  <FlagUserDialog flag={flag} />
                ) : flag.flaggedArticleComment ? (
                  <FlagArticleCommentDialog flag={flag} />
                ) : (
                  <></>
                )}
              </Grid>
            )}
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default FlagDialog;
