/* eslint-disable @typescript-eslint/no-explicit-any */
import { SubscriptionPlan } from '../../../models/SubscriptionPlan';
import {
  ActionTypes,
  IFetchSubscriptionPlanRejectedAction,
  IFetchSubscriptionPlanSuccessAction,
  ISubscriptionPlanPendingAction,
  ISubscriptionPlanResponse,
  ISubscriptionPlanState,
  SubscriptionPlanActionTypes,
} from './SubscriptionPlan-slice.types';

export const initialState: ISubscriptionPlanState = {
  isLoading: false,
  error: null,
  palnListing: null,
  subscriptionPlanUsers: null,
};

export const subscriptionPlanReducer = (state = initialState, action: SubscriptionPlanActionTypes): ISubscriptionPlanState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        palnListing: action.payload as ISubscriptionPlanResponse,
      };
    case ActionTypes.FETCH_SUBSCRIPTION_PLAN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subscriptionPlanUsers: action.payload as SubscriptionPlan,
      };
    default:
      return state;
  }
};

const matchPending = (): ISubscriptionPlanPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (data: ISubscriptionPlanResponse, action: 'fetch' | 'update'): IFetchSubscriptionPlanSuccessAction | SubscriptionPlan => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_SUBSCRIPTION_PLAN_SUCCESS : ActionTypes.CREATE_SUBSCRIPTION_PLAN_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchSubscriptionPlanRejectedAction => {
  return {
    type: ActionTypes.FETCH_SUBSCRIPTION_PLAN_FAILURE,
    payload: error,
  };
};

export const SubscriptionPlanActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
