import React from 'react';

export const CloseIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 10.607 8.834 13 13 6" stroke="#fff" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default CloseIcon;
