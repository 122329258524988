import { Article, ArticlesActionTypes, ArticlesState } from './models';
import {
  ADD_ARTICLE,
  CHANGE_ARTICLE_STAUTS,
  CREATE_ARTICLE_SUCCESS,
  DELETE_ARTICLE,
  GETTING_ARTICLES,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLE_SUCCESS,
} from './types';

const INITIAL_STATE: ArticlesState = {
  articles: [] as Article[],
  article: {} as Article,
  loading: true,
  loadingDetailArticle: true,
  addLoading: false,
  error: '',
};
export const articleReducer = (state: ArticlesState = INITIAL_STATE, action: ArticlesActionTypes): ArticlesState => {
  switch (action.type) {
    case GETTING_ARTICLES:
      return {
        ...state,
        loading: true,
      };
    case GET_ARTICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        articles: action.payload,
      };
    case GET_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        article: action.payload,
      };
    case ADD_ARTICLE:
      return {
        ...state,
        loading: false,
      };
    case CREATE_ARTICLE_SUCCESS:
      return {
        ...state,
        article: action.article,
      };
    case DELETE_ARTICLE:
      return {
        ...state,
        loading: false,
      };
    case CHANGE_ARTICLE_STAUTS:
      return {
        ...state,
        loading: false,
        article: action.payload,
      };
    default:
      return state;
  }
};
