/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Stack, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AlertIcon } from '../../../../../assets/icons/alert-danger.svg';
import { ConfirmAcceptRejectProps } from './confirm-accept-reject-dialog.types';
import Iconify from '../../../../Iconify';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmAcceptReject({ onClose, open, onAcceptReject, isAcceptReject }: ConfirmAcceptRejectProps) {
  const { t } = useTranslation();
  return (
    <>
      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={onClose} fullWidth>
        <DialogTitle>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {isAcceptReject == 1 ? t('adds.confirm_accept') : t('adds.confirm_reject')}
            </Typography>
            <Iconify icon={AlertIcon} />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{isAcceptReject == 1 ? t('adds.confirm_accept_message') : t('adds.confirm_reject_message')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onAcceptReject}
            sx={{
              backgroundColor: isAcceptReject == 1 ? '#4caf50' : '#f44336',
              borderRadius: '0px',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: isAcceptReject == 1 ? '#4caf50' : '#f44336',
                color: '#FFFFFF',
              },
            }}
          >
            {isAcceptReject == 1 ? t('adds.accept') : t('adds.reject')}
          </Button>
          <Button
            onClick={onClose}
            sx={{
              backgroundColor: '#ebe5e5',
              borderRadius: '0px',
              color: '#000000',
              '&:hover': {
                backgroundColor: '#ebe5e5',
                color: '#000000',
              },
            }}
          >
            {t('common.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
