import { permissionActionTypes, UserState } from './models';
import { GET_PERMISSION_ATTEMPT, GET_PERMISSION_ERROR, GET_PERMISSION_SUCCESS } from './types';

const INITIAL_STATE: UserState = {
  Permission: {
    loader: false,
    error: '',
    userPermission: undefined,
  },
};
export const permissionReducer = (state = INITIAL_STATE, action: permissionActionTypes): UserState => {
  switch (action.type) {
    case GET_PERMISSION_ATTEMPT:
      return { ...state, Permission: { ...state.Permission, loader: true, error: '' } };
    case GET_PERMISSION_SUCCESS:
      return { ...state, Permission: { ...state.Permission, loader: false, userPermission: action.userPermission } };
    case GET_PERMISSION_ERROR:
      return { ...state, Permission: { ...state.Permission, loader: false, error: action.error } };

    default:
      return state;
  }
};
