import { PasswordDialogActionTypes, PasswordDialogState } from './models';
import produce from 'immer';
import { HIDE_DIALOG, SHOW_DIALOG } from './types';

const INITIAL_STATE: PasswordDialogState = {
  open: false,
};
export const passwordDialogReducer = (state = INITIAL_STATE, action: PasswordDialogActionTypes): PasswordDialogState =>
  produce(state, (draft) => {
    switch (action.type) {
      case SHOW_DIALOG:
        draft.open = true;
        break;
      case HIDE_DIALOG:
        draft.open = false;
        break;
    }
  });
