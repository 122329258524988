/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { RootState } from '../../rootStore';
import { IFetchApprovedAdsSuccessAction, ApprovedAdsActionTypes } from '../slice/ApprovedAds-slice.types';
import { approvedAdsActions, approvedAdsReducer } from '../slice/ApprovedAds-slice';

export const approvedAdsApi = {
  reducerPath: 'approvedUsers',
  reducer: approvedAdsReducer,
  endpoints: {
    getApprovedAdsUsers: (data: any) => async (dispatch: ThunkDispatch<RootState, undefined, ApprovedAdsActionTypes>) => {
      dispatch(approvedAdsActions.matchPending());
      sendAsyncRequest(HttpMethod.Post, ENDPOINTS.APPROVED_ADS_USERS, data, true, {})
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(approvedAdsActions.matchFulFilled(responseBody, 'fetch') as IFetchApprovedAdsSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(approvedAdsActions.matchRejected(error));
        });
    },
  },
};
