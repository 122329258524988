/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { RootState } from '../../rootStore';
import { IFetchAdvertisementsSuccessAction, AdvertisementsActionTypes } from '../slice/Advertisments-slice-types';
import { advertisementsActions, advertisementsReducer } from '../slice/Advertisements-slice';

export const advertisementsApi = {
  reducerPath: 'advertisements',
  reducer: advertisementsReducer,
  endpoints: {
    getAdvertisementsLists: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, AdvertisementsActionTypes>) => {
      dispatch(advertisementsActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.ADVERTISEMENTS, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(advertisementsActions.matchFulFilled(responseBody, 'fetch') as IFetchAdvertisementsSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(advertisementsActions.matchRejected(error));
        });
    },

    createAdvertisements: (data: any) => async (dispatch: ThunkDispatch<RootState, undefined, AdvertisementsActionTypes>) => {
      dispatch(advertisementsActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Post, ENDPOINTS.ADVERTISEMENTS, data, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(advertisementsActions.matchFulFilled(responseBody, 'create') as IFetchAdvertisementsSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(advertisementsActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },

    updateAdvertisements: (id: number, data: any) => async (dispatch: ThunkDispatch<RootState, undefined, AdvertisementsActionTypes>) => {
      dispatch(advertisementsActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Put, `${ENDPOINTS.ADVERTISEMENTS}/${id}`, data, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(advertisementsActions.matchFulFilled(responseBody, 'update') as IFetchAdvertisementsSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(advertisementsActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },

    deleteAdvertisements: (id: number) => async (dispatch: ThunkDispatch<RootState, undefined, AdvertisementsActionTypes>) => {
      dispatch(advertisementsActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Delete, `${ENDPOINTS.ADVERTISEMENTS}/${id}`, {}, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(advertisementsActions.matchFulFilled(responseBody, 'delete') as IFetchAdvertisementsSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(advertisementsActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },
  },
};
