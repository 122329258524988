/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { RootState } from '../../rootStore';
import { IFetchRevenueReportsSuccessAction, RevenueReportsActionTypes } from '../slice/RevenueReports-slice.types';
import { revenueReportsActions, revenueReportReducer } from '../slice/RevenueReports-slice';

export const revenuReportsApi = {
  reducerPath: 'revenueReports',
  reducer: revenueReportReducer,
  endpoints: {
    getRevenueReportsUsers: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, RevenueReportsActionTypes>) => {
      dispatch(revenueReportsActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.GET_REVENUE_REPORTS, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(revenueReportsActions.matchFulFilled(responseBody, 'fetch') as IFetchRevenueReportsSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(revenueReportsActions.matchRejected(error));
        });
    },

    getExportUsers: () => async (dispatch: ThunkDispatch<RootState, undefined, RevenueReportsActionTypes>) => {
      dispatch(revenueReportsActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.PENDING_ADS_USERS, {}, true, {})
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(revenueReportsActions.matchFulFilled(responseBody, 'fetch') as IFetchRevenueReportsSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(revenueReportsActions.matchRejected(error));
        });
    },
  },
};
